import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';
import themeVars from 'assets/scss/_theme-vars.module.scss';
import { pxToRem } from 'utils/mui';

const Chip = (theme: Theme): Components<Theme> => ({
  MuiChip: {
    defaultProps: {
      variant: 'filled',
      size: 'medium',
      color: 'default',
      deleteIcon: <FontAwesomeIcon icon={faClose} />,
    },

    styleOverrides: {
      root: {
        '&.MuiChip-filledDefault': {
          backgroundColor: theme.palette.primary.lighter,
        },
        /**
         * className variants
         *
         */
        '&.chip-badge': {
          borderRadius: theme.customBorderRadius.xs,
          padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
          ...theme.typography.body2,

          '& .MuiChip-label': {
            fontWeight: theme.typography.fontWeightMedium,
          },

          '&--default': {
            backgroundColor: theme.palette.gray.lighter,
            color: theme.palette.gray.dark,
          },
          '&--info': {
            backgroundColor: theme.palette.info.lighter,
            color: theme.palette.info.dark,
          },
          '&--success': {
            backgroundColor: theme.palette.success.lighter,
            color: theme.palette.success.dark,
          },
          '&--warning': {
            backgroundColor: theme.palette.warning.lighter,
            color: theme.palette.warning.dark,
          },
          '&--danger': {
            backgroundColor: theme.palette.error.lighter,
            color: theme.palette.error.dark,
          },
        },

        /**
         * Depreciated classname variants
         * Note: These should not be used and will be removed in future releases
         */
        '&.custom-chip-label': {
          borderRadius: '0px !important',
        },
        '&.custom-chip-label-outlined': {
          // TODO: Might need to change
          background: '#d8f9ff',
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          borderRadius: '0px !important',
        },
        '&.custom-chip-times': {
          background: theme.palette.gray.lighter,
          borderRadius: '5px',
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,

          '&:hover': {
            background: theme.palette.gray.light,
          },
          svg: {
            width: pxToRem(+themeVars.body1FontSize),
            height: pxToRem(+themeVars.body1FontSize),
            color: theme.palette.secondary.light,
            '&:hover': {
              color: theme.palette.secondary.main,
            },
          },
        },
        '&.chip-secondary': {
          background: theme.palette.secondary.main,
          color: theme.palette.primary.lighter,
        },
        '&.chip-primary': {
          background: theme.palette.primary.main,
          color: theme.palette.primary.lighter,
        },
        /** ******************************************** */
      },

      // Find out why styles to the label are not being overridden
      label: {
        ...theme.typography.body2,
        padding: 0,
        fontWeight: theme.typography.fontWeightRegular,
      },

      sizeMedium: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      },

      sizeSmall: {
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      },

      deleteIcon: {
        color: theme.palette.gray.main,
        fontSize: 16,
        marginLeft: theme.spacing(1),
        marginRight: 0,
      },
    },
  },
});

export default Chip;
