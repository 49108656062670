import {
  BloodGroup,
  ClientEnrollmentStatus,
  RelationshipToPrimaryMember,
} from 'enums/client-management';

const {
  AB_NEGATIVE,
  AB_POSITIVE,
  A_NEGATIVE,
  A_POSITIVE,
  B_NEGATIVE,
  B_POSITIVE,
  O_NEGATIVE,
  O_POSITIVE,
  UNK,
} = BloodGroup;

const { CHILD, DEPENDENT, OTHERS, PRIMARY, SPOUSE } =
  RelationshipToPrimaryMember;

export const relationshipOptions = [
  {
    id: 1,
    label: PRIMARY,
    value: PRIMARY,
  },
  {
    id: 2,
    label: SPOUSE,
    value: SPOUSE,
  },
  {
    id: 3,
    label: CHILD,
    value: CHILD,
  },
  {
    id: 4,
    label: OTHERS,
    value: OTHERS,
  },
];

export const relationshipOptionForAdvanceFiltering = [
  {
    id: 1,
    label: PRIMARY,
    value: PRIMARY,
  },
  {
    id: 2,
    label: DEPENDENT,
    value: DEPENDENT,
  },
];

export const bloodGroupOptions = [
  { label: A_POSITIVE, value: A_POSITIVE },
  { label: A_NEGATIVE, value: A_NEGATIVE },
  { label: B_POSITIVE, value: B_POSITIVE },
  { label: B_NEGATIVE, value: B_NEGATIVE },
  { label: AB_POSITIVE, value: AB_POSITIVE },
  { label: AB_NEGATIVE, value: AB_NEGATIVE },
  { label: O_POSITIVE, value: O_POSITIVE },
  { label: O_NEGATIVE, value: O_NEGATIVE },
  { label: UNK, value: UNK },
];

export const bloodGroupList = [
  A_POSITIVE,
  A_NEGATIVE,
  B_POSITIVE,
  B_NEGATIVE,
  AB_POSITIVE,
  AB_NEGATIVE,
  O_POSITIVE,
  O_NEGATIVE,
  UNK,
];

const {
  ALL,
  ACTIVE,
  INACTIVE,
  APPROVED,
  DECLINED,
  CANCELLED,
  COMPLETED,
  SUBMITTED,
} = ClientEnrollmentStatus;

export const clientStatus = (isCompleteMembershipEnabled: boolean) => [
  {
    id: 1,
    value: ALL,
    label: ALL,
  },
  {
    id: 2,
    value: ACTIVE,
    label: ACTIVE,
  },
  {
    id: 3,
    value: INACTIVE,
    label: INACTIVE,
  },
  {
    id: 4,
    value: APPROVED,
    label: APPROVED,
  },
  {
    id: 5,
    value: DECLINED,
    label: DECLINED,
  },
  {
    id: 6,
    value: isCompleteMembershipEnabled ? COMPLETED : CANCELLED,
    label: isCompleteMembershipEnabled ? COMPLETED : CANCELLED,
  },
  {
    id: 7,
    value: SUBMITTED,
    label: SUBMITTED,
  },
];
