import { memo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CometChat } from '@cometchat-pro/chat';
import {
  faClose,
  faPaperclip,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import LoadingIndicator from 'common/LoadingIndicator';
import { CustomIconButton } from 'features/cases/common';
import { ISelectedUser } from 'interfaces/chat';
import { IResponseMessage } from 'interfaces/virtual-visit/comet-chat';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import {
  addMessage,
  selectFileUpload,
  selectIsContentLoading,
  selectMessages,
  selectSelectedFriend,
  setIsWindowOpen,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import { getFirstLetterOfFirstAndLastName } from 'utils/common';
import { getCurrentTime } from 'utils/moment';

import { ChatItem } from './ChatItem';
import ChatUploadDocumentsModal from './FileUploadModal';

const ChatContent = () => {
  const timeStamp = getCurrentTime();
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);
  const messages = useAppSelector(selectMessages);
  const messageRef = useRef<HTMLDivElement | any>(null);
  const dispatch = useDispatch();
  const selectedUser = useAppSelector<ISelectedUser | null>(
    selectSelectedFriend
  );
  const isContentLoading = useAppSelector(selectIsContentLoading);
  const [openFileModal, setOpenFileModal] = useState(false);
  const uploadingFiles = useAppSelector(selectFileUpload);

  const onPressSend = async () => {
    const message = messageRef?.current?.value ?? '';
    if (!message) return;

    await CometChatService.sendTextMessage({
      receiverType: CometChat.RECEIVER_TYPE.USER,
      guId: selectedUser?.uid ?? '',
      receiverID: selectedUser?.uid ?? '',
      message,
    }).then(({ id, initiatorName }: { id: string; initiatorName: string }) => {
      dispatch(
        addMessage({
          payload: {
            id,
            text: message,
            isSentByMe: true,
            isTextMessage: true,
            time: timeStamp?.toUpperCase(),
            sender: initiatorName,
          },
        })
      );
      messageRef.current.value = '';
    });
  };

  if (isContentLoading)
    return (
      <Box width={380}>
        <LoadingIndicator />
      </Box>
    );

  return (
    <>
      {openFileModal && (
        <ChatUploadDocumentsModal
          eventId={selectedUser?.uid ?? ''}
          handleClose={() => setOpenFileModal(false)}
        />
      )}
      {selectedUser ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={2}
          width={380}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack alignItems="center" direction="row">
              <Avatar
                sx={{
                  alignSelf: 'flex-start',
                  marginRight: theme.spacing(1),
                  width: 36,
                  height: 36,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  gutterBottom={false}
                  textTransform="uppercase"
                  variant="body2"
                >
                  {getFirstLetterOfFirstAndLastName(
                    selectedUser?.demographic?.fullName ||
                      selectedUser?.name ||
                      ''
                  )}
                </Typography>
              </Avatar>
              <Stack>
                <Typography
                  gutterBottom={false}
                  textTransform="capitalize"
                  variant="body2"
                >
                  {selectedUser?.demographic?.fullName ||
                    selectedUser?.name ||
                    ''}
                </Typography>
                <Typography
                  gutterBottom={false}
                  textTransform="capitalize"
                  variant="body2"
                >
                  {selectedUser?.conversationWith?.status ?? 'offline'}
                </Typography>
              </Stack>
            </Stack>
            <IconButton
              onClick={() => {
                dispatch(setIsWindowOpen(false));
              }}
              size="large"
              sx={{ alignSelf: 'flex-end', mb: 1 }}
            >
              <FontAwesomeIcon
                color={theme.palette.primary.main}
                icon={faClose}
              />
            </IconButton>
          </Box>
          <Stack height={380} mr={1} overflow="auto" pb={1}>
            <Box mt={2}>
              {messages?.length > 0 &&
                messages?.map((item: IResponseMessage) => (
                  <Box key={item.payload.id}>
                    <ChatItem item={item} scrollRef={scrollRef} />
                  </Box>
                ))}
            </Box>
          </Stack>
          {uploadingFiles.length > 0 &&
            uploadingFiles.map((index) => (
              <Stack key={`${index}`}>
                <Typography alignSelf="start">Sending File...</Typography>
                <Divider sx={{ marginY: theme.spacing(1) }} />
              </Stack>
            ))}
          <TextField
            inputRef={messageRef}
            multiline
            placeholder="Type a message"
            rows={1}
            sx={{ paddingTop: 1 }}
          />
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="flex-end"
            marginTop={theme.spacing(1)}
          >
            <CustomIconButton
              icon={faPaperclip}
              iconColor={theme.palette.common.black}
              onClick={() => setOpenFileModal(true)}
            />
            <IconButton
              onClick={onPressSend}
              sx={{ backgroundColor: theme.palette.primary.main }}
            >
              <FontAwesomeIcon
                color={theme.palette.common.white}
                icon={faPaperPlane}
              />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          justifyItems="center"
          width={380}
        >
          <Typography>Select a user to start a conversation</Typography>
        </Box>
      )}
    </>
  );
};

export default memo(ChatContent);
