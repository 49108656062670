import { Modal } from 'common';

import ChatUploadDocument from './FileUpload';

const ChatUploadDocumentsModal = ({
  eventId,
  handleClose,
}: {
  handleClose: () => void;
  eventId: string;
}) => (
  <Modal
    handleClose={handleClose}
    icon="faUpload"
    maxWidth="md"
    open
    title="Upload Files"
  >
    <ChatUploadDocument eventID={eventId || ''} handleClose={handleClose} />
  </Modal>
);

export default ChatUploadDocumentsModal;
