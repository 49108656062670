import { CometChat } from '@cometchat-pro/chat';
import axios from 'axios';
import { IAdaptedUserTable } from 'interfaces/chat';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import { store } from 'stores';
import {
  setIsContentLoading,
  setLastMessage,
  setMessages,
  setSelectedFriend,
} from 'stores/chat';
import { getCometChatUID } from 'utils/common';
import { formatTimeStampToString } from 'utils/moment';

export const uploadFileChat = (context: any, sessionID: string) =>
  CometChatService.sendFileMessageUser(context, sessionID);

export const extractTextFromMessage = (
  textMessage: CometChat.TextMessage
): string | null => {
  if (textMessage instanceof CometChat.TextMessage) {
    const messageDatas = textMessage.getData();
    if (messageDatas) {
      return messageDatas.text;
    }
  }
  return null;
};

export const onPressFriend = async (
  uid: string,
  item: IAdaptedUserTable,
  index: number,
  tenantUserId: string,
  gropuCode: string
) => {
try {
  store.dispatch(setSelectedFriend(JSON.stringify({ ...item, index })));

  const chat = await CometChatService.fetchMessageByUser(uid);
  const messages = chat;

  const mapMessage = await Promise.all(
    chat?.map(async (message) => {
      if (message.type === 'file') {
        const file = await axios.get(message?.data?.url);
        const { data: text, name } = file.data;
        const decryptedData = {
          payload: {
            id: message.id,
            text,
            uid: item?.uid,
            time: formatTimeStampToString(message?.updatedAt ?? ''),
            senderId: message?.sender?.uid,
            name: message?.sender?.name,
            sender: message?.sender?.name,
            fileName: name,
            isSentByMe:
              getCometChatUID(gropuCode, item?.uid)?.toLocaleLowerCase() ===
              message?.sender?.uid?.toLowerCase(),
            isTextMessage: false,
          },
        };
        return decryptedData;
      }

      const isSentByMe =
        uid?.toLowerCase() !== message?.sender?.uid?.toLowerCase();
      const payload = {
        payload: {
          id: message.id,
          text: message.text,
          isSentByMe,
          isTextMessage: true,
          time: formatTimeStampToString(message.updatedAt),
          sender: message.sender.name,
        },
      };
      return payload;
    })
  );

  if (messages?.length) {
    const lastMessage = messages[messages.length - 1];
    CometChat.markAsRead(lastMessage);
    store.dispatch(setLastMessage(lastMessage));
  }

  store.dispatch(
    setMessages({
      messages: mapMessage,
      uid: tenantUserId,
    })
  );
  store.dispatch(setIsContentLoading(false));
} catch (error) {
  store.dispatch(setIsContentLoading(false));
}
};
  