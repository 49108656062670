import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Stack,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';

interface IProps {
  text?: string;
  containerSx?: SxProps;
  iconSize?: SizeProp | undefined;
  textProps?: TypographyProps;
}

const NoRecordsFoundDisplay = ({
  text,
  containerSx,
  iconSize,
  textProps,
}: IProps): JSX.Element => (
  <Box
    sx={{
      backgroundColor: (theme) => theme.palette.common.white,
      padding: 2,
      ...containerSx,
    }}
  >
    <Stack direction="column" spacing={1}>
      <FontAwesomeIcon icon={faFileAlt} size={iconSize} />
      <Typography
        data-cy="no-record-found"
        display="block"
        gutterBottom={false}
        textAlign="center"
        {...textProps}
      >
        {text}
      </Typography>
    </Stack>
  </Box>
);

NoRecordsFoundDisplay.defaultProps = {
  text: 'No Records Found',
  containerSx: null,
  iconSize: 'lg',
  textProps: undefined,
};

export default NoRecordsFoundDisplay;
