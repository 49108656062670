const apiRoutes = {
  activityLog: {
    getAll: '/activity-logs',
  },
  appointments: {
    add: '/appointments',
    appointmentDetails: '/appointments/:id',
    changeStatus: '/appointments/:id/status',
    edit: '/appointments/:id',
    getAll: '/appointments',
    remove: '/appointments/:id',
  },
  cases: {
    add: '/referral-cases',
    addBloodDonors: '/referral-cases/:id/blood-donors',
    addNote: '/referral-cases/:id/notes',
    addFile: '/referral-cases/:id/files',
    addOrEditReferringPhysician: '/referral-cases/:id/referring-physician',
    addReferringProvider: '/referral-cases/:id/provider',
    updateReferringProvider: '/referral-cases/:id/provider/:providerId',
    assign: '/referral-cases/:id/assign',
    deleteBloodDonor: '/referral-cases/:id/blood-donors/:donorId',
    deleteReferringProvider: '/referral-cases/:id/provider/:providerId',
    deleteNote: '/referral-cases/:id/notes/:noteId',
    getNoteHistory: '/referral-cases/:id/notes/:noteId/history',
    deleteTask: '/tasks/:taskId',
    download: '/referral-cases/:id/files/:fileId/download',
    deleteFile: '/referral-cases/:id/files/:fileId',
    forward: '/referral-cases/:id/forward',
    get: '/referral-cases/:id',
    getGroupSelect: '/clients/:id/groups',
    getHistory: '/referral-cases/:id/files/:fileId/history',
    list: '/referral-cases',
    notify: '/comms/assign-referral-case',
    remainingTime: '/referral-cases/timesheets-sponsor',
    service: '/referral-cases/:id/services',
    share: '/referral-cases/:id/share',
    selectProvider: '/referral-cases/:id/provider/:providerId/select',
    tasks: '/tasks',
    timelog: '/referral-cases/:id/timesheets',
    updateBloodDonor: '/referral-cases/:id/blood-donors/:donorId',
    updateNote: '/referral-cases/:id/notes/:noteId',
    updatePatient: '/referral-cases/:id/patient',
    updateStatus: '/referral-cases/:id/status',
    updatetimelog: '/referral-cases/:id/timesheets/:timelogId',
    updateTask: '/tasks/:taskId',
    updateSelectedBloodDonors: '/referral-cases/:id/blood-donors/:donorId',
  },
  calender: {
    getAll: '/calendars',
    create: '/events',
    getOne: '/events/:id',
    editRecurring: '/events/recurring/:groudId',
    generateRecurringtext: '/events/recurring-text',
  },
  emailNotes: {
    add: '/referral-cases/:id/notes',
    get: '/referral-cases/:caseId/notes/:messageId',
  },
  documents: {
    add: '/documents',
    addTags: '/documents/:documentId',
    attach: '/documents/attach',
    convertToESign: '/documents/:documentId/e-sign/convert',
    delete: '/documents/:documentId/:associationId',
    detail: '/documents/:documentId',
    getESignEditLink: '/documents/:documentId/e-sign/edit-link',
    list: '/documents',
    rename: '/documents/:documentId',
    share: '/documents/:documentId/share',
  },
  bundle: {
    list: 'https://dev-api.vitafyhealth.com/api/bundles',
  },
  auth: {
    activateAccount: '/auth/activate-account',
    impersonate: '/auth/impersonate',
    userExists: '/users/exists',
    linkAccount: '/auth/link-account',
    revertImpersonate: '/auth/revert-impersonate',
    login: '/auth/login',
    forgetPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    setPassword: '/auth/set-password',
    verifyCode: '/auth/verify-code',
    twoFactorAuthentication: '/auth/two-factor-authentication',
  },
  billings: {
    invoices: {
      getAll: '/invoices',
      getOne: '/invoices/:invoiceId',
    },
    subscriptions: {
      getAll: '/subscriptions',
    },
    transactions: {
      addNote: '/transactions/:transactionId/notes',
      addTransaction: '/transactions',
      emailReceipt: '/transactions/:transactionId/send-receipt',
      getAll: '/transactions',
      getOne: '/transactions/:transactionId',
      refundableAmountCheck: '/transactions/:transactionId/refundable',
      refundTransaction: '/transactions/:transactionId/refund',
      reprocessTransaction: 'transactions/:transactionId/reprocess',
    },
    upcoming: {
      getAll: '/subscriptions',
      getDetails: '/subscriptions/:subscriptionId/details',
      updateStatus: '/subscriptions/:subscriptionId',
    },
  },
  clientAgreement: {
    decode: '/agreement/decode',
    saveAgreement: '/agreement',
    updateAgreement: 'lookup/:id',
    sendAgreement: '/agreement/send',
    verifyESignAgreement: '/agreement/e-sign',
  },
  clientManagement: {
    client: {
      add: '/clients',
      addClientEnrollment: '/clients/:id/enrollments',
      addClientAncillaryEnrollments: '/clients/:clientId/ancillary-services',
      approveEnrollment: '/clients/:id/enrollments/:enrollmentId/approve',
      cancelEnrollment: '/clients/:id/enrollments/:enrollmentId/cancel',
      changeEnrollment: '/clients/:id/enrollments/:enrollmentId/change',
      clientExits: '/clients/exists',
      completeEnrollment: '/clients/:id/enrollments/:enrollmentId/complete',
      declineEnrollment: '/clients/:id/enrollments/:enrollmentId/decline',
      edit: '/clients/:id',
      getAll: '/clients',
      getEnrollment: '/clients/:id/enrollments',
      getAllMemberships: '/clients/:id/memberships',
      getEnrollmentDetail: '/clients/:id/calculate-price/:enrollmentId',
      getOne: '/clients/:id',
      summary: '/clients/:id/dashboard-summary',
      restartEnrollment: '/clients/:id/enrollments/:enrollmentId/restart',
      updateEnrollment: '/clients/:id/enrollments/:enrollmentId',
    },
  },
  entity: { getAll: '/entities', getOne: '/entities/:id?type=:userType' },
  comms: {
    getAllCommunicationsOutbox: '/comms/outbox',
    getAllCommunicationsInbox: '/comms/inbox',
  },
  files: {
    download: '/files/download',
    upload: '/files',
  },
  langDict: {
    getAll: '/lang-dictionary/en/tenant',
  },
  interval: {
    getAll: '/interval',
  },
  otp: {
    generate: '/otp',
    decode: '/otp/decode',
    verify: '/otp/validate',
    exists: 'otp/exists',
  },
  payment: {
    addPaymentSource: '/merchant-customers/:clientId/payment-sources',
    deletePaymentSource:
      '/merchant-customers/:merchantCustomerId/payment-sources/:paymentSourceId',
    fetchMerchantProfile: 'merchants/:tenantId',
    getPaymentSources: '/merchant-customers',
    setPrimaryPaymentSource:
      '/merchant-customers/:merchantCustomerId/payment-sources/:paymentSourceId',
  },
  reports: {
    advocateCases: {
      getAll: '/reports/advocate-cases',
    },
    clients: {
      getAll: '/reports/clients',
    },
    commissionReport: {
      getAll: '/reports/commissions',
    },
    dashboard: {
      summary: '/reports/summary',
      getSalesRevenue: '/reports/sales-revenue',
      getRevenueSource: '/reports/revenue-source',
      getMembership: '/reports/membership',
    },
    salesByCompany: {
      getAll: '/reports/sales-by-company',
    },
    salesByRep: {
      getAll: '/reports/sales-by-rep',
      getDetail: '/reports/sales-by-rep-details',
    },
  },
  questionnaireRespondents: {
    getQuestionnaireResponse:
      '/respondents/:respondentId/questionnaires/Membership_Eligibility',
  },
  setting: {
    communication: {
      addEmailCommunicationTemplate: '/message-templates',
      editEmailCommunicationTemplate: '/message-templates/:id',
      getAll: '/message-templates',
      getEmailCommunicationTemplate: '/lookup',
      getOne: '/message-templates/:id',
      removeEmailCommunicationTemplate: '/message-templates/:id',
    },
    contacts: {
      add: '/contacts',
      edit: '/contacts/:id',
      getAll: '/contacts',
      getOne: '/contacts/:id',
      remove: '/contacts/:id',
    },
    labService: {
      add: '/lab-services',
      edit: '/lab-services/:id',
      remove: '/lab-services/:id',
      getAll: '/lab-services',
      getOne: '/lab-services/:id',
    },
  },
  location: {
    getAll: '/locations',
  },
  lookup: {
    getAll: '/lookup',
  },
  leads: {
    add: '/leads',
    delete: '/leads/:id',
    edit: '/leads/:id',
    getAll: '/leads/',
    getOne: '/leads/:id',
    updateLeadIdWithStatus: '/leads/status/:id',
    convertToClient: '/leads/convert-to-client/:id',
  },
  networkConfigs: {
    getAll: '/network-configs',
  },
  networks: {
    offerings: {
      getAll: '/offerings',
      getAllV2: '/offerings',
      getOne: '/offerings/:offeringId',
      add: '/offerings',
      edit: '/offerings',
      remove: '/offerings/:offeringId',
      networkSearch: '/providers/search',
    },
    physician: {
      add: '/physicians',
      edit: {
        profile: '/physicians/:id',
        contacts: '/physicians/:providerId/contacts',
        files: {
          edit: '/physicians/:providerId/files',
          delete: '/physicians/:providerId/files/:fileId',
          rename: '/physicians/:providerId/files/:fileId/rename',
        },
        financial: '/physicians/:providerId/financial',
        others: '/physicians/:providerId/others',
      },
      getAll: '/master-physicians',
      create: '/master-physicians',
      search: '/master-physicians/autocomplete',
      remove: '/physicians/:id',
    },
    facility: {
      add: '/facilities',
      edit: {
        profile: '/facilities/:id',
        contacts: '/facilities/:providerId/contacts',
        files: {
          edit: '/facilities/:providerId/files',
          delete: '/facilities/:providerId/files/:fileId',
          rename: '/facilities/:providerId/files/:fileId/rename',
        },
        financial: '/facilities/:providerId/financial',
        others: '/facilities/:providerId/others',
      },
      getAll: '/master-facilities',
      create: '/master-facilities',
      search: '/master-facilities/autocomplete',
      remove: '/facilities/:id',
    },
    networkDirectory: {
      add: '/networks',
      edit: '/networks',
      addNew: '/networks/providers',
      assignProvider: '/networks/assign',
      getAllPhysiciansByNetworkCode: '/physicians',
      getAllFacilityByNetworkCode: '/facilities',
      getAllFacilityByProviderId: '/physicians/:id/facilities',
      getAllPhysicianByProviderId: '/facilities/:id/physicians',
      getPhysicianByProviderId: '/physicians/:id',
      getFacilityByProviderId: '/facilities/:id',
      getNetworkByNetworkDirectoryId: '/networks/:networkDirectoryId',
      linkFacility: '/physicians/:providerId/facilities',
      linkPhysician: '/facilities/:providerId/physicians',
      unLinkFacility: '/physicians/:providerId/facilities/:associatedId',
      unLinkPhysician: '/facilities/:providerId/physicians/:associatedId',
      remove: '/networks/:id',
      unlinkAssignProvider: '/networks/:id',
    },
    services: {
      addService: '/services',
      autoComplete: '/services/autocomplete',
      editService: '/services',
      getAll: '/services',
      getBundleCode: '/services/bundle-code',
      getOne: '/services/:id',
      getUniversalService: '/services/universal',
      masterCodes: {
        getAll: '/master-codes/autocomplete',
      },
    },
    taxonomies: {
      getAll: '/taxonomies',
      search: 'taxonomies/autocomplete',
    },
  },
  note: {
    add: '/notes',
    edit: '/notes/:noteId',
    getAll: '/notes',
    remove: '/notes/:noteId',
  },
  status: {
    create: '/status',
    edit: '/status/tenant/:statusId',
    getAllWithCode: '/status/tenant/name-code',
    getAll: '/status/tenant',
    getOne: '/status/tenant/:statusId',
    getstatusCount: '/status/tenant/:statusId/modify',
    remove: '/status/tenant/:statusId',
  },
  supportInquiry: '/support',
  testReport: {
    add: '/test-reports',
    remove: '/test-reports/:id',
    getAll: '/test-reports',
    getOne: '/test-reports/:id',
    sendEmail: '/test-reports/:id/email',
    checkPatientDob: '/test-reports/:id/verify',
  },
  tenantManagement: {
    tenant: {
      add: '/tenants',
      assignUser: '/tenants/:id/assign-users',
      client: '/tenants/:client',
      edit: '/tenants/:id',
      editLogo: '/tenants/:tenantId/logo',
      getAll: '/tenants',
      getAllUsers: '/tenants/:id/users',
      getContacts: '/tenants/:id/contacts',
      getOne: '/tenants/:id',
      getPermissions: '/tenants/:id/permissions',
      getSettings: '/tenants/:id/settings',
      getUsers: '/tenants/:id/users',
      removeAssignUser: '/tenants/:id/users/:userId',
      savePermissions: '/tenants/:id/permissions',
      saveSettings: '/tenants/:id/settings',
      saveContacts: '/tenants/:id/contacts',
      switchTenant: '/users/:userId/switch/:tenantId',
      verifyCode: '/tenants/verify-code',

      ancillaryOfferings: {
        add: '/tenants/:id/ancillary-offerings',
        edit: '/tenants/:id/ancillary-offerings/:tenantOfferingId',
        getAll: '/tenants/:id/ancillary-offerings',
        getOne: '/tenants/:id/ancillary-offerings/:tenantOfferingId',
        remove: '/tenants/:id/ancillary-offerings/:tenantOfferingId',
      },

      membershipOfferings: {
        add: '/tenants/:id/membership-offerings',
        edit: '/tenants/:id/membership-offerings/:tenantOfferingId',
        getAll: '/tenants/:id/membership-offerings',
        getOne: '/tenants/:id/membership-offerings/:tenantOfferingId',
        remove: '/tenants/:id/membership-offerings/:tenantOfferingId',
      },

      services: {
        add: '/tenants/:id/services',
        edit: '/tenants/:id/services/:serviceId',
        getAll: '/tenants/:id/services',
        getOne: '/tenants/:id/services/:serviceId',
        remove: '/tenants/:id/services/:serviceId',
      },
    },
    user: {
      add: '/users',
      assignTenant: '/users/:id/assign-tenant',
      deleteGroupAdmin: '/users/:userId/tenants/:tenantId',
      edit: '/users/:id',
      getAll: '/users',
      getAllTenants: '/users/:id/tenants',
      getOne: '/users/:id',
      getUserRoles: '/users/:id/roles',
      getPermissions: '/users/:id/permissions',
      getTenants: '/users/:id/tenants',
      removeAssignTenant: '/users/:userId/tenants/:tenantId',
      savePermissions: '/users/:id/permissions',
      sentInvite: '/users/:id/invite',
    },
  },
  timesheet: {
    main: 'timesheets',
    activity: {
      getAll: '/referral-cases/timesheets-by-activities',
    },
    advocate: {
      getAll: '/referral-cases/timesheets-by-advocates',
      getDetail: '/referral-cases/my-timesheets',
    },
    myTimesheet: {
      getAll: '/referral-cases/my-timesheets',
    },
    sponsor: {
      getAll: '/referral-cases/timesheets-sponsor',
      timesheets: '/client-timesheets',
    },
  },
  tinyUrl: {
    validateUrl: '/short-url/validate',
  },
  virtualVisit: {
    events: {
      get: '/events/:id',
      remove: '/events/:id',
      getAll: '/events?type=VIRTUAL VISIT',
      add: '/events',
      edit: '/events/:id',
      start: '/events/virtual-visit/:eventId/start',
      join: '/events/virtual-visit/:eventId/join',
      leave: '/events/virtual-visit/:eventId/leave',
      end: '/events/virtual-visit/:eventId/end',
      cancel: '/events/:eventId/cancel',
      generateLink: '/events/virtual-visit/link',
      requestReschedule: '/events/:eventId/reschedule-request',
      requestCancel: '/events/:eventId/cancel-request',
      recording: '/events/virtual-visit/:eventId/recorded-file',
      deleteUser: `END_POINT/users/:uid`,
    },
  },
};

export default apiRoutes;
