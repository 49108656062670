import { useFormContext } from 'react-hook-form';

import { Box, Typography } from '@mui/material';
import { PaymentMethod } from 'enums/Payment';
import { IAdaptedPaymentSource, ISetAsPrimaryForm } from 'interfaces/payment';
import { useRemovePaymentSourceMutation } from 'services/payment';
import { useConfirmationModal } from 'stores/ConfirmationModal';
import {
  getBankAccountNumberDisplayText,
  getCardExpiryText,
  getCardNumberDisplayText,
} from 'utils/payment';

import PaymentInformationCardDisplay from './PaymentInformationCardDisplay';

interface IProps {
  data: IAdaptedPaymentSource;
  isSetAsPrimaryDisabled: boolean;
}

const PaymentInformationCard = ({ data, isSetAsPrimaryDisabled }: IProps) => {
  const { watch, setValue } = useFormContext<ISetAsPrimaryForm>();

  const { paymentMode } = data;
  const paymentSourceId = watch('paymentSourceId');

  const deleteConfirmationModal = useConfirmationModal();
  const removePaymentSourceMutation = useRemovePaymentSourceMutation();

  const isChecked = paymentSourceId === data.id;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('paymentSourceId', event.target.value);
  };

  const onDeleteHandler = async () => {
    const result = await deleteConfirmationModal?.openConfirmationModal({
      title: `Delete Payment Source`,
      content: 'Are you sure you want to delete this payment source?',
      contentExtra: (
        <Box sx={{ color: (theme) => theme.palette.text.secondary }}>
          {(paymentMode === PaymentMethod.CASH ||
            paymentMode === PaymentMethod.CHECK ||
            paymentMode === PaymentMethod.OTHERS) && (
            <Typography variant="body1">
              Payment Method: {paymentMode}
            </Typography>
          )}
          {paymentMode === PaymentMethod.CARD && (
            <Typography variant="body1">
              Card Number: {getCardNumberDisplayText(data.last4Digit)} (expires
              in {getCardExpiryText(data.expiryMonth, data.expiryYear)})
            </Typography>
          )}
          {paymentMode === PaymentMethod.BANK && (
            <Typography variant="body1">
              Bank: {getBankAccountNumberDisplayText(data.last4Digit)}
            </Typography>
          )}
        </Box>
      ),
    });

    if (result) {
      deleteConfirmationModal?.changeSubmittingStatus(true);
      removePaymentSourceMutation.mutate(
        {
          merchantCustomerId: data.merchantCustomerId,
          paymentSourceId: data.id,
        },
        {
          onSettled: () => {
            deleteConfirmationModal?.changeSubmittingStatus(false);
          },
        }
      );
    }
  };

  return (
    <PaymentInformationCardDisplay
      data={data}
      isChecked={isChecked}
      isSetAsPrimaryDisabled={isSetAsPrimaryDisabled}
      onChangePrimaryCardHandler={onChangeHandler}
      onDeleteHandler={onDeleteHandler}
    />
  );
};

export default PaymentInformationCard;
