import { EventType, VirtualVisitStatus } from 'enums/virtual-visit';
import { convertToPlainString } from 'utils/common';

import { eventModalKey } from './calender';

export const MAX_FILE_SIZE = 5000000;
export const RECORDING_INTERVAL = 1000 * 10;
export const VIRTUAL_VISIT_MESSAGE = {
  ALREADY_JOINED: 'Cannot Join the same meeting',
  ERROR: 'Something went wrong. Please try again',
};

export const EVENT_TYPE = {
  [eventModalKey.ADD_SCHDEULE]: EventType.SCHEDULE,
  [eventModalKey.ADD_APPOINTMENTS]: EventType.APPOINTMENTS,
  [eventModalKey.ADD_TASK]: EventType.TASK,
  [eventModalKey.ADD_VIRTUAL_VISIT]: EventType.VIRTUAL_VISIT,
  [eventModalKey.EDIT_VIRTUAL_VISIT]: EventType.VIRTUAL_VISIT,
};

export const statusOrder = [
  VirtualVisitStatus.NEW,
  convertToPlainString(VirtualVisitStatus.IN_PROGRESS),
  VirtualVisitStatus.COMPLETED,
  VirtualVisitStatus.CANCELLED,
];
