import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { AvatarDisplay } from 'common';
import { ClientEnrollmentStatus } from 'enums/client-management';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import {
  IAdaptedClientGroupTableRow,
  IAdaptedClientIndividualTableRow,
} from 'interfaces/client-management';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

interface IProps {
  data: IAdaptedClientIndividualTableRow | IAdaptedClientGroupTableRow;
  clientStatus: string;
}

const MemberCardDisplay = ({ data, clientStatus }: IProps) => {
  const theme = useTheme();
  const isCustomMinutesMembershipEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.CUSTOM_MINUTES_MEMBERSHIP
  );

  return (
    <Card>
      <CardContent>
        {/* Header */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box alignItems="center" display="flex">
              <AvatarDisplay alt="John Cena" />
              <Box ml={1} pr={6} width="100%">
                <Tooltip placement="top" title={data.name}>
                  <Typography
                    color="text.secondary"
                    data-cy="client-expand-membership-name"
                    fontWeight={theme.typography.fontWeightMedium}
                    gutterBottom={false}
                    noWrap
                    variant="body2"
                    width="100%"
                  >
                    {data.name}
                  </Typography>
                </Tooltip>
                {data?.primary && (
                  <Tooltip placement="top" title={data.primary}>
                    <Typography
                      color="text.secondary"
                      data-cy="client-expand-membership-primary"
                      display="block"
                      gutterBottom={false}
                      noWrap
                      variant="caption"
                      width="100%"
                    >
                      {data.primary}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            alignItems="flex-end"
            display="flex"
            flexDirection="column"
            item
            xs={6}
          >
            <Tooltip
              placement="top"
              title={data.membership?.offeringName || ''}
            >
              <Typography
                color="text.secondary"
                data-cy="client-expand-membership-offeringname"
                fontWeight={theme.typography.fontWeightMedium}
                gutterBottom={false}
                noWrap
                textAlign="right"
                variant="body2"
                width="100%"
              >
                {data.membership?.offeringName}
              </Typography>
            </Tooltip>
            <Typography
              color="text.secondary"
              data-cy="client-expand-membership-benefitdate"
              gutterBottom={false}
              noWrap
              variant="caption"
            >
              Benefit: {data.benefit || '-'}
            </Typography>
            {isCustomMinutesMembershipEnabled &&
              clientStatus !== ClientEnrollmentStatus.SUBMITTED && (
                <Typography
                  color="text.secondary"
                  gutterBottom={false}
                  noWrap
                  variant="caption"
                >
                  Hours Purchased:{' '}
                  {Number(data?.membership?.hoursPurchased)?.toLocaleString() ||
                    '-'}{' '}
                  mins
                </Typography>
              )}
          </Grid>
        </Grid>

        {/* <Box component={Divider} my={2} /> */}

        {/* Content */}
        {/* <Box>
          <Typography
            color="text.secondary"
            gutterBottom={false}
            noWrap
            variant="caption"
          >
            Additional Services
          </Typography>
          <Typography
            color="text.secondary"
            fontWeight={theme.typography.fontWeightMedium}
            gutterBottom={false}
            noWrap
            variant="body2"
          >
            -
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default MemberCardDisplay;
