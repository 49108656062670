import uiRoutes from 'constants/uiRoutes';
import { IChangeCredential, IChangeCredentialForm } from 'interfaces/auth';
import { IPermission } from 'interfaces/common';
import { AppDispatch } from 'stores';
import { setMenus, setTenantData } from 'stores/auth';
import { setAuthToken } from 'utils/axios';
import { decodeJwtToken } from 'utils/jwt';
import { setLocal } from 'utils/storage';

// TODO: Add proper description to the util function
export const getRouteToMenuMap = (data: IPermission[]) => {
  const menus: IPermission[] = [];
  data.forEach((menu) => {
    if (menu.children && menu.children.length > 0) {
      menu.children?.forEach((childMenu) => {
        menus.push(childMenu);
      });
    }
    menus.push(menu);
  });

  const menuMap = menus.reduce((map, obj) => {
    if (obj.routePath) {
      const newMenuObj = {
        [obj.routePath]: {
          parentId: obj.parentId,
          resourceId: obj.resourceId,
        },
      } as const;
      return { ...map, ...newMenuObj };
    }
    return map;
  }, {});

  return menuMap;
};

export const setAuthState = ({
  token,
  permissions,
  dispatch,
}: {
  token: string;
  permissions: IPermission[];
  dispatch: AppDispatch;
}) => {
  const decodedInfo = decodeJwtToken(token);
  const tenantMetaData = decodedInfo?.tenantAssociation?.metaData;
  const { title, faviconUrl, description } = tenantMetaData;

  setLocal('jwtToken', token);
  setLocal('menus', permissions);
  setLocal('siteTitle', title || '');
  setLocal('siteFavicon', faviconUrl || '');
  setLocal('siteDescription', description || '');

  setAuthToken(token);

  dispatch(setMenus(permissions));
  dispatch(setTenantData(token));
};

export const formatCredentialsPayload = (
  data: IChangeCredentialForm,
  userId?: string,
  username?: string
) => {
  const payload: IChangeCredential = {
    userId: userId || '',
    username: username || '',
    password: data.password,
    oldPassword: data.oldPassword,
  };
  return payload;
};

/**
 * This function takes an array of permissions and returns an array of route paths by
 * mapping through the children of each menu item.
 * @param {IPermission[]} permissions - The `permissions` parameter is an array of objects that
 * represent the permissions for a user. Each object in the array contains information about a menu and
 * its children routes.
 */
export const getRoutePathsFromPermissions = (permissions: IPermission[]) =>
  permissions?.flatMap(
    (menu) =>
      menu.routePath ||
      menu?.children?.map((route) => route?.routePath || '') ||
      []
  ) || [];

/**
 * This function checks if the current route is accessible based on a list of available routes and a
 * set of routes to bypass.
 * @param {string[]} availableRoutes - an array of strings representing the routes that a user has
 * access to in the application.
 * @returns An object with two properties: `canAccessRoute` and `route`. `canAccessRoute` is a boolean
 * value indicating whether the current route is accessible based on the available routes and the
 * routes to bypass. `route` is a string value representing the current route obtained from the
 * `window.location` object.
 */
export const checkIfCurrentRouteIsAccessible = (availableRoutes: string[]) => {
  const { pathname: currentRoute } = window.location;
  const routesToBypass = [
    uiRoutes.cases,
    uiRoutes.settings,
    uiRoutes.appointmentCheckin,
    uiRoutes.overview,
    uiRoutes.auth.logout,
  ];
  const availableRoutesWithBypass = [...availableRoutes, ...routesToBypass];
  const canAccessRoute =
    currentRoute === uiRoutes.index
      ? true
      : !!availableRoutesWithBypass.find((route) =>
          currentRoute.includes(route)
        );
  // Currently disabled route permission check
  return { canAccessRoute: canAccessRoute || true, route: currentRoute };
};
