import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@mui/material';

interface IProps {
  title?: string;
  subtitle?: string;
  cardNumber: string;
  cardExpiry: string;
  onEditClick?: VoidFunction;
}

const CardInformationDisplay = ({
  title,
  subtitle,
  cardNumber,
  cardExpiry,
  onEditClick,
}: IProps) => (
  <>
    <Box
      alignItems="center"
      color="text.secondary"
      display="flex"
      justifyContent="space-between"
    >
      <Box>
        {title && (
          <Typography
            color="text.secondary"
            fontWeight={(theme) => theme.typography.fontWeightMedium}
            gutterBottom={false}
            mt={2}
            variant="body1"
          >
            {title}
          </Typography>
        )}
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      </Box>

      {onEditClick && (
        <Button
          onClick={onEditClick}
          startIcon={<FontAwesomeIcon icon={faEdit} />}
          type="button"
          variant="text"
        >
          Edit Card
        </Button>
      )}
    </Box>
    <Typography variant="body2">
      Card Number &nbsp;:
      <Typography color="text.primary" component="span" variant="body2">
        &nbsp;{cardNumber}
      </Typography>
    </Typography>
    <Typography variant="body2">
      Expiration &nbsp; &nbsp; &nbsp; &nbsp;:
      <Typography color="text.primary" component="span" variant="body2">
        &nbsp;{cardExpiry}
      </Typography>
    </Typography>
  </>
);

CardInformationDisplay.defaultProps = {
  onEditClick: null,
  title: '',
  subtitle: 'Use recently entered card information',
};

export default CardInformationDisplay;
