import { Outlet, useSearchParams } from 'react-router-dom';

import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import CopyrightFooter from 'common/CopyrightFooter';
import ListDisplay from 'common/display/ListDisplay';
import LoadingIndicator from 'common/LoadingIndicator';
import Logo from 'common/Logo';
import 'assets/scss/_auth-layout.scss';
import commonConstants from 'constants/common';
import { LookUpCategory } from 'enums/lookups';
import { useLookUpQuery } from 'services/lookups';
import { useTenantClientDetailQuery } from 'services/tenant-management/tenant';

const AuthLayout = ({ children }: { children?: React.ReactNode }) => {
  const [searchParams] = useSearchParams();

  const client = searchParams.get('client') ?? '';
  const { data, isLoading } = useTenantClientDetailQuery(client, {
    enabled: !!client,
  });

  const networkOfferingStatusQuery = useLookUpQuery(
    {
      category: LookUpCategory.VERSION_CONTROL,
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
    },
    { enabled: true }
  );

  const version =
    (networkOfferingStatusQuery.data &&
      networkOfferingStatusQuery?.data[0]?.description) ||
    '';

  if (isLoading) {
    return <LoadingIndicator containerHeight="100vh" />;
  }

  return (
    <Box className="heroku-img">
      <Container className="box-flexContainer ">
        <Logo
          src={data?.metaData?.logoUrl}
          sx={{
            maxWidth: '100%',
            height: 100,
            alignSelf: 'center',
            pb: 4,
            display: { xs: 'block', md: 'none' },
            objectFit: 'contain',
          }}
        />
        <Paper
          sx={{
            maxWidth: '750px',
            mb: 5,
          }}
        >
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            sx={{
              width: { xs: 'auto', md: '750px' },
            }}
          >
            <Grid
              item
              paddingX={3}
              paddingY={4}
              sx={{
                backgroundColor: (theme) => theme.palette.gray.lighter,
                height: '460px',
                display: { xs: 'none', md: 'block' },
              }}
              xs={6}
            >
              <Stack
                display="flex"
                height="100%"
                justifyContent="center"
                spacing={2}
              >
                <Logo
                  src={data?.metaData?.logoUrl ?? ''}
                  sx={{
                    height: 100,
                    alignSelf: 'flex-start',
                    pb: 1,
                    maxWidth: '100%',
                    objectFit: 'contain',
                  }}
                />
                <Typography variant="h4" width={200}>
                  {data
                    ? data?.displayName || data?.businessName || ''
                    : 'Welcome To Vitafy Portal'}
                </Typography>
                <Typography variant="body1">
                  {data
                    ? data?.metaData?.tagLine || ''
                    : 'A free-market healthcare solution.'}
                </Typography>
                <Divider />
                {data ? (
                  (
                    <ListDisplay
                      list={[
                        {
                          icon: <FontAwesomeIcon icon={faPhone} />,
                          text: data.formattedPhone,
                          typographyProps: {
                            variant: 'body2',
                          },
                        },
                        {
                          icon: <FontAwesomeIcon icon={faEnvelope} />,
                          text: data?.email ?? 'N/A',
                          typographyProps: {
                            variant: 'body2',
                          },
                        },
                      ]}
                    />
                  ) || null
                ) : (
                  <Typography variant="body2">
                    Vitafy Health is a free-market platform that connects all
                    the healthcare decision makers that by working together
                    helps lower the cost of healthcare, provides flexible
                    options and personalized care compared to traditional health
                    insurance plans.{' '}
                  </Typography>
                )}
                <Box
                  alignItems="flex-end"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                >
                  <Typography variant="body1">Version {version}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={6} paddingY={4} xs={12}>
              {children ?? <Outlet />}
            </Grid>
          </Grid>
        </Paper>
        <Box className="auth-bottom-content">
          <CopyrightFooter />
        </Box>
      </Container>
    </Box>
  );
};

AuthLayout.defaultProps = {
  children: null,
};

export default AuthLayout;
