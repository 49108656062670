import { DatePickerProps, DatePicker as MuiDatePicker } from '@mui/lab';
import { SxProps, TextField, TextFieldProps } from '@mui/material';
import config from 'config';

export interface IProps extends Partial<DatePickerProps> {
  disabled?: boolean;
  disabledKeyDown?: boolean;
  inputStyle?: SxProps;
  inputProps?: TextFieldProps & { 'data-cy'?: string };
  handleChange?: (date: unknown) => void;
  value?: string;
}

const DatePicker = ({
  disabled = false,
  disabledKeyDown = false,
  inputStyle,
  mask = '__/__/____',
  inputFormat = config.dateViewFormat,
  inputProps,
  handleChange,
  value,
  ...props
}: IProps) => {
  const onChange = (date: unknown) => {
    if (handleChange) {
      handleChange(date);
    }
  };

  return (
    <MuiDatePicker
      disabled={disabled}
      inputFormat={inputFormat}
      mask={mask}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          error={value ? params.error : false}
          fullWidth
          onKeyDown={(e) => {
            if (disabledKeyDown) {
              e.preventDefault();
            }
          }}
          size="small"
          sx={{
            ...inputStyle,
            cursor: disabledKeyDown ? 'pointer' : 'default',
          }}
          variant="standard"
          {...inputProps}
        />
      )}
      value={value ?? ''}
      {...props}
    />
  );
};

DatePicker.defaultProps = {
  disabled: false,
  disabledKeyDown: false,
  inputStyle: null,
  inputProps: null,
  handleChange: null,
  value: null,
};

export default DatePicker;
