import { IListResponse, IResponse } from 'interfaces/http';
import {
  IAdaptedContact,
  IAdaptedContactTableRow,
  IContact,
  IContactAssociation,
  IContactForm,
  IContactSchema,
  IContactTableRow,
} from 'interfaces/settings/contacts';
import { RootState, store } from 'stores';
import { formatPhone, getFullName } from 'utils/common';
import { cloneDeep } from 'utils/lodash';

export const getStatusFromAssociations = (
  associations: IContactAssociation[]
) => {
  const rootState: RootState = store.getState();
  const authTenantId = rootState.auth.tenantData?.tenantAssociation?.tenantId;

  const associatedTenant = associations.find(
    (item) => item.referenceId === authTenantId
  );
  if (!associatedTenant) {
    return '';
  }

  return associatedTenant.status;
};

export const adaptContactList = (
  res: IResponse<IListResponse<IContactTableRow>>
): IResponse<IListResponse<IAdaptedContactTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data.rows.map((contact) => ({
      ...contact,
      fullName: getFullName(
        contact.firstName,
        contact.lastName,
        contact.middleName
      ),
      formattedPhone: formatPhone(contact.phone),
      status: getStatusFromAssociations(contact.associations),
    })),
  },
});

export const adaptContact = (
  res: IResponse<IContact>
): IResponse<IAdaptedContact> => ({
  ...res,
  data: {
    ...res.data,
    fullName: getFullName(
      res.data.firstName,
      res.data.lastName,
      res.data.middleName
    ),
    formattedPhone: formatPhone(res.data.phone),
    status: getStatusFromAssociations(res.data.associations),
  },
});

export const formatContactSavePayload = (
  data: IContactForm
): IContactSchema => {
  const payload = cloneDeep(data);
  delete payload.imageUrl;

  if (payload.newImageData) {
    payload.imageData = payload.newImageData.imageData;
  }

  delete payload.newImageData;

  return payload;
};
