import { useState } from 'react';

import { Box } from '@mui/material';
import { ModalFooter } from 'common';
import FileDropzone from 'common/dropzone';
import commonConstants from 'constants/common';
import { defaultMaxFileSize } from 'constants/file';
import { DocumentSubType } from 'enums/documents';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';
import { IFileSchema } from 'interfaces/documents';
import { useAddDocumentMutation } from 'services/documents';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isCancelButtonRequired?: boolean;
}
const ChooseDropZoneClientFiles = ({
  setStep,
  isCancelButtonRequired,
}: IProps) => {
  const [selectedFiles, setSelectedFiles] = useState<IFileSchema[]>([]);
  const addDocumentMutation = useAddDocumentMutation();

  const totalFileSize = selectedFiles.reduce(
    (prev, curr) => prev + curr.size,
    0
  );

  const isFileSizeExceeds = totalFileSize > defaultMaxFileSize;

  const isDisabled = () => {
    const containError = selectedFiles.find((e) => e.error);
    if (!selectedFiles.length || containError || isFileSizeExceeds) return true;
    return false;
  };

  const editClientData = useAppSelector(
    selectEditClientData
  ) as IAdaptedClientGroup & IAdaptedClientIndividual;

  const onFileChange = (files: IFileSchema[]) => {
    const data = files.map((e: IFileSchema) => {
      const { error, ...rest } = e;
      return rest;
    });
    setSelectedFiles(data);
  };

  const handleClose = () => {
    setStep(1);
  };

  const handleActionButtonClick = () => {
    if (selectedFiles) {
      const data = {
        files: selectedFiles,
        type: commonConstants.CLIENT,
        subType: DocumentSubType.Client,
        associationId: editClientData!.clientId!,
      };
      addDocumentMutation.mutate(
        {
          data,
        },
        {
          onSuccess: () => {
            setStep(0);
          },
        }
      );
    }
  };

  return (
    <Box>
      <Box minHeight="60vh" padding={2}>
        <FileDropzone onChange={onFileChange} />
      </Box>
      <ModalFooter
        actionButtonLabel="Upload"
        actionButtonType="button"
        cancelButtonType="back"
        isCancelRequired={isCancelButtonRequired}
        isDisabled={isDisabled()}
        isLoading={addDocumentMutation.isLoading}
        onActionButtonClick={handleActionButtonClick}
        onCancelButtonClick={handleClose}
      />
    </Box>
  );
};
ChooseDropZoneClientFiles.defaultProps = {
  isCancelButtonRequired: true,
};

export default ChooseDropZoneClientFiles;
