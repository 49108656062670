import { ClientEnrollmentStatus } from 'enums/client-management';
import { Status } from 'enums/common';
import {
  BillingInterval,
  FormattedBillingInterval,
  MembershipStatus,
} from 'enums/settings/tenant-offerings';
import { TenantContact, TenantGroupCode } from 'enums/tenant-management/tenant';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  IAdaptedTenant,
  IAdaptedTenantContacts,
  IAdaptedTenantSettingItem,
  IAdaptedTenantTableRow,
  ITenant,
  ITenantContacts,
  ITenantContactsForm,
  ITenantContactsSchema,
  ITenantSetting,
  ITenantTableRow,
} from 'interfaces/tenant-management/tenant';
import {
  IAdaptedTenantAncillaryOffering,
  IAdaptedTenantAncillaryOfferingTableRow,
  IAddTenantAncillaryOfferingForm,
  IAddTenantAncillaryOfferingSchema,
  ITenantAncillaryOffering,
  ITenantAncillaryOfferingTableRow,
} from 'interfaces/tenant-management/tenant/tenantAncillaryOffering';
import {
  IAddTenantMembershipOfferingForm,
  ITenantMembershipOfferingTableRow,
} from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import {
  IAdaptedUserTableRow,
  IAddUserForm,
  IAddUserSchema,
  IUserTableRow,
} from 'interfaces/tenant-management/user';
import { RootState, store } from 'stores';
import {
  attachAddressObject,
  capitalizeFirstLetter,
  convertStringToNumber,
  formatCurrency,
  formatDate,
  formatPhone,
  getAgeRange,
  getFullName,
  getQuantityRange,
  unformatDate,
  unformatPhone,
} from 'utils/common';
import {
  formatDateView,
  getCurrentDate,
  momentDateIsBetweenTwoDates,
  momentDateOnlyIsSameOrAfter,
} from 'utils/moment';
import { sortObjectArray } from 'utils/sortingUtil';

import { ITenantSettingItem } from '../../../interfaces/tenant-management/tenant/index';

const selectBillingInterval = (interval: string) => {
  switch (interval) {
    case BillingInterval.MONTHLY:
      return FormattedBillingInterval.MONTHLY;
    case BillingInterval.BI_YEARLY:
      return FormattedBillingInterval.BI_YEARLY;
    case BillingInterval.YEARLY:
      return FormattedBillingInterval.YEARLY;
    case BillingInterval.ONE_TIME:
      return FormattedBillingInterval.ONE_TIME;
    case BillingInterval.QUARTERLY:
      return FormattedBillingInterval.QUARTERLY;
    default:
      return '';
  }
};

export const checkTenantIsV360 = () => {
  const rootState: RootState = store.getState();
  const tenantData = rootState?.auth?.tenantData;
  const code = tenantData?.tenantAssociation?.code;

  const isTenantV360 = code === TenantGroupCode.V360;

  return isTenantV360;
};

export const checkTenantIsGraith = () => {
  const rootState: RootState = store.getState();
  const tenantData = rootState?.auth?.tenantData;
  const code = tenantData?.tenantAssociation?.code;

  const isTenantV360 = code === TenantGroupCode.GRAITH_CARE;

  return isTenantV360;
};

export const formatBillingType = (billingType: string) =>
  `per ${billingType?.toLowerCase()}`;

export const formatBillingInterval = (billingInterval: string) =>
  `per ${selectBillingInterval(billingInterval)}`;

export const formatActiveStatus = (
  startDate: string,
  endDate: string,
  status: string
) => {
  const currentDate = getCurrentDate();

  const { APPROVED, CANCELLED, COMPLETED } = ClientEnrollmentStatus;

  const isStatusApprovedorCancelledorCompleted =
    status === APPROVED || status === CANCELLED || status === COMPLETED;

  if (startDate && endDate) {
    const result =
      momentDateIsBetweenTwoDates(startDate, endDate) &&
      isStatusApprovedorCancelledorCompleted;
    return result ? `& ${Status.ACTIVE}` : `& ${Status.INACTIVE}`;
  }
  if (startDate && !endDate) {
    const result =
      momentDateOnlyIsSameOrAfter(currentDate, startDate) &&
      isStatusApprovedorCancelledorCompleted;
    return result ? `& ${Status.ACTIVE}` : `& ${Status.INACTIVE}`;
  }
  return '';
};

export const formatUserSavePayload = (data: IAddUserForm): IAddUserSchema => {
  const payload: IAddUserSchema = {
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    email: data.email,
    dob: formatDate(data.dob ?? ''),
    gender: data.gender,
    phone: data.phone && unformatPhone(data.phone),
    enableImpersonation: data.enableImpersonation,
    address: attachAddressObject(data),
    countryCode: data?.countryCode,
    phoneExt: data?.phoneExt,
  };
  return payload;
};

export const formatProfileSettingsUserSavePayload = (
  data: IAddUserForm
): IAddUserSchema => {
  const payload: IAddUserSchema = {
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    email: data.email,
    dob: formatDate(data.dob ?? ''),
    gender: data.gender,
    phone: data.phone && unformatPhone(data.phone),
    enableImpersonation: data.enableImpersonation,
    address: attachAddressObject(data),
    countryCode: data?.countryCode,
    phoneExt: data?.phoneExt,
  };
  return payload;
};

export const adaptTenant = (
  res: IResponse<ITenant>
): IResponse<IAdaptedTenant> => ({
  ...res,
  data: {
    ...res.data,
    formattedPhone: formatPhone(res?.data?.phone) ?? 'N/A',
  },
});

export const adaptTenantList = (
  res: IResponse<IListResponse<ITenantTableRow>>
): IResponse<IListResponse<IAdaptedTenantTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((tenant) => ({
      ...tenant,
      contactName:
        getFullName(tenant.contactFirstName, tenant.contactLastName) || '',
      phone: formatPhone(tenant.phone),
      updatedBy: capitalizeFirstLetter(tenant.updatedBy),
      updatedAt: formatDateView(tenant.updatedAt) || '-',
    })),
  },
});

export const adaptTenantUserList = (
  res: IResponse<IUserTableRow[]>
): IResponse<IAdaptedUserTableRow[]> => ({
  ...res,
  data: res.data?.map((user) => ({
    ...user,
    demographic: {
      ...user.demographic,
      fullName: getFullName(
        user.demographic?.firstName,
        user.demographic?.lastName,
        user.demographic?.middleName
      ),
      phone: formatPhone(user.demographic?.phone),
      unFormattedPhone: user.demographic?.phone,
    },
    formattedUpdatedBy: capitalizeFirstLetter(user.updatedBy.name),
    updatedDate: formatDateView(user.updatedAt) || '-',
  })),
});

export const adaptTenantSettingList = (
  res: IResponse<ITenantSetting>
): IResponse<IAdaptedTenantSettingItem[]> => {
  const { data } = res;

  const settingList: IAdaptedTenantSettingItem[] = [];

  if (data) {
    Object.keys(data).forEach((key) => {
      const adaptedList = data[key as keyof ITenantSetting].map(
        (item: ITenantSettingItem) => ({
          ...item,
          parent: key,
        })
      );
      settingList.push(...adaptedList);
    });
  }

  const sortedSettingList = sortObjectArray(settingList, 'name');

  return {
    ...res,
    data: sortedSettingList,
  };
};

export const adaptTenantContacts = (
  res: IResponse<ITenantContacts>
): IResponse<IAdaptedTenantContacts> => {
  const { data } = res;

  const primaryContact = data.contact.find(
    (item) => item.type === TenantContact.PRIMARY
  );
  const supportContact = data.contact.find(
    (item) => item.type === TenantContact.SUPPORT
  );

  const adaptedData: IAdaptedTenantContacts = {
    primaryFirstName: primaryContact?.firstName ?? '',
    primaryLastName: primaryContact?.lastName ?? '',
    primaryEmail: primaryContact?.email ?? '',
    primaryPhone: primaryContact?.phone ?? '',

    supportFirstName: supportContact?.firstName ?? '',
    supportLastName: supportContact?.lastName ?? '',
    supportEmail: supportContact?.email ?? '',
    supportPhone: supportContact?.phone ?? '',
  };

  return {
    ...res,
    data: adaptedData,
  };
};

export const formatTenantContactsPayload = (
  data: ITenantContactsForm
): ITenantContactsSchema => ({
  contact: [
    {
      firstName: data.primaryFirstName,
      lastName: data.primaryLastName,
      email: data.primaryEmail,
      phone: data.primaryPhone,
      type: TenantContact.PRIMARY,
    },
    {
      firstName: data.supportFirstName,
      lastName: data.supportLastName,
      email: data.supportEmail,
      phone: data.supportPhone,
      type: TenantContact.SUPPORT,
    },
  ],
});

export const adaptTenantAncillaryOfferingList = (
  res: IResponse<IListResponse<ITenantAncillaryOfferingTableRow>>
): IResponse<IListResponse<IAdaptedTenantAncillaryOfferingTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((item) => ({
      ...item,
      formattedMemberPrice: formatCurrency(item.memberPrice, false),
      formattedNonMemberPrice: formatCurrency(item.nonMemberPrice, false),
      formattedTotalPrice: formatCurrency(
        item.memberPrice + item.nonMemberPrice,
        false
      ),
    })),
  },
});

export const adaptTenantAncillaryOffering = (
  res: IResponse<ITenantAncillaryOffering>
): IResponse<IAdaptedTenantAncillaryOffering> => ({
  ...res,
  data: {
    ...res.data,
    unformattedEffectiveStartDate: unformatDate(res.data.effectiveStartDate),
    unformattedEffectiveEndDate: unformatDate(res.data.effectiveEndDate),
  },
});

export const formatAddAncillaryOfferingPayload = (
  data: IAddTenantAncillaryOfferingForm
): IAddTenantAncillaryOfferingSchema => ({
  ...data,
  ...convertStringToNumber(['memberPrice', 'nonMemberPrice', 'order'], data),
  effectiveStartDate: formatDate(data.effectiveStartDate),
  effectiveEndDate: formatDate(data.effectiveEndDate),
});

export const adaptOfferingList = (
  res: IResponse<IListResponse<ITenantMembershipOfferingTableRow>>
) => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data?.rows?.map((item: any) => ({
      ...item,
      id: item.id,
      category: item.category,
      tenantId: item.tenantId,
      tenantOfferingId: item.tenantOfferingId,
      name: item.name,
      ageRange: getAgeRange(item.minAge, item.maxAge),
      qtyRange: getQuantityRange(item.minQuantity, item.maxQuantity),
      unformattedPrice: item.price,
      price: formatCurrency(item.price),
      visibility: item.visibility || 'N/A',
      status: item.status ?? MembershipStatus.INACTIVE,
      membershipId: item.membershipId,
      registrationFee: item.registrationFee
        ? formatCurrency(item.registrationFee)
        : null,
      unformattedRegistrationFee: item.registrationFee,
      statusDateRange: `${item.effectiveStartDate || 'N/A'} - ${
        item.effectiveEndDate || 'N/A'
      }`,
      billingInterval: item.billingInterval,
      formattedBillingInterval: formatBillingInterval(item.billingInterval),
      formattedBillingType: formatBillingType(item.billingType),
      billingType: item.billingType,
      formattedPriceIncludingRegFee:
        formatCurrency(item.price + item.registrationFee) ?? '-',
    })),
  },
});

export const formatTenantOfferingPayload = (
  payload: IAddTenantMembershipOfferingForm
): IAddTenantMembershipOfferingForm => ({
  ...payload,
  ...convertStringToNumber(
    [
      'maxQuantity',
      'minQuantity',
      'minAge',
      'maxAge',
      'price',
      'registrationFee',
      'hoursPurchased',
    ],
    payload
  ),
  effectiveStartDate: payload.effectiveStartDate
    ? formatDate(payload.effectiveStartDate)
    : payload.effectiveStartDate,
  effectiveEndDate: payload.effectiveEndDate
    ? formatDate(payload.effectiveEndDate)
    : payload.effectiveEndDate,
});
