import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent } from '@mui/material';
import { LoadingIndicator, Modal, ModalFooter } from 'common';
import { CountryCodes } from 'enums/country';
import ModalKey from 'enums/ModalKey';
import {
  IAddUserForm,
  IAddUserSchema,
} from 'interfaces/tenant-management/user';
import { AddUserSchema } from 'schema/tenant-management/user';
import {
  useAddUserMutation,
  useEditUserMutation,
  useUserDetailQuery,
} from 'services/tenant-management/users';
import { selectAuthTenantData } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { unformatDate } from 'utils/common';
import { formatUserSavePayload } from 'utils/tenant-management/users';

import AddUserForm from './AddUserForm';

const defaultValues = {
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
  email: '',
  phone: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  phoneExt: '1',
  countryCode: CountryCodes.USA,
  state: '',
  zip: '',
  enableImpersonation: false,
};

const AddUserModal = (): JSX.Element => {
  const methods = useForm<IAddUserForm>({
    resolver: yupResolver(AddUserSchema),
    defaultValues,
  });

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const userId = searchParams.get('id');
  // Add User and Add Admin User both use same modal. It's distinguished by type query param in url
  const isAddAdminUser = searchParams.get('type') === ModalKey.ADD_ADMIN_USER;
  const isEditMode = !!userId;

  const authTenantData = useAppSelector(selectAuthTenantData);
  const authTenantId = authTenantData?.tenantAssociation.tenantId;

  const userDetailQuery = useUserDetailQuery(userId!, {
    enabled: !!userId,
  });

  const addUserMutation = useAddUserMutation();
  const editUserMutation = useEditUserMutation();

  useEffect(() => {
    if (userDetailQuery?.data) {
      const data = userDetailQuery?.data?.demographic;

      methods.reset({
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob ? unformatDate(data.dob) : data.dob,
        gender: data.gender,
        email: data.email,
        phone: data.phone,
        addressLine1: data.address?.addressLine1,
        addressLine2: data.address?.addressLine2,
        city: data.address?.city,
        state: data.address?.state,
        zip: data.address?.zip,
        enableImpersonation:
          userDetailQuery?.data?.enableImpersonation ?? false,
        countryCode:
          userDetailQuery?.data?.demographic?.countryCode || CountryCodes.USA,
        phoneExt: userDetailQuery?.data?.demographic?.phoneExt || '1',
        textEnabled:
          userDetailQuery?.data?.demographic?.textEnabled || undefined,
      });
    }
  }, [userDetailQuery?.data, methods]);

  const handleClose = () => {
    navigate(-1);
  };

  const onSubmit = (data: IAddUserForm) => {
    if (isAddAdminUser && !authTenantId) {
      return;
    }

    let payload: IAddUserSchema = formatUserSavePayload(data);

    if (isAddAdminUser && authTenantId) {
      payload = {
        ...payload,
        tenantId: authTenantId,
      };
    }

    if (isEditMode) {
      editUserMutation.mutate({ userId, data: payload });
      handleClose();
      return;
    }

    addUserMutation.mutate({ data: payload });
    handleClose();
  };

  const handleCancelButtonClick = () => {
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      icon={isEditMode ? 'faUserPen' : 'faUserPlus'}
      open
      title={isEditMode ? 'Edit User' : 'Add User'}
    >
      {userDetailQuery.isLoading ? (
        <LoadingIndicator containerHeight="40vh" />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent>
              <AddUserForm isAddAdminUser={isAddAdminUser} />
            </DialogContent>
            <ModalFooter
              isLoading={
                addUserMutation.isLoading || editUserMutation.isLoading
              }
              onCancelButtonClick={handleCancelButtonClick}
            />
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default AddUserModal;
