import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as clientAPI from 'api/client-management';
import UserType from 'enums/User';
import useClientListQueryKey from 'hooks/useClientListQueryKey';
import {
  IAddClientSchema,
  ICheckClientExistsSchema,
  IClientDetailFilter,
  IClientFilters,
  IClientTableRow,
} from 'interfaces/client-management';
import { IFilter } from 'interfaces/common';
import { IError } from 'interfaces/http';
import { useSnackbar } from 'notistack';
import { adaptClient, adaptClientList } from 'utils/client-management';

export const clientKeys = {
  all: ['clients'] as const,
  lists: () => [...clientKeys.all, 'list'] as const,
  list: (filters: IClientFilters) =>
    [...clientKeys.lists(), { filters }] as const,
  details: () => [...clientKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...clientKeys.details(), id] as const,
};

export const clientExistsKeys = {
  all: ['clients-exists'] as const,
  lists: () => [...clientExistsKeys.all, 'list'] as const,
  list: (filters: IFilter) =>
    [...clientExistsKeys.lists(), { filters }] as const,
  details: () => [...clientExistsKeys.all, 'detail'] as const,
  detail: (id: number | string) => [...clientExistsKeys.details(), id] as const,
};

export const useClientQuery = (
  filters: IClientFilters,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    clientKeys.list(filters),
    () => clientAPI.getClients(filters),
    {
      enabled,
      select: adaptClientList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useClientDetailQuery = (
  clientId: string,
  { enabled }: { enabled: boolean },
  queryParams?: IClientDetailFilter
) => {
  const queryInfo = useQuery(
    clientKeys.detail(clientId),
    () => clientAPI.getClientDetail(clientId, queryParams),
    {
      enabled,
      select: adaptClient,
    }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddClientMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  return useMutation(
    ({ data }: { data: IAddClientSchema }) => clientAPI.addClient(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        const clientType = res?.data?.type;

        if (!clientType) return;

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) return;

        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            count: oldData.data.count + 1,
            rows: [res.data, ...oldData.data.rows],
          },
        }));
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useCheckClientExistsMutation = () =>
  useMutation(({ data }: { data: ICheckClientExistsSchema }) =>
    clientAPI.checkClientsExists(data)
  );

export const useEditClientMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { groupQueryKey, individualQueryKey } = useClientListQueryKey();

  return useMutation(
    ({ clientId, data }: { clientId: string; data: IAddClientSchema }) =>
      clientAPI.editClient(clientId, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });

        const clientType = res?.data?.type;

        if (!clientType) {
          return;
        }

        const queryKey =
          clientType === UserType.GROUP ? groupQueryKey : individualQueryKey;
        const queryData = queryClient.getQueryData(queryKey);

        if (!queryData) {
          return;
        }
        queryClient.setQueryData(
          clientKeys.detail(res.data.clientId),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              ...res.data,
            },
          })
        );

        queryClient.setQueryData(queryKey, (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            rows: oldData.data.rows.map((item: IClientTableRow) => {
              if (item.clientId !== res.data.clientId) return item;
              return { ...item, ...res.data };
            }),
          },
        }));
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
