import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { AuthLayout, DashboardLayout, UiGuidelinesLayout } from 'common';
import AgreementSentSuccess from 'common/AgreementSentSuccess';
//  TODO: Reverting for user verify for now
// import VerifyUserLayout from 'common/layouts/auth/verify-user';
import NoteLayout from 'common/layouts/email-notes';
import ReceiptLayout from 'common/layouts/receipt';
import { secretKey } from 'constants/secretKey';
import uiRoutes from 'constants/uiRoutes';
import Home from 'features/home';
import { getLocal, getSession } from 'utils/storage';
import { checkTenantIsV360 } from 'utils/tenant-management/tenant';

import { lazyImportWithRetry } from '../common/LazyLoader';

// Lazy load route level components (for bundle splitting)

const ActivateAccount = lazyImportWithRetry(
  () => import('features/auth/ActivateAccount')
);
//  TODO: Reverting for user verify for now
// const ActivateAccountSignup = lazyImportWithRetry(
//   () => import('features/auth/verify-user/activate-account-signup')
// );
const ActivityLog = lazyImportWithRetry(() => import('features/activity-log'));
const Appointment = lazyImportWithRetry(() => import('features/appontments'));
const Admins = lazyImportWithRetry(() => import('features/settings/admins'));
const BillingInvoice = lazyImportWithRetry(
  () => import('features/invoice/billings-invoice/BillingInvoice')
);
const ClientGroup = lazyImportWithRetry(() => import('features/client-groups'));
const ClientIndividual = lazyImportWithRetry(
  () => import('features/client-individuals')
);
const ClientVirtualVisit = lazyImportWithRetry(
  () => import('features/virtual-visit/comet-chat')
);

const CommissionReport = lazyImportWithRetry(
  () => import('features/reports/commission-report')
);
const Calender = lazyImportWithRetry(() => import('features/calender'));
const VirtualVisit = lazyImportWithRetry(
  () => import('features/virtual-visit')
);
const Communication = lazyImportWithRetry(
  () => import('features/settings/communication')
);
const Cases = lazyImportWithRetry(() => import('features/cases'));
const ClientAgreement = lazyImportWithRetry(
  () => import('features/client-agreement')
);
const ConvertToESignSuccess = lazyImportWithRetry(
  () => import('features/documents/documents/ConvertToESignSuccess')
);
const Dashboard = lazyImportWithRetry(() => import('features/dashboard'));
const Documents = lazyImportWithRetry(
  () => import('features/documents/documents')
);
const ConvertToESign = lazyImportWithRetry(
  () => import('features/documents/documents/ConvertToESign')
);
const EditCommunicationTemplate = lazyImportWithRetry(
  () => import('features/settings/communication/edit-communication-template')
);
const ESignatures = lazyImportWithRetry(
  () => import('features/documents/e-signatures')
);
const ESignatureSubmitRedirectView = lazyImportWithRetry(
  () => import('features/documents/documents/ESignatureSubmitRedirectView')
);
const FailedTransactions = lazyImportWithRetry(
  () => import('features/billings/failed')
);
const TinyUrl = lazyImportWithRetry(() => import('features/misc/tiny-url'));
const Inbox = lazyImportWithRetry(() => import('features/comms/inbox'));
const LabService = lazyImportWithRetry(
  () => import('features/settings/lab-service')
);
const Login = lazyImportWithRetry(() => import('features/auth/Login'));
const Logout = lazyImportWithRetry(() => import('features/auth/Logout'));
const ModalSwitch = lazyImportWithRetry(() => import('./ModalSwitch'));
const OfferingNetworks = lazyImportWithRetry(
  () => import('features/networks/network-offerings')
);
const Outbox = lazyImportWithRetry(() => import('features/comms/outbox'));
const NoMatch = lazyImportWithRetry(() => import('features/misc/no-match'));
const Profile = lazyImportWithRetry(() => import('features/settings/profile'));
const ReportDashboard = lazyImportWithRetry(
  () => import('features/reports/dashboard')
);
const FacilityNetworks = lazyImportWithRetry(
  () => import('features/networks/network-facilities')
);
const PhysicianNetworks = lazyImportWithRetry(
  () => import('features/networks/network-physicians')
);
const ServiceNetworks = lazyImportWithRetry(
  () => import('features/networks/network-services')
);
const TenantMembershipOffering = lazyImportWithRetry(
  () => import('features/settings/tenant-membership-offerings')
);
const ReportPrintPreview = lazyImportWithRetry(
  () => import('features/reports/dashboard/ReportPrintPreview')
);
const ResetPassword = lazyImportWithRetry(
  () => import('features/auth/ResetPassword')
);
const SalesByRep = lazyImportWithRetry(
  () => import('features/reports/sales-by-rep')
);
const SalesByCompany = lazyImportWithRetry(
  () => import('features/reports/sales-by-company')
);
const Tenant = lazyImportWithRetry(
  () => import('features/tenant-management/tenant')
);
const TenantAncillaryOfferings = lazyImportWithRetry(
  () => import('features/settings/tenant-ancillary-offerings')
);
const Transactions = lazyImportWithRetry(
  () => import('features/billings/transactions')
);

const TransactionReceiptAuthenticate = lazyImportWithRetry(
  () => import('features/receipt/transaction/TransactionReceiptAuthenticate')
);

const TestReport = lazyImportWithRetry(() => import('features/test-report'));
const TransactionReceipt = lazyImportWithRetry(
  () => import('features/receipt/transaction/TransactionReceipt')
);
const NoteAuthenticate = lazyImportWithRetry(
  () => import('features/email-notes-files/NoteAuthenticate')
);
const EmailNotesFiles = lazyImportWithRetry(
  () => import('features/email-notes-files')
);
const UiGuidelines = lazyImportWithRetry(
  () => import('features/ui-guidelines')
);
const UpcomingTransactions = lazyImportWithRetry(
  () => import('features/billings/upcoming')
);
const User = lazyImportWithRetry(
  () => import('features/tenant-management/user')
);

const ProfileSettings = lazyImportWithRetry(
  () => import('features/profile-settings')
);

const TestResultDetails = lazyImportWithRetry(
  () => import('features/test-report/details/TestResultDetails')
);

const Activity = lazyImportWithRetry(
  () => import('features/timesheet/activity')
);

const AdvocateCases = lazyImportWithRetry(
  () => import('features/reports/advocate-cases')
);

const TimesheetByAdvocate = lazyImportWithRetry(
  () => import('features/timesheet/advocate')
);

const TimesheetBySponsor = lazyImportWithRetry(
  () => import('features/timesheet/sponsor')
);
//  TODO: Reverting for user verify for now
// const UserExistsLinkAccount = lazyImportWithRetry(
//   () => import('features/auth/verify-user/user-exists')
// );

// const VerifyUserAccount = lazyImportWithRetry(
//   () => import('features/auth/verify-user/verify-user-exists')
// );

const MyTimesheet = lazyImportWithRetry(
  () => import('features/timesheet/my-timesheet')
);

const Invoices = lazyImportWithRetry(
  () => import('features/billings/invoices')
);

const InitiateVirtualVisit = lazyImportWithRetry(
  () => import('features/virtual-visit/comet-chat')
);

const QuestionnaireResponse = lazyImportWithRetry(
  () => import('features/quesitonnaire-respondent')
);

const Leads = lazyImportWithRetry(
  () => import('features/leads/leads-individuals')
);

const QuestionnaireAuthenticate = lazyImportWithRetry(
  () =>
    import(
      'features/quesitonnaire-respondent/QuestionnaireRespondentAuthenticate'
    )
);

const SharedReferralCaseAuthenticate = lazyImportWithRetry(
  () =>
    import('features/cases/shared-referral-case/SharedReferralCaseAuthenticate')
);

const StartSigning = lazyImportWithRetry(
  () => import('features/documents/documents/StartSigning')
);

const ClientsReport = lazyImportWithRetry(
  () => import('features/reports/clients')
);

const VirtualVisitLobbyPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/LobbyPage')
);
const VirtualVisitConfirmationPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/ConfirmationPage')
);
const VirtualVisitRequestPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/RequestReschedule')
);
const VirtualVisitRequestCancelPage = lazyImportWithRetry(
  () => import('features/virtual-visit/virtual-visit/RequestCancel')
);

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // const permissions = useMemo(() => getLocal('menus', true), []);

  const caseId = searchParams.get('caseId');
  const messageId = searchParams.get('messageId');
  const fromEmail = searchParams.get('fromEmail');
  const codeLink = searchParams.get('link');

  const isEmailNote = !!caseId && !!messageId && !!fromEmail && !!codeLink;

  const isAuthenticated = !!getLocal('jwtToken');

  // Disabled route permission check for now

  // const availableRoutes = useMemo(
  //   () => getRoutePathsFromPermissions(permissions),
  //   [permissions]
  // );

  // const { canAccessRoute } = checkIfCurrentRouteIsAccessible(availableRoutes);

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return (
      <Navigate
        replace
        state={{ from: location }}
        to={
          isEmailNote
            ? `${uiRoutes.noteAuthenticate}${location.search}`
            : `/${uiRoutes.auth.login}`
        }
      />
    );
  }

  // if (!canAccessRoute) {
  //   return <Navigate replace to={uiRoutes.index} />;
  // }

  return children;
};

const RequireQuestionnaireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { respondentId, code } = useParams();

  const isAuthenticated = !!getLocal('jwtToken');
  const isQuestionnaireResponse = !!respondentId && !!code;
  const showQuestionnaire = getSession('showQuestionnaire');

  if (showQuestionnaire === secretKey.QUESTIONNAIRE_KEY) return children;

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        state={{ from: location }}
        to={
          isQuestionnaireResponse
            ? `${uiRoutes.questionnaireResponseAuthenticate}${location.search}&respondentId=${respondentId}`
            : `/${uiRoutes.auth.login}`
        }
      />
    );
  }
  return children;
};

const RequireVitafy360Auth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const isUserVitafy360 = checkTenantIsV360();

  if (!isUserVitafy360) {
    return <Navigate replace state={{ from: location }} to="/" />;
  }
  return children;
};

const Router = (): JSX.Element => (
  <Routes>
    <Route element={<ClientAgreement />} path={uiRoutes.clientAgreement}>
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route
        element={
          <RequireQuestionnaireAuth>
            <QuestionnaireResponse />
          </RequireQuestionnaireAuth>
        }
        path={uiRoutes.questionnaireResponse}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>

    <Route element={<NoteLayout />}>
      <Route
        element={<TransactionReceiptAuthenticate />}
        path={uiRoutes.transcationReceiptAuthenticate}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>

    <Route element={<NoteLayout />}>
      <Route
        element={<QuestionnaireAuthenticate />}
        path={uiRoutes.questionnaireResponseAuthenticate}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>
    <Route element={<NoteLayout />}>
      <Route
        element={<SharedReferralCaseAuthenticate />}
        path={uiRoutes.referralCases.share}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>

    <Route>
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route element={<TestResultDetails />} path={uiRoutes.testResult} />
    </Route>
    {/* TODO: Reverting for user verify for now */}
    {/* <Route element={<VerifyUserLayout />}>
      <Route
        element={<VerifyUserAccount />}
        path={uiRoutes.auth.verifyAccount}
      />
    </Route>

    <Route element={<VerifyUserLayout />}>
      <Route
        element={<UserExistsLinkAccount />}
        path={uiRoutes.auth.userExistsLinkAccount}
      />
    </Route>
    <Route element={<VerifyUserLayout />}>
      <Route
        element={<ActivateAccountSignup />}
        path={uiRoutes.auth.activateAccountSignUp}
      />
    </Route> */}

    <Route element={<AuthLayout />}>
      <Route element={<Login />} path={uiRoutes.auth.login}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<ActivateAccount />}
        path={uiRoutes.auth.activateAccount}
      />
      <Route element={<Logout />} path={uiRoutes.auth.logout} />
      <Route element={<ResetPassword />} path={uiRoutes.auth.resetPassword} />
    </Route>
    <Route
      element={
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      }
    >
      <Route element={<Appointment />} path={uiRoutes.appointmentCheckin}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Home />} path={uiRoutes.index} />
      <Route element={<ActivityLog />} path={uiRoutes.activityLog} />
      <Route element={<Dashboard />} path={uiRoutes.dashboard}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<ClientGroup />} path={uiRoutes.groups}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RequireVitafy360Auth>
            <ClientGroup />
          </RequireVitafy360Auth>
        }
        path={uiRoutes.tenantManagement.allGroups}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Documents />} path={uiRoutes.documents.documents}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<ESignatures />} path={uiRoutes.documents.eSignatures}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<ConvertToESign />}
        path={uiRoutes.documents.convertToESignature}
      />
      <Route element={<ClientIndividual />} path={uiRoutes.individuals}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={
          <RequireVitafy360Auth>
            <ClientIndividual />
          </RequireVitafy360Auth>
        }
        path={uiRoutes.tenantManagement.allIndividuals}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Cases />} path={uiRoutes.cases}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<CommissionReport />}
        path={uiRoutes.reports.commissionReport}
      />
      <Route
        element={<CommissionReport />}
        path={uiRoutes.reports.comissionReport}
      />
      <Route element={<Calender />} path={uiRoutes.calender}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Leads />} path={uiRoutes.leads}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<Tenant />} path={uiRoutes.tenantManagement.tenants}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={<TenantMembershipOffering />}
        path={uiRoutes.profileSettings.tenantMembershipOfferings}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={<TenantAncillaryOfferings />}
        path={uiRoutes.profileSettings.tenantAncillaryOfferings}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Admins />} path={uiRoutes.profileSettings.admins}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Profile />} path={uiRoutes.profileSettings.profile}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<FacilityNetworks />}
        path={uiRoutes.networks.facilityNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<VirtualVisit />}
        path={uiRoutes.virtualVisit.virtualVisit}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<OfferingNetworks />}
        path={uiRoutes.networks.offeringNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<PhysicianNetworks />}
        path={uiRoutes.networks.physicianNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<ServiceNetworks />}
        path={uiRoutes.networks.serviceNetworks}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<LabService />}
        path={uiRoutes.profileSettings.labService}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Outbox />} path={uiRoutes.comms.outbox}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Inbox />} path={uiRoutes.comms.inbox}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route
        element={<AdvocateCases />}
        path={uiRoutes.reports.advocateCases}
      />
      <Route element={<ReportDashboard />} path={uiRoutes.reports.dashboard} />
      <Route element={<SalesByRep />} path={uiRoutes.reports.salesByRep} />
      <Route
        element={<SalesByCompany />}
        path={uiRoutes.reports.salesByCompany}
      />
      <Route element={<ClientsReport />} path={uiRoutes.reports.clients} />
      <Route element={<Transactions />} path={uiRoutes.billings.transactions}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<TestReport />} path={uiRoutes.testReport}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={<UpcomingTransactions />}
        path={uiRoutes.billings.upcoming}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<Invoices />} path={uiRoutes.billings.invoices}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={<FailedTransactions />}
        path={uiRoutes.billings.failedTransactions}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={<Communication />}
        path={uiRoutes.profileSettings.communication}
      >
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route
        element={<EditCommunicationTemplate />}
        path={uiRoutes.profileSettings.editCommunicationTemplate}
      />

      <Route element={<User />} path={uiRoutes.tenantManagement.users}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<ProfileSettings />} path={uiRoutes.settings}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>

      <Route element={<Activity />} path={uiRoutes.timesheet.byActivity} />
      <Route
        element={<TimesheetByAdvocate />}
        path={uiRoutes.timesheet.byAdvocate}
      />
      <Route
        element={<TimesheetBySponsor />}
        path={uiRoutes.timesheet.bySponsor}
      />
      <Route element={<MyTimesheet />} path={uiRoutes.timesheet.myTimesheet} />
    </Route>
    <Route element={<UiGuidelinesLayout />}>
      <Route element={<UiGuidelines />} path={uiRoutes.uiGuidelines} />
    </Route>

    <Route
      element={<ConvertToESignSuccess />}
      path={uiRoutes.documents.convertToESignatureSuccess}
    />

    <Route
      element={<ESignatureSubmitRedirectView />}
      path={uiRoutes.documents.eSignatureSubmitRedirect}
    />

    <Route
      element={<InitiateVirtualVisit />}
      path={uiRoutes.virtualVisit.startVirtualVisit}
    >
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route path={uiRoutes.virtualVisit.clientVirtualVisit}>
      <Route
        element={<ClientVirtualVisit />}
        path={uiRoutes.virtualVisit.clientVirtualVisit}
      />
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route element={<TransactionReceipt />} path={uiRoutes.receipt} />
    </Route>
    <Route element={<ReceiptLayout />}>
      <Route element={<BillingInvoice />} path={uiRoutes.invoice} />
    </Route>

    <Route element={<NoteLayout />}>
      <Route element={<NoteAuthenticate />} path={uiRoutes.noteAuthenticate}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
      <Route element={<EmailNotesFiles />} path={uiRoutes.notes}>
        <Route element={<ModalSwitch />} path={uiRoutes.modal} />
      </Route>
    </Route>
    <Route
      element={<ReportPrintPreview />}
      path={uiRoutes.reports.printPreview}
    />
    <Route
      element={<AgreementSentSuccess />}
      path={uiRoutes.clientSuccessAgreement}
    />
    <Route
      element={<VirtualVisitLobbyPage />}
      path={uiRoutes.virtualVisit.lobby}
    >
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route
      element={<VirtualVisitConfirmationPage />}
      path={uiRoutes.virtualVisit.confirmation}
    >
      <Route element={<ModalSwitch />} path={uiRoutes.modal} />
    </Route>
    <Route
      element={<VirtualVisitRequestPage />}
      path={uiRoutes.virtualVisit.rescheduleRequest}
    />
    <Route
      element={<VirtualVisitRequestCancelPage />}
      path={uiRoutes.virtualVisit.cancelRequest}
    />

    <Route element={<StartSigning />} path={uiRoutes.startSigning} />
    {/* Tiny url hash route. This route should be at the end before no match (or wildcard) route */}
    <Route element={<TinyUrl />} path={uiRoutes.tinyUrl} />
    {/* No match found should have the lowest precedence */}
    <Route element={<NoMatch />} path="*" />
  </Routes>
);

export default Router;
