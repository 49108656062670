import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, useTheme } from '@mui/material';
import axios from 'axios';
import { ModalFooter } from 'common';
import FileDropzone from 'common/dropzone';
import { defaultMaxFileSize } from 'constants/file';
import { MAX_FILE_SIZE } from 'constants/virtualVisit';
import { IFileSchema } from 'interfaces/documents';
import { IFileUpload } from 'interfaces/virtual-visit';
import { useSnackbar } from 'notistack';
import { useAddDocumentMutation } from 'services/documents';
import { addMessage, setFileUpload, setFileUploadComplete } from 'stores/chat';
import { uploadFileChat } from 'utils/chat';
import { getCurrentTime } from 'utils/moment';

interface Props {
  eventID: string;
  handleClose?: () => void;
}

const ChatUploadDocument = ({ eventID, handleClose = () => {} }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const timeStamp = getCurrentTime();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFiles, setSelectedFiles] = useState<IFileSchema[]>([]);
  const addDocumentMutation = useAddDocumentMutation();
  const onFileChange = (files: IFileSchema[]) => {
    if (files[0]?.error) {
      return;
    }
    const data = files.map((e: IFileSchema) => {
      const { error, ...rest } = e;
      return rest;
    });
    setSelectedFiles(data);
  };
  const totalFileSize = selectedFiles.reduce(
    (prev, curr) => prev + curr.size,
    0
  );
  const isFileSizeExceeds = totalFileSize > defaultMaxFileSize;
  const isDisabled = () => {
    const containError = selectedFiles.find((e) => e.error);
    if (!selectedFiles.length || containError || isFileSizeExceeds) return true;
    return false;
  };

  const handleActionButtonClick = () => {
    if (selectedFiles && selectedFiles.length < 2) {
      handleClose();
      dispatch(setFileUpload({ ...selectedFiles[0], isUploading: true }));
      uploadFileChat(selectedFiles[0], eventID).then(
        // used any because of  Property 'data' is protected in type 'MediaMessage' but public in type 'IFileUpload' and used outside the block.
        async (message: IFileUpload | any) => {
          dispatch(setFileUploadComplete({ id: selectedFiles[0].id }));
          const file = await axios.get(message?.data?.url);
          const { data: text, name } = file.data;
          dispatch(
            addMessage({
              payload: {
                text,
                isSentByMe: true,
                isTextMessage: false,
                time: timeStamp,
                sender: message?.sender?.name,
                title: message.title,
                type: message?.data?.attachments[0]?.extension,
                fileName: name,
              },
            })
          );
        }
      );
    } else {
      enqueueSnackbar('You can only upload one file at a time.', {
        variant: 'error',
      });
    }
  };

  return (
    <Box>
      <Box minHeight="60vh" padding={2}>
        <FileDropzone maxSize={MAX_FILE_SIZE} onChange={onFileChange} />
      </Box>
      <ModalFooter
        actionButtonLabel="Upload"
        actionButtonType="button"
        cancelButtonType="back"
        isDisabled={isDisabled()}
        isLoading={addDocumentMutation.isLoading}
        onActionButtonClick={handleActionButtonClick}
        onCancelButtonClick={handleClose}
        style={{
          color: `${theme.palette.common.white} !important`,
          backgroundColor: `${theme.palette.secondary.main} !important`, // added !import since comet chat is overriding style
          padding: '10px !important',
          borderRadius: '4px !important',
        }}
      />
    </Box>
  );
};

ChatUploadDocument.defaultProps = {
  handleClose: () => {},
};

export default ChatUploadDocument;
