import { useQuery } from 'react-query';

import * as lookUpAPI from 'api/lookups';
import { ILookUpFilter } from 'interfaces/common';

export const lookUpKeys = {
  all: ['look-up'] as const,
  lists: () => [...lookUpKeys.all, 'list'] as const,
  list: (filters: ILookUpFilter) =>
    [...lookUpKeys.lists(), { filters }] as const,
  search: (filters: ILookUpFilter) =>
    [...lookUpKeys.lists(), 'search', { filters }] as const,
};

export const useLookUpQuery = (
  filters: ILookUpFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    lookUpKeys.list(filters),
    () => lookUpAPI.getAllLookUp(filters),
    { enabled }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
