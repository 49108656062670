import PerfectScrollbar from 'react-perfect-scrollbar';

import { IPermission } from 'interfaces/common';
import { selectActiveMenu, selectMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import SidebarMenu from './SidebarMenu';

const SidebarMenuList = () => {
  const activeMenu = useAppSelector(selectActiveMenu);
  const menus = useAppSelector(selectMenu);
  return (
    <PerfectScrollbar>
      {menus?.map((menu: IPermission) => (
        <SidebarMenu
          isActive={activeMenu?.resourceId === menu.resourceId}
          key={menu.resourceId}
          menu={menu as IPermission}
        />
      ))}
    </PerfectScrollbar>
  );
};

export default SidebarMenuList;
