import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';

interface IRadioBoxButtonProps {
  label: string;
  isActive: boolean;
  onClick: VoidFunction;
  isDisabled?: boolean;
  width?: string;
}

/**
 * Radio button that looks more like a rectangular button rather than circular check that the default radio has
 *
 */
export const RadioBoxButton = ({
  label,
  isActive,
  onClick,
  isDisabled,
  width = '100%',
  ...other
}: IRadioBoxButtonProps) => (
  <Button
    className={isActive ? 'radioBoxButton--active' : ''}
    color={isActive ? 'primary' : undefined}
    disabled={isDisabled}
    onClick={() => onClick()}
    size="small"
    startIcon={isActive ? <FontAwesomeIcon icon={faCircleCheck} /> : undefined}
    sx={{
      width,
      maxWidth: width,
    }}
    tabIndex={0}
    type="button"
    variant="radioBoxButton"
    {...other}
  >
    <Typography sx={{ my: 0, mx: 1, fontSize: 'inherit' }}>{label}</Typography>
  </Button>
);

RadioBoxButton.defaultProps = {
  isDisabled: false,
  width: '100%',
};
