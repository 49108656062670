import React, { useState } from 'react';
import { FlagIcon } from 'react-flag-kit';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import config from 'config';
import { countryCodesAndPhoneExtension } from 'constants/countryCodesAndPhnExt';
import regex from 'constants/regex';
import { CountryCodes } from 'enums/country';
import { getSelectedPhoneExtension } from 'utils/countryCodesPhoneExt';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<any>, ICustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format={config.phone}
        getInputRef={ref}
        isNumericString
        mask="_"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }
);

interface IProps {
  disabled?: boolean;
  isRequired?: boolean;
}

const FormPhoneCountryInput = ({ disabled, isRequired }: IProps) => {
  const [selectedCode, setSelectedCode] = useState(CountryCodes.USA);

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const phoneExt = getValues('phoneExt');

  const countryCode = getValues('countryCode');

  const adaptCountryCode = !countryCode ? CountryCodes.USA : countryCode;
  const isCountrySelectedUS = adaptCountryCode === CountryCodes.USA;

  const onInputChange = (value: string) => {
    if (regex.ONLY_DIGITS.test(value) || !value) {
      setValue('phone', value);
      clearErrors('phone');
    }
  };

  const onSelectChange = (e: any) => {
    setSelectedCode(e.target.value);
    setValue('countryCode', e.target.value);
    setValue(
      'phoneExt',
      getSelectedPhoneExtension(
        e.target.value,
        countryCodesAndPhoneExtension
      )?.replace('+', '')
    );
    setValue('addressLine1', '');
    setValue('addressLine2', '');
    setValue('city', '');
    setValue('state', '');
    setValue('zip', '');
    setValue('phone', '');
  };

  return (
    <Box alignItems="end" display="flex" gap={1} justifyContent="center">
      <Controller
        control={control}
        name="countryCode"
        render={({ field }) => (
          <Select
            {...field}
            defaultValue="US"
            disabled={disabled}
            MenuProps={MenuProps}
            onChange={(e) => onSelectChange(e)}
            renderValue={(selected: any) => (
              <FlagIcon code={selected as any} height="15px" width="21px" />
            )}
            size="small"
            sx={{
              height: '24px',
              display: 'flex',
              alignItems: 'baseline',
              pb: 3.5,
            }}
            variant="standard"
          >
            {countryCodesAndPhoneExtension.map((item) => (
              <MenuItem key={item.countryCode} value={item.countryCode}>
                <Box
                  alignItems="center"
                  display="flex"
                  gap={1}
                  justifyContent="center"
                >
                  <FlagIcon
                    code={item.countryCode as any}
                    height="15px"
                    width="21px"
                  />
                  <Box>
                    <Typography gutterBottom={false}>
                      {item.countryName}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </Select>
        )}
      />

      <Box alignItems="baseline" display="flex" width="100%">
        <TextField
          disabled={disabled}
          inputProps={{ readOnly: true }}
          size="small"
          sx={{ width: '54px' }}
          value={
            phoneExt
              ? `+${phoneExt}`
              : getSelectedPhoneExtension(
                  selectedCode,
                  countryCodesAndPhoneExtension
                )
          }
          variant="standard"
        />
        <Controller
          control={control}
          name="phone"
          render={({ field }) =>
            isCountrySelectedUS ? (
              <TextField
                {...field}
                disabled={disabled}
                error={!!errors.phone}
                fullWidth
                id="masked-phone-input"
                InputProps={{
                  inputComponent: CustomNumberFormat as any,
                }}
                label={isRequired ? 'Phone *' : 'Phone'}
                size="small"
                variant="standard"
              />
            ) : (
              <TextField
                {...field}
                disabled={disabled}
                error={!!errors.phone}
                id="masked-phone-input"
                label={isRequired ? 'Phone *' : 'Phone'}
                onChange={(e) => onInputChange(e.target.value)}
                size="small"
                sx={{ width: '100%' }}
                variant="standard"
              />
            )
          }
        />
      </Box>
    </Box>
  );
};

FormPhoneCountryInput.defaultProps = {
  disabled: false,
  isRequired: false,
};

export default FormPhoneCountryInput;
