import { useMemo } from 'react';

import { TenantSettingsType } from 'enums/tenant-management/tenant';
import { selectTenantSettings } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

export const useGetEnrollmentTenantSettings = (code: string) => {
  const tenantSettings = useAppSelector(selectTenantSettings);

  const settingStatus = useMemo(() => {
    const codeSettings = tenantSettings?.enrollment?.find(
      (item) => item.code === code
    );
    if (!codeSettings) return null;

    if (codeSettings.type === TenantSettingsType.MULTISELECT) {
      return codeSettings.status.split(',').map((item) => item.trim());
    }
    return codeSettings.status;
  }, [code, tenantSettings]);
  return settingStatus;
};
