import { Link, Typography } from '@mui/material';
import commonConstants from 'constants/common';

const CopyrightFooter = () => (
  <>
    <Typography variant="body2">
      <Link
        gutterBottom={false}
        href={commonConstants.TERMS_AND_CONDITION}
        lineHeight={2.5}
        variant="body1"
      >
        Terms & Conditions
      </Link>{' '}
      |{' '}
      <Link
        gutterBottom={false}
        href={commonConstants.PRIVACY_POLICY}
        lineHeight={2.5}
        variant="body1"
      >
        Privacy Policy
      </Link>
    </Typography>
    <Typography display="block" variant="body2">
      Copyright © {new Date().getFullYear()}&nbsp;
      <Link
        gutterBottom={false}
        href={commonConstants.VITAFY_URL}
        lineHeight={2.5}
        variant="body1"
      >
        Vitafy LLC
      </Link>
      . Powered by&nbsp;
      <Link
        gutterBottom={false}
        href={commonConstants.VITAFY_URL}
        lineHeight={2.5}
        variant="body1"
      >
        Vitafy 360
      </Link>
      .
    </Typography>
  </>
);

export default CopyrightFooter;
