import { configureStore } from '@reduxjs/toolkit';

import activityLogReducer from './activity-log';
import appointmentReducer from './appointment';
import authReducer from './auth';
import failedTransactionReducer from './billings/failed';
import invoiceReducer from './billings/invoices';
import transactionReducer from './billings/transactions/index';
import upcomingReducer from './billings/upcoming';
import calenderReducer from './calender';
import caseReducer from './cases';
import chatReducer from './chat';
import clientAgreementReducer from './client-agreement';
import clientReducer from './client-management';
import clientGroupReducer from './client-management/group';
import clientIndividualReducer from './client-management/individual';
import documentsReducer from './documents';
import emailNotesFilesReducer from './email-notes-files';
import langDictReducer from './language-dictionary';
import leadsReducer from './leads';
import messageModal from './messageModal';
import networkReducer from './network';
import networkFacilityReducer from './networks/network-facility';
import networkOfferingsReducer from './networks/network-offerings';
import networkPhysicianReducer from './networks/network-physician';
import networkServiceReducer from './networks/network-services';
import noteReducer from './note';
import paymentReducer from './Payment';
import advocateCasesReducer from './reports/advocate-cases';
import clientsReportReducer from './reports/clients-report';
import commissionReportReducer from './reports/commission-report';
import salesByCompanyReducer from './reports/sales-by-company';
import salesByRepReducer from './reports/sales-by-rep';
import labServiceReducer from './settings/LabService';
import profileAdminReducer from './settings/ProfileAdmins';
import referringAgentReducer from './settings/ReferringAgent';
import tenantAncillaryOfferingsReducer from './settings/TenantAncillaryOffering';
import tenantMembershipOfferingsReducer from './settings/TenantMembershipOffering';
import tenantServiceReducer from './settings/TenantService';
import tenantReducer from './tenant-management/tenant';
import userReducer from './tenant-management/user';
import testReportReducer from './test-report';
import activityReducer from './timesheet/activity';
import timesheetByAdvocateReducer from './timesheet/advocate';
import myTimesheetReducer from './timesheet/my-timesheet';
import timesheetBySponsorReducer from './timesheet/sponsor';
import virtualVisitReducer from './virtual-visit';

export const store = configureStore({
  reducer: {
    activity: activityReducer,
    activityLog: activityLogReducer,
    advocateCasesReport: advocateCasesReducer,
    appointment: appointmentReducer,
    auth: authReducer,
    case: caseReducer,
    calender: calenderReducer,
    client: clientReducer,
    clientAgreement: clientAgreementReducer,
    clientGroup: clientGroupReducer,
    clientIndividual: clientIndividualReducer,
    commissionReport: commissionReportReducer,
    documents: documentsReducer,
    emailNotesFiles: emailNotesFilesReducer,
    failedTransactions: failedTransactionReducer,
    leads: leadsReducer,
    invoice: invoiceReducer,
    labService: labServiceReducer,
    langDict: langDictReducer,
    myTimesheet: myTimesheetReducer,
    network: networkReducer,
    networkFacility: networkFacilityReducer,
    networkOffering: networkOfferingsReducer,
    networkPhysician: networkPhysicianReducer,
    networkService: networkServiceReducer,
    note: noteReducer,
    payment: paymentReducer,
    profileAdmin: profileAdminReducer,
    referringAgent: referringAgentReducer,
    salesByCompany: salesByCompanyReducer,
    salesByRep: salesByRepReducer,
    tenant: tenantReducer,
    tenantAncillaryOffering: tenantAncillaryOfferingsReducer,
    tenantMembershipOffering: tenantMembershipOfferingsReducer,
    tenantService: tenantServiceReducer,
    testReport: testReportReducer,
    timesheetByAdvocate: timesheetByAdvocateReducer,
    timesheetBySponsor: timesheetBySponsorReducer,
    transactions: transactionReducer,
    upcoming: upcomingReducer,
    user: userReducer,
    clientsReport: clientsReportReducer,
    messageModal,
    virtualVisit: virtualVisitReducer,
    chat: chatReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
