import { Controller, useFormContext } from 'react-hook-form';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, MenuItem, TextField, TextFieldProps } from '@mui/material';
import themeVars from 'assets/scss/_theme-vars.module.scss';
import { ISelectOptionProps } from 'interfaces/common';

type Props = {
  name: string;
  label: string;
  data: any[];
  showHelperText?: boolean;
  isRequired?: boolean;
  inputPropsDisable?: boolean;
  clearable?: boolean;
} & TextFieldProps;

const FormSelectTextField = ({
  name,
  label,
  showHelperText,
  isRequired,
  data,
  clearable,
  inputPropsDisable,
  ...others
}: Props) => {
  const {
    watch,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const selectedValue = watch(name);

  const handleClear = () => {
    setValue(name, '');
  };

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <TextField
          {...others}
          {...field}
          error={!!errors[name]}
          fullWidth
          helperText={
            showHelperText ? (errors[name]?.message as React.ReactNode) : null
          }
          InputProps={{
            endAdornment: (
              <IconButton
                className="clear-btn"
                disabled={inputPropsDisable}
                onClick={handleClear}
                size="small"
                sx={{
                  display: 'none',
                  position: 'absolute',
                  right: 15,
                }}
              >
                <FontAwesomeIcon icon={faClose} size="xs" />
              </IconButton>
            ),
            ...others.InputProps,
          }}
          label={`${label} ${isRequired ? '*' : ''}`}
          select
          size={others?.size ?? 'small'}
          sx={{
            '& .Mui-focused,  &:hover': {
              '& .clear-btn': {
                display: selectedValue && clearable ? 'inherit' : 'none',
              },
            },
          }}
          variant="standard"
        >
          {data.map((option: ISelectOptionProps) => (
            <MenuItem
              key={option.value}
              sx={{ fontSize: `${themeVars.body2FontSize}px` }}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

FormSelectTextField.defaultProps = {
  showHelperText: false,
  isRequired: false,
  clearable: true,
  inputPropsDisable: false,
};

export default FormSelectTextField;
