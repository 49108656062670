import { useFormContext } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';
import LocationAutocomplete from 'common/autocomplete/LocationAutocomplete';
import { ILocationDetail } from 'interfaces/common';
import { IAdaptedLocation } from 'interfaces/location';

type Props = {
  name: string;
  disabled?: boolean;
  onSelectHandler?: (data: IAdaptedLocation) => void;
  showHelperText?: boolean;
  variant?: 'filled' | 'standard';
  disableClearable?: boolean;
  pinCurrentLocation?: boolean;
} & TextFieldProps;

const FormLocationAutocomplete = ({
  name,
  disabled,
  variant,
  onSelectHandler,
  showHelperText,
  disableClearable,
  pinCurrentLocation,
  ...others
}: Props): JSX.Element => {
  const methods = useFormContext();
  const {
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = methods;

  const selectedLocation = watch('location');

  const onSelectLocation = (data: IAdaptedLocation) => {
    setValue(name, {
      zip: data.zip,
      city: data.city,
      st: data.st,
      lat: data.lat,
      lng: data.lng,
      name: data.name,
    });
    clearErrors(name);

    if (onSelectHandler !== undefined) {
      onSelectHandler(data);
    }
  };

  const onCurrentLocationSelect = (loc: ILocationDetail) => {
    if (!loc) return;

    onSelectLocation({
      zip: loc.zip,
      city: loc.city,
      st: loc.zip?.toString() || '',
      lat: loc.lat,
      lng: loc.lng,
      name: loc.displayName,
    });
  };

  return (
    <LocationAutocomplete
      defaultValue={selectedLocation}
      disableClearable={disableClearable}
      handleChange={onSelectLocation}
      name={name}
      onCurrentLocationSelect={onCurrentLocationSelect}
      pinCurrentLocation={pinCurrentLocation}
      textFieldFilledVariantProps={{
        sx: {
          width: 220,
          ...others?.sx,
        },
      }}
      textFieldProps={{
        ...others,
        error: !!errors[name],
        helperText: showHelperText
          ? (errors[name]?.message as React.ReactNode)
          : null,
      }}
      variant={variant}
    />
  );
};

FormLocationAutocomplete.defaultProps = {
  disabled: false,
  showHelperText: false,
  variant: 'outlined',
  onSelectHandler: undefined,
  disableClearable: true,
  pinCurrentLocation: false,
};

export default FormLocationAutocomplete;
