import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, SxProps, Typography, useTheme } from '@mui/material';
import { FormCheckbox } from 'common';
import paymentConstants from 'constants/payment';
import uiRoutes from 'constants/uiRoutes';
import { useAppSelector } from 'stores/hooks';
import { selectGatewayDetail } from 'stores/Payment';

interface IProps {
  wrapperSx?: SxProps;
}

const AgreeTerms = ({ wrapperSx }: IProps) => {
  const theme = useTheme();
  const gatewayDetail = useAppSelector(selectGatewayDetail);

  const gatewayMetadata = gatewayDetail?.gatewayType
    ? paymentConstants.gatewayMetadata[gatewayDetail.gatewayType]
    : null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...wrapperSx,
      }}
    >
      <FormCheckbox
        data-cy="clients-agree-terms"
        label={
          <Typography variant="body1">
            I agree with{' '}
            <Link component={RouterLink} to={uiRoutes.index} variant="body1">
              terms{' '}
            </Link>{' '}
            and{' '}
            <Link component={RouterLink} to={uiRoutes.index} variant="body1">
              policy
            </Link>
          </Typography>
        }
        name="agreeTerms"
      />

      {gatewayMetadata?.image && gatewayMetadata?.link && (
        <Box
          component="a"
          href={gatewayMetadata.link}
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...theme.typography.body2,
            textDecoration: 'none',
            color: theme.palette.text.secondary,
          }}
          target="_blank"
        >
          Powered by
          <Box
            alt="payment-gateway"
            component="img"
            height={gatewayMetadata.imageHeight}
            ml={1}
            src={gatewayMetadata.image}
          />
        </Box>
      )}
    </Box>
  );
};

AgreeTerms.defaultProps = {
  wrapperSx: null,
};

export default AgreeTerms;
