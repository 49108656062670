import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  faCalendarCheck,
  faCircleCheck,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import DatePicker from 'common/DatePicker';
import NoRecordsFoundDisplay from 'common/display/NoRecordsFoundDisplay';
import FormBanner from 'common/form/FormBanner';
import LoadingIndicator from 'common/LoadingIndicator';
import ModalFooter from 'common/ui/modal/ModalFooter';
import config from 'config';
import commonConstants from 'constants/common';
import {
  ChangeMembershipActionType,
  MembershipOption,
  OfferingType,
} from 'enums/client-management';
import { DateUnit } from 'enums/moment';
import { BillingInterval } from 'enums/settings/tenant-offerings';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import {
  IChangeMembershipSelectedOffering,
  IMembershipOfferings,
} from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { useTenantOfferingQuery } from 'services/tenant-management/tenant/tenantMembershipOffering';
import { selectAuthTenantAssociation } from 'stores/auth';
import {
  selectChangeMembershipClientData,
  selectChangeMembershipModal,
  selectChangeMembershipSelectedData,
  selectCurrentMembershipSelectedData,
  setChangeMembershipSelectedData,
  setChangeMembershipStep,
} from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  getNextBillingDate,
  getNextBillingDateSelectOptions,
} from 'utils/client-management/enrollment';
import {
  addUnitToDate,
  checkIfDateisInFuture,
  formatDateView,
  getCurrentDate,
  getMomentDate,
} from 'utils/moment';

const ChangeMembershipPlanLists = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId');
  const clientEnrollmentId = searchParams.get('clientEnrollmentId');

  const changeMembershipActionType = useAppSelector(
    selectChangeMembershipModal
  )?.actionType;
  const clientData = useAppSelector(selectChangeMembershipClientData) || null;
  const currentMembershipSelectedData =
    useAppSelector(selectCurrentMembershipSelectedData) || null;
  const changeMembershipSelectedData =
    useAppSelector(selectChangeMembershipSelectedData) || null;

  const userIsClient = useCheckUserIsClient();

  const [selectedMembership, setSelectedMembership] = useState<string>(
    changeMembershipSelectedData?.selectedMembershipType ||
      MembershipOption.PUBLIC
  );
  const [membershipDate, setMembershipDate] = useState<string>(
    changeMembershipSelectedData?.startDate || getCurrentDate()
  );
  const [allMembershipOfferingData, setAllMembershipOfferingData] = useState<
    IMembershipOfferings[]
  >([]);
  const [membershipOfferingList, setMembershipOfferingList] = useState<
    IMembershipOfferings[]
  >([]);
  const [isMembershipOfferSelected, setIsMembershipOfferSelected] =
    useState<boolean>(false);
  const [endDate, setEndDate] = useState<string>('');
  const [isSelectedDateInvalid, setIsSelectedDateInvalid] =
    useState<boolean>(false);

  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);
  const tenantId = currentMembershipSelectedData?.tenantId
    ? currentMembershipSelectedData.tenantId
    : clientData?.tenantId;

  const authTenantId = authTenantAssociationData?.tenantId;
  const isSelectedMembershipBenefitStartDateInFuture = checkIfDateisInFuture(
    currentMembershipSelectedData?.startDate || ''
  );

  const tenantOfferingQuery = useTenantOfferingQuery(
    tenantId!,
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      type: OfferingType.MEMBERSHIP,
      clientId: clientId!,
      clientEnrollmentId: clientEnrollmentId || '',
    },
    {
      enabled: !!authTenantId,
    }
  );

  const clearEndDate = () => {
    setEndDate('');
  };

  const endDateOptions = useMemo(() => {
    if (changeMembershipActionType !== ChangeMembershipActionType.CHANGE) {
      return [];
    }

    const billingInterval =
      currentMembershipSelectedData?.offerings?.billingInterval;

    // Dropdown option is not shown if the billing interval is 'One Time'
    if (billingInterval === BillingInterval.ONE_TIME) {
      return [];
    }

    const startDate = currentMembershipSelectedData?.startDate;

    if (!(billingInterval && startDate)) return [];

    const nextBillingDate = getNextBillingDate(
      startDate,
      billingInterval as BillingInterval
    );

    return getNextBillingDateSelectOptions({
      billingInterval: billingInterval as BillingInterval,
      nextBillingDate: nextBillingDate as Date | string,
      benefitStartDate: startDate,
    });
  }, [
    currentMembershipSelectedData?.offerings?.billingInterval,
    currentMembershipSelectedData?.startDate,
    changeMembershipActionType,
  ]);

  useEffect(() => {
    if (endDateOptions.length > 0) {
      setEndDate(endDateOptions[0]?.value as string);
    }
  }, [endDateOptions]);

  const isLoading =
    tenantOfferingQuery.isLoading || tenantOfferingQuery.isFetching;

  useEffect(() => {
    const membershipOptionsOfferingData = tenantOfferingQuery.data?.rows || [];
    setAllMembershipOfferingData(membershipOptionsOfferingData);
    let filteredOfferingList = [];
    if (changeMembershipActionType === ChangeMembershipActionType.ADD) {
      filteredOfferingList = membershipOptionsOfferingData.filter(
        (item) => item.visibility === selectedMembership
      );
    } else {
      filteredOfferingList = membershipOptionsOfferingData.filter(
        (item) =>
          item.visibility === selectedMembership &&
          item.tenantOfferingId !== currentMembershipSelectedData?.offerings.id
      );
    }
    setMembershipOfferingList(filteredOfferingList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMembershipSelectedData?.offerings.id,
    selectedMembership,
    tenantOfferingQuery.data?.rows,
  ]);

  useEffect(() => {
    let filteredOfferingList = [];
    if (changeMembershipActionType === ChangeMembershipActionType.ADD) {
      filteredOfferingList = allMembershipOfferingData.filter(
        (item) => item.visibility === selectedMembership
      );
    } else {
      filteredOfferingList = allMembershipOfferingData.filter(
        (item) =>
          item.visibility === selectedMembership &&
          item.tenantOfferingId !== currentMembershipSelectedData?.offerings.id
      );
    }

    setMembershipOfferingList(filteredOfferingList);
    setIsMembershipOfferSelected(false);
    dispatch(
      setChangeMembershipSelectedData({
        selectedMembershipType:
          changeMembershipSelectedData?.selectedMembershipType,
      })
    );
  }, [
    selectedMembership,
    allMembershipOfferingData,
    dispatch,
    changeMembershipSelectedData?.selectedMembershipType,
    currentMembershipSelectedData?.offerings.id,
    changeMembershipActionType,
  ]);

  useEffect(() => {
    dispatch(
      setChangeMembershipSelectedData({
        startDate: membershipDate ?? getCurrentDate(),
      })
    );
  }, [dispatch, membershipDate]);

  useEffect(() => {
    dispatch(
      setChangeMembershipSelectedData({
        selectedMembershipType: selectedMembership,
      })
    );
  }, [dispatch, selectedMembership]);

  /**
   * Side effect to retain the previous values for start date and selected membership
   */
  useEffect(() => {
    if (changeMembershipSelectedData?.selectedMembershipType) {
      setSelectedMembership(
        changeMembershipSelectedData?.selectedMembershipType as string
      );
    }

    if (changeMembershipSelectedData?.startDate) {
      setMembershipDate(changeMembershipSelectedData?.startDate as string);
    }

    if (
      changeMembershipSelectedData?.startDate &&
      changeMembershipSelectedData?.selectedMembershipType &&
      changeMembershipSelectedData?.selectedOffering !== null
    ) {
      setIsMembershipOfferSelected(true);
    }
  }, [changeMembershipSelectedData]);

  useEffect(() => {
    if (currentMembershipSelectedData?.endDate) {
      setEndDate(currentMembershipSelectedData?.endDate);
    }
  }, [currentMembershipSelectedData]);

  const selectedOfferingStateId =
    changeMembershipSelectedData?.selectedOffering?.tenantOfferingId || '';

  const handlePrevious = () => {
    setSelectedMembership(MembershipOption.PUBLIC);
    dispatch(
      setChangeMembershipSelectedData({
        selectedMembershipType: null,
        startDate: getCurrentDate(),
        selectedOffering: null,
      })
    );
    dispatch(
      setChangeMembershipStep({
        step: 0,
        actionType: changeMembershipActionType,
      })
    );
  };

  const handleContinue = () => {
    if (changeMembershipActionType === ChangeMembershipActionType.CHANGE) {
      dispatch(
        setChangeMembershipSelectedData({
          endDate,
        })
      );
    }
    dispatch(
      setChangeMembershipStep({
        step: 2,
        actionType: changeMembershipActionType,
      })
    );
  };

  const selectedMemberOption = (item: IChangeMembershipSelectedOffering) => {
    setIsMembershipOfferSelected(true);
    dispatch(
      setChangeMembershipSelectedData({
        selectedMembershipType: selectedMembership,
        selectedOffering: item!,
        startDate: membershipDate,
      })
    );
  };

  const getMembershipStartOrEndText = () => {
    if (
      changeMembershipActionType === ChangeMembershipActionType.CHANGE &&
      !isSelectedMembershipBenefitStartDateInFuture
    ) {
      return 'end';
    }
    if (
      isSelectedMembershipBenefitStartDateInFuture &&
      changeMembershipActionType === ChangeMembershipActionType.CHANGE
    ) {
      return 'start';
    }
    return 'membership and start';
  };

  return (
    <Box>
      {!isLoading && (
        <Box>
          <Box
            sx={{
              maxHeight: '60vh',
              overflowY: 'auto',
            }}
          >
            <FormBanner
              description={`Please select a new ${getMembershipStartOrEndText()} date
            from below:`}
              title={
                <>
                  {changeMembershipActionType ===
                    ChangeMembershipActionType.ADD && (
                    <Typography
                      color="gray.darker"
                      fontWeight="medium"
                      gutterBottom={false}
                      variant="body1"
                    >
                      You are adding a new membership for{' '}
                      {clientData?.fullName
                        ? clientData?.fullName
                        : clientData?.businessName}
                      .
                    </Typography>
                  )}
                  {changeMembershipActionType ===
                    ChangeMembershipActionType.CHANGE && (
                    <Typography
                      color="gray.darker"
                      fontWeight="medium"
                      gutterBottom={false}
                      style={{ fontSize: '17px' }}
                      variant="body1"
                    >
                      You are updating the{' '}
                      {currentMembershipSelectedData?.offerings.name} for{' '}
                      {clientData?.fullName
                        ? clientData?.fullName
                        : clientData?.businessName}
                      .
                    </Typography>
                  )}
                </>
              }
            />

            {changeMembershipActionType !==
              ChangeMembershipActionType.CHANGE && (
              <Box>
                <Box>
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    marginBottom={0.5}
                    padding={2}
                    spacing={2}
                  >
                    <Grid item xs={8}>
                      <Typography
                        fontWeight="medium"
                        gutterBottom={false}
                        variant="body2"
                      >
                        Membership Options
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid
                        alignItems="center"
                        container
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          sx={{ textAlign: 'right', paddingRight: '0.5rem' }}
                          xs={3}
                        >
                          <Typography variant="caption">Show</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            data-cy="client-modifyMembership-membership-options"
                            disabled={!!userIsClient}
                            fullWidth
                            onChange={(e) =>
                              setSelectedMembership(e.target.value)
                            }
                            select
                            size="small"
                            value={selectedMembership}
                            variant="standard"
                          >
                            <MenuItem
                              data-cy={`client-modifyMembership-membership-options-${MembershipOption.PUBLIC}`}
                              value={MembershipOption.PUBLIC}
                            >
                              {MembershipOption.PUBLIC}
                            </MenuItem>
                            <MenuItem
                              data-cy={`client-modifyMembership-membership-options-${MembershipOption.PRIVATE}`}
                              value={MembershipOption.PRIVATE}
                            >
                              {MembershipOption.PRIVATE}
                            </MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {!isLoading && membershipOfferingList.length > 0 && (
                  <Box marginBottom={2} paddingX={2}>
                    {membershipOfferingList.map((item) => (
                      <Grid
                        container
                        data-cy={`client-modifyMembership-membershipOfferingList-${item.name}`}
                        display="flex"
                        justifyContent="space-between"
                        key={item.id}
                        onClick={() => selectedMemberOption(item)}
                        sx={{
                          border: `1px solid ${
                            selectedOfferingStateId === item.tenantOfferingId
                              ? theme.palette.primary.main
                              : theme.palette.gray.main
                          }`,
                          paddingY: '0.3rem',
                          borderRadius: '3px',
                          cursor: 'pointer',
                          marginBottom: '0.5rem',
                        }}
                      >
                        <Grid item position="relative" px={3} xs={8}>
                          <Typography
                            color="text.primary"
                            display="inline"
                            variant="body2"
                          >
                            {selectedOfferingStateId ===
                              item.tenantOfferingId && (
                              <Box
                                color={theme.palette.primary.main}
                                component={FontAwesomeIcon}
                                icon={faCircleCheck}
                                left={6}
                                position="absolute"
                                top={6}
                              />
                            )}
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            color="primary.main"
                            display="inline"
                            variant="body2"
                          >
                            {item.price}{' '}
                            <Typography
                              display="inline"
                              sx={{ color: theme.palette.text.primary }}
                              variant="body2"
                            >
                              {item.formattedBillingType}{' '}
                              {item.formattedBillingInterval}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                )}
                {!isLoading && membershipOfferingList.length === 0 && (
                  <Box>
                    <NoRecordsFoundDisplay />
                  </Box>
                )}
              </Box>
            )}
            <Box marginBottom={2}>
              <Grid
                alignItems="center"
                container
                display="flex"
                justifyContent="space-between"
                padding={2}
                spacing={2}
              >
                <Grid item>
                  <Typography fontWeight="medium" variant="body2">
                    When would you like to{' '}
                    {changeMembershipActionType ===
                      ChangeMembershipActionType.CHANGE &&
                    !isSelectedMembershipBenefitStartDateInFuture
                      ? 'end'
                      : 'start'}{' '}
                    membership?
                  </Typography>
                </Grid>
                <Grid item>
                  {changeMembershipActionType ===
                    ChangeMembershipActionType.ADD && (
                    <Box>
                      <DatePicker
                        disablePast={
                          changeMembershipActionType !==
                          ChangeMembershipActionType.ADD
                        }
                        inputFormat={config.dateViewFormat}
                        inputProps={{
                          variant: 'standard',
                          'data-cy':
                            'client-modifyMembership-start-membership-date',
                        }}
                        inputStyle={{ width: '240px', marginLeft: 2 }}
                        label="Start Date"
                        onChange={(date) =>
                          setMembershipDate(
                            formatDateView(date as string) || ''
                          )
                        }
                        value={membershipDate}
                      />
                    </Box>
                  )}
                  {changeMembershipActionType ===
                    ChangeMembershipActionType.CHANGE && (
                    <Box>
                      {!isSelectedMembershipBenefitStartDateInFuture &&
                      endDateOptions.length > 0 ? (
                        <>
                          <FontAwesomeIcon
                            color={theme.palette.primary.main}
                            icon={faCalendarCheck}
                            style={{
                              margin: '0.5rem 0.5rem 0 0',
                            }}
                          />
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ marginLeft: '0.5rem' }}
                                >
                                  <IconButton
                                    aria-label="clear end date"
                                    edge="end"
                                    onClick={clearEndDate}
                                    style={{ marginLeft: '-2.6rem' }}
                                  >
                                    <FontAwesomeIcon
                                      fontSize={16}
                                      icon={faClose}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setEndDate(e.target.value)}
                            select
                            size="small"
                            sx={{ width: '150px' }}
                            value={endDate}
                            variant="standard"
                          >
                            {endDateOptions.map((x: any) => (
                              <MenuItem value={x.value}>{x.label}</MenuItem>
                            ))}
                          </TextField>
                        </>
                      ) : (
                        <DatePicker
                          // disableFuture
                          inputProps={{
                            variant: 'standard',
                          }}
                          inputStyle={{ width: '240px', marginLeft: 2 }}
                          label={
                            isSelectedMembershipBenefitStartDateInFuture
                              ? 'Start Date'
                              : 'End Date'
                          }
                          minDate={
                            // eslint-disable-next-line no-nested-ternary
                            !isSelectedMembershipBenefitStartDateInFuture
                              ? currentMembershipSelectedData?.startDate
                                ? getMomentDate(
                                    addUnitToDate(
                                      currentMembershipSelectedData?.startDate,
                                      1,
                                      DateUnit.DAYS
                                    )
                                  )
                                : undefined
                              : null
                          }
                          onChange={(date) =>
                            setEndDate(formatDateView(date as string) || '')
                          }
                          onError={(e) => {
                            if (
                              e === null &&
                              changeMembershipActionType ===
                                ChangeMembershipActionType.CHANGE
                            ) {
                              setIsSelectedDateInvalid(false);
                            } else {
                              setIsSelectedDateInvalid(true);
                            }
                          }}
                          value={endDate}
                        />
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box marginBottom={2} paddingX={2}>
              {changeMembershipActionType !==
                ChangeMembershipActionType.CHANGE && (
                <Alert severity="info" sx={{ marginBottom: '0 !important' }}>
                  Billing will start on the membership start date. An upcoming
                  billing will also be set for the next billing cycle according
                  to the membership selected.
                </Alert>
              )}
            </Box>
          </Box>
          <ModalFooter
            actionButtonLabel="Continue"
            actionButtonType="button"
            cancelButtonType="previous"
            isDisabled={
              (!isMembershipOfferSelected || !membershipDate) &&
              (changeMembershipActionType !==
                ChangeMembershipActionType.CHANGE ||
                isSelectedDateInvalid)
            }
            isLoading={false}
            onActionButtonClick={handleContinue}
            onCancelButtonClick={handlePrevious}
          />
        </Box>
      )}
      {isLoading && <LoadingIndicator />}
    </Box>
  );
};

export default ChangeMembershipPlanLists;
