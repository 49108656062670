import { useEffect, useMemo, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import { Theme, useTheme } from '@mui/material';
import {
  CoreChartOptions,
  DatasetChartOptions,
  DoughnutControllerChartOptions,
  ElementChartOptions,
  PluginChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { IChartRawData } from 'interfaces/reports/dashboard';
import { mapColorsToLabels, prepareChartData } from 'utils/chart';

interface IDoughnutChart {
  rawData: IChartRawData[];
  backgroundColor?: string | string[];
  borderColor?: string | string[];
  options:
    | _DeepPartialObject<
        CoreChartOptions<'doughnut'> &
          ElementChartOptions<'doughnut'> &
          PluginChartOptions<'doughnut'> &
          DatasetChartOptions<'doughnut'> &
          DoughnutControllerChartOptions
      >
    | undefined;
}

const DoughnutChart = ({
  rawData,
  backgroundColor,
  borderColor,
  options,
  ...rest
}: IDoughnutChart) => {
  const theme: Theme = useTheme();
  const chartRef = useRef<ChartJSOrUndefined<
    'doughnut',
    unknown[],
    unknown
  > | null>(null);

  const { data, labels } = useMemo(() => prepareChartData(rawData), [rawData]);

  const { backgroundColors, borderColors } = useMemo(
    () => mapColorsToLabels(labels),
    [labels]
  );

  useEffect(
    () => () => {
      if (chartRef?.current) {
        chartRef.current?.destroy();
      }
    },
    []
  );

  return (
    <Doughnut
      {...rest}
      data={{
        labels,
        datasets: [
          {
            data,
            backgroundColor: backgroundColors || theme.palette.primary.main,
            borderColor: borderColors || theme.palette.primary.main,
            borderWidth: 1,
          },
        ],
      }}
      options={options}
      ref={chartRef}
    />
  );
};

DoughnutChart.defaultProps = {
  backgroundColor: '',
  borderColor: '',
};

export default DoughnutChart;
