import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  FormAddressInput,
  FormCheckbox,
  FormGenderInput,
  FormMaskedDateInput,
  FormMaskedLast4SSNInput,
  FormMaskedPhoneInput,
  FormSelectTextField,
  FormTextfield,
} from 'common';
import FormPhoneCountryInput from 'common/form/FormPhoneCountryInput';
import FormTagInput from 'common/form/FormTagInput';
import { bloodGroupOptions } from 'constants/client';
import {
  EnrollmentCode,
  MiscellaneousCode,
} from 'enums/tenant-management/tenant';
import UserType from 'enums/User';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import 'react-phone-input-2/lib/style.css';
import { getAddClientSchema } from 'schema/client-management';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import {
  checkIsEnrollmentSettingEnabled,
  checkIsMiscellaneousSettingEnabled,
} from 'utils/tenantSetting';

interface IProps {
  // 'isEmailRequired', 'isPhoneRequired' are to show '*' in required field.
  // There should be a better way to do this
  isEmailRequired?: boolean;
  isPhoneRequired?: boolean;
  allDisabled?: boolean;
}

// Common form, used for PrimaryForm, GroupMemberForm and DependentForm
const IndividualForm = ({
  allDisabled,
  isEmailRequired,
  isPhoneRequired,
}: IProps): JSX.Element => {
  const { watch, setValue } = useFormContext();

  const phoneValue = watch('phone');

  const editClientData = useAppSelector(selectEditClientData);
  const isUserAgent = useCheckUserIsAgent();
  const isUserClient = useCheckUserIsClient();
  const hideAddNoteField = isUserClient || isUserAgent;
  const isEditMode = !!editClientData?.clientId;
  const isMembershipSelected = !!editClientData?.membership?.id;

  const isInternationalAddressEnabled = checkIsMiscellaneousSettingEnabled(
    MiscellaneousCode.INTERNATIONAL_ADDRESS
  );

  const isPhoneValid = getAddClientSchema({}).fields.phone.isValidSync(
    phoneValue
  );

  useEffect(() => {
    if (!isPhoneValid) {
      setValue('textEnabled', false);
    }
  }, [isPhoneValid, setValue]);

  const isBloodGroupEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.BLOOD_GROUP
  );
  const isEmailEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.ANCILLARY_EMAIL
  );

  const getEmailValidation = () => {
    if (editClientData?.type === UserType.GROUP) return isEmailRequired;

    if (!isEditMode) {
      return isEmailRequired;
    }

    // Email validation from tenant settings is only valid for non-membership client in edit mode.
    return isMembershipSelected ? isEmailRequired : isEmailEnabled;
  };

  return (
    <>
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Personal Information
      </Typography>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={4}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-first-name',
            }}
            label="First Name"
            name="firstName"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-middle-name',
            }}
            label="Middle Name"
            name="middleName"
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-last-name',
            }}
            label="Last Name"
            name="lastName"
            required
          />
        </Grid>
      </Grid>
      <Grid container mb={2} spacing={2}>
        <Grid container item spacing={2} xs={6}>
          <Grid item xs={6}>
            <FormMaskedDateInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'date-input',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormMaskedLast4SSNInput
              disabled={allDisabled}
              inputProps={{
                'data-cy': 'last-four-ssn',
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={6}>
          {isBloodGroupEnabled && (
            <Grid item xs={4}>
              <FormSelectTextField
                data={bloodGroupOptions}
                disabled={allDisabled}
                inputProps={{
                  'data-cy': 'blood-group',
                }}
                label="Blood Group *"
                name="bloodGroup"
              />
            </Grid>
          )}
          <Grid item xs={isBloodGroupEnabled ? 8 : 12}>
            <FormGenderInput isDisabled={allDisabled} />
          </Grid>
        </Grid>
      </Grid>

      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Contact Information
      </Typography>
      <Grid container mb={2} spacing={2}>
        {/* <Grid item xs={12}>
          <FormCommunicationMediumSelect
            isRequired
            label="Communication Medium"
            name="communicationMedium"
          />
        </Grid> */}
        <Grid item xs={6}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-email',
            }}
            label="Email"
            name="email"
            required={getEmailValidation()}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack justifyContent="flex-end">
            {isInternationalAddressEnabled ? (
              <FormPhoneCountryInput
                data-cy="clients-phone-country-custom-input"
                disabled={allDisabled}
                isRequired={isPhoneRequired}
              />
            ) : (
              <FormMaskedPhoneInput
                disabled={allDisabled}
                inputProps={{
                  'data-cy': 'clients-phone-country-input',
                }}
                isRequired={isPhoneRequired}
              />
            )}

            {!allDisabled && (
              <Box display="flex" justifyContent="flex-start" mt={0.5}>
                <FormCheckbox
                  color="primary"
                  data-cy="text-enabled"
                  disabled={!phoneValue || !isPhoneValid}
                  label="Allow Text Notifications"
                  name="textEnabled"
                  sizeSmall
                />
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Grid mt={allDisabled ? -2 : -4}>
        <FormAddressInput allDisabled={allDisabled} />
      </Grid>
      <Grid item mt={2} xs={12}>
        <FormTagInput
          disabled={allDisabled}
          inputProps={{
            'data-cy': 'clients-tag',
          }}
          label="Add Tags"
          name="tags"
          showHelperText
          size="small"
        />
      </Grid>
      {!hideAddNoteField && (
        <Grid mt={2}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{
              'data-cy': 'clients-note',
            }}
            label="Add Note"
            multiline
            name="note"
            rows={4}
          />
        </Grid>
      )}
    </>
  );
};

IndividualForm.defaultProps = {
  allDisabled: false,
  isEmailRequired: true,
  isPhoneRequired: true,
};

export default IndividualForm;
