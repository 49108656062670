import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Typography } from '@mui/material';
import { FormMaskedDateInput } from 'common';
import ModalKey from 'enums/ModalKey';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';

interface ISelectedMembership {
  type: string;
  isActive: boolean;
}

interface IOnSelectedValue {
  name: string;
  tenantOfferingId: string;
  price: string;
  billingInterval: string;
  billingType: string;
}

interface IMembershipRadioCardProps {
  fieldName: string;
  data: IAdaptedTenantMembershipOfferingTableRow;
  onChange: (value: IOnSelectedValue) => void;
  selectedMembership: ISelectedMembership;
  onSelectMembership: (value: ISelectedMembership) => void;
}

const MembershipRadioCard = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: IMembershipRadioCardProps, ref) => {
    const {
      fieldName,
      data,
      onChange,
      selectedMembership,
      onSelectMembership,
      ...other
    } = props;

    const [searchParams] = useSearchParams();
    const selectedTenantOfferingId = searchParams.get('tenantOfferingId');
    const modalType = searchParams.get('type');

    const isActive =
      selectedMembership.type === data.tenantOfferingId &&
      selectedMembership.isActive;

    // In 'Change Membership' mode, the selected membership along with the date should be disabled.
    const isDisabled =
      modalType === ModalKey.CHANGE_CLIENT_ENROLLMENT &&
      data.tenantOfferingId === selectedTenantOfferingId;

    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      if (!inputRef || !inputRef.current) {
        return;
      }
      if (isActive) {
        inputRef.current.focus();
      }
    }, [isActive]);

    const onCardClick = () => {
      if (isDisabled) return;
      const {
        name,
        tenantOfferingId,
        price,
        billingInterval,
        billingType,
        unformattedRegistrationFee,
        hoursPurchased,
      } = data;
      const onChangedData = {
        name,
        tenantOfferingId,
        price,
        billingInterval,
        billingType,
        registrationFee: unformattedRegistrationFee,
        hoursPurchased,
      };
      onChange(onChangedData);
      onSelectMembership({ type: data.tenantOfferingId, isActive: true });
    };

    return (
      <Card className={isActive ? 'active-membership-card' : 'membership-card'}>
        <CardContent
          onClick={onCardClick}
          sx={{
            color: (theme) =>
              isDisabled ? theme.palette.text.secondary : 'inherit',
            minHeight: '190px',
          }}
          {...other}
        >
          <Typography fontWeight="medium" gutterBottom={false} variant="body1">
            {data.name}
          </Typography>
          <Typography gutterBottom={false} variant="h5">
            {data.price}
          </Typography>
          <Typography display="block" gutterBottom={false} variant="caption">
            {data.formattedBillingType} {data.formattedBillingInterval}
          </Typography>

          <Typography
            display="block"
            variant="caption"
            visibility={+data.unformattedRegistrationFee ? 'visible' : 'hidden'}
          >
            Reg. Fee {data.registrationFee}
          </Typography>

          <FormMaskedDateInput
            disabled={isDisabled}
            inputProps={{
              'data-cy': `Benefit-Start-Date-${data.name}`,
            }}
            inputRef={inputRef}
            label="Benefit Start Date"
            name={fieldName}
            sx={{ width: '156px' }}
          />
          <FontAwesomeIcon
            icon={faCircleCheck}
            size="lg"
            style={{ display: 'none' }}
          />
        </CardContent>
      </Card>
    );
  }
);

export default MembershipRadioCard;
