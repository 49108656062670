const toastMessageConstant = {
  TOKEN_EXPIRED: 'Session expired. Please log in to continue.',
  CASES: {
    ADD_REFERRING_PHYSICIAN: 'Referring Physician Added Successfully',
    ADD_NEW_CASE: 'Referral Case Added Successfully',
    ADD_NEW_NOTE: 'Note Added Successfully',
    ADD_NEW_FILE: 'File Added Successfully',
    ADD_NEW_TIMELOG: 'Timelog Added Successfully',
    ADD_DONOR: 'Blood Donors Added Successfully',
    ADD_TASK: 'Task Added Successfully',
    CHANGE_STATUS: 'Status Changed Successfully',
    CHANGE_TENANT: 'Tenant Changed Successfully',
    DELETE_NEW_TIMELOG: 'Timelog deleted Successfully',
    DELETED_NOTE: 'Note Deleted Successfully',
    DELETED_TASK: 'Task Deleted Successfully',
    DONOR_DELETED: 'Blood Donor Deleted Successfully',
    REFERRING_PROVIDER_DELETED: 'Referring Provider Deleted Successfully',
    DONOR_REMOVED: 'Blood Donor Removed Successfully',
    PROVIDER_REMOVED: 'Provider Removed Successfully',
    PROVIDER_SELECTED: 'Provider Selected Successfully',
    DONOR_SELECTED: 'Blood Donor Selected Successfully',
    EDIT_REFERRING_PHYSICIAN: 'Referring Physician Updated Successfully',
    FILE_UPLOADED: 'File Uploaded Successfully',
    FILE_ATTACHED: 'File Attached Successfully',
    FILE_RENAMED: 'File Renamed Successfully',
    FILE_DELETED: 'File Deleted Successfully',
    FILE_SHARED: 'File Shared Successfully',
    NOTIFY: 'User Notified Successfully',
    SHARED: 'Referral Case Shared Successfully',
    UPDATE_SERVICE: 'Service Updated Successfully',
    UPDATE_PATIENT: 'Patient Information Updated Successfully',
    UPDATE_NOTE: 'Note Updated Successfully',
    UPDATE_NEW_TIMELOG: 'Timelog Updated Successfully',
    USER_ASSIGN: 'User Assigned Successfully',
  },
  DOCUMENT: {
    DOCUMENT_UPLOADED: 'Document Uploaded Successfully',
    DOCUMENT_RENAMED: 'Document Renamed Successfully',
    DOCUMENT_DELETED: 'Document Deleted Successfully',
    DOWNLOAD_UNSUCCESS: 'This file cannot be downloaded',
  },
  ADMIN: {
    DELETE: 'Admin Deleted Successfully',
  },
  VIRTUAL_VISIT: {
    UNEXPECTED_ERROR: "Could'nt start the meeting. Please try again later",
    COPIED: 'Link Copied!',
    UNABLE_TO_START_MEETING:
      'Cannot start the meeting. It is already in progress or has been cancelled or completed',
  },
  LEAD: {
    UNABLE_TO_EDIT_NOTE: 'You cannot edit this note',
    UNABLE_TO_DELETE_NOTE: 'You cannot delete this note',
  },
  INVALID_MAINTENANCE_CODE: 'Invalid secret key',
};

export default toastMessageConstant;
