import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import NetworkAutocompleteDetails from 'common/autocomplete/NetworkAutocompleteDetails';

interface IProps {
  selectedNetworkId: string;
  onChange: (value: string) => void;
  allTenantsOptionEnabled?: boolean;
  disableClearable?: boolean;
  placeholder?: string;
  width?: number;
  disabled?: boolean;
  handleClearAction?: VoidFunction;
  sx?: SxProps<Theme> | undefined;
  variant?: 'standard' | 'outlined';
}

const NetworkSelect = ({
  selectedNetworkId,
  onChange,
  sx,
  variant,
  disableClearable,
  disabled,
  allTenantsOptionEnabled,
  handleClearAction,
  placeholder,
  width,
}: IProps) => {
  const handleSelectNetwork = (data: string | null) => {
    if (!data) return;

    onChange(data);
  };
  return (
    <Box alignItems="center" display="inline-flex">
      <NetworkAutocompleteDetails
        allTenantsOptionEnabled={allTenantsOptionEnabled}
        disableClearable={disableClearable}
        disabled={disabled}
        handleClearAction={handleClearAction}
        inputProps={{
          sx: {
            width: `${width}px !important`,
            ...sx,
          },
        }}
        isDefaultSet={!selectedNetworkId}
        onSelectHandler={handleSelectNetwork}
        placeholder={placeholder}
        selectedNetworkId={selectedNetworkId}
        variant={variant || 'outlined'}
      />
    </Box>
  );
};

NetworkSelect.defaultProps = {
  sx: undefined,
  allTenantsOptionEnabled: undefined,
  disableClearable: undefined,
  handleClearAction: undefined,
  placeholder: undefined,
  disabled: undefined,
  variant: 'outlined',
  width: 250,
};

export default NetworkSelect;
