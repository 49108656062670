import { VirtualVisitSocketEvents } from 'enums/virtual-visit';
import { io } from 'socket.io-client';
import { getLocal } from 'utils/storage';

const socket: any = {
  instance: null,
  io(eventId: string, userId: string) {
    if (!this.instance) {
      const jwtToken = getLocal('jwtToken');
      this.instance = io(process.env.REACT_APP_SOCKET_URL || '', {
        path: '/socket/socket.io',
        query: { eventId },
        auth: {
          userId,
          token: jwtToken ? `Bearer ${jwtToken}` : null,
        },
      });
    }
    return this.instance;
  },
};

export default socket;

export const decryptMessageSocket = (base64Data: string) =>
  new Promise((resolve) => {
    socket.instance?.emit(
      VirtualVisitSocketEvents.DECRYPT_MESSAGE,
      { message: base64Data },
      ({ data }: { data: { message: string } }) => resolve(data)
    );
  });
