import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatStore, ISelectedUser } from 'interfaces/chat';
import { IUserTableRow } from 'interfaces/tenant-management/user';
/* eslint-disable no-param-reassign */
import { RootState } from 'stores';
import { getCometChatUID } from 'utils/common';

/** Commented code may be needed in future  */

const initialState: IChatStore = {
  friendList: [],
  friends: [],
  messages: [],
  selectedFriend: null,
  acceptedFriends: [],
  uploadFiles: [],
  isContentLoading: false,
  isLoading: true,
  isWindowOpen: false,
  lastMessage: null,
  unreadMessageCount: 0,
};

export const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setAcceptedFriends: (state, action: PayloadAction<string>) => {
      const data = JSON.parse(action?.payload);
      const mapFriends = data?.map((item: ISelectedUser) => ({
        ...item.conversationWith,
        userId: item.conversationWith.uid,
        lastMessage: { ...item.lastMessage },
        demographic: { fullName: item.conversationWith.name },
        isFriend: true,
      }));
      state.friends = [...mapFriends, ...state.friends];
      state.acceptedFriends = mapFriends;
      state.friendList = [...mapFriends, ...state.friends];
    },
    setSelectedFriend: (state, action: PayloadAction<string>) => {
      state.selectedFriend = JSON.parse(action.payload);
    },
    setFriends: (state, action) => {
      const data = action?.payload?.data;
      const acceptedFriends = state?.acceptedFriends?.map(
        (item) => item.userId
      );

      const filterFriends = data?.filter(
        (item: IUserTableRow) =>
          !acceptedFriends.includes(
            getCometChatUID(
              action?.payload?.groupCode?.toLowerCase(),
              item.userId
            )
          )
      );
      state.friends = [...state.acceptedFriends, ...filterFriends];
      state.friendList = [...state.acceptedFriends, ...filterFriends];
    },
    setConvertToFriend: (state, action) => {
      const findFriend: IUserTableRow | any = state?.friends?.find(
        (item: IUserTableRow) =>
          getCometChatUID(
            action?.payload?.groupCode,
            item?.userId
          )?.toLocaleLowerCase() === action?.payload?.id?.toLowerCase()
      );
      const filterFriends = state?.friends?.filter(
        (item: IUserTableRow) => item?.userId !== findFriend?.userId
      );
      if (!findFriend?.userId) return;

      const payload = {
        ...findFriend,
        uid:
          getCometChatUID(action?.payload?.groupCode, findFriend?.userId) ?? '',
        userId:
          getCometChatUID(action?.payload?.groupCode, findFriend?.userId) ?? '',
        isFriend: true,
        lastMessage: { text: action.payload.text },
      };

      state.friends = [payload, ...filterFriends];
      state.friendList = [payload, ...filterFriends];
      state.acceptedFriends = [payload, ...state.acceptedFriends];
    },
    setMessages: (state, action) => {
      const mapPayload = action.payload?.messages;
      state.messages = mapPayload;
      state.isContentLoading = false;
    },
    addMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    filterUser: (state, action) => {
      // let tempUsers = state.friendList;
      // let tempUsers = [];
      // tempUsers = tempUsers.filter((item: any) =>
      //   item?.demographic?.fullName
      //     ?.toLowerCase()
      //     ?.includes(user?.toLowerCase())
      // );
      state.friends = action.payload;
    },
    searchUser: (state, action) => {
      if (!action?.payload?.text) {
        state.friends = [...state.acceptedFriends, ...action.payload.data];
      } else {
        const acceptedFriends = state?.acceptedFriends?.map((item) =>
          item?.userId?.toLocaleLowerCase()
        );
        const filterFriends = action.payload.data?.filter(
          (item: IUserTableRow) =>
            !acceptedFriends.includes(
              getCometChatUID(
                action?.payload?.groupCode?.toLowerCase(),
                item.userId
              )
            )
        );

        const filterAcceptedFriend = state.acceptedFriends?.filter((item) =>
          item?.demographic?.fullName
            ?.toLowerCase()
            .match(new RegExp(action?.payload?.text?.toLowerCase()))
        );

        state.selectedFriend = null;
        state.friends = [...filterAcceptedFriend, ...filterFriends];
      }
    },
    setIsContentLoading: (state, action) => {
      state.isContentLoading = action.payload;
    },
    setFileUpload: (state, action) => {
      state.uploadFiles = [...state.uploadFiles, action.payload];
    },
    setFileUploadComplete: (state, action) => {
      const files = state.uploadFiles;
      const modifyFileUpload = files
        .map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              isUploading: false,
            };
          }
          return item;
        })
        .filter((item) => item?.isUploading);
      state.uploadFiles = modifyFileUpload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsWindowOpen: (state, action) => {
      state.isWindowOpen = action.payload;
    },
    setLastMessage: (state, action) => {
      state.lastMessage = action.payload;
    },
    setUnreadCount: (state, action) => {
      state.unreadMessageCount = action.payload;
    },
    setAddUnreadCount: (state) => {
      state.unreadMessageCount += 1;
    },
    setFriendRequest: (state, action) => {
      const { friend } = action.payload;

      const payload = {
        ...friend,
        demographic: { fullName: friend?.name },
        uid: friend?.uid ?? '',
        userId: friend?.uid ?? '',
        isFriend: true,
        lastMessage: { text: action.payload.text },
      };

      const filterFriends = state.friends.filter(
        (item) => item.userId?.toLowerCase() !== friend?.uid?.toLowerCase()
      );
      state.friends = [payload, ...filterFriends];
      state.friendList = [payload, ...filterFriends];
    },
  },
});

// Actions
export const {
  setAcceptedFriends,
  setFriends,
  addMessage,
  setMessages,
  setSelectedFriend,
  filterUser,
  searchUser,
  setIsContentLoading,
  setConvertToFriend,
  setFileUpload,
  setFileUploadComplete,
  setIsLoading,
  setIsWindowOpen,
  setLastMessage,
  setUnreadCount,
  setAddUnreadCount,
  setFriendRequest,
} = slice.actions;

// Selectors
export const selectUnacceptedFriends = (state: RootState) => state.chat.friends;
export const selectFriends = (state: RootState) => state.chat.friends;
export const selectAcceptedFriends = (state: RootState) =>
  state.chat.acceptedFriends;
export const selectMessages = (state: RootState) => state.chat.messages;
export const selectLastMessage = (state: RootState) => state.chat.lastMessage;
export const selectSelectedFriend = (state: RootState) =>
  state.chat.selectedFriend;
export const selectIsContentLoading = (state: RootState) =>
  state.chat.isContentLoading;
export const selectIsWindowOpen = (state: RootState) => state.chat.isWindowOpen;
export const selectIsLoading = (state: RootState) => state.chat.isLoading;
export const selectFileUpload = (state: RootState) => state.chat.uploadFiles;
export const selectUnreadCount = (state: RootState) =>
  state.chat.unreadMessageCount;
// Reducer
export default slice.reducer;
