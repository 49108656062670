import { Outlet, useLocation } from 'react-router-dom';

import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import 'assets/scss/_auth-layout.scss';
import CopyrightFooter from 'common/CopyrightFooter';
import Logo from 'common/Logo';
import uiRoutes from 'constants/uiRoutes';
import { useTenantDetailQuery } from 'services/tenant-management/tenant';
import { selectEmailNotesData } from 'stores/email-notes-files';
import { useAppSelector } from 'stores/hooks';

const NoteLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const tenantId = useAppSelector(selectEmailNotesData)?.tenantId;
  const { data } = useTenantDetailQuery(tenantId, {
    enabled: !!tenantId,
  });

  const FULLSCREEN_ON_MOBILE_ROUTES = [
    uiRoutes.notes,
    `${uiRoutes.notes}/modal`,
  ];

  if (
    FULLSCREEN_ON_MOBILE_ROUTES.includes(location.pathname) &&
    isSmallerThanMd
  ) {
    return <Outlet />;
  }

  const isBackgroundImageOrBoxshadowVisible =
    location.pathname.includes(uiRoutes.noteAuthenticate) ||
    location.pathname.includes(uiRoutes.questionnaireResponseAuthenticate) ||
    location.pathname.includes(uiRoutes.referralCases.share) ||
    location.pathname.includes(uiRoutes.transcationReceiptAuthenticate)
      ? 'none'
      : '';

  return (
    <Box
      className="heroku-img"
      sx={{
        backgroundImage: isBackgroundImageOrBoxshadowVisible,
      }}
    >
      <Container
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2rem',
        }}
      >
        <Logo
          src={data?.metaData?.imageUrl ?? ''}
          sx={{
            height: 60,
            display: 'block',
            marginX: 'auto',
            marginBottom: '1rem',
          }}
        />
        <Paper
          sx={{
            width: '100%',
            maxWidth: '600px',
            boxShadow: isBackgroundImageOrBoxshadowVisible,
          }}
        >
          <Box>
            <Outlet />
          </Box>
        </Paper>
        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          <CopyrightFooter />
        </Box>
      </Container>
    </Box>
  );
};

export default NoteLayout;
