import React, { useEffect, useState } from 'react';

import {
  faEdit,
  faSave,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { IFileSchema } from 'interfaces/documents';
import {
  getFileNameWithExtension,
  getFilenameWithoutExtension,
} from 'utils/file';

const DropZoneFileList = ({
  file,
  handleFileDelete,
  handleFileEdit,
}: {
  file: IFileSchema;
  handleFileDelete: (id: string) => void;
  handleFileEdit: (file: any) => void;
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [name, setName] = useState('');
  const theme = useTheme();

  const borderColor = file.error
    ? theme.palette.error.main
    : theme.palette.primary.main;

  const handleSave = () => {
    const modifiedName = getFileNameWithExtension(file.name, name);
    const info = {
      ...file,
      name: modifiedName,
    };
    setIsEditMode(false);
    handleFileEdit(info);
  };
  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => setIsEditMode(false);

  useEffect(() => {
    if (isEditMode) {
      const nameWithoutExtension = getFilenameWithoutExtension(file.name);
      setName(nameWithoutExtension);
    }
  }, [file.name, isEditMode]);
  return (
    <Box
      component="li"
      display="flex"
      justifyContent="space-between"
      key={file.id}
      marginBottom={1}
    >
      <Box
        sx={{
          ...(!isEditMode && {
            borderBottom: `1px solid ${borderColor}`,
          }),
          textAlign: 'left',
        }}
        width="95%"
      >
        {isEditMode ? (
          <TextField
            autoFocus
            fullWidth
            name="name"
            onChange={(e) => setName(e.target.value.trim())}
            value={name}
            variant="standard"
          />
        ) : (
          <Typography>{file.name}</Typography>
        )}
      </Box>
      <Box textAlign={name ? 'right' : 'center'} width="12%">
        {isEditMode ? (
          <>
            {name && (
              <IconButton onClick={handleSave}>
                <FontAwesomeIcon icon={faSave} size="xs" />
              </IconButton>
            )}
            <IconButton onClick={handleCancel}>
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </IconButton>
          </>
        ) : (
          <>
            <Tooltip placement="bottom" title="Edit Filename">
              <IconButton onClick={handleEdit}>
                <FontAwesomeIcon icon={faEdit} size="xs" />
              </IconButton>
            </Tooltip>
            <Tooltip placement="bottom" title="Delete File">
              <IconButton onClick={() => handleFileDelete(file.id)}>
                <FontAwesomeIcon icon={faTrashAlt} size="xs" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DropZoneFileList;
