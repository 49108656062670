import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { PaymentInformation } from 'common';
import commonConstants from 'constants/common';
import { PaymentSourceStatus } from 'enums/Payment';
import { ISetAsPrimaryForm } from 'interfaces/payment';
import {
  usePaymentSourceQuery,
  useSetPrimaryPaymentSourceMutation,
} from 'services/payment';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { getLocationSpecificTenantId } from 'utils/client-management';

const SetAsPrimaryForm = () => {
  const setAsPrimaryFormMethods = useForm<ISetAsPrimaryForm>();
  const { watch, setValue } = setAsPrimaryFormMethods;

  const setAsPrimaryFormRef = useRef<HTMLFormElement>(null);

  const editClientData = useAppSelector(selectEditClientData);
  const locationSpecificTenantId = getLocationSpecificTenantId();

  const paymentSourceQuery = usePaymentSourceQuery(
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      clientId: editClientData?.clientId || '',
      tenantId: locationSpecificTenantId,
    },
    {
      enabled: !!(editClientData?.clientId && locationSpecificTenantId),
    }
  );

  const setPrimaryPaymentSourceMutation = useSetPrimaryPaymentSourceMutation();

  const onSetAsPrimarySubmit = (submitData: ISetAsPrimaryForm) => {
    // Get merchantCustomerId
    const selectedPaymentSource = paymentSourceQuery.data?.rows.find(
      (item) => item.id === submitData.paymentSourceId
    );

    if (
      !selectedPaymentSource ||
      selectedPaymentSource.status === PaymentSourceStatus.PRIMARY
    ) {
      return;
    }

    const payload = {
      paymentSourceId: submitData.paymentSourceId,
      merchantCustomerId: selectedPaymentSource.merchantCustomerId,
      status: PaymentSourceStatus.PRIMARY,
    };

    setPrimaryPaymentSourceMutation.mutate({
      paymentSourceId: submitData.paymentSourceId,
      merchantCustomerId: selectedPaymentSource.merchantCustomerId,
      data: payload,
    });
  };

  const paymentSourceId = watch('paymentSourceId');

  useEffect(() => {
    if (paymentSourceId) {
      // Trigger form submit as soon as the user clicks on the radio button
      setAsPrimaryFormRef.current?.requestSubmit();
    }
  }, [paymentSourceId]);

  useEffect(() => {
    if (
      paymentSourceQuery.data?.rows &&
      paymentSourceQuery.data?.rows?.length > 0
    ) {
      const primaryPaymentSource = paymentSourceQuery.data.rows.find(
        (item) => item.status === PaymentSourceStatus.PRIMARY
      );
      if (!primaryPaymentSource) {
        return;
      }

      setValue('paymentSourceId', primaryPaymentSource.id);
    }
  }, [paymentSourceQuery.data?.rows, setValue]);

  const noPaymentSourcesFound =
    (!paymentSourceQuery.isLoading &&
      paymentSourceQuery.data?.rows &&
      paymentSourceQuery.data?.rows.length < 1) ??
    true;

  return (
    <FormProvider {...setAsPrimaryFormMethods}>
      <form
        onSubmit={setAsPrimaryFormMethods.handleSubmit(onSetAsPrimarySubmit)}
        ref={setAsPrimaryFormRef}
      >
        <PaymentInformation
          data={paymentSourceQuery?.data?.rows ?? []}
          isLoading={paymentSourceQuery.isLoading}
          isSetAsPrimaryDisabled={setPrimaryPaymentSourceMutation.isLoading}
          noRecordsFound={noPaymentSourcesFound}
        />
      </form>
    </FormProvider>
  );
};

export default SetAsPrimaryForm;
