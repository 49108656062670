import { useEffect, useMemo } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Box } from '@mui/material';
import ChatComponent from 'common/chat';
import LoadingIndicator from 'common/LoadingIndicator';
import SupportInquiry from 'common/support-inquiry';
import uiRoutes from 'constants/uiRoutes';
import { UsersCode } from 'enums/tenant-management/tenant';
import { useSwitchTenantMutation } from 'services/tenant-management/tenant';
import { useAssignedTenantQuery } from 'services/tenant-management/users';
import { selectAuthTenantData, selectTenantSettings } from 'stores/auth';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { setAuthState } from 'utils/auth';
import { checkIsUsersSettingEnabled } from 'utils/tenantSetting';

import Header from './Header';
import Sidebar from './Sidebar';

const DashboardLayout = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const tenantData = useAppSelector(selectAuthTenantData);
  const tenantSettings = useAppSelector(selectTenantSettings);

  const tenantMutation = useSwitchTenantMutation();

  const userId = tenantData?.userId ?? '';
  const clientCode = searchParams.get('client');

  const isAdminChatEnabled = checkIsUsersSettingEnabled(UsersCode.ADMIN_CHAT, {
    tenantSettings,
  });

  const tenantQuery = useAssignedTenantQuery(userId, {
    enabled: !!userId,
  });

  const tenantList = useMemo(
    () =>
      tenantQuery?.data?.map((item) => ({
        // eslint-disable-next-line no-underscore-dangle
        id: item._id,
        label: item.displayName,
        tenantId: item.tenantId,
        code: item.code,
      })),
    [tenantQuery?.data]
  );

  useEffect(() => {
    if (clientCode && tenantList) {
      const selectedTenantId = tenantList?.find(
        (item) => item.code === clientCode
      )?.tenantId;
      tenantMutation.mutate(
        { userId, tenantId: selectedTenantId || '' },
        {
          onSuccess: (response) => {
            if (response.success) {
              setAuthState({
                token: response.data.token,
                permissions: response.data.permissions,
                dispatch,
              });
              navigate(uiRoutes.index);
              window.location.reload();
            }
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode, tenantList]);

  const noPaddingRoutes = [
    uiRoutes.profileSettings.profile,
    uiRoutes.cases,
    `${uiRoutes.cases}/${uiRoutes.modal}`,
    uiRoutes.comms.outbox,
    uiRoutes.comms.inbox,
    uiRoutes.settings,
    uiRoutes.networks.facilityNetworks,
    uiRoutes.networks.physicianNetworks,
    `${uiRoutes.networks.facilityNetworks}/${uiRoutes.modal}`,
    `${uiRoutes.networks.physicianNetworks}/${uiRoutes.modal}`,
    uiRoutes.networks.offeringNetworks,
    `${uiRoutes.networks.offeringNetworks}/${uiRoutes.modal}`,
    `${uiRoutes.settings}/${uiRoutes.modal}`,
  ];

  const shouldRemovePadding = noPaddingRoutes.includes(location.pathname);
  return (
    <>
      {!!clientCode && <LoadingIndicator />}
      {!clientCode && (
        <Box className="dashboard">
          <Header />
          <Sidebar />
          <Box
            className={`dashboard__content-wrapper ${
              shouldRemovePadding && 'dashboard__content-wrapper--no-padding'
            }`}
          >
            <Outlet />
          </Box>
          <SupportInquiry />
          {isAdminChatEnabled && <ChatComponent />}
        </Box>
      )}
    </>
  );
};

export default DashboardLayout;