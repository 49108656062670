export default {
  activityLog: '/user-activities',
  appointmentCheckin: '/appointments',
  auth: {
    activateAccount: 'activate-account',
    activateAccountSignUp: 'activate-account-signup',
    login: 'login',
    logout: 'logout',
    resetPassword: 'reset-password',
    userExistsLinkAccount: 'user-exists-link-account',
    verifyAccount: 'verify-account',
  },
  billings: {
    failedTransactions: '/failed-transactions',
    transactions: '/transactions',
    upcoming: '/upcoming-transactions',
    invoices: '/invoices',
  },
  cases: '/cases',
  calender: '/calendar',
  referralCases: {
    share: '/referral-case/share',
  },
  clientAgreement: '/client/agreement',
  clientSuccessAgreement: '/client/agreement/success',
  comms: {
    inbox: '/communications/inbox',
    outbox: '/communications/outbox',
  },
  questionnaireResponse: '/clients/:respondentId/questionnaires/:code',
  questionnaireResponseAuthenticate: '/questionnaire-response-authenticate',

  dashboard: '/dashboard',
  documents: {
    documents: '/documents',
    convertToESignature: '/documents/:documentId/convert-to-esignature',
    convertToESignatureSuccess: '/documents/convert-to-esignature/success',
    eSignatures: '/e-signatures',
    eSignatureSubmitRedirect: '/e-signature-submit-redirect',
  },
  groups: '/groups',
  index: '/',
  individuals: '/individuals',
  invoice: '/invoice/:invoiceId',
  leads: '/leads',
  modal: 'modal',
  networks: {
    offeringNetworks: '/networks/offerings',
    physicianNetworks: '/networks/physicians',
    serviceNetworks: '/networks/services',
    facilityNetworks: '/networks/facilities',
  },
  noteAuthenticate: '/note-authenticate',
  notes: '/notes',
  overview: 'overview',
  pageNotFound: 'page-not-found',
  receipt: '/receipt/:transactionId',
  reports: {
    advocateCases: '/reports/advocate-cases/by-status',
    clients: '/reports/clients',
    commissionReport: '/reports/commissions',
    comissionReport: '/reports/comissions',
    dashboard: '/reports/dashboard',
    salesByCompany: '/reports/sales-by-company',
    salesByRep: '/reports/sales-by-rep',
    printPreview: '/reports/:chartType',
  },
  startSigning: '/start-signing',
  tenantManagement: {
    tenants: '/tenants',
    users: '/users',
    allIndividuals: '/all-individuals',
    allGroups: '/all-groups',
  },
  testResult: '/medical-report/:id',
  tinyUrl: 'redirect/:hash',
  transcationReceiptAuthenticate: '/transaction-receipt-authenticate',
  profileSettings: {
    admins: '/settings/admins',
    communication: '/settings/comms-templates',
    editCommunicationTemplate: '/settings/comms-templates/:id',
    labService: '/settings/medical-tests',
    profile: '/settings/profile',
    tenantAncillaryOfferings: '/settings/ancillary-offerings',
    tenantMembershipOfferings: '/settings/membership-offerings',
  },
  settings: '/settings',
  timesheet: {
    byActivity: '/timesheet/by-activities',
    byAdvocate: '/timesheet/by-advocate',
    bySponsor: '/timesheet/by-sponsor',
    myTimesheet: '/timesheet/self',
  },
  testReport: '/medical-reports',
  virtualVisit: {
    virtualVisit: '/virtual-visit',
    startVirtualVisit: '/start/:eventId',
    clientVirtualVisit: '/virtual-visit/start/:sessionId',
    lobby: '/virtual-visit/lobby',
    confirmation: '/virtual-visit/confirmation',
    rescheduleRequest: '/virtual-visit/reschedule-request/:eventId',
    cancelRequest: '/virtual-visit/cancel-request/:eventId',
  },
  uiGuidelines: '/ui-guidelines',
};
