import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import { validateDob } from 'utils/moment';
import * as Yup from 'yup';

const { REQUIRED, INVALID } = validationMessage;

export const AddUserSchema = Yup.object().shape({
  firstName: Yup.string().nullable().trim().required(REQUIRED),
  lastName: Yup.string().nullable().trim().required(REQUIRED),
  dob: Yup.string()
    .nullable()
    .test('checkIfDobIsValid', INVALID, (value) => {
      if (!value) return true;
      return validateDob(value);
    }),
  gender: Yup.string().nullable(),
  email: Yup.string()
    .nullable()
    .trim()
    .matches(regex.EMAIL, {
      message: INVALID,
    })
    .required(REQUIRED),
  phone: Yup.string().nullable().matches(regex.PHONE, {
    excludeEmptyString: true,
    message: INVALID,
  }),
  addressLine1: Yup.string().nullable(),
  addressLine2: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zip: Yup.string().nullable(),
  // TODO: Removed zip code validation as we need alphanumeric zip codes for implementing international countries.
  //   .matches(regex.ZIP, {
  //   excludeEmptyString: true,
  //   message: INVALID,
  // }),
  enableImpersonation: Yup.bool().nullable(),
});

export const UpdateUserSchema = AddUserSchema.omit(['enableImpersonation']);
