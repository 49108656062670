import { useFormContext } from 'react-hook-form';

import { Grid } from '@mui/material';
import { FormClientAutocomplete, FormTextfield } from 'common';
import FormReferringAgentAutocomplete from 'common/form/FormReferringAgentAutocomplete';
import { ClientEnrollmentStatus } from 'enums/client-management';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import UserType from 'enums/User';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { IAdaptedClientTableRow } from 'interfaces/client-management';
import { IReferredBy } from 'interfaces/client-management/enrollment';
import { selectTenantSettings } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import {
  checkEnrollmentAffiliateVisibleTenantSetting,
  checkIsEnrollmentSettingEnabled,
} from 'utils/tenantSetting';

import IndividualForm from './IndividualForm';

interface IProps {
  allDisabled?: boolean;
  isSponsorIdEnabled?: boolean;
}

const PrimaryForm = ({
  allDisabled,
  isSponsorIdEnabled,
}: IProps): JSX.Element => {
  const { setValue, watch } = useFormContext();

  const tenantSettings = useAppSelector(selectTenantSettings);

  const editClientData = useAppSelector(selectEditClientData);
  const isStatusApproved =
    editClientData?.membership?.status === ClientEnrollmentStatus.APPROVED;

  const isUserAgent = useCheckUserIsAgent();
  const isUserClient = useCheckUserIsClient();

  const isClientAffiliateEnabled = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.CLIENT_AFFILIATE
  );

  const isAffiliateEnabled =
    checkEnrollmentAffiliateVisibleTenantSetting(tenantSettings);

  const watchedReferredBy: IReferredBy | null = watch('referredBy');
  const isReferredByDisabled = isUserAgent || isUserClient || isStatusApproved;

  return (
    <>
      {/* TODO: We are hardcoding below condition .. Later it will be based according to Tenant Settings */}
      {isAffiliateEnabled && (
        <>
          <Grid mb={2}>
            {isClientAffiliateEnabled ? (
              <FormClientAutocomplete
                disabled={isReferredByDisabled}
                inputProps={{
                  'data-cy': 'clients-referred-by',
                }}
                label="Referred by"
                name="referredBy"
                placeholder="Type name or email to search"
                setFieldValue={(client: IAdaptedClientTableRow | null) =>
                  setValue('referredBy', {
                    id: client?.clientId || '',
                    name: client?.name || '',
                  })
                }
                userType={UserType.INDIVIDUAL}
                watchedFieldValue={watchedReferredBy?.id || ''}
              />
            ) : (
              <FormReferringAgentAutocomplete
                disabled={isReferredByDisabled}
                inputProps={{
                  'data-cy': 'clients-referred-by',
                }}
                name="referredBy"
              />
            )}
          </Grid>{' '}
        </>
      )}

      <IndividualForm />
      {isSponsorIdEnabled && (
        <Grid container>
          <Grid mb={4} mt={4} xs={6}>
            <FormTextfield
              disabled={allDisabled || isUserAgent || isUserClient}
              inputProps={{
                'data-cy': 'clients-member-id',
              }}
              label="Member ID"
              name="sponsorId"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
PrimaryForm.defaultProps = {
  allDisabled: false,
  isSponsorIdEnabled: false,
};
export default PrimaryForm;
