import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Box, FormControl, Grid } from '@mui/material';
import ModalKey from 'enums/ModalKey';
import { IStartDates } from 'interfaces/client-management/enrollment';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { getBenefitStartDate } from 'utils/client-management/enrollment';
import { unformatDate } from 'utils/common';

import MembershipRadioCard from './MembershipRadioCard';

interface ISelectedMembership {
  type: string;
  isActive: boolean;
}

interface IProps {
  tenantOfferingId?: string;
  offerings: IAdaptedTenantMembershipOfferingTableRow[];
  startDate?: string;
}

const FormMembershipInput = ({
  tenantOfferingId,
  offerings,
  startDate,
}: IProps) => {
  const { control, reset, getValues, setValue } = useFormContext();

  const [searchParams] = useSearchParams();
  const modalType = searchParams.get('from');
  const selectedTenantOfferingId = searchParams.get('tenantOfferingId');

  const editClientData = useAppSelector(selectEditClientData);

  const [selectedMembership, setSelectedMembership] =
    useState<ISelectedMembership>({ type: '', isActive: false });

  /**
   * Populate existing membership and start date
   */
  useEffect(() => {
    if (startDate && tenantOfferingId && offerings?.length) {
      let membership = {
        name: '',
        tenantOfferingId: '',
        price: '',
        billingInterval: '',
        billingType: '',
        registrationFee: 0,
        hoursPurchased: 0,
      };

      // If in 'Change Membership' mode, the selected membership (tenant offering) should not be pre-selected.
      // However, the start date should be populated.

      if (modalType !== ModalKey.CHANGE_CLIENT_ENROLLMENT) {
        setSelectedMembership({
          type: tenantOfferingId,
          isActive: true,
        });

        const selectedOffering = offerings.find(
          (el: IAdaptedTenantMembershipOfferingTableRow) =>
            el.tenantOfferingId === tenantOfferingId
        );

        if (selectedOffering) {
          membership = {
            name: selectedOffering.name,
            tenantOfferingId: selectedOffering.tenantOfferingId,
            price: selectedOffering.price,
            billingInterval: selectedOffering.billingInterval,
            billingType: selectedOffering.billingType,
            registrationFee: selectedOffering.unformattedRegistrationFee,
            hoursPurchased: selectedOffering.hoursPurchased as number,
          };
        }
      }

      const startDates: IStartDates = {};

      offerings.forEach((item) => {
        startDates[item.tenantOfferingId] =
          startDate && tenantOfferingId === item.tenantOfferingId
            ? unformatDate(startDate)
            : '';
      });

      reset({
        membership,
        startDates,
      });
    }
  }, [tenantOfferingId, reset, startDate, offerings, modalType]);

  /**
   * If benefit start date is not populated already, populate it with today's date if enrolled date is in the past.
   * If enrolled date is in the future, populate it with enrolled date
   *
   * @param {string} id :: tenantOfferingId
   */
  const populateBenefitStartDateOnSelect = (id: string) => {
    const existingStartDates = getValues('startDates');
    if (!existingStartDates[id]) {
      const enrolledDate = editClientData?.createdAt;
      const benefitStartDate = getBenefitStartDate(enrolledDate!);

      setValue(`startDates[${id}]`, benefitStartDate);
    }
  };

  const handleSelectMembership = (value: ISelectedMembership) => {
    setSelectedMembership(value);

    populateBenefitStartDateOnSelect(value.type);
  };

  const membershipOfferingsToShow = useMemo(() => {
    const isChangeMembershipMode =
      modalType === ModalKey.CHANGE_CLIENT_ENROLLMENT;

    /**
     * While changing membership only the membership offerings with price greater the currently selected one is to be shown.
     * Changing membership is essentially a upgrading membership.
     */
    if (
      isChangeMembershipMode &&
      offerings.length &&
      selectedTenantOfferingId
    ) {
      const selected = offerings.find(
        (item: IAdaptedTenantMembershipOfferingTableRow) =>
          item.tenantOfferingId === selectedTenantOfferingId
      );

      if (!selected) return offerings;

      return offerings.filter(
        (item: IAdaptedTenantMembershipOfferingTableRow) =>
          item.unformattedPrice >= selected.unformattedPrice
      );
    }

    return offerings;
  }, [offerings, selectedTenantOfferingId, modalType]);

  return (
    <Box sx={{ cursor: 'pointer' }}>
      <FormControl sx={{ width: '100%' }} variant="outlined">
        <Controller
          control={control}
          name="membership"
          render={({ field }) => (
            <Grid container spacing={1}>
              {membershipOfferingsToShow?.map(
                (membership: IAdaptedTenantMembershipOfferingTableRow) => {
                  const fieldName = `startDates[${membership.tenantOfferingId}]`;

                  return (
                    <Grid item key={membership.tenantOfferingId} xs={6}>
                      <MembershipRadioCard
                        data={membership}
                        data-cy={`add-client-${membership?.name}`}
                        {...field}
                        fieldName={fieldName}
                        onSelectMembership={handleSelectMembership}
                        selectedMembership={selectedMembership}
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          )}
        />
      </FormControl>
    </Box>
  );
};

FormMembershipInput.defaultProps = {
  tenantOfferingId: undefined,
  startDate: undefined,
};

export default FormMembershipInput;
