import { useMutation, useQuery } from 'react-query';

import * as authAPI from 'api/auth';
import {
  IActivateAccountSchema,
  IChangeCredential,
  IForgetPasswordSchema,
  IImpersonateSchema,
  ILinkAccountSchema,
  ILoginSchema,
  IRevertImpersonateSchema,
  ITwoFactorAuthenticationSchema,
  IVerifyAccountSchema,
} from 'interfaces/auth';
import { IResetPassword } from 'interfaces/auth/index';
import { IError } from 'interfaces/http';
import { useSnackbar } from 'notistack';

export const authKeys = {
  all: ['auth'] as const,
  code: () => [...authKeys.all, 'code'] as const,
};

export const useActivateAccountMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: IActivateAccountSchema }) =>
      authAPI.activateAccount(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useCheckUserExistsMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: IVerifyAccountSchema }) => authAPI.checkUserExists(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useLinkAccountMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: ILinkAccountSchema }) => authAPI.linkAccount(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useForgetPasswordMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ username }: IForgetPasswordSchema) => authAPI.forgetPassword(username),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useImpersonateMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: IImpersonateSchema }) => authAPI.impersonateUser(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
    }
  );
};
export const useLoginMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: ILoginSchema }) => authAPI.loginUser(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
export const useRevertImpersonateMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: IRevertImpersonateSchema }) =>
      authAPI.revertImpersonateUser(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useResetPasswordMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: IResetPassword }) => authAPI.resetPassword(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useChangeCredentialMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: IChangeCredential }) => authAPI.changeCredential(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useVerifyCodeQuery = ({
  code,
  enabled,
}: {
  code: string;
  enabled: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryInfo = useQuery(authKeys.code(), () => authAPI.verifyCode(code), {
    enabled,
    onError: (err: IError) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    },
  });

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTwoFactorAuthenticationdMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    ({ data }: { data: ITwoFactorAuthenticationSchema }) =>
      authAPI.twoFactorAuthentication(data),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
