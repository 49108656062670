import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  faCheckCircle as faCheckFilled,
  faCog,
  faLongArrowAltLeft,
  faSignOut,
  faUser,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FilterOptionsState,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import uiRoutes from 'constants/uiRoutes';
import { ReferenceType } from 'enums/common';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useCheckUserIsClientIndividualOrDependentOrGroupMember } from 'hooks/useCheckUserIsClientIndividualOrDependentOrGroupMember';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { IRoles } from 'interfaces/tenant-management/tenant';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { getLocal } from 'utils/storage';

// Show dropdown instead of autocomplete for tenant switch below this threshold
const THRESHOLD_FOR_TENANT_DROPDOWN = 5;

interface ITenantOption {
  id: string;
  label: string;
  tenantId: string;
  referenceId?: string;
  code: string;
}

interface IProps {
  isImpersonated: boolean;
  isRevertLoading: boolean;
  handleRevertImpersonate: VoidFunction;
  tenantList: ITenantOption[];
  onTenantSwitch: (newTenantId: string, newReferenceId?: string) => void;
  onRolesSwitch: (
    label: string,
    referenceId: string,
    refenceSubType: string
  ) => void;
  handleLogout: VoidFunction;
  userRoles: IRoles[];
  isRolesSwitched: boolean;
}

interface IClientHeaderProps {
  label: string;
  referenceId: string;
  referenceSubType: string;
  onRolesSwitch: (
    label: string,
    referenceId: string,
    referenceSubType: string
  ) => void;
  isRolesSwitched: boolean;
}

const ClientInfoHeader = ({
  label,
  onRolesSwitch,
  referenceSubType,
  referenceId,
  isRolesSwitched,
}: IClientHeaderProps) => {
  const adaptLabel =
    label === ReferenceType.TENANT ? ReferenceType.ADMIN : label;
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const referenceType = authTenantAssociation?.referenceType;
  const [isLoading, setIsLoading] = useState(false);

  const adaptIcon = (iconLabel: string) => {
    if (iconLabel === ReferenceType.TENANT) return faUserTie;
    return faUser;
  };

  return (
    <Box key={referenceId}>
      <List
        className="ellipse-menu-list"
        disablePadding
        sx={{ marginY: '0 !important', marginBottom: -1 }}
      >
        <ListItem
          disablePadding
          onClick={() => {
            if (referenceType !== label) {
              setIsLoading(true);
              onRolesSwitch(label, referenceId, referenceSubType);
            }
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <Box
                component={FontAwesomeIcon}
                icon={adaptIcon(label)}
                size="xs"
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            </ListItemIcon>
            <ListItemText primary={adaptLabel} />
            {isLoading && !isRolesSwitched && (
              <CircularProgress size={20} sx={{ marginRight: 1 }} />
            )}

            {referenceType === label && (
              <ListItemIcon>
                <Box
                  component={FontAwesomeIcon}
                  icon={faCheckFilled}
                  size="xs"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export const HeaderPopover = ({
  isImpersonated,
  isRevertLoading,
  handleRevertImpersonate,
  tenantList,
  onTenantSwitch,
  onRolesSwitch,
  handleLogout,
  userRoles,
  isRolesSwitched,
}: IProps) => {
  const navigate = useNavigate();
  const isClientSponsor = useCheckUserIsClient();
  const isClientIndividualDependentGroupMember =
    useCheckUserIsClientIndividualOrDependentOrGroupMember();
  const isClientAgent = useCheckUserIsAgent();
  const isLoggedInAs = getLocal(commonConstants.OLD_TOKEN_REFERENCE);
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);

  const [changeValue, setValueChanged] = useState(false);

  const isUserEmail = useCheckUserIsEmail();

  const activeTenantOption = useMemo(
    () =>
      tenantList?.find((item) =>
        isClientSponsor
          ? item?.referenceId === authTenantAssociation?.referenceId
          : item.tenantId === authTenantAssociation?.tenantId
      ),
    [
      authTenantAssociation?.referenceId,
      authTenantAssociation?.tenantId,
      isClientSponsor,
      tenantList,
    ]
  );

  // Exclude active tenant from the list for autocomplete
  const tenantListForAutocomplete = useMemo(
    () =>
      tenantList.filter((item) =>
        isClientSponsor
          ? item.referenceId !== authTenantAssociation?.referenceId
          : item.tenantId !== authTenantAssociation?.tenantId
      ),
    [
      authTenantAssociation?.referenceId,
      authTenantAssociation?.tenantId,
      isClientSponsor,
      tenantList,
    ]
  );

  const onChangeInput = (newInputValue: Partial<ITenantOption>) => {
    const { tenantId, referenceId } = newInputValue;
    if (!tenantId) return;
    onTenantSwitch(tenantId, referenceId);
  };

  const onClientChangeInput = (newInputValue: Partial<ITenantOption>) => {
    const { referenceId } = newInputValue;

    if (!referenceId) return;
    const newTenantId =
      tenantList.find((item) => item.referenceId === referenceId)?.tenantId ||
      '';
    onTenantSwitch(newTenantId, referenceId);
  };

  const filterOptions = (
    options: ITenantOption[],
    state: FilterOptionsState<ITenantOption>
  ) => {
    const keyword = state.inputValue.toLowerCase();
    const optionList = options.filter(
      (option) =>
        option.code?.toLowerCase().includes(keyword) ||
        option.label?.toLowerCase().includes(keyword)
    );
    return optionList;
  };

  const renderedLocationSwitch =
    tenantList?.length > 1 ? (
      <>
        <Typography gutterBottom={false} variant="body2">
          Select Location
        </Typography>
        {tenantList?.length < THRESHOLD_FOR_TENANT_DROPDOWN ? (
          <FormControl
            className="select-filled-variant"
            fullWidth
            size="small"
            sx={{ mt: `4px !important` }}
            variant="outlined"
          >
            {isClientSponsor ? (
              <Select
                name="tenant-select"
                onChange={(e) =>
                  onClientChangeInput({ referenceId: e.target.value })
                }
                value={activeTenantOption?.referenceId || ''}
              >
                {tenantList?.map((tenant: ITenantOption) => (
                  <MenuItem key={tenant.referenceId} value={tenant.referenceId}>
                    {tenant.label}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                name="tenant-select"
                onChange={(e) => onChangeInput({ tenantId: e.target.value })}
                value={activeTenantOption?.tenantId || ''}
              >
                {tenantList?.map((tenant: ITenantOption) => (
                  <MenuItem key={tenant.id} value={tenant.tenantId}>
                    {tenant.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        ) : (
          <Autocomplete
            disableClearable
            filterOptions={filterOptions}
            freeSolo
            id="tenant-autocomplete"
            onChange={(event, newValue) =>
              onChangeInput(newValue as ITenantOption)
            }
            options={changeValue ? tenantListForAutocomplete ?? [] : []}
            renderInput={(params) => (
              <FormControl fullWidth variant="standard">
                <TextField
                  onChange={() => !changeValue && setValueChanged(true)}
                  {...params}
                  className="filled-variant"
                  InputProps={{
                    ...params.InputProps,
                    type: 'Search',
                  }}
                  name="tenant"
                  placeholder="Search"
                  size="small"
                />
              </FormControl>
            )}
            sx={{ marginTop: `5px !important` }}
          />
        )}
      </>
    ) : (
      ' '
    );

  const renderedAgentHeader = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{renderedLocationSwitch}</>
  );

  const displayLocationSwitch =
    !isUserEmail &&
    !isLoggedInAs &&
    !isClientIndividualDependentGroupMember &&
    !isClientAgent;

  return (
    <Box>
      <Stack spacing={2}>
        {displayLocationSwitch && renderedLocationSwitch}
        {isClientAgent && renderedAgentHeader}
        {!isUserEmail &&
          userRoles.map((item: IRoles) => (
            <ClientInfoHeader
              isRolesSwitched={isRolesSwitched}
              key={item.referenceId}
              label={item.referenceType}
              onRolesSwitch={onRolesSwitch}
              referenceId={item.referenceId}
              referenceSubType={item.referenceSubType}
            />
          ))}

        <Divider />

        <List className="ellipse-menu-list" sx={{ marginY: '0 !important' }}>
          {!isUserEmail && (
            <ListItem
              disablePadding
              onClick={() => navigate(uiRoutes.settings)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Box
                    component={FontAwesomeIcon}
                    icon={faCog}
                    size="xs"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </ListItemButton>
            </ListItem>
          )}

          {isImpersonated && (
            <ListItem disablePadding onClick={handleRevertImpersonate}>
              <ListItemButton>
                <ListItemIcon>
                  {isRevertLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Box
                      component={FontAwesomeIcon}
                      icon={faLongArrowAltLeft}
                      size="xs"
                      sx={{ color: (theme) => theme.palette.primary.main }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`Switch to ${getLocal(
                    commonConstants.IMPERSONATED_BY
                  )}`}
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon>
                <Box
                  component={FontAwesomeIcon}
                  icon={faSignOut}
                  size="xs"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>
    </Box>
  );
};
