export enum CallActionType {
  INITIATED = 'initiated',
  ONGOING = 'ongoing',
  ENDED = 'ended',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  UNANSWERED = 'unanswered',
}

export enum CallType {
  VIDEO = 'video',
  AUDIO = 'audio',
  AN = 'an',
  A = 'a',
}

export enum SenderType {
  YOU = 'You',
}

export enum VirtualVisitClientType {
  CLIENT = 'CLIENT',
}

export enum EventType {
  ALL = 'ALL',
  VIRTUAL_VISIT = 'VIRTUAL_VISIT',
  SCHEDULE = 'SCHEDULE',
  APPOINTMENTS = 'APPOINTMENT',
  TASK = 'TASK',
}
export enum RecordType {
  WEB_AUDIO = 'audio/webm',
}

export enum VirtualVisitStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  RESCHEDULE = 'RESCHEDULE',
  CANCEL = 'CANCEL',
}
export enum VirtualVisitRequestType {
  CANCEL = 'CANCEL',
  RESCHEDULE = 'RESCHEDULE',
}

export enum VirtualVisitRequestStatus {
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
}
/** 
Client User ~ Client Admin (Group Admin)
Tenant User ~ Tenant Admin 
*/

export enum EntityType {
  TENANT_USER = 'TENANT_USER',
  CLIENT_USER = 'CLIENT_USER',
  CLIENT = 'CLIENT',
  AGENT = 'AGENT',
  GUEST = 'GUEST',
}

export enum VirtualVisitSocketEvents {
  ENCRYPT_MESSAGE = 'message:encrypt',
  DECRYPT_MESSAGE = 'message:decrypt',
  START_RECORDING = 'recording:start',
  STOP_RECORDING = 'recording:stop',
  RECORDING_CHUNK = 'recording:chunk',
}

export enum VirtualVisitFileType {
  RECORDING = 'recording',
  FILE = 'file',
}
