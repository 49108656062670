import { useEffect, useMemo, useRef } from 'react';
import { ChartProps, Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import { hexToRgb, Theme, useTheme } from '@mui/material';
import {
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import config from 'config';
import { Charts } from 'enums/charts';
import { IChartRawData } from 'interfaces/reports/dashboard';
import { prepareChartData } from 'utils/chart';

interface ILineChart extends Partial<ChartProps> {
  rawData: IChartRawData[];
  label?: string;
  fill?: boolean;
  displayLegend?: boolean;
}

export const useDefaultLineChartScaleOptions = () => {
  const theme: Theme = useTheme();
  const defaultScaleOptions: _DeepPartialObject<ScaleChartOptions<'line'>> = {
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: config.dateViewFormat,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: Charts.MAX_X_AXES_LABELS,
        },
        grid: {
          color: theme.palette.grey[100],
          borderColor: theme.palette.secondary.light,
        },
      },
      y: {
        display: true,
        grid: {
          color: theme.palette.grey[300],
          borderColor: theme.palette.secondary.light,
        },
      },
    },
  };

  return { defaultScaleOptions };
};

const LineChart = ({
  fill = false,
  rawData,
  label,
  options,
  displayLegend = false,
  ...rest
}: ILineChart) => {
  const theme: Theme = useTheme();
  const { defaultScaleOptions } = useDefaultLineChartScaleOptions();

  const { data, labels } = useMemo(() => prepareChartData(rawData), [rawData]);

  const chartRef = useRef<ChartJSOrUndefined<
    'line',
    unknown[],
    unknown
  > | null>(null);

  useEffect(
    () => () => {
      if (chartRef?.current) {
        chartRef.current?.destroy();
      }
    },
    []
  );

  const customOptions:
    | _DeepPartialObject<
        CoreChartOptions<'line'> &
          ElementChartOptions<'line'> &
          PluginChartOptions<'line'> &
          DatasetChartOptions<'line'> &
          LineControllerChartOptions
      >
    | undefined = useMemo(
    () => ({
      ...defaultScaleOptions,
      ...options,
      plugins: {
        legend: {
          display: displayLegend,
        },
        ...options?.plugins,
      },
    }),
    [defaultScaleOptions, options, displayLegend]
  );

  return (
    <Line
      {...rest}
      data={{
        labels,
        datasets: [
          {
            label,
            showLine: true,
            tension: 0.4,
            data,
            fill,
            backgroundColor: `rgba(${hexToRgb(theme.palette.primary.main)
              .replace('rgb', '')
              .replace('(', '')
              .replace(')', '')}, 0.3)`,
            borderColor: theme.palette.primary.main,
            borderCapStyle: 'butt',
            borderWidth: 2,
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: theme.palette.primary.main,
            pointBackgroundColor: theme.palette.primary.main,
            pointBorderWidth: 2.5,
            pointHoverRadius: 2.5,
            pointHoverBackgroundColor: theme.palette.primary.main,
            pointHoverBorderColor: theme.palette.secondary.main,
            pointHoverBorderWidth: 5,
            pointRadius: 1,
            pointHitRadius: 20,
          },
        ],
      }}
      options={customOptions}
      ref={chartRef}
    />
  );
};

LineChart.defaultProps = {
  label: '',
  displayLegend: false,
  fill: false,
};

export default LineChart;
