import { FilterChip } from 'common';
import commonConstants from 'constants/common';
import { Interval } from 'enums/common';

interface IProps {
  filters: [string, unknown][];
  onDelete: (label: string) => void;
}

const UserAppliedFilter = ({ filters, onDelete }: IProps): JSX.Element => (
  <>
    {filters.map(([k, v]) => {
      // eslint-disable-next-line no-param-reassign
      if (v === Interval.WEEKLY) v = 'This Week';
      if (k !== commonConstants.IS_ADVANCED_SEARCH) {
        return (
          <FilterChip
            key={k}
            label={k}
            onDelete={onDelete}
            value={v as string}
          />
        );
      }
      return '';
    })}
  </>
);

export default UserAppliedFilter;
