import { useFormContext } from 'react-hook-form';

import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import AvatarPlaceholder from 'assets/images/png/avatar-placeholder.png';
import { convertFileToBase64 } from 'utils/common';

interface IProps {
  border?: boolean;
  round?: boolean;
  imageStyle?: SxProps<Theme> | undefined;
  imageContainerStyle?: SxProps<Theme> | undefined;
  imageInputId: string;
}

const FormImagePicker = ({
  border,
  round,
  imageStyle,
  imageContainerStyle,
  imageInputId,
}: IProps) => {
  const { watch, setValue } = useFormContext();

  const theme = useTheme();

  const currentImageUrl = watch('imageUrl');
  const newImageData = watch('newImageData');

  const onImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length < 1) {
      return;
    }

    const preview = URL.createObjectURL(e.target.files[0]);
    const img = e.target.files[0];
    const imageData = {
      fileName: img.name,
      contentType: img.type,
      base64: await convertFileToBase64(img),
      size: img.size,
      filePath: `image/agentimage/${(+new Date())?.toString()}_${img.name}`,
    };

    setValue('newImageData', { preview, imageData });
  };

  const onCancelClick = () => {
    setValue('newImageData', null);
  };

  const getImageSrcToDisplay = () => {
    if (newImageData?.preview) {
      return newImageData.preview;
    }

    if (currentImageUrl && currentImageUrl.length) {
      return currentImageUrl;
    }

    return AvatarPlaceholder;
  };

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Box
        sx={{
          border: border ? `1px solid ${theme.palette.gray.light}` : '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          borderRadius: round ? '50%' : '',
          backgroundColor: theme.palette.common.white,
          overflow: 'hidden',
          width: '180px',
          height: '180px',
          lineHeight: '180px',
          ...imageContainerStyle,
        }}
      >
        <Box
          alt="thumbnail"
          component="img"
          src={getImageSrcToDisplay()}
          sx={{
            objectFit: 'contain',
            width: '100%',
            maxHeight: '100%',

            ...imageStyle,
          }}
        />
      </Box>

      <Box mt={1}>
        {newImageData ? (
          <Typography
            gutterBottom={false}
            onClick={onCancelClick}
            sx={{ cursor: 'pointer' }}
            variant="body2"
          >
            Cancel
          </Typography>
        ) : (
          <>
            <Typography
              component="label"
              gutterBottom={false}
              htmlFor={imageInputId}
              sx={{ cursor: 'pointer' }}
              variant="body2"
            >
              {currentImageUrl ? 'Change Picture' : 'Upload Picture'}
            </Typography>
            <Box
              accept="image/png, image/jpeg"
              component="input"
              hidden
              id={imageInputId}
              onChange={onImageUpload}
              type="file"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

FormImagePicker.defaultProps = {
  border: true,
  round: false,
  imageStyle: undefined,
  imageContainerStyle: undefined,
};

export default FormImagePicker;
