import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, FormControl, Grid, Typography } from '@mui/material';
import { RadioBoxButton } from 'common/ui/RadioBoxButton';
import { langDictConstant } from 'constants/languageDictionary';
import { Gender } from 'enums/common';
import { MiscellaneousCode } from 'enums/tenant-management/tenant';
import { GenderType } from 'interfaces/common';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import { selectLangDict } from 'stores/language-dictionary';
import { getTextFromLangDict } from 'utils/languageDictionary';
import { getInputLabel } from 'utils/misc';
import { checkIsMiscellaneousSettingEnabled } from 'utils/tenantSetting';

const GENDER_MAP = {
  Male: {
    value: Gender.MALE,
    label: Gender.MALE,
  },
  Female: {
    value: Gender.FEMALE,
    label: Gender.FEMALE,
  },
  Others: {
    value: Gender.OTHERS,
    label: Gender.OTHERS,
  },
};

interface IGenderRadioButtonProps {
  type: GenderType;
  value: GenderType;
  onChange: (value: GenderType) => void;
  isDisabled?: boolean;
  dataCy?: string;
}

const GenderRadioButton = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: IGenderRadioButtonProps, ref) => {
    const { type, value, onChange, isDisabled = false, dataCy } = props;

    return (
      <RadioBoxButton
        data-cy={dataCy}
        isActive={value === type}
        isDisabled={isDisabled}
        label={GENDER_MAP[type].label}
        onClick={() => onChange(value === type ? ('' as GenderType) : type)}
      />
    );
  }
);

GenderRadioButton.defaultProps = {
  isDisabled: false,
  dataCy: '',
};

interface IProps {
  isRequired?: boolean;
  isDisabled?: boolean;
  showOthers?: boolean;
  labelProp?: string;
}

const FormGenderInput = ({
  isRequired = false,
  isDisabled = false,
  showOthers = false,
  labelProp,
}: IProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const showOtherInGenderTenantSettings = checkIsMiscellaneousSettingEnabled(
    MiscellaneousCode.OTHERS_IN_GENDER
  );

  const showOtherInGender = showOtherInGenderTenantSettings || showOthers;

  const langDict = useAppSelector(selectLangDict);
  const authTenantAssocation = useAppSelector(selectAuthTenantAssociation);

  const label = getTextFromLangDict(langDict?.dict, {
    key: langDictConstant.GENDER,
    tenantCode: authTenantAssocation?.code || '',
    groupCode: authTenantAssocation?.groupCode || '',
  });

  return (
    <Box>
      <Typography
        color={errors.gender ? 'error' : undefined}
        gutterBottom={false}
        sx={{ display: 'block', marginTop: '-4px', mb: 0.5 }}
        variant="caption"
      >
        {getInputLabel(label, isRequired) || labelProp}
      </Typography>

      <FormControl sx={{ width: '100%' }} variant="outlined">
        <Controller
          control={control}
          name="gender"
          render={({ field }) => (
            <Grid container spacing={1}>
              <Grid item xs={showOtherInGender ? 4 : 6}>
                <GenderRadioButton
                  dataCy="gender-male"
                  isDisabled={isDisabled}
                  type={Gender.MALE}
                  {...field}
                />
              </Grid>
              <Grid item xs={showOtherInGender ? 4 : 6}>
                <GenderRadioButton
                  dataCy="gender-female"
                  isDisabled={isDisabled}
                  type={Gender.FEMALE}
                  {...field}
                />
              </Grid>
              {showOtherInGender && (
                <Grid item xs={4}>
                  <GenderRadioButton
                    dataCy="gender-others"
                    isDisabled={isDisabled}
                    type={Gender.OTHERS}
                    {...field}
                  />
                </Grid>
              )}
            </Grid>
          )}
        />
      </FormControl>
    </Box>
  );
};

FormGenderInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
  showOthers: false,
  labelProp: '',
};

export default FormGenderInput;
