import { UseMutationResult } from 'react-query';

import { CometChat } from '@cometchat-pro/chat';
import config from 'config';
import commonConstants from 'constants/common';
import toastMessageConstant from 'constants/toastMessage';
import uiRoutes from 'constants/uiRoutes';
import {
  VirtualVisitClientType,
  VirtualVisitStatus,
} from 'enums/virtual-visit';
import { IAdaptedTenantData } from 'interfaces/auth';
import { IError, IListResponse, IResponse } from 'interfaces/http';
import { ILookUpsTableRow } from 'interfaces/lookups';
import {
  IAdaptedVirtualVisitDetail,
  IAdaptedVirtualVisitTableRow,
  IInvitee,
  IVirtualVisitStatusType,
  IVirtualVisitTableRow,
} from 'interfaces/virtual-visit';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import {
  capitalizeFirstLetter,
  capitalizeText,
  convertToPlainString,
  formatPhone,
  sliceText,
} from 'utils/common';
import {
  formatDateView,
  getDateDuration,
  getMinute,
  getTime,
} from 'utils/moment';
import { setLocal } from 'utils/storage';

export const downloadAttachedFile = (
  base64Data: string,
  fileName: string,
  callBack?: () => void
) => {
  const linkSource = `data:application/octet-stream;base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  if (callBack) callBack();
};

const formatDateRange = (start?: string, end?: string) => {
  if (start && !end) {
    return `${start} - N/A`;
  }
  if (start && end) {
    return `${start} - ${end}`;
  }
  return '';
};

const formatFullDateTime = (
  formattedRescheduleDate: string,
  formattedStartDateTime: string,
  formattedEndDateTime: string
) => {
  const formattedDateTime = `${formattedRescheduleDate} ${
    formattedStartDateTime && ' | '
  } ${formattedStartDateTime}${
    formattedEndDateTime && ' - '
  } ${formattedEndDateTime}`;
  return formattedDateTime;
};

export const adaptVirtualVisit = (
  res: IResponse<IListResponse<IVirtualVisitTableRow>>
): IResponse<IListResponse<IAdaptedVirtualVisitTableRow>> => ({
  ...res,
  data: {
    ...res.data,
    rows: res.data.rows.map((item: IVirtualVisitTableRow) => {
      const client =
        item.invitees?.find(
          (val: IInvitee) =>
            val.type?.toLocaleLowerCase() ===
            VirtualVisitClientType.CLIENT.toLocaleLowerCase()
        ) ?? {};

      return {
        ...item,
        // eslint-disable-next-line no-underscore-dangle
        id: item._id,
        title: capitalizeFirstLetter(item.title),
        slicedDescription: sliceText(item.description, 50),
        isCancelled: !!(
          item?.requests?.filter(
            (filterItem) =>
              filterItem.type === VirtualVisitStatus.CANCEL &&
              filterItem.status === VirtualVisitStatus.NEW
          )?.length && item?.status === VirtualVisitStatus.NEW
        ),
        isRescheduled: !!(
          item?.requests?.filter(
            (filterItem) =>
              filterItem.type === VirtualVisitStatus.RESCHEDULE &&
              filterItem.status === VirtualVisitStatus.NEW
          )?.length && item?.status === VirtualVisitStatus.NEW
        ),
        formattedStartDate:
          formatDateView(
            item.startDateTime?.toString(),
            config.dateViewFormat
          ) || '',
        status: capitalizeText(convertToPlainString(item?.status ?? '')),
        formattedStartTime:
          formatDateView(item.startDateTime?.toString(), config.timeFormat) ||
          '',
        formattedEndTime:
          formatDateView(item.endDateTime?.toString(), config.timeFormat) || '',
        client: { ...client, phone: formatPhone(client.phone ?? '') },
        invitees: item.invitees.filter(
          (value: IInvitee) => !value.isPrimary && !value.isHost
        ),

        formattedResponse: item?.requests?.map((mapData) => ({
          ...mapData,
          createdDate:
            formatDateView(
              mapData?.created?.date?.toString() || '',
              config.dateViewFormat
            ) || '',
          formattedRescheduleDate:
            formatDateView(
              mapData?.startDateTime?.toString(),
              config.dateViewFormat
            ) || '',
          formattedStartDateTime:
            formatDateView(
              mapData?.startDateTime?.toString() || '',
              config.timeFormat
            ) || '',
          formattedEndDateTime:
            formatDateView(
              mapData.endDateTime?.toString() || '',
              config.timeFormat
            ) || '',
          formattedFullDateTime: formatFullDateTime(
            formatDateView(
              mapData?.startDateTime?.toString(),
              config.dateViewFormat
            ) || '',
            formatDateView(
              mapData?.startDateTime?.toString() || '',
              config.timeFormat
            ) || '',
            formatDateView(
              mapData.endDateTime?.toString() || '',
              config.timeFormat
            ) || ''
          ),
        })),
        formattedInvitee: item?.invitees?.filter(
          (filterData) => filterData?.isPrimary && !filterData.isHost
        ),
        formattedCreatedDate:
          formatDateView(
            item.created.date?.toString(),
            config.dateViewFormat
          ) || '',
        duration:
          item.virtualVisit.startTime && item.virtualVisit.endTime
            ? getDateDuration(
                item.virtualVisit.startTime ?? '',
                item.virtualVisit.endTime ?? ''
              )
            : 'N/A',
        dateRange: formatDateRange(
          formatDateView(
            item.virtualVisit.startTime?.toString() ?? '',
            config.timeFormat
          ) ?? '',
          formatDateView(
            item.virtualVisit.endTime?.toString() ?? '',
            config.timeFormat
          ) ?? ''
        ),
      };
    }),
  },
});

export const adaptVirtualVisitDetail = (
  res: IResponse<IVirtualVisitTableRow>
): IResponse<IAdaptedVirtualVisitDetail> => {
  const mapPrimaryClient =
    res.data?.invitees
      .filter((item) => item?.isPrimary && !item.isHost)
      .map((item) => ({
        ...item,
        clientId: item?.id,
        name: item?.name,
        lastName: item?.name,
        phone: item?.phone,
        email: item?.email,
      })) ?? [];
  const mapName = mapPrimaryClient
    .filter((item) => item)
    ?.map((mapData) => mapData?.name)[0]
    ?.trim()
    .split(' ');
  const primaryClientId =
    mapPrimaryClient?.map((mapData) => mapData?.clientId)[0] || '';
  const email = mapPrimaryClient?.map((mapData) => mapData?.email)[0] || '';
  const phone = mapPrimaryClient?.map((mapData) => mapData?.phone)[0] || '';
  const firstName = (mapName?.length && mapName[0]) || '';
  const lastName = (mapName?.length && mapName[mapName.length - 1]) || '';
  const startTime = getTime(res?.data?.startDateTime?.toString());
  const endTime = getTime(res?.data?.endDateTime?.toString());
  const startMinutes = getMinute(res?.data?.startDateTime?.toString());
  const endMinutes = getMinute(res?.data?.endDateTime?.toString());

  const client =
    res?.data?.invitees.find(
      (item) => item.type === VirtualVisitClientType.CLIENT && item?.isPrimary
    ) || {};

  const mapGuests = res?.data?.invitees
    .filter((item) => !item?.isPrimary && !item.isHost)
    .map((item) => ({
      ...item,
      clientId: item?.id || '',
      name: item?.name || '',
      phone: item?.phone || '',
      email: item?.email || '',
    }));
  return {
    ...res,
    data: {
      ...res.data,
      formattedStartTime: new Date(startTime + startMinutes)
        .toString()
        .replace('am', 'AM')
        .replace('pm', 'PM'),
      formattedEndTime: new Date(endTime + endMinutes)
        .toString()
        .replace('am', 'AM')
        .replace('pm', 'PM'),
      client,
      guest: mapGuests || [],
      dateTime:
        formatDateView(
          res.data.startDateTime.toString() ?? '',
          config.fullDateViewFormat
        ) ?? '',
      eventStatus:
        res.data.virtualVisit.status === VirtualVisitStatus.NEW
          ? 'not started'
          : 'ended',
      primaryClient: {
        clientId: primaryClientId,
        email,
        phone,
        firstName,
        lastName,
      },
    },
  };
};

export const uploadFileVirtualVisit = (context: any, sessionID: string) =>
  CometChatService.sendFileMessage(context, sessionID);

export const extractTextFromMessage = (
  textMessage: CometChat.TextMessage
): string | null => {
  if (textMessage instanceof CometChat.TextMessage) {
    const messageDatas = textMessage.getData();
    if (messageDatas) {
      return messageDatas.text;
    }
  }
  return null;
};

export const extractSenderFromMessage = (
  textMessage: CometChat.TextMessage
): string | null => {
  if (textMessage instanceof CometChat.TextMessage) {
    const messageDatas: any = textMessage.getSender();
    if (messageDatas) {
      return messageDatas?.name;
    }
  }
  return null;
};
export const convertToDateTime = (
  time?: string | null,
  date?: string | null
) => {
  const [day, month, year] = date?.split('/') ?? [];
  const [hour, minute] = time?.split(':') ?? [];
  return new Date(+year, +month - 1, +day, +hour, +minute);
};

export const getStatus = (type: string) => {
  const virtualVisitStatus: IVirtualVisitStatusType = {
    new: 'info',
    cancelled: 'danger',
    completed: 'success',
    'in progress': 'warning',
  };
  return virtualVisitStatus[type.toLowerCase()];
};

export const b64toBlob = (base64: string, contentType: string) => {
  const sliceSize = 512;
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const checkIfVirtualVisit = (returnUrl: string) => {
  const isVirtualVisit = returnUrl?.includes('/virtual-visit/');
  return isVirtualVisit;
};

export const openWindow = (
  eventId: string,
  setMeetingId: (value: React.SetStateAction<string | null>) => void
) => {
  window.open(
    uiRoutes.virtualVisit.clientVirtualVisit.replace(':sessionId', eventId)
  );
  setMeetingId(null);
};
interface IStartPress {
  item: IAdaptedVirtualVisitTableRow | IAdaptedVirtualVisitDetail;
  tenantData: IAdaptedTenantData | null;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey;
  lookup: ILookUpsTableRow[] | undefined;
  sessionId: string;
  setMeetingId: (value: React.SetStateAction<string | null>) => void;
  startVirtualVisitMutation: UseMutationResult<
    IResponse<IVirtualVisitTableRow>,
    IError,
    {
      eventId: string;
    },
    unknown
  >;
}
export const onStartPress = async ({
  item,
  tenantData,
  enqueueSnackbar,
  lookup,
  sessionId,
  setMeetingId,
  startVirtualVisitMutation,
}: IStartPress) => {
  if (item?.status?.toUpperCase() !== VirtualVisitStatus.NEW) {
    enqueueSnackbar(
      toastMessageConstant.VIRTUAL_VISIT.UNABLE_TO_START_MEETING,
      {
        variant: 'error',
      }
    );
    return;
  }

  if (sessionId) {
    setMeetingId(item?.eventId);
    try {
      const tenantId = tenantData?.userId ?? '';
      const user = await CometChatService.login(tenantId ?? '');
      const autoRecording = lookup?.length
        ? lookup?.[0]?.metaData.autoRecord
        : false;
      setLocal(commonConstants.VIRTUAL_VISIT.VIRTUAL_VISIT_USER, {
        state: {
          item,
          isHost: true,
          tenantId: tenantId ?? '',
          tenantName: tenantData?.name,
          autoRecording,
        },
      });
      if (user) {
        startVirtualVisitMutation.mutate(
          { eventId: item.eventId },
          {
            onSuccess: () => {
              setLocal(commonConstants.VIRTUAL_VISIT.VIRTUAL_VISIT_USER, {
                state: {
                  item,
                  isHost: true,
                  tenantId: tenantId ?? '',
                  tenantName: tenantData?.name,
                  autoRecording,
                },
              });
              openWindow(item.eventId, setMeetingId);
            },
            onError: () => setMeetingId(null),
          }
        );
      } else {
        await CometChatService.createUser(
          tenantId ?? '',
          tenantData?.name || ''
        );
        const loggedUser = await CometChatService.login(tenantId ?? '');

        if (loggedUser) {
          startVirtualVisitMutation.mutate(
            { eventId: item.eventId },
            {
              onSuccess: () => openWindow(item.eventId, setMeetingId),
              onError: () => setMeetingId(null),
            }
          );
        } else {
          throw Error('');
        }
      }
    } catch (error) {
      enqueueSnackbar(toastMessageConstant.VIRTUAL_VISIT.UNEXPECTED_ERROR, {
        variant: 'error',
      });
    }
  }
};
