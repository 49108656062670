import { Box, Typography, useTheme } from '@mui/material';
import { Logo } from 'common';
import { useClientDetailQuery } from 'services/client-management';
import { useTenantDetailQuery } from 'services/tenant-management/tenant';
import { selectAuthTenantData } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { formatPhone } from 'utils/common';

import BloodGroupDOBDateDisplay from './BloodGroupDOBDateDisplay';

interface IProps {
  clientId: string;
}

const MembershipIdCard = ({ clientId }: IProps) => {
  const authTenant = useAppSelector(selectAuthTenantData);
  const editClientData = useAppSelector(selectEditClientData);

  const theme = useTheme();

  const clientDetailQuery = useClientDetailQuery(
    clientId!,
    {
      enabled: !!clientId,
    },
    {
      clientEnrollmentId: editClientData?.clientEnrollmentId,
    }
  );

  const clientData = clientDetailQuery?.data;
  const tenantId = clientData?.tenantId || '';
  const tenantAssociation = authTenant?.tenantAssociation;
  const themeColor = tenantAssociation?.metaData.themeColor?.primary;

  const { data } = useTenantDetailQuery(tenantId, {
    enabled: !!tenantId,
  });

  const isPhoneandEmailExist = data?.phone && data?.email;

  return (
    <Box
      border={1}
      borderColor={theme.palette.gray.light}
      borderRadius={2}
      height="300px"
      maxWidth="500px"
      mt={2}
      width="500px"
    >
      <Box display="flex" justifyContent="space-between" p={2}>
        <Logo
          src={authTenant?.tenantAssociation.metaData.imageUrl}
          sx={{
            height: 70,
            alignSelf: 'flex-start',
            pb: 1,
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
        <Box alignItems="flex-end" display="flex" flexDirection="column">
          <Typography
            color={theme.palette.gray.dark}
            fontWeight="medium"
            gutterBottom={false}
            pt={1}
            variant="body2"
          >
            Membership
          </Typography>
          <Typography
            color={themeColor || theme.palette.primary.main}
            fontWeight="medium"
            gutterBottom={false}
            pt={1}
            variant="body1"
          >
            {clientData?.membership?.offeringName} Plan
          </Typography>
        </Box>
      </Box>
      <Box
        gap={2}
        padding={2}
        sx={{ backgroundColor: themeColor || theme.palette.primary.main }}
      >
        <Typography
          color={theme.palette.background.paper}
          gutterBottom={false}
          variant="body1"
        >
          {clientData?.name}
        </Typography>
        <Typography
          color={theme.palette.background.paper}
          gutterBottom={false}
          variant="body2"
        >
          {clientData?.clientId?.split('-')[4]}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <BloodGroupDOBDateDisplay data={clientData} />
        <Box
          display="flex"
          flexDirection="column"
          gap={0.25}
          justifyContent="space-between"
          p={2}
        >
          <Box>
            <Box alignItems="flex-start" display="flex">
              <Typography
                color={theme.palette.common.black}
                gutterBottom={false}
                pl={1}
                variant="caption"
              >
                For support:
              </Typography>
            </Box>
            <Box alignItems="center" display="flex">
              <Typography
                color={theme.palette.common.black}
                fontWeight="medium"
                gutterBottom={false}
                pl={1}
                variant="caption"
              >
                {data?.phone && formatPhone(data?.phone || '')}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex">
              <Typography
                color={theme.palette.common.black}
                fontWeight="medium"
                gutterBottom={false}
                pl={1}
                variant="caption"
              >
                {data?.email}
              </Typography>
            </Box>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
            pt={4}
          >
            <Typography
              color={theme.palette.common.black}
              // Hardcoded fontsize as we needed fontSize 10px for this text.
              fontSize="10px"
              gutterBottom={false}
              mt={isPhoneandEmailExist ? 0 : 1.9}
              variant="caption"
            >
              Powered by Vitafy 360
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MembershipIdCard;
