import apiRoutes from 'constants/apiRoutes';
import { ILookUpFilter } from 'interfaces/common';
import { IResponse } from 'interfaces/http';
import { ILookUpsTableRow } from 'interfaces/lookups';
import { baseRequest } from 'utils/axios';

const { getAll } = apiRoutes.lookup;

export const getAllLookUp = async (
  params: ILookUpFilter
): Promise<IResponse<ILookUpsTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: getAll,
    params,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};
