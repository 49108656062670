import { ITenantData } from 'interfaces/auth';
import jwtDecode from 'jwt-decode';

import { getLocal } from './storage';

export const decodeJwtToken = (token: string): ITenantData => jwtDecode(token);

/**
 * Check if the jwt token has been expired
 *
 * @param {string} token
 * @returns {boolean} true if expired
 */
export const isTokenExpired = (token: string) => {
  const { exp }: ITenantData = jwtDecode(token);
  const expirationTime = exp * 1000 - 60000;
  if (Date.now() >= expirationTime) return true;
  return false;
};

export const getTenantFromToken = () =>
  decodeJwtToken(getLocal('jwtToken')).tenantAssociation;

export const getUserDetailFromToken = () =>
  decodeJwtToken(getLocal('jwtToken'));
