import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { CometChat } from '@cometchat-pro/chat';
import {
  Avatar,
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import LoadingIndicator from 'common/LoadingIndicator';
import apiRoutes from 'constants/apiRoutes';
import { IAdaptedUserTable, ISelectedUser } from 'interfaces/chat';
import { IUserTableRow } from 'interfaces/tenant-management/user';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import { selectAuthTenantData } from 'stores/auth';
import {
  searchUser,
  selectFriends,
  selectIsLoading,
  selectLastMessage,
  selectSelectedFriend,
  setConvertToFriend,
  setIsContentLoading,
  setSelectedFriend,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import { selectUserFilter } from 'stores/tenant-management/user';
import { baseRequest } from 'utils/axios';
import { onPressFriend } from 'utils/chat';
import {
  getCometChatUID,
  getFirstLetterOfFirstAndLastName,
  getFullName,
} from 'utils/common';
import { getTimeAgoString } from 'utils/moment';
import { adaptUserList } from 'utils/tenant-management/users';

let debounceTimeout: NodeJS.Timeout | null = null;
const { tenantManagement } = apiRoutes;
const ChatList = () => {
  const url = process.env.REACT_APP_COMET_CHAT_ENDPOINT ?? '';
  const theme = useTheme();
  const dispatch = useDispatch();
  const scrollRef: HTMLDivElement | any = useRef(null);

  const filters = useAppSelector(selectUserFilter);
  const friends = useAppSelector(selectFriends);
  const authTenantData = useAppSelector(selectAuthTenantData);
  const isLoading = useAppSelector(selectIsLoading);
  const selectedUser = useAppSelector<ISelectedUser | null>(
    selectSelectedFriend
  );
  const message = useAppSelector(selectLastMessage);

  const groupCode = authTenantData?.tenantAssociation?.code ?? '';

  useEffect(() => {
    if (message) {
      CometChat.markAsRead(message);
    }
  }, [message]);

  const createCometChatFriends = async (
    uid: string,
    user: any,
    index: number
  ) => {
    const body = {
      accepted: [uid],
    };
    const config = {
      method: 'POST',
      url: `${url}/users/${getCometChatUID(
        groupCode,
        authTenantData?.userId ?? ''
      )}/friends`,
      headers: {
        apiKey: process.env.REACT_APP_API_KEY ?? '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: body,
    };
    axios(config).finally(() => {
      onPressFriend(
        uid,
        {
          ...user,
          uid,
          name: getFullName(
            user.demographic.firstName,
            user.demographic.middleName,
            user.demographic.lastName
          ),
        },
        index,
        authTenantData?.userId ?? '',
        groupCode
      );
      dispatch(setIsContentLoading(false));
    });
  };

  const onPressUser = async (item: IUserTableRow, index: number) => {
    if (!item.userId) {
      dispatch(setIsContentLoading(false));
    } else {
      dispatch(setSelectedFriend(JSON.stringify({ ...item, index })));
      dispatch(
        setConvertToFriend({
          groupCode,
          id: getCometChatUID(groupCode, item.userId),
        })
      );
      CometChatService.createUser(
        getCometChatUID(groupCode, item.userId),
        item.demographic.firstName ?? ''
      ).finally(async () => {
        const cometChatUID = getCometChatUID(groupCode, item.userId);
        createCometChatFriends(cometChatUID, item, index);
      });
    }
  };

  /** If infinite scroll is implemented.. than use this */

  // useEffect(() => {
  //   let debounceTimer: NodeJS.Timeout;

  //   const handleScroll = () => {
  //     clearTimeout(debounceTimer);

  //     debounceTimer = setTimeout(async () => {
  //       const container = scrollableContainerRef.current;
  //       if (!container) return;

  //       const { scrollTop, clientHeight, scrollHeight } = container;

  //       if (scrollTop + clientHeight >= scrollHeight - 100) {
  //         const newOffset = filters.offset + 25;

  //         const { response } = await baseRequest({
  //           method: 'GET',
  //           url: tenantManagement.user.getAll,
  //           params: {
  //             offset: newOffset,
  //             limit: filters.limit,
  //             tenantId: authTenantData?.tenantAssociation.tenantId,
  //           },
  //         });

  //         dispatch(setFriends(response?.data.rows));
  //       }
  //     }, 500);
  //   };

  //   const container = scrollableContainerRef.current;
  //   if (container) {
  //     container.addEventListener('scroll', handleScroll);
  //   }

  //   return () => {
  //     if (container) {
  //       container.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, [
  //   authTenantData?.tenantAssociation.tenantId,
  //   dispatch,
  //   filters.limit,
  //   filters.offset,
  // ]);

  const onChangeSearch = async (value: string) => {
    if (debounceTimeout !== null) {
      clearTimeout(debounceTimeout as unknown as number);
    }

    debounceTimeout = setTimeout(async () => {
      const { response } = await baseRequest({
        method: 'GET',
        url: tenantManagement.user.getAll,
        params: {
          keyword: value,
          offset: filters.offset,
          limit: filters.limit,
          tenantId: authTenantData?.tenantAssociation.tenantId,
        },
      });

      const adaptedData = adaptUserList(response?.data ?? []);

      dispatch(
        searchUser({
          data: adaptedData?.data?.rows ?? [],
          text: value,
          groupCode,
        })
      );
    }, 300);
  };

  if (isLoading)
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    );

  return (
    <Box p={1} sx={{ height: 500 }}>
      <TextField
        fullWidth
        onChange={(e) => {
          if (e.target?.value?.length >= 3 || !e.target?.value)
            onChangeSearch(e.target.value);
        }}
        placeholder="Search User"
        sx={{ paddingX: 1, pb: theme.spacing(2), pt: theme.spacing(1) }}
      />

      <Box height={450} mb={20} mr={2} overflow="auto" pb={20} ref={scrollRef}>
        {friends?.map((item: IAdaptedUserTable, index: number) => (
          <Box
            key={item.userId}
            p={0.5}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                selectedUser && selectedUser.index === index
                  ? theme.palette.gray.lighter
                  : theme.palette.common.white,
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              key={item.userId}
              mx={1}
              my={1}
              onClick={() => {
                scrollRef.current.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
                dispatch(setIsContentLoading(true));
                return item?.uid
                  ? onPressFriend(
                      item?.uid,
                      item,
                      index,
                      authTenantData?.userId ?? '',
                      groupCode
                    )
                  : onPressUser(item, 0);
              }}
            >
              <Avatar
                sx={{
                  alignSelf: 'flex-start',
                  marginRight: theme.spacing(1),
                  width: 36,
                  height: 36,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  gutterBottom={false}
                  textTransform="uppercase"
                  variant="body2"
                >
                  {getFirstLetterOfFirstAndLastName(
                    item?.demographic?.fullName || ''
                  )}
                </Typography>
              </Avatar>
              <Box display="flex" flexDirection="column" width="85%">
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontWeight="medium" variant="caption">
                    {item?.demographic?.fullName ?? ''}
                  </Typography>
                  {item?.lastActiveAt && (
                    <Typography textTransform="capitalize" variant="caption">
                      {getTimeAgoString(item.lastActiveAt)}
                    </Typography>
                  )}
                </Stack>
                <Typography noWrap variant="body2">
                  {item?.uid
                    ? item?.lastMessage?.text
                    : 'Start Conversation ✋'}
                </Typography>
              </Box>
            </Box>
            {selectedUser &&
              selectedUser.index !== index &&
              selectedUser.index - 1 !== index && (
                <Divider component="li" sx={{ listStyle: 'none' }} />
              )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};;

export default ChatList;
