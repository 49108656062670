import regex from 'constants/regex';
import validationMessage from 'constants/validationMessage';
import { ClientType } from 'enums/client-management';
import { CountryCodes } from 'enums/country';
import * as Yup from 'yup';

const { REQUIRED, INVALID, EXACTLY_FOUR_DIGITS } = validationMessage;

export const getAddClientSchema = ({
  isBloodGroupEnabled = false,
  isEmailEnabled = false,
  isEditMode = false,
  isMembershipSelected = false,
}) =>
  Yup.object().shape({
    businessName: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.GROUP,
        then: Yup.string().trim().required(REQUIRED),
      }),
    firstName: Yup.string().nullable().trim().required(REQUIRED),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable().trim().required(REQUIRED),
    email: Yup.string()
      .nullable()
      .trim()
      .matches(regex.EMAIL, {
        message: INVALID,
      })
      .when('clientType', {
        is: (clientType: string) => {
          // Use email required/not tenant setting only for non-membership client (ancillary only) in edit mode
          if (
            isEditMode &&
            !isMembershipSelected &&
            clientType === ClientType.PRIMARY
          ) {
            return isEmailEnabled;
          }

          return (
            clientType === ClientType.GROUP ||
            clientType === ClientType.GROUP_MEMBER ||
            clientType === ClientType.PRIMARY
          );
        },
        then: Yup.string().trim().required(REQUIRED).matches(regex.EMAIL, {
          message: INVALID,
        }),
      }),
    phone: Yup.string()
      .nullable()
      .trim()
      .when('clientType', {
        is: (clientType: string) =>
          clientType === ClientType.GROUP ||
          clientType === ClientType.GROUP_MEMBER ||
          clientType === ClientType.PRIMARY,
        then: Yup.string().nullable().trim().required(REQUIRED),
      })
      .when('countryCode', {
        is: (countryCode: string) => countryCode === CountryCodes.USA,
        then: Yup.string().matches(regex.PHONE, {
          excludeEmptyString: true,
          message: INVALID,
        }),
      }),
    // TODO: This format was used for phone numbers before international address was introduced so commenting this out for now as we may need this for future
    // .matches(regex.PHONE, {
    //   excludeEmptyString: true,
    //   message: INVALID,
    // })
    // .when('clientType', {
    //   is: (clientType: string) =>
    //     clientType === ClientType.GROUP ||
    //     clientType === ClientType.GROUP_MEMBER ||
    //     clientType === ClientType.PRIMARY,
    //   then: Yup.string().required(REQUIRED).matches(regex.PHONE, {
    //     excludeEmptyString: false,
    //     message: INVALID,
    //   }),
    // }),
    addressLine1: Yup.string().nullable(),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    // zip: Yup.string().nullable().matches(regex.ZIP, {
    //   excludeEmptyString: true,
    //   message: INVALID,
    // }),
    note: Yup.string().nullable(),
    lastSSN: Yup.string().nullable().matches(regex.ONLY4Digit, {
      message: EXACTLY_FOUR_DIGITS,
      excludeEmptyString: true,
    }),
    dob: Yup.string().nullable(),
    gender: Yup.string().nullable(),
    groupId: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.GROUP_MEMBER,
        then: Yup.string().trim().required(REQUIRED),
      }),
    groupName: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.GROUP_MEMBER,
        then: Yup.string().trim().required(REQUIRED),
      }),
    primaryId: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.DEPENDENT,
        then: Yup.string().trim().required(REQUIRED),
      }),
    primaryName: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.DEPENDENT,
        then: Yup.string().trim().required(REQUIRED),
      }),

    relationship: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) => clientType === ClientType.DEPENDENT,
        then: Yup.string().trim().required(REQUIRED),
      }),
    bloodGroup: Yup.string()
      .nullable()
      .when('clientType', {
        is: (clientType: string) =>
          isBloodGroupEnabled &&
          [
            ClientType.PRIMARY,
            ClientType.DEPENDENT,
            ClientType.GROUP_MEMBER,
          ].includes(clientType as ClientType),
        then: Yup.string().trim().required(REQUIRED),
      }),
    textEnabled: Yup.boolean(),
  });

export const CancelEnrollmentSchema = Yup.object().shape({
  endDate: Yup.string().required(REQUIRED),
  note: Yup.string().nullable(),
});

export const CompleteMembershipSchema = Yup.object().shape({
  endDate: Yup.string().required(REQUIRED),
  note: Yup.string().nullable(),
});
