import config from 'config';
import {
  EMonth,
  EventInterval,
  IFrequency,
  IWEEKDAY,
  RecurringMonthType,
} from 'enums/calender';
import ModalKey from 'enums/ModalKey';
import { EventType } from 'enums/virtual-visit';
import { IEventFilter } from 'interfaces/calender';
import { getCurrentDate, MomentDate } from 'utils/moment';

export const eventType = {
  all: { display: 'All', isActive: true, value: EventType.ALL },
  appointment: {
    display: 'Appointment',
    isActive: true,
    value: EventType.APPOINTMENTS,
  },
  virtualvisit: {
    display: 'Virtual Visit',
    isActive: true,
    value: EventType.VIRTUAL_VISIT,
  },
  task: { display: 'Task', isActive: true, value: EventType.TASK },
  schedule: {
    display: 'Schedule',
    isActive: true,
    value: EventType.SCHEDULE,
  },
};

export const eventModalKey = {
  EDIT_VIRTUAL_VISIT: ModalKey.EDIT_VIRTUAL_VISIT,
  ADD_SCHDEULE: ModalKey.ADD_SCHDEULE,
  ADD_APPOINTMENTS: ModalKey.ADD_APPOINTMENTS,
  ADD_TASK: ModalKey.ADD_TASK,
  ADD_VIRTUAL_VISIT: ModalKey.ADD_VIRTUAL_VISIT,
};

export const eventModalRoute = [
  ModalKey.EDIT_VIRTUAL_VISIT,
  ModalKey.ADD_SCHDEULE,
  ModalKey.ADD_APPOINTMENTS,
  ModalKey.ADD_TASK,
  ModalKey.ADD_VIRTUAL_VISIT,
];

export const eventId = [
  {
    id: 'all',
  },
  {
    id: 'appointment',
  },
  {
    id: 'virtualvisit',
  },
  // {
  //   id: 'task',
  // },
  // {
  //   id: 'schedule',
  // },
];

export const recurringInterval = [
  {
    label: 'Day',
    value: IFrequency.DAILY,
  },
  {
    label: 'Week',
    value: IFrequency.WEEKLY,
  },

  {
    label: 'Month',
    value: IFrequency.MONTHLY,
  },
];

export const DEFAULT_EVENT_FILTER: IEventFilter = {
  eventType: '',
  currentDate: getCurrentDate(config.yearMonthDateFormat),
  interval: EventInterval.MONTH,
  people: '',
  search: '',
};

export const weekNames = [
  {
    label: 'Sun',
    value: IWEEKDAY.SUN,
  },
  {
    label: 'Mon',
    value: IWEEKDAY.MON,
  },
  {
    label: 'Tue',
    value: IWEEKDAY.TUE,
  },
  {
    label: 'Wed',
    value: IWEEKDAY.WED,
  },
  {
    label: 'Thu',
    value: IWEEKDAY.THU,
  },
  {
    label: 'Fri',
    value: IWEEKDAY.FRI,
  },
  {
    label: 'Sat',
    value: IWEEKDAY.SAT,
  },
];

export const monthDropDown = [
  {
    label: 'Only This Month',
    value: RecurringMonthType.ONLY_THIS_MONTH,
  },
  {
    label: 'Every Month',
    value: RecurringMonthType.REPEAT_EVERY_MONTH,
  },
];

export const everyMonthArray = Object.keys(EMonth)
  .slice(0, 12)
  .map((str) => Number(str));

const currentMonth = MomentDate().month() + 1;

export const onlyThisMonthArray = (month?: number) => [month || currentMonth];

export const recurringDefaultValue = {
  isRecurring: false,
  repeatEvery: 1,
  repeatOn: '',
  interval: IFrequency.WEEKLY,
  never: 'never',
  endsOn: null || new Date().toString(),
  month: RecurringMonthType.REPEAT_EVERY_MONTH,
  occurrence: 1,
  isOnlyThisEvent: false,
};
