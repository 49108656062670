import { Grid, Typography } from '@mui/material';
import UserType from 'enums/User';
import {
  IAdaptedClientGroup,
  IAdaptedClientIndividual,
} from 'interfaces/client-management';

const MembershipDetail = ({
  clientData,
}: {
  clientData: IAdaptedClientGroup | IAdaptedClientIndividual | null;
}) => {
  const getDisplayName = () => {
    if (!clientData) {
      return '';
    }
    if (clientData?.type === UserType.GROUP) {
      return (clientData as IAdaptedClientGroup)?.businessName;
    }
    return (clientData as IAdaptedClientIndividual)?.fullName;
  };

  return (
    <Grid container display="flex" justifyContent="space-between">
      <Grid item xs={9}>
        <Typography
          display="block"
          fontWeight="medium"
          gutterBottom={false}
          variant="body1"
        >
          Design Membership for
        </Typography>
        <Typography
          color="gray.darker"
          display="block"
          fontWeight="medium"
          variant="body1"
        >
          {getDisplayName()}
        </Typography>
      </Grid>
      <Grid item textAlign="right" xs={3}>
        <Typography display="block" gutterBottom={false} variant="body2">
          Enrolled Date
        </Typography>
        <Typography
          color="gray.darker"
          display="block"
          fontWeight="regular"
          variant="body1"
        >
          {clientData?.formattedCreatedAt}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MembershipDetail;
