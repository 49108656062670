/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { SortOrder } from 'enums/common';
import { NetworkOfferingsSortBy } from 'enums/networks/network-offerings';
import { IFilter, SortOrderType } from 'interfaces/common';
import {
  INetworkDirectoryFacilityNetworkOffering,
  INetworkDirectoryFacilityTableRow,
  INetworkDirectoryPhysicianTableRow,
} from 'interfaces/networks/network-directory';
import { INetworkMasterCode } from 'interfaces/networks/network-mastercode';
import {
  INetworkOfferingAdvancedSearchForm,
  IOfferingNetworkAutocomplete,
  NetworkOfferingSortByType,
} from 'interfaces/networks/network-offerings';
import { IAddPhysicianForm } from 'interfaces/networks/network-physician';
import { IAdaptedNetworkServicesTableRow } from 'interfaces/networks/network-services';
import { INetwork, ITenantNetworkTableRow } from 'interfaces/tenant-networks';
import { RootState } from 'stores';
import { checkIfValuesInObjectIsEmpty } from 'utils/common';

import {
  IAddFacilityForm,
  INetworkFacilityTableRow,
} from '../../../interfaces/networks/network-facility/index';

const { DEFAULT_TABLE_FILTER, DEFAULT_RADIUS } = commonConstants;

const advancedSearchDefaultValues = {
  status: '',
  isAdvancedSearch: false,
};

const sortDefaultValues = {
  sortBy: NetworkOfferingsSortBy.PROVIDER_NAME,
  sortOrder: SortOrder.ASC,
};

interface INetworkServiceSort {
  sortBy: NetworkOfferingSortByType;
  sortOrder: SortOrderType;
}

export interface IOfferingsFilter extends IFilter {
  networkId?: string;
  physicianId?: string;
  facilityId?: string;
  serviceId?: string;
  latitude?: number | string;
  longitude?: number | string;
  radius?: number;
  entityType?: string;
}

interface INetworksStateFlag {
  facility: boolean;
  physician: boolean;
  service: boolean;
  offering: boolean;
}

interface NetworkServiceState {
  advancedSearch: INetworkOfferingAdvancedSearchForm;
  filters: IOfferingsFilter;
  sort: INetworkServiceSort;
  facilityFormData: IAddFacilityForm | null;
  physicianFormData: IAddPhysicianForm | null;
  selectedProvider: Partial<
    INetworkDirectoryFacilityNetworkOffering &
      INetworkFacilityTableRow &
      INetwork
  > | null;
  selectedPhysicianProvider: INetworkDirectoryPhysicianTableRow | null;
  selectedPhysician: Partial<INetworkDirectoryPhysicianTableRow> | null;
  selectedFacility: Partial<INetworkDirectoryFacilityTableRow> | null;
  selectedService: Partial<
    IAdaptedNetworkServicesTableRow & INetworkMasterCode
  > | null;
  networksFlag: INetworksStateFlag;
  offeringId: string;
  offeringNetwork: Partial<INetwork> | null;
  selectedOfferingSearchServiceORProvider: IOfferingNetworkAutocomplete | null;
  selectedNetwork: ITenantNetworkTableRow | null;
  selectedOfferingFormNetwork: ITenantNetworkTableRow | null;
}

const networksStateDefaultValue = {
  service: false,
  facility: false,
  physician: false,
  offering: false,
};

const initialState: NetworkServiceState = {
  advancedSearch: { ...advancedSearchDefaultValues },
  filters: {
    ...DEFAULT_TABLE_FILTER,
    networkId: '',
    latitude: '',
    longitude: '',
    radius: DEFAULT_RADIUS,
  },
  sort: { ...sortDefaultValues },
  networksFlag: { ...networksStateDefaultValue },
  facilityFormData: null,
  physicianFormData: null,
  selectedProvider: null,
  selectedPhysicianProvider: null,
  selectedFacility: null,
  selectedPhysician: null,
  selectedService: null,
  offeringId: '',
  offeringNetwork: null,
  selectedOfferingSearchServiceORProvider: null,
  selectedNetwork: null,
  selectedOfferingFormNetwork: null,
};

export const slice = createSlice({
  name: 'network-offerings',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (
      state,
      action: PayloadAction<Partial<IOfferingsFilter>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = {
        ...DEFAULT_TABLE_FILTER,
        // networkCode: state.filters.networkCode,
        networkId: state.filters.networkId,
        physicianId: '',
        facilityId: '',
        serviceId: '',
        latitude: '',
        longitude: '',
        radius: DEFAULT_RADIUS,
      };
    },

    changeSortByAndOrder: (
      state,
      action: PayloadAction<INetworkServiceSort>
    ) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<INetworkOfferingAdvancedSearchForm>>
    ) => {
      const advanceFilters = { ...state.advancedSearch, ...action.payload };
      const { isAdvancedSearch, ...rest } = advanceFilters;
      state.advancedSearch = {
        ...advanceFilters,
        isAdvancedSearch: !checkIfValuesInObjectIsEmpty(rest),
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    setFacilityFormData: (state, action: PayloadAction<IAddFacilityForm>) => {
      state.facilityFormData = action.payload;
    },

    setPhysicianFormData: (state, action: PayloadAction<IAddPhysicianForm>) => {
      state.physicianFormData = action.payload;
    },

    setSelectedProviderOfferings: (
      state,
      action: PayloadAction<
        Partial<
          INetworkDirectoryFacilityNetworkOffering &
            INetworkFacilityTableRow &
            INetwork
        >
      >
    ) => {
      state.selectedProvider = action.payload;
    },

    setSelectedPhysicianProviderOfferings: (
      state,
      action: PayloadAction<INetworkDirectoryPhysicianTableRow>
    ) => {
      state.selectedPhysicianProvider = action.payload;
    },
    setOfferingId: (state, action: PayloadAction<string>) => {
      state.offeringId = action.payload;
    },

    setOfferingNetwork: (state, action: PayloadAction<INetwork>) => {
      state.offeringNetwork = action.payload;
    },
    setSelectedFacilityOfferings: (
      state,
      action: PayloadAction<Partial<INetworkDirectoryFacilityTableRow>>
    ) => {
      state.selectedFacility = action.payload;
    },
    setSelectedPhysicianOfferings: (
      state,
      action: PayloadAction<Partial<INetworkDirectoryPhysicianTableRow>>
    ) => {
      state.selectedPhysician = action.payload;
    },
    setSelectedServiceOfferings: (
      state,
      action: PayloadAction<
        Partial<IAdaptedNetworkServicesTableRow & INetworkMasterCode>
      >
    ) => {
      state.selectedService = action.payload;
    },
    setSelectedOfferingSearchServiceORProvider: (
      state,
      action: PayloadAction<IOfferingNetworkAutocomplete>
    ) => {
      state.selectedOfferingSearchServiceORProvider = action.payload;
    },
    setSelectedOfferingNetwork: (
      state,
      action: PayloadAction<ITenantNetworkTableRow>
    ) => {
      state.selectedNetwork = action.payload;
    },
    setSelectedOfferingFormNetwork: (
      state,
      action: PayloadAction<ITenantNetworkTableRow>
    ) => {
      state.selectedOfferingFormNetwork = action.payload;
    },
    changeNetworksFlagStatus: (
      state,
      action: PayloadAction<Partial<INetworksStateFlag>>
    ) => {
      state.networksFlag = { ...state.networksFlag, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    resetNetworksFlagStatus: (state) => {
      state.networksFlag = { ...networksStateDefaultValue };
    },

    resetSelectedProvider: (state) => {
      state.selectedProvider = null;
    },
    resetSelectedPhysicianOffering: (state) => {
      state.selectedPhysician = null;
    },
    resetSelectedFacilityOffering: (state) => {
      state.selectedFacility = null;
    },
    resetSelectedPhysicianProvider: (state) => {
      state.selectedPhysicianProvider = null;
    },

    resetSelectedService: (state) => {
      state.selectedService = null;
    },

    resetFacilityFormData: (state) => {
      state.facilityFormData = null;
    },

    resetPhysicianFormData: (state) => {
      state.physicianFormData = null;
    },
    resetSelectedOfferingSearchServiceORProvider: (state) => {
      state.selectedOfferingSearchServiceORProvider = null;
    },
    resetSelectedOfferingFormNetwork: (state) => {
      state.selectedOfferingFormNetwork = null;
    },
    resetAllFilters: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
      state.filters = {
        ...DEFAULT_TABLE_FILTER,
        networkId: '',
        latitude: '',
        longitude: '',
        radius: DEFAULT_RADIUS,
      };
      state.sort = { ...sortDefaultValues };
      state.networksFlag = { ...networksStateDefaultValue };
      state.selectedProvider = null;
      state.selectedService = null;
      state.facilityFormData = null;
      state.physicianFormData = null;
      state.offeringId = '';
      state.selectedOfferingSearchServiceORProvider = null;
      state.selectedNetwork = null;
      state.selectedOfferingFormNetwork = null;
    },
  },
});

// Actions
export const {
  changeAdvancedSearch,
  changeFilters,
  changeNetworksFlagStatus,
  changeSortByAndOrder,
  resetFilters,
  resetAdvancedSearch,
  resetAllFilters,
  resetNetworksFlagStatus,
  resetSelectedProvider,
  resetSelectedService,
  resetFacilityFormData,
  resetPhysicianFormData,
  resetSelectedPhysicianProvider,
  resetSelectedOfferingSearchServiceORProvider,
  resetSelectedFacilityOffering,
  resetSelectedPhysicianOffering,
  resetSelectedOfferingFormNetwork,
  setFacilityFormData,
  setPhysicianFormData,
  setOfferingId,
  setSelectedProviderOfferings,
  setSelectedServiceOfferings,
  setSelectedPhysicianProviderOfferings,
  setSelectedOfferingSearchServiceORProvider,
  setSelectedFacilityOfferings,
  setSelectedPhysicianOfferings,
  setSelectedOfferingNetwork,
  setSelectedOfferingFormNetwork,
} = slice.actions;

// Selectors
export const selectNetworkOfferingsAdvancedSearch = (state: RootState) =>
  state.networkOffering.advancedSearch;
export const selectNetworkOfferingsFilter = (state: RootState) =>
  state.networkOffering.filters;
export const selectNetworkOfferingsSort = (state: RootState) =>
  state.networkOffering.sort;
export const selectSelectedProvider = (state: RootState) =>
  state.networkOffering.selectedProvider;
export const selectFacilityFormData = (state: RootState) =>
  state.networkOffering.facilityFormData;
export const selectPhysicianFormData = (state: RootState) =>
  state.networkOffering.physicianFormData;
export const selectNetworksStateFlag = (state: RootState) =>
  state.networkOffering.networksFlag;
export const selectSelectedServices = (state: RootState) =>
  state.networkOffering.selectedService;
export const selectSelectedFacility = (state: RootState) =>
  state.networkOffering.selectedFacility;
export const selectSelectedPhysician = (state: RootState) =>
  state.networkOffering.selectedPhysician;
export const selectOfferingId = (state: RootState) =>
  state.networkOffering.offeringId;
export const selectOfferingNetwork = (state: RootState) =>
  state.networkOffering.offeringNetwork;
export const selectSelectedPhysicianProvider = (state: RootState) =>
  state.networkOffering.selectedPhysicianProvider;
export const selectSelectedOfferingSearchServiceORProvider = (
  state: RootState
) => state.networkOffering.selectedOfferingSearchServiceORProvider;
export const selectSelectedOfferingNetwork = (state: RootState) =>
  state.networkOffering.selectedNetwork;
export const selectSelectedOfferingFormNetwork = (state: RootState) =>
  state.networkOffering.selectedOfferingFormNetwork;
// Reducer
export default slice.reducer;
