import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
/* eslint-disable no-param-reassign */
import {
  IAdaptedReferralCase,
  IIsEditModeDefaultValueProps,
  IReferralCaseAdvancedSearchForm,
  IReferralCaseList,
} from 'interfaces/cases';
import { IReferralCaseTimelog } from 'interfaces/cases/index';
import { IFilter } from 'interfaces/common';
import { IDocument } from 'interfaces/documents';
import { INetworkOfferingsTableRow } from 'interfaces/networks/network-offerings';
import { INetworkPhysicianTableRow } from 'interfaces/networks/network-physician';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const advancedSearchDefaultValues = {
  status: '',
  From: { id: '', name: '' },
  To: { id: '', name: '' },
  onlyMyCases: false,
};

interface CaseState {
  list: IReferralCaseList[];
  timelog: IReferralCaseTimelog[];
  selectedReferralCase: IAdaptedReferralCase | null;
  filters: IFilter;
  advancedSearch: IReferralCaseAdvancedSearchForm;
  remainingTime: number;
  documents: IDocument[] | null;
  files: {
    step: number;
  };
  notes: {
    editMode: IIsEditModeDefaultValueProps;
    text: string;
  };
  isNoteBoxExpanded: boolean;
  referenceNote: string;
  selectedPhysician: INetworkPhysicianTableRow | null;
  selectedOffering: INetworkOfferingsTableRow | null;
  isLoadMore: boolean;
}

const initialState: CaseState = {
  list: [],
  timelog: [],
  selectedReferralCase: null,
  filters: { ...DEFAULT_TABLE_FILTER },
  advancedSearch: { ...advancedSearchDefaultValues },
  remainingTime: 0,
  documents: null,
  files: {
    step: 0,
  },
  notes: {
    editMode: { status: false, data: null },
    text: '',
  },
  isNoteBoxExpanded: false,
  referenceNote: '',
  selectedPhysician: null,
  selectedOffering: null,
  isLoadMore: false,
};

export const slice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
    resetFiltersWithoutKeyword: (state) => {
      state.filters = {
        ...DEFAULT_TABLE_FILTER,
        keyword: state.filters.keyword,
      };
    },

    changeAdvancedSearchWithResetPagination: (
      state,
      action: PayloadAction<Partial<IReferralCaseAdvancedSearchForm>>
    ) => {
      state.filters = {
        ...state.filters,
        limit: DEFAULT_TABLE_FILTER.limit,
        offset: DEFAULT_TABLE_FILTER.offset,
      };
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    changeAdvancedSearch: (
      state,
      action: PayloadAction<Partial<IReferralCaseAdvancedSearchForm>>
    ) => {
      state.advancedSearch = { ...state.advancedSearch, ...action.payload };
    },

    resetAdvancedSearch: (state) => {
      state.advancedSearch = { ...advancedSearchDefaultValues };
    },

    changeReferralCaseList: (state, action) => {
      state.list = action.payload;
    },
    updateReferralCaseList: (state, action) => {
      state.list = [...state.list, ...action.payload];
    },
    changeSelectedReferralCaseList: (state, action) => {
      state.selectedReferralCase = action.payload;
    },
    setSelectedPhysicianInCases: (
      state,
      action: PayloadAction<INetworkPhysicianTableRow>
    ) => {
      state.selectedPhysician = action.payload;
    },
    setSelectedOfferingInCases: (
      state,
      action: PayloadAction<INetworkOfferingsTableRow>
    ) => {
      state.selectedOffering = action.payload;
    },
    clearSelectedPhysicianInCases: (state) => {
      state.selectedPhysician = null;
    },
    clearSelectedOfferingInCases: (state) => {
      state.selectedOffering = null;
    },
    changeSelectedReferralCaseTimelog: (state, action) => {
      state.timelog = action.payload;
    },
    changeRemainingTime: (state, action) => {
      state.remainingTime = action.payload;
    },
    changeFilesTabStep: (state, action) => {
      state.files.step = action.payload;
    },
    changeDocuments: (state, action) => {
      state.documents = action.payload;
    },
    changeReferenceNote: (state, action) => {
      state.referenceNote = action.payload;
    },
    changeLoadMore: (state, action) => {
      state.isLoadMore = action.payload;
    },
    changeNotes: (state, action) => {
      state.notes = {
        ...state.notes,
        ...action.payload,
      };
    },
    changeNoteBoxExpanded: (state, action) => {
      state.isNoteBoxExpanded = action.payload;
    },
  },
});

// Actions
export const {
  changeReferralCaseList,
  changeSelectedReferralCaseList,
  changeSelectedReferralCaseTimelog,
  changeFilters,
  resetFiltersWithoutKeyword,
  changeAdvancedSearch,
  resetFilters,
  changeAdvancedSearchWithResetPagination,
  resetAdvancedSearch,
  updateReferralCaseList,
  changeRemainingTime,
  changeFilesTabStep,
  changeDocuments,
  changeNotes,
  changeNoteBoxExpanded,
  changeReferenceNote,
  setSelectedPhysicianInCases,
  clearSelectedPhysicianInCases,
  clearSelectedOfferingInCases,
  changeLoadMore,
  setSelectedOfferingInCases,
} = slice.actions;

// Selectors
export const selectCaseFilter = (state: RootState) => state.case.filters;
export const selectCaseAdvancedSearch = (state: RootState) =>
  state.case.advancedSearch;
export const selectCase = (state: RootState) => state.case;
export const selectCaseList = (state: RootState) => state.case.list;
export const selectCurrentReferralCase = (state: RootState) =>
  state.case.selectedReferralCase;
export const selectCurrentReferralCaseTimeLog = (state: RootState) =>
  state.case.timelog;
export const selectCurrentReferralCaseRemainingTime = (state: RootState) =>
  state.case.remainingTime;
export const selectCurrentReferralCaseFilesTabStep = (state: RootState) =>
  state.case.files.step;
export const selectCurrentReferralCaseDocuments = (state: RootState) =>
  state.case.documents;
export const selectCurrentReferralCaseNotes = (state: RootState) =>
  state.case.notes;
export const selectCurrentReferralCaseNoteBoxExpanded = (state: RootState) =>
  state.case.isNoteBoxExpanded;
export const selectCurrentReferralCaseReference = (state: RootState) =>
  state.case.referenceNote;
export const selectSelectedPhysician = (state: RootState) =>
  state.case.selectedPhysician;
export const selectSelectedOffering = (state: RootState) =>
  state.case.selectedOffering;
export const selectLoadMore = (state: RootState) => state.case.isLoadMore;

// Reducer
export default slice.reducer;
