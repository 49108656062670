import { Box } from '@mui/material';
import { IPermission } from 'interfaces/common';
import { selectActiveMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import HeaderMenu from './HeaderMenu';
import HeaderMenuChild from './HeaderMenuChild';

const HeaderMenuList = () => {
  const activeMenu = useAppSelector(selectActiveMenu);

  const renderedHeaderMenuContainer = (
    <Box display="flex">
      {activeMenu?.children &&
        activeMenu?.children.map((menu) => (
          <HeaderMenuChild key={menu.resourceId} menu={menu} />
        ))}
    </Box>
  );

  if (activeMenu?.children) {
    return (
      <Box display="flex">
        <Box alignItems="center" display="flex">
          <HeaderMenu menu={activeMenu} />
        </Box>
        {renderedHeaderMenuContainer}
      </Box>
    );
  }

  if (activeMenu) {
    return <HeaderMenu menu={activeMenu as IPermission} />;
  }

  return <p>...</p>;
};

export default HeaderMenuList;
