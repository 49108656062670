export enum ReferringAgentStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  NEW = 'New',
}

export enum RefferingAgentSortBy {
  NAME = 'name',
  EMAIL = 'email',
}
