import { useEffect, useMemo, useRef, useState } from 'react';

import {
  Autocomplete,
  Box,
  CircularProgress,
  debounce,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import GenderDobLast4SSNDisplay from 'common/display/GenderDobLast4SSNDisplay';
import commonConstants from 'constants/common';
import { ClientGroupSortBy } from 'enums/client-management';
import { ReferenceSubType, ReferenceType, SortOrder } from 'enums/common';
import UserType from 'enums/User';
import {
  IAdaptedClientTableRow,
  IClientFilters,
} from 'interfaces/client-management';
import { IInvitee } from 'interfaces/virtual-visit';
import {
  useClientDetailQuery,
  useClientQuery,
} from 'services/client-management';
import { RootState, store } from 'stores';
import { selectAuthTenantData } from 'stores/auth';
import { setParentClient } from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

const { DEFAULT_TABLE_FILTER } = commonConstants;

// Check is Agent login
// Depreciated: Use useCheckUserIsAgent() hook instead
const checkUserIsAgent = () => {
  const rootState: RootState = store.getState();
  const tenantData = rootState.auth?.tenantData;
  const referenceType = tenantData?.tenantAssociation?.referenceType;
  const referenceSubType = tenantData?.tenantAssociation?.referenceSubType;

  const isAgent =
    referenceType === ReferenceType.AGENT &&
    referenceSubType === ReferenceSubType.AGENT;

  return isAgent;
};

interface IProps {
  clientId?: string;
  clients?: IAdaptedClientTableRow[] | null;
  userType: UserType | 'All';
  disabled: boolean;
  autoFocusEnabled?: boolean;
  inputProps?: TextFieldProps & { 'data-cy'?: string };
  multiple?: boolean;
  onSelectHandler?: (client: IAdaptedClientTableRow | null) => void;
  onMultiSelectHandler?: (client: IAdaptedClientTableRow[] | null) => void;
  showPrimaryMembersOnly?: boolean;
  showSponsorMembersOnly?: boolean;
  textFieldVariant?: 'standard' | 'outlined';
  limitTags?: number;
  autoFocusInput?: boolean;
  guestData?: IInvitee[];
}

interface IClientFiltersWithSort extends IClientFilters {
  sortBy: string;
  sortOrder: string;
  isPrimary?: boolean;
  isSponsor?: boolean;
}

const ClientAutocomplete = ({
  autoFocusEnabled,
  clientId,
  clients,
  limitTags,
  userType,
  inputProps,
  disabled,
  multiple,
  onSelectHandler,
  onMultiSelectHandler,
  showPrimaryMembersOnly = false,
  showSponsorMembersOnly = false,
  textFieldVariant = 'standard',
  autoFocusInput = true,
  guestData,
}: IProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const tenantData = useAppSelector(selectAuthTenantData);
  const dispatch = useAppDispatch();

  const clientIsAgent = checkUserIsAgent();
  // const clientIsAgent = useCheckUserIsAgent(); // TODO: Find out why this doesn't work.

  const [value, setValue] = useState<
    IAdaptedClientTableRow | IAdaptedClientTableRow[] | null
  >(null);

  const [filters, setFilters] = useState<
    IClientFiltersWithSort & { groupCode: string }
  >({
    ...DEFAULT_TABLE_FILTER,
    type: '',
    tenantId: tenantData?.tenantAssociation.tenantId ?? '',
    sortBy: ClientGroupSortBy.NAME,
    sortOrder: SortOrder.ASC,
    isPrimary: showPrimaryMembersOnly, // only primary members are fetched if true
    isSponsor: showSponsorMembersOnly,
    groupCode: tenantData?.tenantAssociation.groupCode || '',
  });

  const clientQuery = useClientQuery(
    {
      ...filters,
      ...(clientIsAgent && {
        agentId: tenantData?.tenantAssociation?.referenceId,
      }),
    },
    {
      enabled: !!filters.type,
    }
  );

  const clientDetail = useClientDetailQuery(clientId!, {
    enabled: !!clientId && !multiple,
  });

  useEffect(() => {
    if (!userType) {
      return;
    }
    setFilters((prevState) => ({
      ...prevState,
      type: userType,
    }));
  }, [userType]);

  useEffect(() => {
    if (multiple) {
      setValue(clients || null);
    }
  }, [clients, multiple]);

  // Populates the value (edit mode)
  useEffect(() => {
    if (clientDetail.data) {
      setValue(clientDetail.data);
      dispatch(setParentClient(clientDetail.data));
    }
  }, [clientDetail.data, dispatch]);

  const handleInputChange = async (_: any, newInputValue: string) => {
    if (newInputValue?.length > 2 || !newInputValue?.length)
      setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
  };

  const onChange = async (
    client: IAdaptedClientTableRow | IAdaptedClientTableRow[] | null
  ) => {
    if (Array.isArray(client)) {
      if (onMultiSelectHandler !== undefined) {
        onMultiSelectHandler(client);
      }
    } else if (onSelectHandler !== undefined) {
      onSelectHandler(client);
    }
  };

  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    []
  );

  const getOptionName = (option: IAdaptedClientTableRow) => {
    if (userType === UserType.GROUP) {
      return option.businessName;
    }

    return option.name;
  };

  const options =
    clientQuery?.data?.rows.filter(
      (filterData) =>
        !guestData?.some((obj) => obj.clientId === filterData.clientId)
    ) || [];

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && autoFocusEnabled) {
      inputRef.current?.focus();
    }
  }, [autoFocusEnabled]);

  const getValue = (
    selectedValue: IAdaptedClientTableRow | IAdaptedClientTableRow[] | null
  ) => {
    if (multiple) {
      if (Array.isArray(selectedValue)) {
        return [...selectedValue];
      }
      return [];
    }

    return selectedValue;
  };

  return (
    <Autocomplete
      autoComplete
      defaultValue={getValue(value)}
      disabled={disabled}
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : getOptionName(option)
      }
      id="async-client-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, newValue) =>
        option.clientId === newValue.clientId
      }
      limitTags={limitTags}
      loading={clientQuery.isLoading}
      multiple={multiple}
      onChange={(
        _,
        newValue: IAdaptedClientTableRow | IAdaptedClientTableRow[] | null
      ) => {
        onChange(newValue || null);
        setValue(newValue);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={debouncedInputChange}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      openOnFocus={false}
      options={options}
      renderInput={(params) => (
        // You can use different variations of textfield here.
        // Please refer TextfieldOverview.tsx for reference.
        <TextField
          {...params}
          {...inputProps}
          autoFocus={autoFocusInput}
          fullWidth
          InputProps={{
            ...params.InputProps,
            ...inputProps?.InputProps,
            inputRef,
            endAdornment: (
              <>
                {clientQuery.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            ...inputProps?.inputProps,
          }}
          label={inputProps?.label ?? 'Select client'}
          placeholder={
            inputProps?.placeholder ?? 'Type name or email of client to search'
          }
          size={inputProps?.size ?? 'small'}
          variant={textFieldVariant}
        />
      )}
      renderOption={(props, option) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Box
          {...props}
          component="li"
          key={option.clientId}
          sx={{
            display: 'block !important',
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Typography color="inherit" gutterBottom={false} variant="body2">
            {getOptionName(option)}
          </Typography>

          <GenderDobLast4SSNDisplay
            dob={option.dob}
            gender={option.gender}
            ssn={option.lastSSN}
          />
        </Box>
      )}
      value={multiple ? value ?? [] : value}
    />
  );
};

ClientAutocomplete.defaultProps = {
  clientId: '',
  clients: [],
  inputProps: null,
  limitTags: undefined,
  multiple: false,
  showPrimaryMembersOnly: false,
  autoFocusEnabled: false,
  showSponsorMembersOnly: false,
  textFieldVariant: 'standard',
  onSelectHandler: undefined,
  onMultiSelectHandler: undefined,
  autoFocusInput: true,
  guestData: [],
};

export default ClientAutocomplete;
