import React, { useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import {
  alpha,
  createTheme,
  darken,
  ThemeProvider,
} from '@mui/material/styles';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

import customBorderRadius from './borderRadius';
import breakpoints from './breakpoints';
import componentsOverrides from './overrides';
import palette from './palette';
import customShadows from './shadows';
import { spacing } from './spacing';
import typography from './typography';

interface Props {
  children: React.ReactNode;
}

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: {
      low: string;
      medium: string;
      high: string;
      shadowInset: string;
      shadowFocus: string;
    };

    customBorderRadius: {
      xs: string;
      sm: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    customShadows?: {
      low?: string;
      medium?: string;
      high?: string;
      shadowInset?: string;
      shadowFocus?: string;
    };

    customBorderRadius?: {
      xs?: string;
      sm?: string;
    };
  }
}

const ThemeConfig = ({ children }: Props): JSX.Element => {
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);

  const dynamicPalette = authTenantAssociation?.metaData?.themeColor;

  const themeOptions = useMemo(() => {
    if (dynamicPalette?.primary) {
      const dynamicPrimary = dynamicPalette.primary;
      palette.primary.main = dynamicPrimary;
      palette.primary.lighter = alpha(dynamicPrimary, 0.1);
      palette.primary.light = alpha(dynamicPrimary, 0.5);
      palette.primary.dark = darken(dynamicPrimary, 0.3);
      palette.primary.darker = darken(dynamicPrimary, 0.6);
    }

    if (dynamicPalette?.secondary) {
      const dynamicSecondary = dynamicPalette.secondary;
      palette.secondary.main = dynamicSecondary;
      palette.secondary.lighter = alpha(dynamicSecondary, 0.1);
      palette.secondary.light = alpha(dynamicSecondary, 0.5);
      palette.secondary.dark = darken(dynamicSecondary, 0.3);
      palette.secondary.darker = darken(dynamicSecondary, 0.6);
    }

    return {
      spacing,
      breakpoints,
      customBorderRadius,
      customShadows,
      palette,
      typography,
    };
  }, [dynamicPalette]);

  const theme = createTheme(themeOptions);
  theme.components = componentsOverrides(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
