import { Chip, SxProps, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/common';
import { startCase } from 'utils/lodash';

interface Props {
  sx?: SxProps;
  label: string;
  value: string;
  capitalize?: boolean;
  onDelete: (label: string) => void;
}
/**
 * @depreciated
 */
const FilterChip = ({ sx, label, value, onDelete, capitalize }: Props) => {
  const toDisplayLabel = startCase(label);
  return (
    <Chip
      label={
        <Typography
          data-cy={`appliedFilter-${label}`}
          gutterBottom={false}
          variant="body2"
        >
          {capitalize ? capitalizeFirstLetter(toDisplayLabel) : toDisplayLabel}:{' '}
          <Typography
            component="span"
            fontWeight={(theme) => theme.typography.fontWeightMedium}
            gutterBottom={false}
            variant="body2"
          >
            {value}
          </Typography>
        </Typography>
      }
      onDelete={() => onDelete(label)}
      size="small"
      sx={{ ...sx }}
    />
  );
};

FilterChip.defaultProps = {
  sx: {
    height: 30,
  },
  capitalize: true,
};

export default FilterChip;
