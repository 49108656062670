import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { TextField, TextFieldProps } from '@mui/material';
import config from 'config';

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CustomNumberFormat = React.forwardRef<NumberFormat<any>, ICustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format={config.last4SSN}
        getInputRef={ref}
        isNumericString
        mask="_"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }
);

type Props = {
  name?: string;
  label?: string;
  isRequired?: boolean;
  showHelperText?: boolean;
} & TextFieldProps;

const FormMaskedLast4SSNInput = ({
  name = 'lastSSN',
  label,
  isRequired,
  showHelperText,
  ...others
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          {...others}
          {...field}
          error={!!errors[name]}
          fullWidth
          helperText={
            showHelperText ? (errors[name]?.message as React.ReactNode) : null
          }
          id="masked-lastSSN"
          InputProps={{
            inputComponent: CustomNumberFormat as any,
          }}
          label={isRequired ? `${label} *` : label}
          size="small"
          variant="standard"
        />
      )}
    />
  );
};

FormMaskedLast4SSNInput.defaultProps = {
  name: 'lastSSN',
  label: 'Last four SSN',
  isRequired: false,
  showHelperText: false,
};

export default FormMaskedLast4SSNInput;
