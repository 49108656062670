import apiRoutes from 'constants/apiRoutes';
import {
  IActivateAccountSchema,
  IChangeCredential,
  IForgetPasswordResponse,
  IImpersonateResponse,
  IImpersonateSchema,
  ILinkAccountSchema,
  ILogin,
  ILoginSchema,
  IResetPassword,
  IRevertImpersonateSchema,
  ITwoFactorAuthenticationSchema,
  IVerifyAccountResponse,
  IVerifyAccountSchema,
  IVerifyCode,
} from 'interfaces/auth/index';
import { IResponse } from 'interfaces/http';
import { baseRequest } from 'utils/axios';

const { auth } = apiRoutes;

export const activateAccount = async (
  data: IActivateAccountSchema
): Promise<IResponse<boolean>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: auth.activateAccount,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const checkUserExists = async (
  data: IVerifyAccountSchema
): Promise<IResponse<IVerifyAccountResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.userExists,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const linkAccount = async (
  data: ILinkAccountSchema
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.linkAccount,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const forgetPassword = async (
  username: string
): Promise<IResponse<IForgetPasswordResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.forgetPassword,
    data: { username },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const impersonateUser = async (
  data: IImpersonateSchema
): Promise<IResponse<IImpersonateResponse>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.impersonate,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const loginUser = async (
  data: ILoginSchema
): Promise<IResponse<ILogin>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.login,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const revertImpersonateUser = async (
  data: IRevertImpersonateSchema
): Promise<IResponse<ILogin>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.revertImpersonate,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
export const resetPassword = async (
  data: IResetPassword
): Promise<IResponse<boolean>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.resetPassword,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const changeCredential = async (
  data: IChangeCredential
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.setPassword,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const verifyCode = async (
  code: string
): Promise<IResponse<IVerifyCode>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.verifyCode,
    data: { code },
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const twoFactorAuthentication = async (
  data: ITwoFactorAuthenticationSchema
): Promise<IResponse<ILogin>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: auth.twoFactorAuthentication,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
