import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import { IListItem } from 'interfaces/common';
import { truncateText } from 'utils/common';

interface IProps {
  list: IListItem[];
}

const ListDisplay = ({ list }: IProps): JSX.Element => (
  <Box component="ul" sx={{ listStyle: 'none' }}>
    {list.map((item) => (
      <Box
        component="li"
        key={item.text?.toString()}
        sx={{ position: 'relative', ml: '1.175rem' }}
      >
        {item.text && (
          <>
            <SvgIcon
              sx={{
                width: '14px',
                height: '14px',
                color: (theme) => theme.palette.gray.main,
                mr: 1,
                position: 'absolute',
                top: '0.175rem',
                left: '-1.175rem',
              }}
            >
              {item.icon}
            </SvgIcon>
            {item.tooltip ? (
              <Tooltip arrow placement="top" title={item.text || ''}>
                <Box className="text-overflow-ellipses" component="div">
                  <Typography {...item?.typographyProps} variant="body2">
                    {truncateText(item.text as string, 40)}
                  </Typography>
                </Box>
              </Tooltip>
            ) : (
              <Box className="text-overflow-ellipses" component="div">
                <Typography {...item?.typographyProps} variant="body2">
                  {item.text}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    ))}
  </Box>
);

export default ListDisplay;
