import { useEffect, useState } from 'react';

import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, useTheme } from '@mui/material';
import { IResponseMessage } from 'interfaces/virtual-visit/comet-chat';
import { downloadAttachedFile } from 'utils/virtual-visit';

export const ChatItem = ({
  item,
  scrollRef,
}: {
  item: IResponseMessage;
  scrollRef: HTMLDivElement | any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollRef]);

  return (
    <Box flexDirection="row" p={1} width="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={item?.payload?.isSentByMe ? 'flex-end' : 'flex-start'}
      >
        <Box
          bgcolor={
            !item?.payload?.isSentByMe
              ? theme.palette.info.lighter
              : theme.palette.gray.lighter
          }
          borderRadius={theme.spacing(0.5)}
          color={theme.palette.gray.darker}
          display="flex"
          flexDirection="column"
          p={1}
        >
          {item.payload.isTextMessage ? (
            <Typography
              color={theme.palette.gray.darker}
              gutterBottom={false}
              variant="body2"
            >
              {item?.payload?.text}
            </Typography>
          ) : (
            <Box
              alignItems="center"
              display="flex"
              gap={1}
              justifyContent="space-between"
            >
              <Box alignItems="center" display="flex">
                <Box mr={1}>
                  <FontAwesomeIcon icon={faPaperclip} />
                </Box>
                <Typography
                  sx={{ wordWrap: 'break-word', maxWidth: 130 }}
                  variant="body1"
                >
                  {item.payload?.fileName}
                </Typography>
              </Box>
              <LoadingButton
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  downloadAttachedFile(
                    item.payload.text,
                    item.payload?.fileName,
                    () => {
                      setIsLoading(false);
                    }
                  );
                }}
                size="small"
                sx={{
                  border: `1px solid ${theme.palette.gray.main} !important`,
                  borderRadius: '4px !important',
                  maxWidth: 100,
                  backgroundColor: `${theme.palette.common.white} !important`,
                }}
                variant="outlined"
              >
                Download File
              </LoadingButton>
            </Box>
          )}
          <Typography
            alignSelf={!item.isSentByMe ? 'flex-end' : 'flex-start'}
            color={theme.palette.gray.main}
            gutterBottom={false}
            mt={0.1}
            textTransform="uppercase"
            variant="caption"
          >
            {item?.payload?.time}
          </Typography>
        </Box>
      </Box>
      <Box ref={scrollRef} />
    </Box>
  );
};
