export enum TenantSortBy {
  NAME = 'name',
  EMAIL = 'email',
  WEBSITE = 'website',
  ADDRESS = 'address',
}

export enum TenantSettingStatus {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum TenantAffiliateSettingStatus {
  Website = 'Website',
  Portal = 'Portal',
  Both = 'Both',
  None = 'None',
}

export enum TenantContact {
  PRIMARY = 'Primary',
  SUPPORT = 'Support',
}

export enum TenantCode {
  DPC = 'DPC',
  BMTCARE = 'BMTCARE',
}

export enum TenantGroupCode {
  V360 = 'V360',
  GRAITH_CARE = 'GC',
}

export enum TenantSettingsType {
  RADIO = 'Radio',
  TEXT = 'Text',
  MULTISELECT = 'Multi-select',
}

export enum MiscellaneousCode {
  CLIENT_AGREEMENT = 'CLIENT_AGREEMENT',
  APPOINTMENT_CHECKIN = 'APPOINTMENT_CHECKIN',
  OTHERS_IN_GENDER = 'OTHERS_IN_GENDER',
  DEPENDENT_RELATIONSHIP = 'DEPENDENT_RELATIONSHIP',
  INTERNATIONAL_ADDRESS = 'INTERNATIONAL_ADDRESS',
}
export enum UsersCode {
  MOBILE_INVITATION = 'MOBILE_INVITATION',
  ADMIN_CHAT = 'ADMIN_CHAT',
}

export enum CaseCode {
  SHARE_CASE = 'SHARE_CASE',
  TENANT_SERVICE = 'TENANT_SERVICE',
  DONOR_MATCHING = 'DONOR_MATCHING',
  TENANT_SERVICE_PORTAL = 'TENANT_SERVICE_PORTAL',
  CASETIMELOG = 'CASETIMELOG',
  CREATEREFERRAL = 'CREATEREFERRAL',
  CREATECASE = 'CREATECASE',
  EDITREQUESTEDTO = 'EDITREQUESTEDTO',
  GROUP_CASES = 'GROUP_CASES',
  CASE_PROVIDER_PROCEDURE = 'CASE_PROVIDER_PROCEDURE',
  CASE_TYPE = 'CASE_TYPE',
}

export enum CaseOptions {
  MEDICAL = 'Medical',
  RX = 'Rx',
  SERVICE = 'Service',
  ADVOCACY = 'Advocacy',
  BLOOD_DONOR = 'Blood Donor',
}

export enum NotificationCode {
  TESTRESULTEMAIL = 'TESTRESULTEMAIL',
  VIDEO_VISIT_EMAIL = 'VIDEO_VISIT_EMAIL',
}

export enum EnrollmentCode {
  ACCEPT_INSURANCE = 'ACCEPT_INSURANCE',
  ADD_CASE_OPTION_FOR_CLIENT = 'ADD_CASE_OPTION_FOR_CLIENT',
  ADD_DEPENDENT_OPTION_FOR_CLIENT = 'ADD_DEPENDENT_OPTION_FOR_CLIENT',
  ADVOCACYPROGRAM = 'ADVOCACYPROGRAM',
  AFFILIATE_VISIBILITY = 'AFFILIATE_VISIBILITY',
  ANCILLARY_EMAIL = 'ANCILLARY_EMAIL',
  APPROVE_MEMBERSHIP = 'APPROVE_MEMBERSHIP',
  BLOOD_GROUP = 'BLOOD_GROUP',
  CHECKINDAYSTHRESHOLD = 'CHECKINDAYSTHRESHOLD',
  CLIENT_AFFILIATE = 'CLIENT_AFFILIATE',
  CLIENT_ENROLLMENT_TYPE = 'CLIENT_ENROLLMENT_TYPE',
  CLIENT_REFERRAL_LINK = 'CLIENT_REFERRAL_LINK',
  COMPLETEMEMBERSHIP = 'COMPLETEMEMBERSHIP',
  CUSTOM_MINUTES_MEMBERSHIP = 'CUSTOM_MINUTES_MEMBERSHIP',
  MEMBER_PROVIDER_ASSOCIATION = 'MEMBER_PROVIDER_ASSOCIATION',
  MEMBERSHIP_ID_CARD = 'MEMBERSHIP_ID_CARD',
  MINIMUM_REQUIREMENT_FEE = 'MINIMUM_REQUIREMENT_FEE',
  MODIFY_MEMBERSHIP_OPTION_FOR_CLIENT = 'MODIFY_MEMBERSHIP_OPTION_FOR_CLIENT',
  SPONSOR_ID = 'SPONSOR_ID',
}

export enum BillingCode {
  CHECKOUT = 'CHECKOUT',
  BANKING = 'BANKING',
  CARD = 'CARD',
  CASH = 'CASH',
  SPONSORACC = 'SPONSORACC',
  CHECK = 'CHECK',
  OTHERS = 'OTHERS',
  ADDTRANSPONSOR = 'ADDTRANSPONSOR', // Add transaction for sponsor (group and primary individual)
  ADDTRANMEMBER = 'ADDTRANMEMBER', // Add transaction for member (group member and dependent)
}

export const TenantSettingCodes = {
  miscellaneous: MiscellaneousCode,
  case: CaseCode,
  enrollment: EnrollmentCode,
  billings: BillingCode,
  notification: NotificationCode,
  users: UsersCode,
};

export enum ClientAgreementSettingOptions {
  BASIC = 'Basic',
  DOCU_SIGN = 'DocuSign',
  DISABLED = 'Disabled',
}
