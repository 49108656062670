import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  FormAddressInput,
  FormCheckbox,
  FormMaskedPhoneInput,
  FormTextfield,
} from 'common';
import FormPhoneCountryInput from 'common/form/FormPhoneCountryInput';
import FormReferringAgentAutocomplete from 'common/form/FormReferringAgentAutocomplete';
import FormTagInput from 'common/form/FormTagInput';
import { MiscellaneousCode } from 'enums/tenant-management/tenant';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { getAddClientSchema } from 'schema/client-management';
import { selectTenantSettings } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';
import {
  checkEnrollmentAffiliateVisibleTenantSetting,
  checkIsMiscellaneousSettingEnabled,
} from 'utils/tenantSetting';

interface IProps {
  allDisabled?: boolean;
  showReferringAgent?: boolean;
  isSponsorIdEnabled?: boolean;
}

const GroupForm = ({
  allDisabled,
  showReferringAgent,
  isSponsorIdEnabled,
}: IProps): JSX.Element => {
  const tenantSettings = useAppSelector(selectTenantSettings);
  const { watch, setValue } = useFormContext();
  const phoneValue = watch('phone');

  const isPhoneValid = getAddClientSchema({}).fields.phone.isValidSync(
    phoneValue
  );
  const isInternationalAddressEnabled = checkIsMiscellaneousSettingEnabled(
    MiscellaneousCode.INTERNATIONAL_ADDRESS
  );

  const isAffiliateEnabled =
    checkEnrollmentAffiliateVisibleTenantSetting(tenantSettings);

  useEffect(() => {
    if (!isPhoneValid) {
      setValue('textEnabled', false);
    }
  }, [isPhoneValid, setValue]);

  const isUserAgent = useCheckUserIsAgent();
  const isUserClient = useCheckUserIsClient();

  const hideAddNoteField = isUserAgent || isUserClient;

  return (
    <>
      {/* TODO: We are hardcoding below condition .. Later it will be based according to Tenant Settings */}
      {showReferringAgent && isAffiliateEnabled && (
        <Grid mb={2}>
          <FormReferringAgentAutocomplete
            disabled={allDisabled || isUserAgent || isUserClient}
            inputProps={{ 'data-cy': 'client-group-autocomplete' }}
            name="referredBy"
          />
        </Grid>
      )}

      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Company Information
      </Typography>
      <Grid container mb={2}>
        <Grid item xs={12}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{ 'data-cy': 'company-name-input' }}
            label="Company Name"
            name="businessName"
            required
          />
        </Grid>
      </Grid>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={6}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{ 'data-cy': 'contact-first-name-input' }}
            label="Contact First Name"
            name="firstName"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{ 'data-cy': 'contact-last-name-input' }}
            label="Contact Last Name"
            name="lastName"
            required
          />
        </Grid>
      </Grid>

      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Contact Information
      </Typography>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={6}>
          <FormTextfield
            disabled={allDisabled}
            inputProps={{ 'data-cy': 'contact-email-input' }}
            label="Email"
            name="email"
            required
          />
        </Grid>

        <Grid item xs={6}>
          <Stack justifyContent="flex-end">
            {isInternationalAddressEnabled ? (
              <FormPhoneCountryInput disabled={allDisabled} isRequired />
            ) : (
              <FormMaskedPhoneInput disabled={allDisabled} isRequired />
            )}

            {!allDisabled && (
              <Box display="flex" justifyContent="flex-start" mt={0.5}>
                <FormCheckbox
                  color="primary"
                  disabled={!phoneValue || !isPhoneValid}
                  label="Allow Text Notifications"
                  name="textEnabled"
                  sizeSmall
                />
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid mt={allDisabled ? -2 : -4}>
        <FormAddressInput allDisabled={allDisabled} />
      </Grid>
      <Grid item mt={2} xs={12}>
        <FormTagInput
          disabled={allDisabled}
          label="Add Tags"
          name="tags"
          showHelperText
          size="small"
        />
      </Grid>
      {!hideAddNoteField && (
        <Grid mt={4}>
          <FormTextfield
            disabled={allDisabled}
            label="Add Note"
            multiline
            name="note"
            rows={4}
          />
        </Grid>
      )}

      {isSponsorIdEnabled && (
        <Grid container>
          <Grid mb={4} mt={4} xs={6}>
            <FormTextfield
              disabled={allDisabled || isUserAgent || isUserClient}
              label="Member Id"
              name="sponsorId"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

GroupForm.defaultProps = {
  allDisabled: false,
  showReferringAgent: false,
  isSponsorIdEnabled: false,
};

export default GroupForm;
