import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Modal } from 'common';
import { ChangeMembershipActionType } from 'enums/client-management';
import { useCheckPermissionToModifyMembership } from 'hooks/useCheckPermissionToModifyMembership';
import { useClientDetailQuery } from 'services/client-management';
import {
  selectChangeMembershipModal,
  setChangeMembershipClientData,
  setChangeMembershipSelectedData,
  setChangeMembershipStep,
  setCurrentMembershipSelectedData,
} from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';

import ChangeMembershipCancellation from './ChangeMembershipCancellation';
import ChangeMembershipCompletion from './ChangeMembershipCompletion';
import ChangeMembershipConfirmation from './ChangeMembershipConfirmation';
import ChangeMembershipList from './ChangeMembershipList';
import ChangeMembershipPlanLists from './ChangeMembershipPlanLists';
import ChangeMembershipSuccess from './ChangeMembershipSuccess';

const ChangeMembershipModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const clientIdFromParams = searchParams.get('clientId');
  const stepFromParams = searchParams.get('step');
  const clientDetail = useClientDetailQuery(clientIdFromParams!, {
    enabled: !!clientIdFromParams,
  })?.data;
  const isModifyMembershipAllowed = useCheckPermissionToModifyMembership();

  useEffect(() => {
    if (clientDetail) {
      dispatch(setChangeMembershipClientData(clientDetail));
    }
  }, [clientDetail, dispatch]);

  const changeMembershipModalData = useAppSelector(selectChangeMembershipModal);
  const modalStep = changeMembershipModalData.step;

  /**
   * Show 'Add' Step if mentioned in the query param
   */
  useEffect(() => {
    if (stepFromParams === ChangeMembershipActionType.ADD) {
      dispatch(
        setChangeMembershipStep({
          step: 1,
          actionType: ChangeMembershipActionType.ADD,
        })
      );
    }
  }, [dispatch, stepFromParams]);

  const handleClose = () => {
    navigate(-1);
    dispatch(setChangeMembershipClientData(null));
    dispatch(setCurrentMembershipSelectedData(null));
    dispatch(
      setChangeMembershipSelectedData({
        selectedMembershipType: null,
        startDate: null,
        endDate: null,
        selectedOffering: null,
      })
    );
    setTimeout(() => {
      dispatch(
        setChangeMembershipStep({
          step: 0,
          actionType: null,
        })
      );
    }, 1000);
  };

  return (
    <Modal
      handleClose={handleClose}
      icon="faFilePen"
      open
      title={isModifyMembershipAllowed ? 'Modify Membership' : 'Buy Membership'}
    >
      {modalStep === 0 && (
        <ChangeMembershipList clientId={clientIdFromParams!} />
      )}
      {modalStep === 1 && <ChangeMembershipPlanLists />}
      {modalStep === 2 && <ChangeMembershipConfirmation />}
      {modalStep === 3 && <ChangeMembershipSuccess />}
      {modalStep === 4 && <ChangeMembershipCompletion />}
      {modalStep === 5 && <ChangeMembershipCancellation />}
    </Modal>
  );
};

export default ChangeMembershipModal;
