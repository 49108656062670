import apiRoutes from 'constants/apiRoutes';
import { IFilter } from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import { ITenantNetworkTableRow } from 'interfaces/tenant-networks';
import { baseRequest } from 'utils/axios';

const { networkConfigs } = apiRoutes;

export const getTenantNetworks = async (
  params: IFilter & { tenantId: string }
): Promise<IResponse<IListResponse<ITenantNetworkTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: networkConfigs.getAll,
    params,
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getNetworkByParams = async ({
  code,
  networkId,
}: {
  code?: string;
  tenantId?: string;
  networkId?: string;
}): Promise<IResponse<IListResponse<ITenantNetworkTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: networkConfigs.getAll,
    params: {
      ...(code && { code }),
      ...(networkId && { networkId }),
    },
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
