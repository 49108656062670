import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import toastMessageConstant from 'constants/toastMessage';
import uiRoutes from 'constants/uiRoutes';
import { useCheckUserIsAgent } from 'hooks/useCheckUserIsAgent';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useSnackbar } from 'notistack';
import { useClientDetailQuery } from 'services/client-management';
import { useLanguageDictionaryQuery } from 'services/language-dictionary';
import { useMerchantProfileQuery } from 'services/payment';
import { useTenantSettingForStoreQuery } from 'services/tenant-management/tenant';
import {
  selectAuthTenantAssociation,
  selectAuthUserClient,
  selectMenu,
  selectTenantSettings,
  setActiveMenu,
  setTenantSettings,
  setUserClient,
} from 'stores/auth';
import { changeFilters as changeFiltersForTransactionTable } from 'stores/billings/transactions';
import { changeFilters as changeFiltersForClientGroupTable } from 'stores/client-management/group';
import { changeFilters as changeFiltersForClientIndividualTable } from 'stores/client-management/individual';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { selectLangDict, setLangDict } from 'stores/language-dictionary';
import { selectGatewayDetail, setGatewayDetail } from 'stores/Payment';
import { getRouteToMenuMap } from 'utils/auth';
import { setAuthToken } from 'utils/axios';
import { isTokenExpired } from 'utils/jwt';
import { clearLocal, clearSession, getLocal } from 'utils/storage';

const { TOKEN_EXPIRED } = toastMessageConstant;

interface Props {
  children: JSX.Element;
}

const AppWrapper = ({ children }: Props) => {
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);
  const menus = useAppSelector(selectMenu);
  const authTenantAssociation = useAppSelector(selectAuthTenantAssociation);
  const authUserClient = useAppSelector(selectAuthUserClient);
  const gatewayDetail = useAppSelector(selectGatewayDetail);
  const environment = gatewayDetail?.environment;
  const langDict = useAppSelector(selectLangDict);

  const routeToMenuMap = useMemo(() => getRouteToMenuMap(menus ?? []), [menus]);

  const tenantSettings = useAppSelector(selectTenantSettings);

  const userIsClient = useCheckUserIsClient();
  const isClientAgent = useCheckUserIsAgent();

  const clientDetailQuery = useClientDetailQuery(
    authTenantAssociation?.referenceId || '',
    {
      enabled: false,
    }
  );

  const merchantProfileQuery = useMerchantProfileQuery(
    authTenantAssociation?.tenantId || '',
    {
      enabled: !!authTenantAssociation?.tenantId && !environment, // Query active only if paymentEnvironment is not populated already
    }
  );

  const languageDictionaryQuery = useLanguageDictionaryQuery(
    { groupCode: authTenantAssociation?.groupCode || '' },
    {
      enabled: !!authTenantAssociation?.groupCode && !langDict.dict,
    }
  );

  // Set up language dictionary
  useEffect(() => {
    if (languageDictionaryQuery.data) {
      dispatch(setLangDict(languageDictionaryQuery.data));
    }
  }, [languageDictionaryQuery.data, dispatch]);

  // Set up payment environment
  useEffect(() => {
    if (merchantProfileQuery.data) {
      const primaryPaymentGateway =
        merchantProfileQuery.data.gatewayDetails?.find(
          (item) => item.isPrimary === 'true'
        );

      if (!primaryPaymentGateway?.environment) {
        return;
      }

      dispatch(setGatewayDetail(primaryPaymentGateway));
    }
  }, [merchantProfileQuery.data, dispatch]);

  const tenantSettingForStoreQuery = useTenantSettingForStoreQuery(
    authTenantAssociation?.tenantId || '',
    {
      // Fetch Tenant setting only if tenant setting is not populated already in store.
      enabled: !!authTenantAssociation?.tenantId && !tenantSettings,
    }
  );

  const { data: settingData } = tenantSettingForStoreQuery;

  useEffect(() => {
    if (settingData) {
      dispatch(setTenantSettings(settingData));
    }
  }, [dispatch, settingData]);

  /**
   * Update token in the header on route change
   * and handle expired token
   */
  useEffect(() => {
    const jwtToken = getLocal('jwtToken');
    if (!jwtToken) return;
    if (isTokenExpired(jwtToken)) {
      clearLocal();
      clearSession();
      setAuthToken('');
      enqueueSnackbar(TOKEN_EXPIRED, {
        variant: 'warning',
      });
      navigate(`/${uiRoutes.auth.login}`);
    }

    setAuthToken(jwtToken);
  }, [navigate, enqueueSnackbar]);

  // Select active menu and update the store
  useEffect(() => {
    if (location.pathname) {
      const activeMenu =
        routeToMenuMap[location.pathname as keyof typeof routeToMenuMap];

      if (!activeMenu) {
        return;
      }

      dispatch(setActiveMenu(activeMenu));
    }
  }, [location.pathname, routeToMenuMap, dispatch]);

  // Set up tenantId for store of respective tables
  useEffect(() => {
    const tenantId = authTenantAssociationData?.tenantId;
    const clientReferenceId = authTenantAssociationData?.referenceId;

    if (userIsClient && clientReferenceId) {
      dispatch(
        changeFiltersForClientIndividualTable({ clientId: clientReferenceId })
      );
    }
    if (isClientAgent && clientReferenceId) {
      dispatch(
        changeFiltersForClientIndividualTable({ agentId: clientReferenceId })
      );
      dispatch(
        changeFiltersForClientGroupTable({ agentId: clientReferenceId })
      );
    }
    if (tenantId) {
      dispatch(changeFiltersForClientIndividualTable({ tenantId }));
      dispatch(changeFiltersForClientGroupTable({ tenantId }));
      dispatch(changeFiltersForTransactionTable({ tenantId }));
    }
  }, [
    isClientAgent,
    userIsClient,
    authTenantAssociationData?.referenceId,
    authTenantAssociationData?.tenantId,
    dispatch,
  ]);

  /**
   * For client login, fetch client and store it in the store.
   */
  useEffect(() => {
    if (userIsClient && authTenantAssociation?.referenceId && !authUserClient) {
      clientDetailQuery.refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsClient, authTenantAssociation?.referenceId, authUserClient]);

  useEffect(() => {
    if (clientDetailQuery.data) {
      dispatch(setUserClient(clientDetailQuery.data));
    }
  }, [clientDetailQuery.data, dispatch]);
  /*  ******************************************************** */

  return children;
};

export default AppWrapper;
