/* eslint-disable no-param-reassign */

import { CometChat } from '@cometchat-pro/chat';
import { CometChatCalls } from '@cometchat-pro/web-calls';
import Buffer from 'buffer';
import {
  RECORDING_INTERVAL,
  VIRTUAL_VISIT_MESSAGE,
} from 'constants/virtualVisit';
import { VirtualVisitSocketEvents } from 'enums/virtual-visit';
import { ICometChatMediaFile } from 'interfaces/chat';
import { IError } from 'interfaces/http';
import {
  IDownloadFile,
  IFiles,
  IVirtualVisitUserLeft,
} from 'interfaces/virtual-visit';
import {
  ICallParams,
  ICometChatMessage,
  ICometChatMessagePayload,
  ICometChatService,
  ICreateGroup,
  IRecorderInterface,
} from 'interfaces/virtual-visit/comet-chat';
import { ChatUtility } from 'utils/cometchat';
import { formatTimeStampToString } from 'utils/moment';
import socket, { decryptMessageSocket } from 'utils/virtual-visit/socket';

/**
 * 
This sets buffer to window object so the rules and implementation is needed.

*/

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Buffer = Buffer;
class CometChatService {
  conversationRequestBuilder: CometChat.ConversationsRequestBuilder;

  messageRequestBuilder: CometChat.MessagesRequestBuilder;

  callSettingsBuilder: CometChat.CallSettingsBuilder;

  userRequestBuilder: CometChat.UsersRequestBuilder;

  constructor() {
    this.conversationRequestBuilder =
      new CometChat.ConversationsRequestBuilder();
    this.callSettingsBuilder = new CometChat.CallSettingsBuilder();
    this.messageRequestBuilder = new CometChat.MessagesRequestBuilder();
    this.userRequestBuilder = new CometChat.UsersRequestBuilder();
  }

  static createUser = async (userID: string, name: string) => {
    try {
      const newUser = new CometChat.User(userID);
      newUser.setName(name);
      await CometChat.createUser(newUser, process.env.REACT_APP_AUTH_KEY ?? '');
    } catch (e) {
      throw Error();
    }
  };

  static login = async (userID: string) => {
    try {
      const user = await CometChat.login(
        userID,
        process.env.REACT_APP_AUTH_KEY
      );
      return user;
    } catch (e) {
      return null;
    }
  };

  static startDirectCall = (
    element: HTMLElement,
    id: string,
    isaudioOnly: boolean,
    onEndCall: () => void
  ) => {
    const sessionID = id;
    const audioOnly = isaudioOnly;
    const defaultLayout = true;

    const callSettings = new CometChat.CallSettingsBuilder()
      .enableDefaultLayout(defaultLayout)
      .setSessionID(sessionID)
      .setIsAudioOnlyCall(audioOnly)
      .build();

    if (typeof element !== null) {
      CometChat.startCall(
        callSettings,
        element,
        new CometChat.OngoingCallListener({
          onUserListUpdated: () => {},
          onCallEnded: () => {
            onEndCall();
          },
          onError: () => {},
          onUserLeft: () => {},
          onMediaDeviceListUpdated: () => {},
          onUserMuted: () => {},
          onScreenShareStarted: () => {},
          onScreenShareStopped: () => {},
          onCallSwitchedToVideo: () => {},
        })
      );
    }
  };

  static startDefaultCall = async ({
    isHost = false,
    uid = '',
    element,
    eventId = '',
    isaudioOnly = false,
    onCallEndButtonPressed = () => {},
    onCallEnded = () => {},
    onCallStarted = () => {},
    authToken = '',
    autoRecord = false,
    createdById = '',
  }: {
    isGuest?: boolean;
    isHost?: boolean;
    uid: string;
    element: HTMLElement;
    eventId: string;
    authToken: string;
    isaudioOnly?: boolean;
    stopRecording?: () => void;
    saveRecording?: () => void;
    onCallEndButtonPressed?: (uid: string, isHost: boolean) => void;
    onCallEnded?: (uid: string) => void;
    onUserLeft?: (user: Partial<IVirtualVisitUserLeft>) => void;
    onCallStarted?: () => void;
    autoRecord?: boolean;
    createdById?: string;
  }) => {
    try {
      if (!authToken) throw Error();
      const defaultLayout = true;
      const generateToken = await CometChatCalls.generateToken(
        eventId,
        authToken
      );
      const callSettings = new CometChatCalls.CallSettingsBuilder()
        .enableDefaultLayout(defaultLayout)
        .setIsAudioOnlyCall(isaudioOnly)
        .showPauseVideoButton(true)
        .startWithVideoMuted(true)
        .showRecordingButton(isHost)
        .startRecordingOnCallStart(autoRecord)
        .showScreenShareButton(false)
        .showVirtualBackgroundSetting(false)
        .setCallListener(
          new CometChatCalls.OngoingCallListener({
            onCallEndButtonPressed: () => {
              onCallEndButtonPressed(uid, isHost);
            },
            onUserLeft: (data) => {
              if (createdById?.toLowerCase() === data?.uid?.toLowerCase())
                onCallEnded(uid);
            },
            onError: () => {},
            onMediaDeviceListUpdated: () => {},
            onUserMuted: () => {},
            onScreenShareStarted: () => {},
            onScreenShareStopped: () => {},
            onCallSwitchedToVideo: () => {},
            onUserJoined: () => {
              onCallStarted();
              const customChatButton = document.getElementById(
                'custom-chat-button'
              ) as HTMLElement;
              customChatButton.style.display = 'block';
            },
          })
        )
        .build();
      await CometChatCalls.startSession(
        generateToken?.token,
        callSettings,
        element
      );
    } catch (error) {
      throw Error();
    }
  };

  getMessagesByUID = async (userID: string, otherUserID: string) => {
    const limit = 30;
    const messageRequest = this.messageRequestBuilder
      .setLimit(limit)
      .setUID(otherUserID)
      .build();

    const messageList = await messageRequest.fetchPrevious().catch(() => []);
    return ChatUtility.transformMessages(messageList, userID);
  };

  getUsers = async () => {
    const userRequest = this.userRequestBuilder.setLimit(30).build();
    return userRequest.fetchNext();
  };

  static createGroupAddMember = (
    guid: string,
    uid: ICreateGroup[],
    gName: string
  ) => {
    const GUID = guid;
    const UID = uid;
    const groupName = gName;
    const groupType: string = CometChat.GROUP_TYPE.PUBLIC;

    const group: CometChat.Group = new CometChat.Group(
      GUID,
      groupName,
      groupType
    );
    const members: Array<CometChat.GroupMember> = UID?.map(
      (item: { id: string; isHost?: boolean }) =>
        new CometChat.GroupMember(
          item?.id,
          item.isHost
            ? CometChat.GROUP_MEMBER_SCOPE.ADMIN
            : CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
        )
    );
    const banMembers: Array<string> = [];

    CometChat.createGroupWithMembers(group, members, banMembers);
  };

  static createGroup = async (guid: string) => {
    const GUID = guid;
    const groupName = 'VIRTUAL VISIT';
    const groupType: string = CometChat.GROUP_TYPE.PUBLIC;
    const password = '';

    try {
      const groupData: CometChat.Group = new CometChat.Group(
        GUID,
        groupName,
        groupType,
        password
      );

      const group = CometChat.createGroup(groupData);
      return await group;
    } catch (e) {
      throw Error();
    }
  };

  static getGroupById = (guid: string) => {
    const GUID = guid;
    const groupDetail = CometChat.getGroup(GUID);
    return groupDetail;
  };

  static sendTextMessage = async ({
    guId,
    receiverType = CometChat.RECEIVER_TYPE.GROUP,
    receiverID,
    message,
  }: {
    guId: string;
    receiverType?: string;
    receiverID: string;
    message: string;
  }): Promise<ICometChatMessage> => {
    const textMessage = new CometChat.TextMessage(
      receiverID,
      message,
      receiverType
    );
    try {
      const sentMessage = await CometChat.sendMessage(textMessage);
      return ChatUtility.transformSingleMessage(sentMessage, guId);
    } catch (err) {
      throw Error('Failed to send message');
    }
  };

  static addMember = (guid: string, uid: string) => {
    const GUID = guid || '';
    const UID = uid || '';
    const membersList: CometChat.GroupMember[] = [
      new CometChat.GroupMember(UID, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT),
    ];

    CometChat.addMembersToGroup(GUID, membersList, []).then();
  };

  static listenForMessage = async ({
    onTextMessageReceived,
    onMediaReceived,
    listenerID,
  }: {
    onTextMessageReceived: (textMessage: CometChat.TextMessage) => void;
    onMediaReceived: (mediaMessage: ICometChatMediaFile) => void;
    listenerID: string;
  }) => {
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived,
        onMediaMessageReceived: onMediaReceived,
        onCustomMessageReceived: () => {},
      })
    );
  };

  static sendFileMessage = (context: IFiles, guid: string) =>
    new Promise((resolve, reject) => {
      const messageType: string = CometChat.MESSAGE_TYPE.FILE;
      const fileData = context;
      const receiverID = guid;
      const receiverType: string = CometChat.RECEIVER_TYPE.GROUP;

      const newBlob = new Blob(
        [JSON.stringify({ name: fileData.name, data: fileData.base64 })],
        {
          type: 'text/plain',
        }
      );
      const mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(
        receiverID,
        newBlob,
        messageType,
        receiverType
      );

      CometChat.sendMediaMessage(mediaMessage)
        .then((response: any) => {
          resolve({
            ...response,
            fileName: fileData.name,
            title: fileData.base64,
          });
        })
        .catch((error: any) => {
          reject(error);
        });
    });

  static sendFileMessageUser = (context: IFiles, guid: string) =>
    new Promise((resolve, reject) => {
      const messageType: string = CometChat.MESSAGE_TYPE.FILE;
      const fileData = context;
      const receiverID = guid;
      const receiverType: string = CometChat.RECEIVER_TYPE.USER;

      const newBlob = new Blob(
        [JSON.stringify({ name: fileData.name, data: fileData.base64 })],
        {
          type: 'text/plain',
        }
      );
      const mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(
        receiverID,
        newBlob,
        messageType,
        receiverType
      );

      CometChat.sendMediaMessage(mediaMessage)
        .then((response: any) => {
          resolve({
            ...response,
            fileName: fileData.name,
            title: fileData.base64,
          });
        })
        .catch((error: any) => {
          reject(error);
        });
    });

  static generateVirgilToken = () => {
    CometChat.callExtension('e2ee', 'GET', '/v1/virgil-jwt', '')
      .then(() => {})
      .catch(() => {});
  };

  static blobToBuffer = (blob: Blob): Promise<Buffer> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(Buffer?.Buffer.from(reader.result as string));
      };

      reader.readAsArrayBuffer(blob);
      reader.onerror = reject;
    });

  static startScreenRecording = (
    mediaRecorderRef: IRecorderInterface,
    recordedChunksRef: IRecorderInterface,
    callBack: (stoppen?: boolean) => void
  ) => {
    const startRecordingAsync = async () => {
      try {
        let payload = {};
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // Merge video and audio streams into a single stream
        const mergedStream = new MediaStream([
          ...screenStream.getTracks(),
          ...audioStream.getTracks(),
        ]);

        mergedStream.getVideoTracks()[0].onended = () => {
          socket?.instance?.emit(VirtualVisitSocketEvents.STOP_RECORDING, {
            ...payload,
            isLast: true,
          });
          callBack(true);
        };

        const recorder = new MediaRecorder(mergedStream);
        mediaRecorderRef.current = recorder;
        recorder.addEventListener('dataavailable', async (event) => {
          payload = {
            partNumber: recordedChunksRef.current,
            chunk: await this.blobToBuffer(event.data),
            isLast: false,
          };

          socket?.instance?.emit(
            VirtualVisitSocketEvents.RECORDING_CHUNK,
            payload
          );
          // eslint-disable-next-line no-plusplus
          recordedChunksRef.current++;
        });
        recorder.start(RECORDING_INTERVAL);
        socket?.instance?.emit(VirtualVisitSocketEvents.START_RECORDING, {
          contentType: 'video/x-matroska;codecs=avc1',
        });
        callBack();
      } catch (error) {
        throw Error();
      }
    };
    startRecordingAsync();
    return null;
  };

  static fetchFileMessage = (
    eventId: string
  ): Promise<ICometChatMessagePayload[]> =>
    new Promise((resolve, reject) => {
      CometChat.getLoggedinUser().then(() => {
        const messagesRequest = new CometChat.MessagesRequestBuilder()
          .setLimit(50)
          .setGUID(eventId)
          .hideReplies(true)
          .build();
        messagesRequest
          .fetchPrevious()
          .then((response: CometChat.BaseMessage[] | any) => {
            resolve(response);
          })
          .catch((error: IError) => {
            reject(error);
          });
      });
    });

  static fetchMessageByUser = (
    uid: string
  ): Promise<ICometChatMessagePayload[]> =>
    new Promise((resolve, reject) => {
      const messagesRequest = new CometChat.MessagesRequestBuilder()
        .setLimit(50)
        .setUID(uid)
        .hideReplies(true)
        .build();
      messagesRequest
        .fetchPrevious()
        .then((response: CometChat.BaseMessage[] | any) => {
          resolve(response);
        })
        .catch((error: IError) => {
          reject(error);
        });
    });

  static fetchConversationList = (): any =>
    new Promise((resolve, reject) => {
      const conversationsRequest = new CometChat.ConversationsRequestBuilder()
        .setLimit(50)
        .build();
      conversationsRequest.fetchNext().then(
        (conversationList) => {
          resolve(conversationList);
        },
        (error) => {
          reject(error);
        }
      );
    });

  static createJoinGroup = async ({
    isGuest = false,
    isHost,
    uid,
    element,
    state,
    eventId,
    autoRecord = false,
    stopRecording,
    saveRecording,
    onCallEnded,
    onCallEndButtonPressed,
    onUserLeft,
    onCallStarted,
    createdById = '',
  }: ICallParams) => {
    try {
      const token = await CometChat.getLoggedinUser();
      const authToken = token?.getAuthToken();
      if (authToken) {
        const group = new CometChat.Group(
          eventId,
          state?.item?.title,
          CometChat.GROUP_TYPE.PUBLIC
        );
        CometChat.createGroup(group)
          .then(() => {
            CometChatService.startDefaultCall({
              isGuest,
              isHost,
              uid,
              element,
              eventId,
              authToken,
              autoRecord,
              isaudioOnly: state?.audioOnly,
              stopRecording,
              saveRecording,
              onCallEnded,
              onCallEndButtonPressed,
              onUserLeft,
              onCallStarted,
              createdById,
            });
          })
          .catch(() => {
            const type: any = CometChat.GROUP_TYPE.PUBLIC;
            CometChat.joinGroup(eventId, type, '').finally(() =>
              CometChatService.startDefaultCall({
                isGuest,
                isHost,
                uid,
                element,
                eventId,
                authToken,
                isaudioOnly: state?.audioOnly,
                stopRecording,
                saveRecording,
                onCallEnded,
                onCallEndButtonPressed,
                autoRecord,
                onUserLeft,
                onCallStarted,
                createdById,
              })
            );
          });
      } else {
        throw Error();
      }
    } catch (error) {
      throw Error();
    }
  };

  static cometchatTenantService = async ({ callParams }: ICometChatService) => {
    await CometChatService.createJoinGroup(callParams);
  };

  static cometchatGuestService = async ({ callParams }: ICometChatService) => {
    try {
      const type: any = CometChat.GROUP_TYPE.PUBLIC;
      const user = await CometChatService.login(callParams.uid ?? '');
      if (!user) {
        await CometChatService.createUser(
          callParams?.uid ?? '',
          callParams?.state?.name || ''
        );

        const loggedUser = await CometChatService.login(callParams.uid ?? '');
        const loginToken = await CometChat.getLoggedinUser();

        const token = loginToken?.getAuthToken() || loggedUser?.getAuthToken();
        if (token) {
          CometChat.joinGroup(callParams?.eventId, type, '').finally(() => {
            CometChatService.startDefaultCall({
              ...callParams,
              authToken: token ?? '',
              isaudioOnly: callParams?.state?.audioOnly,
            });
          });
        } else {
          throw Error();
        }
      } else {
        const token = await CometChat.getLoggedinUser();
        CometChat.joinGroup(callParams?.eventId, type, '').finally(() => {
          CometChatService.startDefaultCall({
            ...callParams,
            authToken: token?.getAuthToken() ?? '',
            isaudioOnly: callParams?.state?.audioOnly,
          }).catch(() => {
            throw Error();
          });
        });
      }
    } catch (error: any) {
      if (
        error.code === 'ERR_ALREADY_JOINED' ||
        error.code === 'ERR_UID_ALREADY_EXISTS'
      )
        throw new Error(VIRTUAL_VISIT_MESSAGE.ALREADY_JOINED);

      throw Error(VIRTUAL_VISIT_MESSAGE.ERROR);
    }
  };

  static decryptMessage = async ({
    fileName,
    text,
    name,
    time,
    uid,
    senderId,
    isText = false,
  }: {
    uid: string;
    text: string;
    name: string;
    time: number;
    senderId: string;
    isText?: boolean;
    fileName?: string;
  }) => {
    const decryptedData = await new Promise<string>((resolve) => {
      decryptMessageSocket(text).then((data: IDownloadFile | any) => {
        resolve(data.message);
      });
    });

    return {
      payload: {
        fileName,
        sender: name,
        text: decryptedData,
        isTextMessage: isText,
        time: formatTimeStampToString(time),
        isSentByMe: uid?.toLocaleLowerCase() === senderId?.toLowerCase(),
      },
    };
  };

  static extractTextFromMessage = (
    textMessage: CometChat.TextMessage
  ): any | null => {
    if (textMessage instanceof CometChat.TextMessage) {
      const messageDatas = textMessage.getData();
      if (messageDatas) {
        return messageDatas.text;
      }
    }
    return null;
  };

  static extractSenderFromMessage = (
    textMessage: CometChat.TextMessage
  ): any | null => {
    if (textMessage instanceof CometChat.TextMessage) {
      const messageDatas: any = textMessage.getSender();
      if (messageDatas) {
        return messageDatas?.name;
      }
    }
    return null;
  };
}

export { CometChatService };
