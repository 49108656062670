import { RefObject, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';
import { CountryCodes } from 'enums/country';
import { IAddress } from 'interfaces/common';
import { googleAutocompleteObject } from 'utils/google-autocomplete-country';

type IProps = {
  label?: string;
  onPopulateFields: (addr: IAddress) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  countryCode?: string;
} & TextFieldProps;

/** An object for creating google places autocomplete instance */
let autoCompleteObj: Record<string, any>;

async function handlePlaceSelect(
  callbackFunc: (addressObj: any) => void,
  autoComplete: any
) {
  const addressObject = autoComplete.getPlace();
  callbackFunc(addressObject);
}

function handleScriptLoad(
  autoCompleteRef: RefObject<any>,
  callbackFunc: (addressObj: any) => void,
  countryCode: string
) {
  const searchOptions = {
    types: ['geocode'],
    componentRestrictions: { country: countryCode },
    fields: ['address_components', 'formatted_address', 'geometry.location'],
  };
  /** Original `autoCompleteObj` object should not be mutated.
   *  If mutated, the google autocomplete will only be initialized for the latest object.
   *  This can cause issues like google autocomplete not working for multiple addresses on the same page
   */
  let autoCompleteInstance = autoCompleteObj;
  autoCompleteInstance = new (window as any).google.maps.places.Autocomplete(
    autoCompleteRef.current,
    searchOptions
  );
  autoCompleteInstance.setFields(['address_components', 'formatted_address']);
  autoCompleteInstance.addListener('place_changed', () =>
    handlePlaceSelect(callbackFunc, autoCompleteInstance)
  );
}

const FormGoogleAutocompleteInput = (props: IProps) => {
  const {
    onPopulateFields,
    label,
    isRequired,
    isDisabled = false,
    countryCode,
    ...others
  } = props;

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const autoCompleteRef = useRef(null);
  const onPopulateFieldsRef = useRef(onPopulateFields);

  useEffect(() => {
    const populateFields = (addressObj: any) => {
      const address = addressObj?.address_components;
      if (!address) return;
      const rawAddress = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        lat: '',
        lng: '',
      };

      address.forEach((x: any) => {
        googleAutocompleteObject(x, rawAddress, countryCode || 'US');
      });
      rawAddress.lat = addressObj.geometry.location.lat();
      rawAddress.lng = addressObj.geometry.location.lng();
      onPopulateFieldsRef.current(rawAddress);
    };

    handleScriptLoad(
      autoCompleteRef,
      populateFields,

      countryCode || 'us'
    );
  }, [setValue, countryCode]);

  return (
    <Controller
      control={control}
      key={countryCode}
      name="addressLine1"
      render={({ field }) => (
        <TextField
          id="address-line-1"
          inputRef={autoCompleteRef}
          {...field}
          {...others}
          disabled={isDisabled}
          error={!!errors.addressLine1}
          fullWidth
          helperText={
            errors.addressLine1?.message
              ? (errors.addressLine1?.message as React.ReactNode)
              : null
          }
          label={isRequired ? `${label} *` : label}
          size="small"
          variant="standard"
        />
      )}
    />
  );
};

FormGoogleAutocompleteInput.defaultProps = {
  isRequired: false,
  isDisabled: false,
  label: 'Address Line 1',
  countryCode: CountryCodes.USA,
};

export default FormGoogleAutocompleteInput;
