import {
  faCakeCandles,
  faCalendarCheck,
  faDroplet,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useTheme } from '@mui/material';
import { IAdaptedClient } from 'interfaces/client-management';

interface IProps {
  data: IAdaptedClient | undefined;
}

const BloodGroupDOBDateDisplay = ({ data }: IProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={1} p={2}>
      {data?.dob && (
        <Box alignItems="center" display="flex">
          <FontAwesomeIcon icon={faCakeCandles} size="sm" />
          <Typography
            color={theme.palette.common.black}
            gutterBottom={false}
            pl={1}
            variant="body2"
          >
            Date of Birth:
          </Typography>
          <Typography
            color={theme.palette.common.black}
            fontWeight="medium"
            gutterBottom={false}
            pl={1}
            variant="body2"
          >
            {data.dob}
          </Typography>
        </Box>
      )}

      {data?.bloodGroup && (
        <Box alignItems="center" display="flex">
          <FontAwesomeIcon icon={faDroplet} size="sm" />
          <Typography
            color={theme.palette.common.black}
            gutterBottom={false}
            pl={1.2}
            variant="body2"
          >
            Blood Group:
          </Typography>
          <Typography
            color={theme.palette.common.black}
            fontWeight="medium"
            gutterBottom={false}
            pl={1}
            variant="body2"
          >
            {data?.bloodGroup}
          </Typography>
        </Box>
      )}
      {data?.membership?.startDate && (
        <Box alignItems="center" display="flex">
          <FontAwesomeIcon icon={faCalendarCheck} size="sm" />
          <Typography
            color={theme.palette.common.black}
            gutterBottom={false}
            pl={1}
            variant="body2"
          >
            Benefit Date:
          </Typography>
          <Typography
            color={theme.palette.common.black}
            fontWeight="medium"
            gutterBottom={false}
            pl={1}
            variant="body2"
          >
            {data?.membership?.startDate}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BloodGroupDOBDateDisplay;
