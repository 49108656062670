import { Ref, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { IconButton } from '@mui/material';
import { AppWrapper } from 'common';
import AxiosInterceptor from 'common/axios-interceptor';
import CacheBuster from 'common/CacheBuster';
import DetectOnlineOffline from 'common/DetectOnlineOffline';
import MenuBuilder from 'common/MenuBuilder';
import MessageModalWrapper from 'common/MessageModalWrapper';
import { StripeWrapper } from 'common/StripeWrapper';
import commonConstants from 'constants/common';
import MaintenancePage from 'features/misc/maintenance';
import { SnackbarProvider } from 'notistack';
import Router from 'routes';
import { store } from 'stores';
import ConfirmationModalProvider from 'stores/ConfirmationModal';
import { checkIsMaintenance } from 'utils/misc';
import { getLocal } from 'utils/storage';

import ThemeConfig from './theme';
import GlobalStyles from './theme/GlobalStyles';

const { TITLE, DESCRIPTION, FAVICON } = commonConstants.DEFAULT_SITE_METADATA;

const isProduction = process.env.NODE_ENV === 'production';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      // staleTime: 60 * 1000, // 60 secs
      useErrorBoundary: (error: any) => error?.status <= 500,
      retry: false,
    },
  },
});

const App = (): JSX.Element => {
  const ref: Ref<SnackbarProvider> = useRef(null);

  let siteTitle = getLocal('siteTitle');
  let siteFavicon = getLocal('siteFavicon');
  let siteDescription = getLocal('siteDescription');

  if (!siteTitle) siteTitle = TITLE;
  if (!siteFavicon) siteFavicon = FAVICON;
  if (!siteDescription) siteDescription = DESCRIPTION;
  const isMaintenance = checkIsMaintenance();
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteTitle}</title>
        <link href={siteFavicon} rel="icon" />
        <meta content={siteDescription} name="description" />
      </Helmet>
      <CacheBuster />
      <Provider store={store}>
        <StripeWrapper>
          <ThemeConfig>
            <GlobalStyles />
            <SnackbarProvider
              // eslint-disable-next-line react/no-unstable-nested-components
              action={(snackbarId) => (
                <IconButton
                  className="snackbar-close-button"
                  color="inherit"
                  onClick={() => ref?.current?.closeSnackbar(snackbarId)}
                  size="small"
                >
                  <FontAwesomeIcon icon={faTimes} size="xs" />
                </IconButton>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              maxSnack={3}
              ref={ref}
            >
              {isMaintenance ? (
                <MaintenancePage />
              ) : (
                <QueryClientProvider client={queryClient}>
                  <BrowserRouter>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DetectOnlineOffline />
                      <MenuBuilder />
                      <ConfirmationModalProvider>
                        <AxiosInterceptor>
                          <AppWrapper>
                            <MessageModalWrapper>
                              <Router />
                            </MessageModalWrapper>
                          </AppWrapper>
                          {isProduction ? null : (
                            <ReactQueryDevtools initialIsOpen={false} />
                          )}
                        </AxiosInterceptor>
                      </ConfirmationModalProvider>
                    </LocalizationProvider>
                  </BrowserRouter>
                </QueryClientProvider>
              )}
            </SnackbarProvider>
          </ThemeConfig>
        </StripeWrapper>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
