/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common';
import { UpcomingSortBy } from 'enums/billings/upcoming';
import { SortOrder } from 'enums/common';
import { UpcomingSortByType } from 'interfaces/billings/upcoming';
import { IFilter, SortOrderType } from 'interfaces/common';
import { RootState } from 'stores';

const { DEFAULT_TABLE_FILTER } = commonConstants;

const sortDefaultValues = {
  sortBy: UpcomingSortBy.BILLING_DATE,
  sortOrder: SortOrder.DESC,
};

interface IUpcomingSort {
  sortBy: UpcomingSortByType;
  sortOrder: SortOrderType;
}

interface UpcomingState {
  filters: IFilter;
  sort: IUpcomingSort;
}

const initialState: UpcomingState = {
  filters: { ...DEFAULT_TABLE_FILTER },
  sort: { ...sortDefaultValues },
};

export const slice = createSlice({
  name: 'upcoming',
  initialState,
  reducers: {
    // Changing the state directly (with mutation) in reducer
    // Redux Toolkit uses Immer under the hood (takes care of immutablility)
    changeFilters: (state, action: PayloadAction<Partial<IFilter>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFilters: (state) => {
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },

    changeSortByAndOrder: (state, action: PayloadAction<IUpcomingSort>) => {
      state.sort = {
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    },

    resetAllFilters: (state) => {
      state.sort = { ...sortDefaultValues };
      state.filters = { ...DEFAULT_TABLE_FILTER };
    },
  },
});

// Actions
export const {
  changeFilters,
  resetFilters,
  changeSortByAndOrder,
  resetAllFilters,
} = slice.actions;

// Selectors
export const selectUpcomingFilter = (state: RootState) =>
  state.upcoming.filters;
export const selectUpcomingSort = (state: RootState) => state.upcoming.sort;
export const selectUpcoming = (state: RootState) => state.upcoming;

// Reducer
export default slice.reducer;
