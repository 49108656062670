import apiRoutes from 'constants/apiRoutes';
import {
  IProfileAdminUserFilter,
  ITenantPermissionFilter,
  IUserTenantsFilter,
} from 'interfaces/auth';
import {
  IFilter,
  IPermission,
  IProfileAdminFilter,
  ISavePermissionSchema,
} from 'interfaces/common';
import { IListResponse, IResponse } from 'interfaces/http';
import {
  ITenant,
  ITenantTableRow,
  IUserRoles,
} from 'interfaces/tenant-management/tenant';
import {
  IAddUserSchema,
  IAssociation,
  IProfileAdminSchema,
  IProfileAdminUsersRow,
  ISendInviteSchema,
  IUser,
  IUserTableRow,
} from 'interfaces/tenant-management/user';
import { baseRequest } from 'utils/axios';

const { tenantManagement } = apiRoutes;

export const assignTenant = async (
  userId: string,
  data: { tenantId: string }
): Promise<IResponse<IAssociation>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.user.assignTenant.replace(':id', userId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addUser = async (
  data: IAddUserSchema
): Promise<IResponse<IUserTableRow>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: tenantManagement.user.add,
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const addProfileAdminUser = async (
  data: IProfileAdminSchema
): Promise<IResponse<IProfileAdminUsersRow>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: tenantManagement.user.add,
    data,
  });
  if (error) {
    throw new Error(error.message);
  }
  return response?.data;
};

export const editUser = async (
  userId: string,
  data: IAddUserSchema
): Promise<IResponse<IUserTableRow>> => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: tenantManagement.user.edit.replace(':id', userId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const editProfileAdminUser = async (
  userId: string,
  data: IProfileAdminSchema
) => {
  const { response, error } = await baseRequest({
    method: 'PUT',
    url: tenantManagement.user.edit.replace(':id', userId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const deleteProfileAdminUser = async (
  userId: string,
  tenantId: string,
  params: IProfileAdminUserFilter
) => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: tenantManagement.user.deleteGroupAdmin
      .replace(':userId', userId)
      .replace(':tenantId', tenantId),
    params,
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getUserRoles = async (
  userId: string
): Promise<IResponse<IUserRoles>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.user.getUserRoles.replace(':id', userId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getAssignedTenants = async (
  userId: string,
  params?: IUserTenantsFilter
): Promise<IResponse<ITenant[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.user.getTenants.replace(':id', userId),
    params,
  });
  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getUsers = async (
  params: IFilter
): Promise<IResponse<IListResponse<IUserTableRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.user.getAll,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getProfileUsers = async (
  params: IProfileAdminFilter
): Promise<IResponse<IListResponse<IProfileAdminUsersRow>>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.user.getAll,
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getUserDetail = async (
  userId: string,
  params?: {
    associatedEntity: boolean;
  }
): Promise<IResponse<IUser>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.user.getOne.replace(':id', userId),
    params: params ?? {},
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getUserTenants = async (
  userId: string
): Promise<IResponse<ITenantTableRow[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.user.getAllTenants.replace(':id', userId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const removeAssignTenant = async (
  tenantId: string,
  userId: string
): Promise<IResponse<string>> => {
  const { response, error } = await baseRequest({
    method: 'DELETE',
    url: tenantManagement.user.removeAssignTenant
      .replace(':tenantId', tenantId)
      .replace(':userId', userId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const getUserPermissions = async (
  userId: string,
  params: ITenantPermissionFilter
): Promise<IResponse<IPermission[]>> => {
  const { response, error } = await baseRequest({
    method: 'GET',
    url: tenantManagement.user.getPermissions.replace(':id', userId),
    params,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const saveUserPermissions = async (
  userId: string,
  data: ISavePermissionSchema[]
): Promise<IResponse<any>> => {
  const { response, error } = await baseRequest({
    method: 'PATCH',
    url: tenantManagement.user.getPermissions.replace(':id', userId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const sendInvite = async (
  userId: string,
  data: ISendInviteSchema
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: tenantManagement.user.sentInvite.replace(':id', userId),
    data,
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};

export const sendProfileAdminInvite = async (
  userId: string
): Promise<IResponse<null>> => {
  const { response, error } = await baseRequest({
    method: 'POST',
    url: tenantManagement.user.sentInvite.replace(':id', userId),
  });

  if (error) {
    throw new Error(error.message);
  }

  return response?.data;
};
