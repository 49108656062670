export enum ReportFilterBy {
  QUATERLY = 'This Quarter',
}

export enum DashboardChart {
  SALES_REVENUE = 'Sales Revenue',
  REVENUE_SOURCE = 'Revenue Source',
  MEMBERSHIP = 'Membership',
}

export enum RevenueFormat {
  NUMBER = 'number',
  CURRENCY = 'currency',
}
