/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router-dom';

import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { defaultMaxFileSize } from 'constants/file';
import ModalKey from 'enums/ModalKey';
import { IFileSchema } from 'interfaces/documents';
import { getFullName } from 'utils/common';
import { toBase64 } from 'utils/file';
import { getUserDetailFromToken } from 'utils/jwt';
import { v4 as uuidv4 } from 'uuid';

import DropZoneFileList from './DropZoneFileList';

const FileDropzone = ({
  isFileSizeExceeds,
  onChange,
  maxSize,
  isPublic = false,
}: {
  isFileSizeExceeds?: boolean;
  onChange: (e: IFileSchema[]) => void;
  maxSize?: number;
  isPublic?: boolean;
}) => {
  const location = useLocation();
  const isFileUploadFromPublic = location.search.includes(
    ModalKey.UPLOAD_EMAIL_NOTES_FILE
  );
  const currentUser =
    isFileUploadFromPublic || isPublic ? null : getUserDetailFromToken();
  const [selectedFiles, setSelectedFiles] = useState<IFileSchema[]>([]);
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (f: any) => {
      const newFiles: IFileSchema[] = [];
      for (let index = 0; index < f.length; index += 1) {
        let error = false;
        if (maxSize && f[index].size > maxSize) {
          error = true;
        }
        const uploadFile = {
          id: uuidv4(),
          name: f[index].name,
          contentType: f[index].type,
          createdBy: getFullName(currentUser?.firstName, currentUser?.lastName),
          // eslint-disable-next-line no-await-in-loop
          base64: await toBase64(f[index]),
          size: f[index].size,
          error,
        };
        newFiles.push(uploadFile);
      }

      setSelectedFiles((prev) => [...prev, ...newFiles]);
      // Since the state doesn't change in this section i.e [...prev, ...newFiles]
      onChange([...selectedFiles, ...newFiles]);
    },
  });

  const handleFileDelete = (id: string) => {
    const files = selectedFiles.filter((e: IFileSchema) => e.id !== id);
    setSelectedFiles(files);
    onChange(files);
  };
  const handleFileEdit = (file: IFileSchema) => {
    const files = selectedFiles.map((e: IFileSchema) => {
      if (e.id === file.id) return file;
      return e;
    });
    setSelectedFiles(files);
    onChange(files);
  };

  const files = selectedFiles.map((file: IFileSchema) => (
    <DropZoneFileList
      file={file}
      handleFileDelete={handleFileDelete}
      handleFileEdit={handleFileEdit}
      key={file.id}
    />
  ));

  return (
    <Box>
      <Box {...getRootProps({ className: 'fileDrop' })}>
        <input {...getInputProps()} />

        <Box>
          <FontAwesomeIcon
            icon={faCloudUpload}
            size="3x"
            style={{ color: theme.palette.primary.main }}
          />
          {!isSmallerThanMd && (
            <Typography>Drag and drop files here or click to browse</Typography>
          )}
          {isSmallerThanMd && <Typography>Click to browse</Typography>}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography gutterBottom={false} variant="caption">
          {/* * Please upload .pdf, .jpg,. jpeg, .doc, .docx, .txt only */}
        </Typography>
        <Typography
          gutterBottom={false}
          sx={{
            color: isFileSizeExceeds ? theme.palette.error.main : 'inherit',
          }}
          variant="caption"
        >
          Max size {maxSize && Math.floor(maxSize / (1000 * 1000))} MB
          {isFileSizeExceeds ? ' *' : ''}
        </Typography>
      </Box>
      <Box component="ul" sx={{ listStyle: 'none', marginTop: 2 }}>
        {files}
      </Box>
    </Box>
  );
};

FileDropzone.defaultProps = {
  isFileSizeExceeds: false,
  maxSize: defaultMaxFileSize,
  isPublic: false,
};
export default FileDropzone;
