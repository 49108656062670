import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAppSelector } from 'stores/hooks';
import { selectStripe } from 'stores/Payment';

interface Props {
  children: JSX.Element;
}

export const StripeWrapper = ({ children }: Props) => {
  const stripeDetail = useAppSelector(selectStripe);

  if (stripeDetail?.publishableKey) {
    const stripePromise = loadStripe(stripeDetail.publishableKey);

    return <Elements stripe={stripePromise}>{children}</Elements>;
  }

  return children;
};
