import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  debounce,
  FormControl,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import { ITenantNetworkTableRow } from 'interfaces/tenant-networks';
import {
  useNetworkQuery,
  useTenantNetworkQuery,
} from 'services/tenant-networks';
import { selectAuthTenantAssociation } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const { DEFAULT_TABLE_FILTER, PAGINATION } = commonConstants;

interface IProps {
  onSelectHandler: (networkId: string | null) => void;
  variant?: 'standard' | 'outlined';
  inputProps?: TextFieldProps;
  disabled?: boolean;
  isDefaultSet?: boolean;
  allTenantsOptionEnabled?: boolean;
  disableClearable?: boolean;
  defaultValue?: string; // Re-consider if sending defaultValue to prefill the input is right approach or not?
  selectedNetworkId?: string;
  placeholder?: string;
  handleClearAction?: VoidFunction;
}

const NetworkAutocompleteDetails = ({
  onSelectHandler,
  inputProps,
  disabled,
  variant,
  disableClearable,
  isDefaultSet,
  defaultValue,
  selectedNetworkId,
  allTenantsOptionEnabled,
  placeholder,
  handleClearAction,
}: IProps) => {
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const tenantId = authTenantAssociationData?.tenantId;

  const [value, setValue] = useState<ITenantNetworkTableRow | null>(null);
  const [filters, setFilters] = useState({
    ...DEFAULT_TABLE_FILTER,
    limit: PAGINATION.MAXIMUM_LIMIT,
    offset: PAGINATION.MINIMUM_OFFSET,
    tenantId: tenantId!,
  });
  const [isValueChanged, setIsValueChanged] = useState<boolean>(false);

  const tenantNetworkQuery = useTenantNetworkQuery(filters, {
    enabled: !!tenantId,
  });

  const networkQuery = useNetworkQuery(
    { networkId: defaultValue!, tenantId: tenantId! },
    {
      enabled: !!defaultValue && !!tenantId,
    }
  );
  const defaultNetwork = networkQuery?.data?.rows[0];
  const handleInputChange = async (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    if (newInputValue.length > 2 || !newInputValue.length)
      setFilters((prevState) => ({ ...prevState, keyword: newInputValue }));
  };
  const debouncedInputChange = useMemo(
    () => debounce(handleInputChange, 300),
    []
  );

  const options = useMemo(() => {
    const networkData = tenantNetworkQuery?.data?.rows || [];
    const isAllNetworkExists = networkData.some((x) => x.id === 'all');
    if (allTenantsOptionEnabled && !isAllNetworkExists) {
      const allNetworkCodes = networkData.map((x) => x.code).join(',');

      networkData?.unshift({
        code: allNetworkCodes || '',
        id: 'all',
        name: 'All Networks',
        networkId: 'all',
      } as any);
    }
    return networkData;
  }, [allTenantsOptionEnabled, tenantNetworkQuery?.data?.rows]);

  const onChange = useCallback(
    (network: ITenantNetworkTableRow | null) =>
      onSelectHandler(network?.networkId || ''),
    [onSelectHandler]
  );

  useEffect(() => {
    // if (
    //   options?.length &&
    //   isDefaultSet &&
    //   !selectedNetworkId &&
    //   !filters.keyword &&
    //   !isValueChanged
    // ) {
    //   const network = options.find((item) => !!item.isPrimary);
    //   if (network) {
    //     setValue(network);
    //     onSelectHandler(network.networkId);
    //   }
    // }
    if (
      options?.length &&
      selectedNetworkId &&
      !filters.keyword &&
      !isValueChanged
    ) {
      const network = options.find(
        (item) => item.networkId === selectedNetworkId
      );
      if (network) {
        setValue(network);
        onSelectHandler(network.networkId);
      }
    }
    // TODO: Continue re-render of parent component issue appears if `onSelectHandler` is included in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.keyword, options, setValue, isDefaultSet, selectedNetworkId]);

  // Populates the value (edit mode)
  useEffect(() => {
    if (defaultValue && defaultNetwork) {
      setValue(defaultNetwork);
    }
  }, [defaultValue, setValue, defaultNetwork]);

  return (
    <div>
      <Autocomplete
        autoComplete
        disableClearable={disableClearable}
        disabled={disabled}
        filterOptions={(x) => x}
        filterSelectedOptions
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : `${option.name}`
        }
        id="tenant-network-autocomplete"
        includeInputInList
        isOptionEqualToValue={(option, newValue) =>
          option.networkId === newValue.networkId
        }
        loading={tenantNetworkQuery.isLoading}
        onChange={(
          event: SyntheticEvent<Element, Event>,
          newValue: ITenantNetworkTableRow | null,
          reason: AutocompleteChangeReason
        ) => {
          setIsValueChanged(true);
          if (reason === 'clear') {
            if (handleClearAction) handleClearAction();
            onChange(null);
            setValue(null);
          } else {
            onChange(newValue);
            setValue(newValue);
          }
        }}
        onInputChange={(e, v) => {
          setIsValueChanged(true);
          debouncedInputChange(e, v);
        }}
        options={options}
        renderInput={(params) =>
          variant === 'outlined' ? (
            <FormControl fullWidth variant="standard">
              <TextField
                {...params}
                className="filled-variant"
                fullWidth
                {...inputProps}
                InputProps={{
                  ...params.InputProps,
                }}
                placeholder={placeholder}
                size={inputProps?.size ?? 'small'}
              />
            </FormControl>
          ) : (
            <TextField
              {...params}
              {...inputProps}
              className="filled-variant filled-variant--white"
              fullWidth
              placeholder={placeholder}
              size={inputProps?.size ?? 'small'}
              sx={{ width: '280px' }}
            />
          )
        }
        renderOption={(props, option) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <Box
            {...props}
            component="li"
            key={option.networkId}
            sx={{
              display: 'block !important',
              '&:hover': {
                color: (theme) => theme.palette.secondary.main,
              },
            }}
          >
            <Typography color="inherit" gutterBottom={false} variant="body2">
              {option.name}
              <Typography
                component="p"
                gutterBottom={false}
                sx={{
                  color: (theme) => theme.palette.gray.main,
                }}
                variant="caption"
              >
                {option.code}
                {option.sourceId && ` - (${option.sourceId})`}
              </Typography>
            </Typography>
          </Box>
        )}
        size={inputProps?.size ?? 'small'}
        value={value as ITenantNetworkTableRow}
      />
    </div>
  );
};

NetworkAutocompleteDetails.defaultProps = {
  variant: 'outlined',
  inputProps: undefined,
  isDefaultSet: false,
  disabled: false,
  defaultValue: undefined,
  selectedNetworkId: undefined,
  allTenantsOptionEnabled: undefined,
  handleClearAction: undefined,
  placeholder: 'Search Network',
  disableClearable: true,
};

export default NetworkAutocompleteDetails;
