import { faClose, faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { useFileDownloadMutation } from 'services/files';
import { truncateText } from 'utils/common';

interface IAttachmentProps {
  id?: string;
  name: string;
  path?: string;
  fullPath?: string;
  onRemoveClick?: (uuid: string) => void;
}

const Attachment = ({
  id,
  name,
  path,
  fullPath,
  onRemoveClick,
}: IAttachmentProps) => {
  const theme = useTheme();

  const fileDownloadMutation = useFileDownloadMutation();

  const viewAttachment = (
    fileName: string,
    filePath: string,
    type: 'view' | 'download'
  ) => {
    fileDownloadMutation.mutate({
      filepath: filePath,
      name: fileName,
      download: { view: type },
    });
  };

  const renderedAttachmentName = (
    <Box alignItems="center" display="flex" justifyContent="flex-start">
      <Box
        color={theme.palette.gray.main}
        component={FontAwesomeIcon}
        icon={faFile}
        paddingLeft={2}
        size="1x"
      />
      <Box marginX={2} width="100%">
        <Tooltip placement="top" title={name}>
          <Typography
            color="text.primary"
            gutterBottom={false}
            sx={{ paddingY: '16px', wordBreak: 'break-word' }}
            variant="caption"
          >
            {truncateText(name, 17)}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );

  return (
    <Box className="attachment" width="100%">
      <Box
        className="attachment__content-wrapper"
        sx={{ cursor: 'pointer', width: '100%' }}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          sx={{ height: '100%', width: '100%' }}
        >
          {fullPath && (
            <Box
              component="a"
              href={fullPath}
              sx={{ flexGrow: 1, width: '100%', textDecoration: 'none' }}
              target="_blank"
            >
              {renderedAttachmentName}
            </Box>
          )}
          {path && (
            <Box
              onClick={() => viewAttachment(name, path, 'view')}
              sx={{ flexGrow: 1, width: '100%', textDecoration: 'none' }}
            >
              {renderedAttachmentName}
            </Box>
          )}

          {fullPath && (
            <Tooltip arrow placement="top" title="Download">
              <Button
                className="attachment__icon-wrapper"
                component="a"
                href={fullPath}
                sx={{ textDecoration: 'none' }}
                target="_blank"
              >
                <Box component={FontAwesomeIcon} icon={faDownload} />
              </Button>
            </Tooltip>
          )}
          {path && (
            <Tooltip arrow placement="top" title="Download">
              <Button
                className="attachment__icon-wrapper"
                onClick={() => viewAttachment(name, path, 'download')}
                sx={{ textDecoration: 'none' }}
              >
                <Box component={FontAwesomeIcon} icon={faDownload} />
              </Button>
            </Tooltip>
          )}
          {onRemoveClick && (
            <Tooltip arrow placement="top" title="Remove">
              <Button
                className="attachment__icon-wrapper"
                onClick={() => id && onRemoveClick(id)}
              >
                <Box component={FontAwesomeIcon} icon={faClose} />
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Attachment.defaultProps = {
  id: '',
  onRemoveClick: undefined,
  path: '',
  fullPath: '',
};

export default Attachment;
