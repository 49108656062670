const commonConstants = {
  ACCEPTED_FILES: [
    '.pdf',
    '.jpg',
    '.jpeg',
    '.doc',
    '.docx',
    '.txt',
    '.PDF',
    '.JPG',
    '.JPEG',
    '.DOC',
    '.DOCX',
    '.TXT',
  ],
  ANCILLARY_OFFERING_OTHER_TYPE: 'Others',
  CASE_ID_QUERY_PARAMETER: 'caseId',
  CONTACT_US_URL: 'https://vitafyhealth.com/contact',
  DEFAULT_LOCATION: {
    zip: 53201,
    city: 'Milwaukee',
    state: 'WI',
    st: 'WI',
    lat: 43.0113,
    lng: -87.958,
    displayName: 'Milwaukee, WI',
  },
  DEFAULT_NETWORK: 'HPS',
  DEFAULT_TABLE_FILTER: {
    keyword: '',
    limit: 25,
    offset: 0,
  },
  MAXIMUM_DEFAULT_TABLE_FILTER: {
    keyword: '',
    limit: 10000,
    offset: 0,
  },
  DEFAULT_PROFILE_ADMINS_FILTER: {
    keyword: '',
    limit: 25,
    offset: 0,
    referenceId: '',
  },
  DEFAULT_QUESTIONNAIRE_RESPONDENT_FILTER: {
    limit: 100,
    offset: 0,
    code: '',
  },
  DEFAULT_AUTOCOMPLETE_TABLE_FILTER: {
    keyword: '',
    limit: 10,
    offset: 0,
  },
  DEFAULT_LEAD_FILTER: {
    // TODO: This search keyname will be changed to keyword in the future after changes in API.
    search: '',
    limit: 25,
    offset: 0,
  },
  DEFAULT_SITE_METADATA: {
    TITLE: 'Vitafy 360',
    DESCRIPTION:
      'Vitafy Health | A free-market healthcare solution for connecting doctors and patients nationwide',
    FAVICON:
      'https://vitafyhealth.s3.us-west-2.amazonaws.com/documents/1696845798424-vitafy-favicon.ico',
  },
  DIGITAL_SIGNATURE_FONTS: ['oleo', 'bebas', 'brush', 'pacifico'],
  DEFAULT_RADIUS: 30,
  DEFAULT_AUTOCOMPLETE_RADIUS: 60,
  MEMBERSHIP_OFFERING_OTHER_TYPE: 'Others',
  NETWORK_DIRECTORY: 'NETWORK_DIRECTORY',
  PAGE_ITEM_LIMITS: [25, 50, 100, 250, 500],
  RADIUS_ITEM_LIMITS: [30, 60, 90, 120, 240],
  PATIENT: 'patient',
  ADMIN: 'admin',
  PAGINATION: {
    MAXIMUM_LIMIT: 999,
    MINIMUM_OFFSET: 0,
  },
  PRIVACY_POLICY: 'https://www.vitafyhealth.com/privacy-policy',
  TERMS_AND_CONDITION: 'https://www.vitafyhealth.com/terms-and-conditions',
  TRANSACTION_STEPS: ['Patient', 'Offerings', 'Checkout'], // Do not change the order of elements inside the array
  TYPE: {
    GROUP: 'Group',
    INDIVIDUAL: 'Individual',
    ALL: 'All',
  },
  TWO_FACTOR_AUTHENTICATION_CODE_DIGITS: 4,
  VITAFY_URL: 'https://vitafyhealth.com/',

  IS_ADVANCED_SEARCH: 'isAdvancedSearch',

  // for demo, remove this after login feature is implemented.
  DEMO_TENANT_NAME: 'Kathmandu DPC',
  DEMO_CODE: 'Direct Primary Care',
  DEMO_MERCHANT_CUSTOMER_ID: '4d5fa30c-9017-403f-9901-c1edf8e824ed',
  CLIENT: 'Client',
  TENANT: 'Tenant',
  VIRTUAL_VISIT: {
    VIRTUAL_VISIT_USER: 'virtual-visit-user',
  },
  DOCUMENT: 'Document',
  REFERRAL_CASE: 'ReferralCase',
  // --------------------------------------------------------
  // for local storage
  OLD_TOKEN_REFERENCE: 'oldJwtToken',
  IMPERSONATED_BY: 'impersonatedBy',
  STATUS: ['Active', 'Inactive'],
  SUPPORT: 'support@vitafy.com',
  DEFAULT_TENANT_CODE: {
    HOLISTA: 'HOLISTA',
  },
};

export default commonConstants;
