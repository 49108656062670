import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Alert, Box, Typography } from '@mui/material';
import MediumSpanText from 'common/display/MediumSpanText';
import LoadingIndicator from 'common/LoadingIndicator';
import ModalFooter from 'common/ui/modal/ModalFooter';
import {
  ActionFrom,
  ChangeMembershipActionType,
  ClientType,
  OfferingType,
} from 'enums/client-management';
import ModalKey from 'enums/ModalKey';
import { DateUnit } from 'enums/moment';
import {
  BillingInterval,
  TenantOfferingCategoryFull,
} from 'enums/settings/tenant-offerings';
import UserType from 'enums/User';
import {
  useAddClientEnrollmentMutation,
  useApproveClientEnrollmentMutation,
  useClientEnrollmentDetailQuery,
  useUpdateClientEnrollmentMutation,
} from 'services/client-management/enrollment';
import {
  selectChangeMembershipClientData,
  selectChangeMembershipModal,
  selectChangeMembershipSelectedData,
  selectCurrentMembershipSelectedData,
  setChangeMembershipStep,
} from 'stores/client-management';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  checkIsClientDependentOrGroupMember,
  checkIsDependentOfGroupMember,
  getClientTypeFromClient,
} from 'utils/client-management';
import { formatCurrency } from 'utils/common';
import {
  addUnitToDate,
  checkDateWithinXDays,
  checkIfDateisInFuture,
  getCurrentDate,
  momentIsToday,
  momentViewFormat,
} from 'utils/moment';

const ChangeMembershipConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [beginDate, setBeginDate] = useState<string>('');
  const [chargeOnDate, setChargeOnDate] = useState<string>('');

  const [searchParams] = useSearchParams();
  const clientStatus = searchParams.get('status');
  const clientEnrollmentIdFromParams = searchParams.get('clientEnrollmentId');

  const isActionFromClientList =
    searchParams.get('actionFrom') === ActionFrom.CLIENT_LIST;

  const dispatch = useAppDispatch();
  const clientData = useAppSelector(selectChangeMembershipClientData) || null;
  const isDependentOfGroupMember = checkIsDependentOfGroupMember(clientData!);
  const type = getClientTypeFromClient(clientData!);
  const changeMembershipActionType = useAppSelector(
    selectChangeMembershipModal
  )?.actionType;
  const changeMembershipSelectedData =
    useAppSelector(selectChangeMembershipSelectedData) || null;
  const currentMembershipSelectedData =
    useAppSelector(selectCurrentMembershipSelectedData) || null;
  const billingInterval =
    changeMembershipSelectedData?.selectedOffering?.billingInterval ||
    currentMembershipSelectedData?.offerings.billingInterval;

  const addClientEnrollmentMutation = useAddClientEnrollmentMutation();
  const updateClientEnrollmentMutation = useUpdateClientEnrollmentMutation();
  const clientType: any = clientData?.type || UserType.INDIVIDUAL;
  const clientId = clientData?.clientId || '';
  const clientEnrollmentId =
    currentMembershipSelectedData?.clientEnrollmentId || '';
  const isClientTypeDependent = clientType === ClientType.DEPENDENT;
  const tenantId = currentMembershipSelectedData?.tenantId
    ? currentMembershipSelectedData.tenantId
    : clientData?.tenantId;
  const isClientDependentOrGroupMember =
    checkIsClientDependentOrGroupMember(clientData);

  const isActionAprove =
    changeMembershipActionType === ChangeMembershipActionType.APPROVE;
  const unformattedRegistrationFee =
    changeMembershipSelectedData?.selectedOffering?.unformattedRegistrationFee;

  const isUpdatingMembership = !changeMembershipSelectedData?.endDate;

  const clientEnrollmentDetailQuery = useClientEnrollmentDetailQuery(
    clientId!,
    clientEnrollmentId!,
    { type: isClientTypeDependent ? clientType : undefined },
    { enabled: isActionAprove }
  );

  const approveClientEnrollmentMutation = useApproveClientEnrollmentMutation();
  const unFormattedPriceWhileAdding =
    changeMembershipSelectedData?.selectedOffering?.unformattedPrice || 0;
  const minQuantity =
    changeMembershipSelectedData?.selectedOffering?.minQuantity || 1;
  const totalFeeWhileAdding =
    Number(unFormattedPriceWhileAdding) * Number(minQuantity);

  const isSelectedMembershipBenefitStartDateInFuture = checkIfDateisInFuture(
    currentMembershipSelectedData?.startDate || ''
  );

  const isFeeZero =
    (ChangeMembershipActionType.APPROVE &&
      Number(clientEnrollmentDetailQuery?.data?.totalAmount) === 0) ||
    (ChangeMembershipActionType.ADD &&
      Number(
        changeMembershipSelectedData?.selectedOffering?.unformattedPrice
      ) === 0);

  const handlePrevious = () => {
    if (isActionFromClientList) {
      navigate(
        `${location.pathname}?type=${
          ModalKey.CHANGE_MEMBERSHIP
        }&clientId=${clientId!}&status=${clientStatus}&clientEnrollmentId=${clientEnrollmentIdFromParams}`
      );
    }
    dispatch(
      setChangeMembershipStep({
        step:
          changeMembershipActionType === ChangeMembershipActionType.APPROVE
            ? 0
            : 1,
        actionType: changeMembershipActionType,
      })
    );
  };

  const handleContinue = () => {
    const selectedOffering =
      changeMembershipSelectedData?.selectedOffering || null;
    const startDate = changeMembershipSelectedData?.startDate || null;
    if (selectedOffering !== null) {
      const payload = {
        startDate: startDate || '',
        offeringName: selectedOffering?.name,
        offeringId: selectedOffering?.tenantOfferingId,
        cost: Number(selectedOffering?.unformattedPrice),
        billingInterval: selectedOffering?.billingInterval,
        billingType: selectedOffering?.billingType,
        registrationFee: selectedOffering?.unformattedRegistrationFee,
        tenantId,
        clientId: clientData?.clientId,
        clientName: clientData?.fullName || clientData?.businessName,
        clientType: clientData?.type,
        membershipType: OfferingType.MEMBERSHIP,
      };

      if (changeMembershipActionType === ChangeMembershipActionType.ADD) {
        addClientEnrollmentMutation.mutate(
          {
            clientId: clientData?.clientId ? clientData.clientId : '',
            enrollmentId: currentMembershipSelectedData?.clientEnrollmentId
              ? currentMembershipSelectedData.clientEnrollmentId
              : '',
            data: payload,
          },
          {
            onSuccess: () => {
              dispatch(
                setChangeMembershipStep({
                  step: 3,
                  actionType: changeMembershipActionType,
                })
              );
            },
          }
        );
      }
    }
    // For Changing Membership
    if (changeMembershipActionType === ChangeMembershipActionType.CHANGE) {
      const data = {
        startDate: currentMembershipSelectedData?.startDate || '',
        offeringName: currentMembershipSelectedData?.offerings.name || '',
        tenantOfferingId: currentMembershipSelectedData?.offerings.id || '',
        totalCost: Number(currentMembershipSelectedData?.offerings.totalCost),
        billingInterval:
          currentMembershipSelectedData?.offerings.billingInterval,
        billingType: currentMembershipSelectedData?.offerings.billingType,
        registrationFee: Number(
          currentMembershipSelectedData?.offerings.registrationFee
        ),
        ...(isSelectedMembershipBenefitStartDateInFuture && {
          startDate: changeMembershipSelectedData?.endDate || '',
          endDate: '',
        }),
        ...(!isSelectedMembershipBenefitStartDateInFuture && {
          endDate: changeMembershipSelectedData?.endDate || '',
        }),
      };

      updateClientEnrollmentMutation.mutate(
        {
          clientId: clientData?.clientId ? clientData.clientId : '',
          enrollmentId: currentMembershipSelectedData?.clientEnrollmentId
            ? currentMembershipSelectedData.clientEnrollmentId
            : '',
          data,
          clientType,
        },
        {
          onSuccess: () => {
            dispatch(
              setChangeMembershipStep({
                step: 3,
                actionType: changeMembershipActionType,
              })
            );
          },
        }
      );
    }
    // For Approving Membership
    if (changeMembershipActionType === ChangeMembershipActionType.APPROVE) {
      const body = {
        offeringName: currentMembershipSelectedData?.offerings.name,
        tenantOfferingId: currentMembershipSelectedData?.offerings.id,
        billingInterval:
          currentMembershipSelectedData?.offerings.billingInterval,
        billingType: currentMembershipSelectedData?.offerings.billingType,
      };
      approveClientEnrollmentMutation.mutate(
        {
          clientId: clientId!,
          enrollmentId: clientEnrollmentId!,
          body,
          clientType: clientType as UserType,
        },
        {
          onSuccess: () => {
            dispatch(
              setChangeMembershipStep({
                step: 3,
                actionType: changeMembershipActionType,
              })
            );
          },
        }
      );
    }
  };

  const getEventText = () => {
    if (
      changeMembershipActionType === ChangeMembershipActionType.CHANGE &&
      isUpdatingMembership
    ) {
      return 'continue again';
    }
    if (changeMembershipActionType === ChangeMembershipActionType.CHANGE) {
      if (isSelectedMembershipBenefitStartDateInFuture) {
        return 'start on ';
      }
      return 'end on ';
    }
    return 'begin on ';
  };

  useEffect(() => {
    const selectedStartDate =
      changeMembershipActionType === ChangeMembershipActionType.ADD
        ? (changeMembershipSelectedData?.startDate as string)
        : (changeMembershipSelectedData?.endDate as string);
    if (
      changeMembershipActionType === ChangeMembershipActionType.ADD ||
      changeMembershipActionType === ChangeMembershipActionType.CHANGE
    ) {
      if (
        changeMembershipSelectedData?.selectedOffering?.billingInterval !==
        BillingInterval.ONE_TIME
      ) {
        const isFutureDate = checkIfDateisInFuture(selectedStartDate);
        const isCurrentDate = momentIsToday(selectedStartDate);
        const isDateWithin7Days = checkDateWithinXDays(selectedStartDate, 7);
        if (isDateWithin7Days) {
          setBeginDate(selectedStartDate);
          setChargeOnDate(getCurrentDate());
        }
        if (!isDateWithin7Days) {
          const convertedDate = addUnitToDate(
            selectedStartDate,
            1,
            DateUnit.MONTHS
          );
          const nextDate = momentViewFormat(convertedDate);
          setBeginDate(selectedStartDate);
          setChargeOnDate(nextDate as string);
        }
        if (isFutureDate && !isCurrentDate) {
          setBeginDate(selectedStartDate);
          setChargeOnDate(selectedStartDate);
        }
      } else {
        setBeginDate(selectedStartDate);
        setChargeOnDate(getCurrentDate());
      }
    }
  }, [changeMembershipActionType, changeMembershipSelectedData]);

  useEffect(() => {
    if (changeMembershipActionType !== ChangeMembershipActionType.APPROVE) {
      clientEnrollmentDetailQuery.remove();
    }
  }, [changeMembershipActionType, clientEnrollmentDetailQuery]);

  const getTotalAmount = () => {
    if (isActionAprove) {
      return `${clientEnrollmentDetailQuery?.data?.formattedTotalAmount}`;
    }
    if (
      changeMembershipActionType === ChangeMembershipActionType.ADD &&
      changeMembershipSelectedData?.selectedOffering?.category ===
        TenantOfferingCategoryFull.GROUP
    ) {
      return `${formatCurrency(totalFeeWhileAdding)}`;
    }
    return changeMembershipSelectedData?.selectedOffering
      ?.formattedPriceIncludingRegFee;
  };

  const getRecurringFeeAmount = () => {
    if (isActionAprove) {
      return `${clientEnrollmentDetailQuery?.data?.formattedSubscriptionAmount}`;
    }
    if (
      changeMembershipActionType === ChangeMembershipActionType.ADD &&
      changeMembershipSelectedData?.selectedOffering?.category ===
        TenantOfferingCategoryFull.GROUP
    ) {
      return `${formatCurrency(totalFeeWhileAdding)}`;
    }
    return changeMembershipSelectedData?.selectedOffering?.price;
  };

  return (
    <Box>
      {!clientEnrollmentDetailQuery.isFetching && (
        <Box paddingX={2} paddingY={5} sx={{ textAlign: 'center' }}>
          <Typography variant="body1">
            You are{' '}
            {changeMembershipActionType === ChangeMembershipActionType.CHANGE
              ? 'updating'
              : 'enrolling'}{' '}
            {changeMembershipActionType !== ChangeMembershipActionType.CHANGE &&
            changeMembershipActionType !==
              ChangeMembershipActionType.APPROVE ? (
              <MediumSpanText>
                {changeMembershipSelectedData?.selectedOffering?.name}
              </MediumSpanText>
            ) : (
              <MediumSpanText>
                {currentMembershipSelectedData?.offerings.name}
              </MediumSpanText>
            )}
          </Typography>
          <Typography sx={{ paddingX: '4rem' }} variant="body1">
            for{' '}
            {isActionAprove ? (
              <MediumSpanText>
                {clientEnrollmentDetailQuery?.data?.formattedClients}{' '}
              </MediumSpanText>
            ) : (
              <MediumSpanText>
                {clientData?.fullName || clientData?.businessName}{' '}
              </MediumSpanText>
            )}
            to {getEventText()}
            {changeMembershipActionType !==
            ChangeMembershipActionType.CHANGE ? (
              <MediumSpanText>
                {isActionAprove
                  ? clientEnrollmentDetailQuery?.data?.startDate
                  : beginDate}
                .
              </MediumSpanText>
            ) : (
              <MediumSpanText>
                {changeMembershipSelectedData?.endDate}.
              </MediumSpanText>
            )}
          </Typography>
          {changeMembershipActionType !== ChangeMembershipActionType.CHANGE &&
          !isDependentOfGroupMember &&
          !isFeeZero
            ? ((type === ClientType.DEPENDENT &&
                changeMembershipActionType !==
                  ChangeMembershipActionType.ADD) ||
                type === ClientType.PRIMARY ||
                type === ClientType.GROUP) && (
                <Typography fontWeight="medium" pb={1} pt={2} variant="h1">
                  {getTotalAmount()}
                </Typography>
              )
            : ((type !== ClientType.DEPENDENT &&
                changeMembershipActionType ===
                  ChangeMembershipActionType.CHANGE) ||
                type === ClientType.PRIMARY ||
                type === ClientType.GROUP) &&
              !isUpdatingMembership && (
                <Typography fontWeight="medium" pb={1} pt={2} variant="h1">
                  {formatCurrency(
                    Number(currentMembershipSelectedData?.offerings.cost) +
                      Number(
                        currentMembershipSelectedData?.offerings.registrationFee
                      )
                  )}
                </Typography>
              )}
          {changeMembershipActionType === ChangeMembershipActionType.ADD &&
            unformattedRegistrationFee! > 0 &&
            !isClientDependentOrGroupMember &&
            !isFeeZero && (
              <>
                <Typography variant="caption">
                  <MediumSpanText>
                    ({changeMembershipSelectedData?.selectedOffering?.price}{' '}
                    {
                      changeMembershipSelectedData?.selectedOffering
                        ?.formattedBillingType
                    }{' '}
                    {
                      changeMembershipSelectedData?.selectedOffering
                        ?.formattedBillingInterval
                    }{' '}
                    +{' '}
                    {
                      changeMembershipSelectedData?.selectedOffering
                        ?.registrationFee
                    }{' '}
                    registration fee)
                  </MediumSpanText>
                </Typography>
                <br />
              </>
            )}
          {changeMembershipActionType !== ChangeMembershipActionType.CHANGE &&
          !isDependentOfGroupMember &&
          !isFeeZero
            ? ((type === ClientType.DEPENDENT &&
                changeMembershipActionType !==
                  ChangeMembershipActionType.ADD) ||
                type === ClientType.PRIMARY ||
                type === ClientType.GROUP) && (
                <Box>
                  <Typography variant="caption">will be charged on </Typography>
                  <Typography variant="caption">
                    {isActionAprove
                      ? clientEnrollmentDetailQuery?.data?.nextBillingDate
                      : chargeOnDate}
                    .
                  </Typography>
                </Box>
              )
            : ((type !== ClientType.DEPENDENT &&
                changeMembershipActionType ===
                  ChangeMembershipActionType.CHANGE) ||
                type === ClientType.PRIMARY ||
                type === ClientType.GROUP) &&
              !isUpdatingMembership && (
                <Typography variant="caption">
                  will be charged on {chargeOnDate}.
                </Typography>
              )}
          {((type === ClientType.DEPENDENT &&
            changeMembershipActionType !== ChangeMembershipActionType.ADD) ||
            type === ClientType.PRIMARY ||
            type === ClientType.GROUP) &&
            !isDependentOfGroupMember &&
            !isFeeZero && (
              <Box>
                {changeMembershipActionType !==
                ChangeMembershipActionType.CHANGE ? (
                  <Box>
                    {billingInterval !== BillingInterval.ONE_TIME && (
                      <Box marginBottom={2} paddingX={5}>
                        <Alert
                          severity="info"
                          sx={{
                            marginX: '2rem !important',
                            marginTop: '2.8rem !important',
                            textAlign: 'left',
                          }}
                        >
                          A recurring fee of {getRecurringFeeAmount()} will be
                          scheduled every{' '}
                          {isActionAprove
                            ? currentMembershipSelectedData?.formattedBillingInterval.replace(
                                'per ',
                                ''
                              )
                            : changeMembershipSelectedData?.selectedOffering?.formattedBillingInterval.replace(
                                'per ',
                                ''
                              )}
                          {isActionAprove && ' unless cancelled'}. Please
                          confirm to continue.
                        </Alert>
                      </Box>
                    )}
                    {billingInterval === BillingInterval.ONE_TIME && (
                      <Box marginBottom={2} paddingX={5}>
                        <Alert
                          severity="info"
                          sx={{
                            marginX: '2rem !important',
                            marginTop: '2.8rem !important',
                            textAlign: 'left',
                          }}
                        >
                          No recurring fee will be scheduled for this plan.
                          Please confirm to continue.
                        </Alert>
                      </Box>
                    )}
                  </Box>
                ) : (
                  type === ClientType.PRIMARY && (
                    <Box>
                      {billingInterval !== BillingInterval.ONE_TIME && (
                        <Box marginBottom={2} paddingX={5}>
                          <Alert
                            severity="info"
                            sx={{
                              marginX: '2rem !important',
                              marginTop: '2.8rem !important',
                              textAlign: 'left',
                            }}
                          >
                            A recurring fee of{' '}
                            {currentMembershipSelectedData?.formattedTotalCost}{' '}
                            will be scheduled every{' '}
                            {currentMembershipSelectedData?.formattedBillingInterval.replace(
                              'per ',
                              ''
                            )}
                            . Please confirm to continue.
                          </Alert>
                        </Box>
                      )}
                      {billingInterval === BillingInterval.ONE_TIME && (
                        <Box marginBottom={2} paddingX={5}>
                          <Alert
                            severity="info"
                            sx={{
                              marginX: '2rem !important',
                              marginTop: '2.8rem !important',
                              textAlign: 'left',
                            }}
                          >
                            No recurring fee will be scheduled for this plan.
                            Please confirm to continue.
                          </Alert>
                        </Box>
                      )}
                    </Box>
                  )
                )}
              </Box>
            )}
        </Box>
      )}
      {clientEnrollmentDetailQuery.isFetching && <LoadingIndicator />}
      <ModalFooter
        actionButtonLabel="Confirm"
        actionButtonType="button"
        cancelButtonType="previous"
        isLoading={
          updateClientEnrollmentMutation.isLoading ||
          approveClientEnrollmentMutation.isLoading ||
          addClientEnrollmentMutation.isLoading
        }
        onActionButtonClick={handleContinue}
        onCancelButtonClick={handlePrevious}
      />
    </Box>
  );
};

export default ChangeMembershipConfirmation;
