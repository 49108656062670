enum ModalKey {
  ADD_ADMIN_USER = 'add_admin_user',
  ADD_APPOINTMENTS = 'add_appointments',
  ADD_PROFILE_ADMIN = 'add_profile_admin',
  ADD_APPOINTMENT = 'add_appointment',
  ADD_ANCILLARY_OFFERING = 'add_ancillary_offering',
  ADD_CLIENT = 'add_client',
  ADD_CLIENT_BILLING = 'add_client_billing',
  ADD_CASE = 'add_case',
  ADD_CASE_SERVICE = 'add_case_service',
  ADD_CASE_TASK = 'add_case_task',
  ADD_COMMUNICATION_TEMPLATE = 'add_communication_template',
  ADD_DOCUMENTS_TAG = 'add_documents_tag',
  ADD_LAB_SERVICE = 'add_lab_service',
  ADD_FACILITY = 'add_facility',
  ADD_OFFERING = 'add_offering',
  ADD_PHYSICIAN = 'add_physician',
  ADD_PATIENT_CASE = 'add_patient_case',
  ADD_REFERRING_AGENT = 'add_referring_agent',
  ADD_REFERRING_PHYSICIAN = 'add_referring_physician',
  ADD_REFERRING_FACILITY = 'add_referring_facility',
  ADD_USER = 'add_user',
  ADD_USER_PERMISSION = 'add_user_permission',
  ADD_TENANT = 'add_tenant',
  ADD_TENANT_SERVICE = 'add_tenant_service',
  ADD_TRANSACTION_NOTE = 'add_transaction_note',
  ADD_TRANSACTION = 'add_transaction',
  ADD_NETWORK_OFFERINGS = 'add_network_offerings',
  ADD_NETWORK_SERVICE = 'add_network_service',
  ADD_NETWORK_PROVIDER_SERVICE = 'add_network_provider_service',
  ADD_VIRTUAL_VISIT = 'add_virtual_visit',
  ADD_LEADS = 'add_leads',
  ADD_SCHDEULE = 'add_schedule',
  ADD_STATUS = 'add_status',
  ADD_TASK = 'add_tasks',
  APPROVE_CLIENT_ENROLLMENT = 'approve_client_enrollment',
  ASSIGN_USER = 'assign_user',
  ASSIGN_TENANT = 'assign_tenant',
  BLOOD_DONOR = 'blood_donor',
  CASE_TIMELOG = 'timelog',
  CHANGE_MEMBERSHIP = 'change_membership',
  COMPLETE_CLIENT_ENROLLMENT = 'complete_client_enrollment',
  CANCEL_CLIENT_ENROLLMENT = 'cancel_client_enrollment',
  CHANGE_CLIENT_ENROLLMENT = 'change_client_enrollment',
  CLIENT_AGREEMENT_SIGNATURE = 'client_agreement_signature',
  CLIENT_VIRTUAL_VISIT = 'client_virtual_visit',
  DELETE_COMMUNICATION_TEMPLATE = 'delete_communication_template',
  EDIT_APPOINTMENT = 'edit_appointment',
  EDIT_CASE_PATIENT = 'edit_case_patient',
  EDIT_CASE_SERVICE = 'edit_case_service',
  EDIT_CASE_TASK = 'edit_case_task',
  EDIT_FACILITY = 'edit_facility',
  EDIT_PHYSICIAN = 'edit_physician',
  EDIT_PROFILE_ADMIN = 'edit_profile_admin',
  EDIT_LAB_SERVICE = 'edit_lab_service',
  EDIT_REFERRING_PHYSICIAN = 'edit_referring_physician',
  EDIT_REFERRING_FACILITY = 'edit_referring_facility',
  EDIT_TENANT = 'edit_tenant',
  EDIT_TRANSACTION_NOTE = 'edit_transaction_note',
  EDIT_NETWORK_OFFERINGS = 'edit_network_offerings',
  EDIT_NETWORK_SERVICE = 'edit_network_service',
  EDIT_VIRTUAL_VISIT = 'edit_virtual_visit',
  FORGET_PASSWORD = 'forget_password',
  LINK_FACILITY = 'link_facility',
  LINK_PHYSICIAN = 'link_physician',
  NOTES_TWO_FACTOR_AUTHENTICATION = 'notes_two_factor_authentication',
  QUESTIONNAIRE_TWO_FACTOR_AUTHENTICATION = 'questionnaire_two_factor_authentication',
  REFUND_TRANSACTION = 'refund_transaction',
  RESTART_CLIENT_ENROLLMENT = 'restart_client_enrollment',
  RE_SCHEDULE_APPOINTMENT = 're_schedule_appointment',
  SCHEDULE_APPOINTMENT = 'schedule_appointment',
  SEND_AGREEMENT = 'send_agreement',
  SEND_TEST_REPORT = 'send_test_report',
  SHARE_REFERRAL_CASE = 'share_referral_case',
  SHARE_REFERRAL_CASE_AUTHENTICATE = 'share_referral_case_authenticate',
  SHARE_MODAL = 'share_modal',
  START_VIRTUAL_VISIT_MODAL = 'start_virtual_visit_modal',
  TWO_FACTOR_AUTHENTICATION = 'two_factor_authentication',
  TRANSACTION_TWO_FACTOR_AUTHNTICATION = 'transaction_two_factor_authentication',
  UPDATE_TENANT_SETTING = 'update_tenant_setting',
  VIEW_PROVIDER_SERVICES = 'view_provider_services',
  UPLOAD_CASE_FILE = 'upload_case_file',
  UPLOAD_DOCUMENTS = 'upload_documents',
  VIRTUALVISIT_UPLOAD_DOCUMENTS = 'virtual_visit_upload_documents',
  UPLOAD_NETWORK_FILES = 'upload_network_file',
  UPLOAD_EMAIL_NOTES_FILE = 'upload_email_notes_file',
  VIEW_CASE = 'view_case',
  USER_NOT_SETUP = 'user_not_setup',
  OFFERING_VIEW_PROVIDER_LIST = 'view_associates_list',
}

export default ModalKey;
