import {
  AppBar,
  Box,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { HeaderMenuList } from 'common';
import { BuyMembershipButton } from 'common/header/BuyMembership';
import HeaderProfile from 'common/header/HeaderProfile';
import { useCheckUserIsEmail } from 'hooks/useCheckUserisEmail';
import { selectActiveMenu } from 'stores/auth';
import { useAppSelector } from 'stores/hooks';

const Header = () => {
  const theme = useTheme();
  const smBreakPoint = useMediaQuery(theme.breakpoints.down('sm'));
  const isUserEmail = useCheckUserIsEmail();
  const activeMenu = useAppSelector(selectActiveMenu);

  return (
    <Box
      className={
        smBreakPoint && isUserEmail
          ? 'dashboard-header-mobile-responsive'
          : 'dashboard-header'
      }
      component={AppBar}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <HeaderMenuList />

        <Box alignItems="center" display="flex" gap={2}>
          <BuyMembershipButton />

          {activeMenu?.metadata?.getHelpLinkUrl && (
            <Button
              onClick={() =>
                window.open(`${activeMenu.metadata?.getHelpLinkUrl}`, '_blank')
              }
              size="small"
              variant="outlined"
            >
              Help Guide
            </Button>
          )}

          <HeaderProfile />
        </Box>
      </Toolbar>
    </Box>
  );
};

export default Header;
