export enum NoteVisibility {
  PUBLIC = 'Public',
  HIDDEN = 'Hidden',
}

export enum ReferralCaseServiceType {
  SERVICE = 'Service',
  MEDICAL = 'Medical',
}

export enum ReferralCaseShareReferenceType {
  EMAIL = 'Email',
  TENANT = 'Tenant',
  FULL = 'Full',
}

export enum ReferralCaseRequestedAssignUserType {
  TO = 'to',
  FROM = 'from',
}

export enum ReferralCaseStatus {
  REQUESTED = 'Requested',
  ACKNOWLEDGED = 'Acknowledged',
  INPROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  DELETED = 'Deleted',
}

export enum TaskStatus {
  SUBMITTED = 'Submitted',
  COMPLETED = 'Completed',
  REMINDED = 'Reminded',
}

export enum SelectionOptions {
  SELECTED = 'Selected',
  UNSELECTED = 'UnSelected',
}

export enum ICaseHistoryAction {
  CREATE = 'CREATE',
  FORWARD = 'FORWARD',
  UPLOAD = 'UPLOAD',
  ASSIGN = 'ASSIGN',
  SHARE = 'SHARE',
  EDIT_PATIENT = 'EDIT_PATIENT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ADD_PROVIDER = 'ADD_PROVIDER',
  EDIT_PROVIDER = 'EDIT_PROVIDER',
  DELETE_PROVIDER = 'DELETE_PROVIDER',
  SELECT_PROVIDER = 'SELECT_PROVIDER',
  SET_APPOINTMENT = 'SET_APPOINTMENT',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  UPDATE_STATUS = 'UPDATE_STATUS',
  UPDATE_REFERRING_PHYSICIAN = 'UPDATE_REFERRING_PHYSICIAN',
  UPDATE_SERVICE = 'UPDATE_SERVICE',
}
export enum ICaseHistoryCategory {
  CASE = 'CASE',
  NOTE = 'NOTE',
  DONOR = 'DONOR',
  FILE = 'FILE',
}
