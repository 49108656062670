import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Stack, Typography } from '@mui/material';
import { RadioBoxButton } from 'common/ui/RadioBoxButton';
import paymentConstants from 'constants/payment';
import { PaymentMethod } from 'enums/Payment';
import { BillingCode } from 'enums/tenant-management/tenant';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { IPaymentForm } from 'interfaces/payment';
import { checkIsBillingSettingEnabled } from 'utils/tenantSetting';

interface IProps {
  options?: PaymentMethod[];
}

const PaymentOptionList = ({ options }: IProps) => {
  const { watch, setValue } = useFormContext<IPaymentForm>();

  const isUserClient = useCheckUserIsClient();

  const paymentMethod = watch('paymentMethod');

  const getPaymentLabel = (label: PaymentMethod) => {
    switch (label) {
      case PaymentMethod.CARD:
        return paymentConstants.label.CARD;
      case PaymentMethod.BANK:
        return paymentConstants.label.BANK;
      case PaymentMethod.CASH:
        return PaymentMethod.CASH;
      case PaymentMethod.CARD_ON_FILE:
        return PaymentMethod.CARD_ON_FILE;
      case PaymentMethod.SPONSOR_ACCOUNT:
        return PaymentMethod.SPONSOR_ACCOUNT;
      case PaymentMethod.CHECK:
        return PaymentMethod.CHECK;
      case PaymentMethod.OTHERS:
        return PaymentMethod.OTHERS;
      default:
        return '';
    }
  };

  const paymentOptions = useMemo(() => {
    if (options && options.length > 0) return options;

    const defaultOptions = [];

    const isCardEnabled = checkIsBillingSettingEnabled(BillingCode.CARD);
    const isBankEnabled = checkIsBillingSettingEnabled(BillingCode.BANKING);
    const isCashEnabled =
      !isUserClient && checkIsBillingSettingEnabled(BillingCode.CASH);
    const isCheckEnabled =
      !isUserClient && checkIsBillingSettingEnabled(BillingCode.CHECK);
    const isOthersEnabled =
      !isUserClient && checkIsBillingSettingEnabled(BillingCode.OTHERS);

    if (isCardEnabled) defaultOptions.push(PaymentMethod.CARD);
    if (isBankEnabled) defaultOptions.push(PaymentMethod.BANK);
    if (isCashEnabled) defaultOptions.push(PaymentMethod.CASH);
    if (isCheckEnabled) defaultOptions.push(PaymentMethod.CHECK);
    if (isOthersEnabled) defaultOptions.push(PaymentMethod.OTHERS);

    return defaultOptions;
  }, [options, isUserClient]);

  return (
    <Stack spacing={1}>
      <Typography color="gray.darker" fontWeight="medium" variant="body1">
        Select Your Payment Information
      </Typography>
      <Stack direction="row" spacing={1}>
        {paymentOptions?.map((type) => (
          <Box key={type} sx={{ flexGrow: 1 }}>
            <RadioBoxButton
              data-cy={`clients-${type}`}
              isActive={paymentMethod === type}
              label={getPaymentLabel(type)}
              onClick={() => setValue('paymentMethod', type)}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

PaymentOptionList.defaultProps = {
  options: [],
};

export default PaymentOptionList;
