import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { DialogContent, Divider } from '@mui/material';
import { ModalFooter } from 'common';
import commonConstants from 'constants/common';
import {
  ClientEnrollmentStatus,
  ClientType,
  OfferingType,
} from 'enums/client-management';
import ModalKey from 'enums/ModalKey';
import UserType from 'enums/User';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { IClientGroup, IClientIndividual } from 'interfaces/client-management';
import {
  IClientEnrollmentSchema,
  IEnrollmentForm,
} from 'interfaces/client-management/enrollment';
import { IAdaptedTenantMembershipOfferingTableRow } from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { useSnackbar } from 'notistack';
import {
  useAddClientEnrollmentMutation,
  useChangeClientEnrollmentMutation,
  useClientEnrollmentQuery,
  useRestartClientEnrollmentMutation,
  useUpdateClientEnrollmentMutation,
} from 'services/client-management/enrollment';
import { useTenantOfferingQuery } from 'services/tenant-management/tenant/tenantMembershipOffering';
import { selectAuthTenantAssociation } from 'stores/auth';
import {
  selectEditClientData,
  selectParentClient,
} from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import {
  getClientSpecificTenantId,
  getClientTypeFromClient,
} from 'utils/client-management';
import {
  formatChangeEnrollmentPayload,
  formatUpdateEnrollmentPayload,
  getBenefitStartDate,
} from 'utils/client-management/enrollment';
import { checkIfDateIsValid } from 'utils/moment';

import FormMembershipInput from './FormMembershipInput';
import MembershipCard from './MembershipCard';
import MembershipDetail from './MembershipDetail';

interface IProps {
  handleCancelButtonClick: VoidFunction;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const defaultValues = {
  startDates: {},
  membership: {
    name: '',
    membershipId: '',
    price: '',
    endDate: '',
    offeringName: '',
    tenantOfferingId: '',
    totalCost: '',
    billingInterval: '',
    billingType: '',
    registrationFee: 0,
  },
};

const Membership = ({ handleCancelButtonClick, setActiveTab }: IProps) => {
  const methods = useForm<IEnrollmentForm>({
    defaultValues,
  });
  const [searchParams] = useSearchParams();
  const type = searchParams.get('from');

  const { enqueueSnackbar } = useSnackbar();

  const { membership, startDates } = methods.watch();

  const editClientData = useAppSelector(selectEditClientData);
  const clientId = editClientData?.clientId || '';
  const clientEnrollmentId = editClientData?.clientEnrollmentId || '';

  // TODO: Need to check if `editClientData` is loaded or not
  // Reason: tenantId is not kept in the query key so, if the authTenantId is set before editClientData?.membership?.tenantId
  // is loaded, it won't cause a refetch after editClientData?.membership?.tenantId is loaded.
  const activeTenantId = getClientSpecificTenantId(editClientData);

  const parentClient = useAppSelector(selectParentClient);
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const tenantGroupCode = authTenantAssociationData?.groupCode;

  const tenantOfferingFetchCondition =
    !!editClientData?.type && !!activeTenantId && !!clientEnrollmentId;

  const clientEnrollmentQuery = useClientEnrollmentQuery(
    clientId!,
    clientEnrollmentId!,
    {
      enabled: !!clientId,
    }
  );

  const enrollmentId = clientEnrollmentQuery?.data?.clientEnrollmentId;
  const updateClientEnrollmentMutation = useUpdateClientEnrollmentMutation();
  const addClientEnrollmentMutation = useAddClientEnrollmentMutation();
  const restartClientEnrollmentMutation = useRestartClientEnrollmentMutation();
  const changeClientEnrollmentMutation = useChangeClientEnrollmentMutation();

  const isUserClient = useCheckUserIsClient();

  const isLoading =
    updateClientEnrollmentMutation.isLoading ||
    restartClientEnrollmentMutation.isLoading ||
    changeClientEnrollmentMutation.isLoading ||
    addClientEnrollmentMutation.isLoading;

  const tenantOfferingQuery = useTenantOfferingQuery(
    activeTenantId!,
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      clientId: editClientData?.clientId,
      clientEnrollmentId,
      type: OfferingType.MEMBERSHIP,
      recommended: true,
    },
    {
      enabled: tenantOfferingFetchCondition,
    }
  );

  const checkIfBenefitStartDateIsValid = (submitData: IEnrollmentForm) => {
    const benefitStartDate =
      submitData.startDates[submitData.membership?.tenantOfferingId];

    // check if the benefit start date is valid
    if (!benefitStartDate || !checkIfDateIsValid(benefitStartDate)) {
      enqueueSnackbar('Invalid benefit start date', {
        variant: 'error',
      });
      return false;
    }

    return true;
  };

  const restartClientEnrollment = (submitData: IEnrollmentForm) => {
    const isValid = checkIfBenefitStartDateIsValid(submitData);

    if (!isValid) {
      return;
    }

    const sendData: IClientEnrollmentSchema =
      formatChangeEnrollmentPayload(submitData);

    restartClientEnrollmentMutation.mutate(
      {
        clientId: clientId!,
        enrollmentId: enrollmentId!,
        data: sendData,
        clientType: editClientData?.type as UserType,
      },
      {
        onSuccess: () => {
          setActiveTab((prevState) => prevState + 1);
        },
      }
    );
  };

  const changeClientEnrollment = (submitData: IEnrollmentForm) => {
    const isValid = checkIfBenefitStartDateIsValid(submitData);

    if (!isValid) {
      return;
    }

    const sendData: IClientEnrollmentSchema =
      formatChangeEnrollmentPayload(submitData);
    changeClientEnrollmentMutation.mutate(
      {
        clientId: clientId!,
        enrollmentId: enrollmentId!,
        data: sendData,
        clientType: editClientData?.type as UserType,
      },
      {
        onSuccess: () => {
          setActiveTab((prevState) => prevState + 1);
        },
      }
    );
  };

  const updateClientEnrollment = (submitData: IEnrollmentForm) => {
    const isValid = checkIfBenefitStartDateIsValid(submitData);

    if (!isValid) {
      return;
    }

    const sendData: IClientEnrollmentSchema = formatUpdateEnrollmentPayload(
      // clientId!,
      submitData,
      activeTenantId!,
      tenantGroupCode,
      editClientData?.type
    );

    updateClientEnrollmentMutation.mutate(
      {
        clientId: clientId!,
        enrollmentId: clientEnrollmentId!,
        data: sendData,
        clientType: editClientData?.type as UserType,
      },
      {
        onSuccess: () => {
          setActiveTab((prevState) => prevState + 1);
        },
      }
    );
  };

  const isApproved =
    clientEnrollmentQuery.data?.status === ClientEnrollmentStatus.APPROVED &&
    type !== ModalKey.CHANGE_CLIENT_ENROLLMENT;
  const isCancelled =
    clientEnrollmentQuery.data?.status === ClientEnrollmentStatus.CANCELLED &&
    type !== ModalKey.RESTART_CLIENT_ENROLLMENT;
  const isCompleted =
    clientEnrollmentQuery.data?.status === ClientEnrollmentStatus.COMPLETED &&
    type !== ModalKey.RESTART_CLIENT_ENROLLMENT;
  const isDeclined =
    clientEnrollmentQuery.data?.status === ClientEnrollmentStatus.DECLINED &&
    type !== ModalKey.RESTART_CLIENT_ENROLLMENT;

  const checkWhetherToShowMembershipReadOnlyView = () => {
    if (isUserClient) return true;
    return isApproved || isCancelled || isCompleted || isDeclined;
  };
  const showMembershipReadOnlyView = checkWhetherToShowMembershipReadOnlyView();

  const onSubmit = (submitData: IEnrollmentForm) => {
    // No need to call API for update enrollment in read only view
    if (showMembershipReadOnlyView) {
      setActiveTab((prevState) => prevState + 1);
      return;
    }

    if (type === ModalKey.RESTART_CLIENT_ENROLLMENT) {
      restartClientEnrollment(submitData);
      return;
    }
    if (type === ModalKey.CHANGE_CLIENT_ENROLLMENT) {
      changeClientEnrollment(submitData);
      return;
    }

    updateClientEnrollment(submitData);
  };

  /**
   *
   * Note: Logic behind pre-selection of offering is placed in the API (`isRecommended` flag).
   * However, benefit start date is populated from the UI.
   *
   * Initial selection of offerings for:
   *
   * Group Member -> Offering of the parent group
   *
   * Primary -> Offering with the lowest price and the client's age should satisfy age range of the offering.
   *            If the offering with the lowest price doesn't have age range (age range = 'N/A'), pre-select the offering.
   *
   * Dependent of Primary -> Offering of the primary individual
   *            If the primary individual has selected offering of type 'Family' (say 'Family Membership'),
   *            it should be pre-selected and there should not be any other options
   *            If primary individual has selected offering of type 'Individual', pre-select the offering as it's done for primary
   *            individual (i.e. the lowest price and satifying the age range)
   *
   * Dependent of Group Member -> Offering of the group member
   *
   * Group -> Offering with the lowest price
   *
   */
  const offeringData: {
    tenantOfferingId: string;
    membershipStartDate: string;
  } = useMemo(() => {
    let offeringObj = {
      tenantOfferingId: '',
      membershipStartDate: '',
    };

    const existingTenantOfferingId = editClientData?.membership?.id;
    const existingStartDate = editClientData?.membership?.startDate;

    if (existingTenantOfferingId) {
      offeringObj = {
        tenantOfferingId: existingTenantOfferingId ?? '',
        membershipStartDate: existingStartDate ?? '',
      };

      return offeringObj;
    }

    const tenantOfferingList =
      (tenantOfferingQuery.data
        ?.rows as IAdaptedTenantMembershipOfferingTableRow[]) || [];

    const offeringToPreselect = tenantOfferingList.find(
      (item) => !!item.isRecommended
    );

    if (!offeringToPreselect) {
      return offeringObj;
    }

    const clientType = getClientTypeFromClient(
      editClientData as IClientGroup | IClientIndividual
    );

    if (
      clientType === ClientType.GROUP_MEMBER ||
      clientType === ClientType.DEPENDENT
    ) {
      const isParentPresent =
        parentClient?.clientId === editClientData?.relationship?.groupId ||
        editClientData?.relationship?.primaryId;

      if (isParentPresent) {
        offeringObj = {
          tenantOfferingId: offeringToPreselect.tenantOfferingId,
          membershipStartDate: getBenefitStartDate(
            parentClient?.membership?.startDate ?? ''
          ),
        };
      }
    }

    if (clientType === ClientType.PRIMARY || clientType === ClientType.GROUP) {
      offeringObj = {
        tenantOfferingId: offeringToPreselect.tenantOfferingId,
        membershipStartDate: getBenefitStartDate(),
      };
    }

    return offeringObj;
  }, [parentClient, editClientData, tenantOfferingQuery.data]);

  const getActionButtonLabel = () => {
    if (showMembershipReadOnlyView) return 'Next';
    return 'Save';
  };

  // Save button should be enabled in 'Approved' state too for 'Change Membership' mode
  const isSaveEnabled =
    showMembershipReadOnlyView ||
    (membership && !!startDates[membership.tenantOfferingId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent className="modal-content modal-content--fixed-height">
          <MembershipDetail clientData={editClientData} />
          <Divider sx={{ marginY: 2 }} />
          {showMembershipReadOnlyView ? (
            <MembershipCard data={clientEnrollmentQuery.data!} />
          ) : (
            <FormMembershipInput
              offerings={tenantOfferingQuery.data?.rows || []}
              startDate={offeringData.membershipStartDate!}
              tenantOfferingId={offeringData.tenantOfferingId!}
            />
          )}
        </DialogContent>
        <ModalFooter
          actionButtonLabel={getActionButtonLabel()}
          cancelButtonType="cancel"
          isCancelRequired={false}
          // isCancelRequired={!!showModalCloseButtonFromStore}
          isDisabled={!isSaveEnabled}
          isLoading={isLoading}
          onCancelButtonClick={handleCancelButtonClick}
        />
      </form>
    </FormProvider>
  );
};

export default Membership;
