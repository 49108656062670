import {
  Alert,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import commonConstants from 'constants/common';
import { OfferingType } from 'enums/client-management';
import { TenantCode } from 'enums/tenant-management/tenant';
import { useCheckUserIsClient } from 'hooks/useCheckUserIsClient';
import { useGetSupportContact } from 'hooks/useGetSupportContact';
import { IClientEnrollment } from 'interfaces/client-management/enrollment';
import { useTenantOfferingQuery } from 'services/tenant-management/tenant/tenantMembershipOffering';
import { selectAuthTenantAssociation } from 'stores/auth';
import { selectEditClientData } from 'stores/client-management';
import { useAppSelector } from 'stores/hooks';
import { getClientSpecificTenantId } from 'utils/client-management';

interface IProps {
  data: IClientEnrollment;
}
const MembershipCard = ({ data }: IProps) => {
  const authTenantAssociationData = useAppSelector(selectAuthTenantAssociation);

  const editClientData = useAppSelector(selectEditClientData);
  const clientId = editClientData?.clientId;
  const locationSpecificTenantName = authTenantAssociationData?.businessName;
  const clientSpecificTenantName = editClientData?.activeTenant?.name;
  const clientEnrollmentId = editClientData?.clientEnrollmentId;
  const tenantNameToDisplay =
    clientSpecificTenantName || locationSpecificTenantName;

  const activeTenantId = getClientSpecificTenantId(editClientData);

  const isUserClient = useCheckUserIsClient();
  const supportContactEmail = useGetSupportContact();

  const tenantOfferingQuery = useTenantOfferingQuery(
    activeTenantId!,
    {
      limit: commonConstants.PAGINATION.MAXIMUM_LIMIT,
      offset: commonConstants.PAGINATION.MINIMUM_OFFSET,
      clientId,
      type: OfferingType.MEMBERSHIP,
      clientEnrollmentId,
    },
    {
      enabled: !!(clientId && activeTenantId && clientEnrollmentId),
    }
  );
  const offering = tenantOfferingQuery?.data?.rows.find(
    (e) => e.tenantOfferingId === data.offerings.id
  );
  const isCodeVisible = (code: string) => {
    if (code === TenantCode.DPC) return true;
    return false;
  };
  const codeDescription = (code: string) => {
    if (code === TenantCode.DPC) return 'Direct Primary Care';
    return '';
  };
  const getTenantName = (name: string, code: string) => {
    if (code === TenantCode.BMTCARE) return 'BMT Care';
    return name;
  };

  if (!data?.offerings?.id && isUserClient) {
    return (
      <Alert
        severity="info"
        sx={{
          textAlign: 'left',
        }}
      >
        No membership selected. If you would like to add/switch membership,
        please reach out to our support at &nbsp;
        <Typography
          component="a"
          display="inline"
          href={`mailto:${supportContactEmail}`}
          variant="body2"
        >
          {supportContactEmail}.
        </Typography>
      </Alert>
    );
  }

  if (!data?.offerings?.id && !isUserClient) {
    return <Typography variant="body2">No membership selected.</Typography>;
  }

  return (
    <>
      {isUserClient && (
        <Alert
          severity="info"
          sx={{
            textAlign: 'left',
            mb: 3,
          }}
        >
          If you would like to switch membership, please reach out to our
          support at &nbsp;
          <Typography
            component="a"
            display="inline"
            href={`mailto:${supportContactEmail}`}
            variant="body2"
          >
            {supportContactEmail}.
          </Typography>
        </Alert>
      )}

      <Grid container>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              {isCodeVisible(offering?.enrollmentCode || '') && (
                <Typography variant="caption">
                  {codeDescription(offering?.enrollmentCode || '')}
                </Typography>
              )}
              <Typography
                fontWeight={(theme) => theme.typography.fontWeightMedium}
                variant="body1"
              >
                {getTenantName(
                  tenantNameToDisplay || '',
                  offering?.enrollmentCode || ''
                )}
              </Typography>
              <Divider />
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                paddingTop={2}
              >
                <Grid item xs={8}>
                  <Typography
                    display="block"
                    fontWeight={(theme) => theme.typography.fontWeightMedium}
                    variant="body1"
                  >
                    {data?.offerings?.name}
                  </Typography>
                  <Box display="flex">
                    <Box>
                      <Typography
                        display="block"
                        lineHeight={0.8}
                        margin={0}
                        variant="caption"
                      >
                        Benefit Start Date
                      </Typography>
                      <Typography display="block" variant="caption">
                        {data.startDate}
                      </Typography>
                    </Box>
                    {data.endDate && (
                      <Box ml={4}>
                        <Typography
                          display="block"
                          lineHeight={0.8}
                          margin={0}
                          variant="caption"
                        >
                          Benefit End Date
                        </Typography>
                        <Typography display="block" variant="caption">
                          {data.endDate}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Grid item textAlign="right" xs={4}>
                  <Typography margin={0} variant="body2">
                    Price
                  </Typography>
                  <Typography
                    fontWeight={(theme) => theme.typography.fontWeightMedium}
                    margin={0}
                  >
                    {' '}
                    {data?.offerings?.formattedTotalCost}
                  </Typography>
                  <Typography
                    display="block"
                    lineHeight={0.8}
                    margin={0}
                    variant="caption"
                  >
                    {data?.offerings?.billingType}
                  </Typography>
                  <Typography display="block" variant="caption">
                    {data?.offerings?.billingInterval}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default MembershipCard;
