import {
  BillingCode,
  CaseCode,
  ClientAgreementSettingOptions,
  EnrollmentCode,
  MiscellaneousCode,
  NotificationCode,
  TenantAffiliateSettingStatus,
  TenantSettingCodes,
  TenantSettingStatus,
  TenantSettingsType,
  UsersCode,
} from 'enums/tenant-management/tenant';
import { ITenantSetting } from 'interfaces/tenant-management/tenant';
import { RootState, store } from 'stores';

// TODO: Need to refactor the checks as they seem to have redundant code.
// We can make a single function for a particular category (kind of what's done for 'billings' with 'checkIsBillingSettingEnabled')

export const checkIsAgreementSettingEnabled = (
  tenantSettings: ITenantSetting | null
): { isEnabled: boolean; value?: ClientAgreementSettingOptions } => {
  if (!tenantSettings?.miscellaneous?.length) return { isEnabled: false };

  const miscellaneous = tenantSettings?.miscellaneous?.find(
    (e) =>
      e.code.toUpperCase() === TenantSettingCodes.miscellaneous.CLIENT_AGREEMENT
  );

  if (!miscellaneous?.status) return { isEnabled: false };

  const isEnabled = [
    ClientAgreementSettingOptions.BASIC,
    ClientAgreementSettingOptions.DOCU_SIGN,
  ].includes(miscellaneous.status as ClientAgreementSettingOptions);

  return {
    isEnabled,
    value: miscellaneous?.status as ClientAgreementSettingOptions,
  };
};

export const checkIsApproveMembershipEnabled = (
  tenantSettings: ITenantSetting | null
) => {
  if (!tenantSettings?.enrollment?.length) return false;
  const enrollment = tenantSettings?.enrollment?.find(
    (e) => e.code === TenantSettingCodes.enrollment.APPROVE_MEMBERSHIP
  );
  const isEnabled = enrollment?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

export const checkIsSponsorIDEnabled = (
  tenantSettings: ITenantSetting | null
) => {
  if (!tenantSettings?.enrollment?.length) return false;
  const enrollment = tenantSettings?.enrollment?.find(
    (e) => e.code === TenantSettingCodes.enrollment.SPONSOR_ID
  );
  const isEnabled = enrollment?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

// Check is setting enabled for those under billings ('Card', 'Banking', 'Cash', etc)
export const checkIsBillingSettingEnabled = (billingCodeKey: BillingCode) => {
  const rootState: RootState = store.getState();
  const tenantSettings = rootState.auth.settings;

  if (!tenantSettings?.billings?.length) return false;
  const setting = tenantSettings?.billings?.find(
    (e) => e.code?.toUpperCase() === TenantSettingCodes.billings[billingCodeKey]
  );
  const isEnabled = setting?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

export const checkIsEnrollmentSettingEnabled = (
  enrollmentCodeKey: EnrollmentCode
) => {
  const rootState: RootState = store.getState();
  const tenantSettings = rootState.auth.settings;

  if (!tenantSettings?.enrollment?.length) return false;
  const setting = tenantSettings?.enrollment?.find(
    (e) =>
      e.code?.toUpperCase() === TenantSettingCodes.enrollment[enrollmentCodeKey]
  );
  const isEnabled = setting?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

export const checkIsCasesSettingEnabled = (casesCodeKey: CaseCode) => {
  const rootState: RootState = store.getState();
  const tenantSettings = rootState.auth.settings;

  if (!tenantSettings?.cases?.length) return false;
  const setting = tenantSettings?.cases?.find(
    (e) => e.code?.toUpperCase() === TenantSettingCodes.case[casesCodeKey]
  );
  const isEnabled = setting?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

export const checkCaseOptionType = (casesCodeKey: CaseCode) => {
  const rootState: RootState = store.getState();
  const tenantSettings = rootState.auth.settings;

  const setting = tenantSettings?.cases?.find(
    (e) => e.code?.toUpperCase() === TenantSettingCodes.case[casesCodeKey]
  );

  return setting?.status;
};

export const checkIsNotificationSettingEnabled = (
  casesCodeKey: NotificationCode
) => {
  const rootState: RootState = store.getState();
  const tenantSettings = rootState.auth.settings;

  if (!tenantSettings?.notification?.length) return false;
  const setting = tenantSettings?.notification?.find(
    (e) =>
      e.code?.toUpperCase() === TenantSettingCodes.notification[casesCodeKey]
  );
  const isEnabled = setting?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

export const checkIsMiscellaneousSettingEnabled = (
  casesCodeKey: MiscellaneousCode
) => {
  const rootState: RootState = store.getState();
  const tenantSettings = rootState.auth.settings;

  if (!tenantSettings?.miscellaneous?.length) return false;
  const setting = tenantSettings?.miscellaneous?.find(
    (e) =>
      e.code?.toUpperCase() === TenantSettingCodes.miscellaneous[casesCodeKey]
  );
  const isEnabled = setting?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

export const getMiscellaneousTenantSetting = (code: MiscellaneousCode) => {
  const rootState: RootState = store.getState();
  const tenantSettings = rootState.auth.settings;

  const setting = tenantSettings?.miscellaneous?.find(
    (item) => item.code === code
  );
  if (!setting) return null;

  if (setting.type === TenantSettingsType.MULTISELECT) {
    return setting.status.split(',').map((item) => item.trim());
  }

  return setting.status;
};

export const checkIsUsersSettingEnabled = (
  code: UsersCode,
  { tenantSettings }: { tenantSettings: ITenantSetting | null }
) => {
  if (!tenantSettings?.users?.length) return false;
  const setting = tenantSettings?.users?.find(
    (e) => e.code?.toUpperCase() === TenantSettingCodes.users[code]
  );
  const isEnabled = setting?.status === TenantSettingStatus.ENABLED;
  return isEnabled;
};

export const checkIsDocuSignSettingEnabled = (
  tenantSettings: ITenantSetting | null
) => {
  const { value } = checkIsAgreementSettingEnabled(tenantSettings);
  return value === ClientAgreementSettingOptions.DOCU_SIGN;
};

export const checkEnrollmentAffiliateVisibleTenantSetting = (
  tenantSettings: ITenantSetting | null
) => {
  const status = tenantSettings?.enrollment?.find(
    (item) => item.code === EnrollmentCode.AFFILIATE_VISIBILITY
  )?.status;

  return [
    TenantAffiliateSettingStatus.Both,
    TenantAffiliateSettingStatus.Portal,
  ].includes(status as TenantAffiliateSettingStatus);
};
