import { useQuery } from 'react-query';

import * as tenantNetworkAPI from 'api/network-configs';
import { IFilter } from 'interfaces/common';

export const tenantNetworksKeys = {
  all: ['network-configs'] as const,
  lists: () => [...tenantNetworksKeys.all, 'list'] as const,
  list: (filters: IFilter & { tenantId: string }) =>
    [...tenantNetworksKeys.lists(), { filters }] as const,
  details: () => [...tenantNetworksKeys.all, 'detail'] as const,
  detail: (code: string) => [...tenantNetworksKeys.details(), code] as const,
};

export const useTenantNetworkQuery = (
  filters: IFilter & { tenantId: string },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantNetworksKeys.list(filters),
    () => tenantNetworkAPI.getTenantNetworks(filters),
    { enabled }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useNetworkQuery = (
  data: { code?: string; tenantId: string; networkId?: string },
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantNetworksKeys.detail(data?.code || data?.networkId||data.tenantId),
    () => tenantNetworkAPI.getNetworkByParams(data),
    { enabled }
  );

  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};
