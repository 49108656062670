import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  IconButton,
  Popover as MuiPopover,
  PopoverOrigin,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

interface Props {
  buttonIcon?: React.ReactNode;
  children: React.ReactNode;
  isIconButton?: boolean;
  sx?: SxProps;
  buttonSx?: SxProps<Theme>;
  buttonText?: string | React.ReactNode;
  open?: boolean;
  setOpen?: any;
  buttonSize?: 'small' | 'medium';
  closeHandler?: VoidFunction;
  onPopoverStateChange?: (isOpen: boolean) => void;
  closeOnMouseLeave?: boolean;
  buttonVariant?: 'outlined' | 'contained';
  datacy?: string;
  triggerElement?: React.ReactNode;
  anchorOrigin?: PopoverOrigin;
}

const Popover = ({
  children,
  isIconButton,
  buttonIcon,
  sx,
  buttonSx,
  buttonText,
  open,
  setOpen,
  closeHandler,
  onPopoverStateChange,
  closeOnMouseLeave,
  buttonSize,
  buttonVariant,
  datacy,
  triggerElement,
  anchorOrigin,
  ...other
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const btnRef = useRef<null | HTMLButtonElement>(null);

  const handleClose = () => {
    setAnchorEl(null);

    if (closeHandler) {
      closeHandler();
    }
  };

  const handleMouseLeave = () => {
    if (closeOnMouseLeave) {
      if (open) return;
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      btnRef?.current?.click();
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (setOpen) {
      setOpen(true);
    }
    if (onPopoverStateChange) {
      onPopoverStateChange(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);

  const id = isOpen ? 'simple-popover' : undefined;

  const theme = useTheme();

  const ChildComponent = () =>
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...child.props, handleClose });
      }
      return child;
    });

  return (
    <>
      {isIconButton && !triggerElement && (
        <IconButton
          aria-label="popover-trigger-button"
          data-cy={`${datacy || 'action'}-popover`}
          onClick={handleClick}
          ref={btnRef}
          size={buttonSize}
          sx={{ ...buttonSx }}
        >
          {buttonIcon}
        </IconButton>
      )}
      {!isIconButton && !triggerElement && (
        <Button
          onClick={handleClick}
          ref={btnRef}
          size="medium"
          startIcon={buttonIcon}
          sx={{ ...buttonSx }}
          variant={buttonVariant}
        >
          {buttonText}
        </Button>
      )}

      {triggerElement && (
        <Box
          bgcolor="transparent"
          border="none"
          component="button"
          onClick={handleClick}
          ref={btnRef}
          sx={{ cursor: 'pointer', width: '100%', height: '100%' }}
        >
          {triggerElement}
        </Box>
      )}

      <MuiPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: anchorOrigin?.vertical || 'bottom',
          horizontal: anchorOrigin?.horizontal || 'right',
        }}
        disableRestoreFocus
        disableScrollLock={false}
        id={id}
        onClose={handleClose}
        onMouseDown={(evt) => {
          evt.stopPropagation();
        }}
        open={isOpen}
        PaperProps={{
          onMouseLeave: handleMouseLeave,
          sx: {
            mt: 0.5,
            p: 2,
            minWidth: 200,
            overflow: 'inherit',
            boxShadow: theme.customShadows.high,
            borderRadius: theme.customBorderRadius.xs,
            ...sx,
          },
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...other}
      >
        {ChildComponent()}
      </MuiPopover>
    </>
  );
};

Popover.defaultProps = {
  sx: null,
  buttonSx: null,
  isIconButton: true,
  buttonText: '',
  open: false,
  setOpen: null,
  closeHandler: undefined,
  buttonSize: 'medium',
  onPopoverStateChange: false,
  closeOnMouseLeave: true,
  buttonIcon: undefined,
  buttonVariant: 'outlined',
  datacy: '',
  triggerElement: '',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export default Popover;
