import { Grid } from '@mui/material';
import {
  FormAddressInput,
  FormCheckbox,
  FormGenderInput,
  FormMaskedDateInput,
  FormMaskedPhoneInput,
  FormTextfield,
} from 'common';
import FormPhoneCountryInput from 'common/form/FormPhoneCountryInput';
import { MiscellaneousCode } from 'enums/tenant-management/tenant';
import { checkIsMiscellaneousSettingEnabled } from 'utils/tenantSetting';

interface IProps {
  isAddAdminUser: boolean;
}

const AddUserForm = ({ isAddAdminUser }: IProps): JSX.Element => {
  const isInternationalAddressEnabled = checkIsMiscellaneousSettingEnabled(
    MiscellaneousCode.INTERNATIONAL_ADDRESS
  );

  return (
    <>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={6}>
          <FormTextfield label="First Name" name="firstName" required />
        </Grid>
        <Grid item xs={6}>
          <FormTextfield label="Last Name" name="lastName" required />
        </Grid>
      </Grid>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={6}>
          <FormMaskedDateInput />
        </Grid>
        <Grid item xs={6}>
          <FormGenderInput />
        </Grid>
      </Grid>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={6}>
          <FormTextfield label="Email" name="email" required />
        </Grid>
        {isInternationalAddressEnabled ? (
          <Grid item xs={6}>
            <FormPhoneCountryInput />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <FormMaskedPhoneInput />
          </Grid>
        )}
      </Grid>

      {!isAddAdminUser && (
        <Grid container mb={2}>
          <Grid item xs={12}>
            <FormCheckbox
              label="Enable Impersonation"
              name="enableImpersonation"
            />
          </Grid>
        </Grid>
      )}

      <FormAddressInput />
    </>
  );
};

export default AddUserForm;
