import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as tenantMembershipOfferingAPI from 'api/tenant-management/tenant/tenantMembershipOffering';
import { IFilter } from 'interfaces/common';
import { IError } from 'interfaces/http';
import {
  IAddTenantMembershipOfferingForm,
  ITenantMembershipOfferingFilter,
  ITenantMembershipOfferingTableRow,
} from 'interfaces/tenant-management/tenant/tenantMembershipOffering';
import { useSnackbar } from 'notistack';
import { RootState, store } from 'stores';
import { adaptOfferingList } from 'utils/tenant-management/tenant';

export const tenantOfferingKeys = {
  all: ['offerings'] as const,
  lists: () => [...tenantOfferingKeys.all, 'list'] as const,
  list: (filters: IFilter) =>
    [...tenantOfferingKeys.lists(), { filters }] as const,
  details: () => [...tenantOfferingKeys.all, 'detail'] as const,
  detail: (id: number | string) =>
    [...tenantOfferingKeys.details(), id] as const,
};

export const useTenantOfferingQuery = (
  tenantId: string,
  filters: ITenantMembershipOfferingFilter,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantOfferingKeys.list(filters),
    () => tenantMembershipOfferingAPI.getTenantOfferings(tenantId, filters),
    {
      enabled,
      select: adaptOfferingList,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useTenantOfferingDetailQuery = (
  tenantId: string,
  tenantOfferingId: string,
  { enabled }: { enabled: boolean }
) => {
  const queryInfo = useQuery(
    tenantOfferingKeys.detail(tenantOfferingId),
    () =>
      tenantMembershipOfferingAPI.getTenantOfferingDetail(
        tenantId,
        tenantOfferingId
      ),
    {
      enabled,
    }
  );
  return {
    ...queryInfo,
    data: queryInfo.data?.data,
  };
};

export const useAddTenantOfferingMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const offeringStore = rootState.tenantMembershipOffering;

  return useMutation(
    ({
      tenantId,
      data,
    }: {
      tenantId: string;
      data: IAddTenantMembershipOfferingForm;
    }) => tenantMembershipOfferingAPI.addTenantOffering(tenantId, data),
    {
      onSuccess: (res) => {
        // add the newly created offering to the list
        queryClient.setQueryData(
          tenantOfferingKeys.list({
            ...offeringStore.filters,
            ...offeringStore.sort,
          }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              count: oldData.data.count + 1,
              rows: [res.data, ...oldData.data.rows],
            },
          })
        );

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useEditTenantOfferingMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const rootState: RootState = store.getState();
  const offeringStore = rootState.tenantMembershipOffering;

  return useMutation(
    ({
      tenantId,
      offeringId,
      data,
    }: {
      tenantId: string;
      offeringId: string;
      data: IAddTenantMembershipOfferingForm;
    }) =>
      tenantMembershipOfferingAPI.editTenantOffering(
        tenantId,
        offeringId,
        data
      ),
    {
      onSuccess: (res) => {
        // update the list with the updated offering
        queryClient.setQueryData(
          tenantOfferingKeys.list({
            ...offeringStore.filters,
            ...offeringStore.sort,
          }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              rows: oldData.data.rows.map(
                (item: ITenantMembershipOfferingTableRow) => {
                  if (item.tenantOfferingId !== res.data.tenantOfferingId)
                    return item;
                  return res.data;
                }
              ),
            },
          })
        );

        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};

export const useRemoveTenantOfferingMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const rootState: RootState = store.getState();
  const memberShipStore = rootState.tenantMembershipOffering;
  return useMutation(
    ({
      tenantId,
      tenantOfferingId,
    }: {
      tenantId: string;
      tenantOfferingId: string;
    }) =>
      tenantMembershipOfferingAPI.removeTenantOffering(
        tenantId,
        tenantOfferingId
      ),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          tenantOfferingKeys.list({
            ...memberShipStore.filters,
            ...memberShipStore.sort,
          }),
          (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              count: oldData.data.count - 1,
              rows: oldData.data.rows.filter(
                (item: ITenantMembershipOfferingTableRow) =>
                  item.tenantOfferingId !== res.data.tenantOfferingId
              ),
            },
          })
        );
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
      },
      onError: (err: IError) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      },
    }
  );
};
