import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogContent } from '@mui/material';
import { ModalFooter } from 'common';
import LabelBoxCard from 'common/LabelBoxCard';
import { DocumentType } from 'enums/documents';
import { IAddURIDocumentForm } from 'interfaces/documents';
import { URIDocumentSchema } from 'schema/documents';
import { useAddDocumentMutation } from 'services/documents';
import { getFullName } from 'utils/common';
import { getUserDetailFromToken } from 'utils/jwt';

import DocumentURIForm from './DocumentURIForm';

const defaultValues = {
  name: '',
  path: '',
};
interface Props {
  associationId: string;
  tenantId?: string;
  subType: string;
  handleClose: VoidFunction;
}
const UploadFromURI = ({
  associationId,
  tenantId,
  handleClose,
  subType,
}: Props) => {
  const methods = useForm<IAddURIDocumentForm>({
    resolver: yupResolver(URIDocumentSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const currentUser = getUserDetailFromToken();

  const addFileMutation = useAddDocumentMutation();

  const onSubmit = (info: IAddURIDocumentForm) => {
    let documentPath = info.path;
    const isPathValid =
      documentPath.includes('http') || documentPath.includes('https');

    if (!isPathValid) {
      documentPath = `https://${documentPath}`;
    }
    const selectedFiles = [
      {
        ...info,
        path: documentPath,
        createdBy: getFullName(currentUser?.firstName, currentUser?.lastName),
      },
    ];
    const data = {
      files: selectedFiles,
      type: DocumentType.URI,
      subType,
      associationId,
      ...(tenantId && { tenantId }),
    };

    addFileMutation.mutate(
      { data },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };
  return (
    <Box>
      <LabelBoxCard label="Upload files through url" title="Import from URL" />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box minHeight="50vh" padding={2}>
            <DialogContent
              className="modal-content modal-content--fixed-height scrollbar"
              sx={{ maxHeight: '50vh !important', padding: '0px 8px 0px 0px' }}
            >
              <DocumentURIForm />
            </DialogContent>
          </Box>
          <ModalFooter
            cancelButtonType="back"
            isDisabled={false}
            isLoading={addFileMutation.isLoading}
            onCancelButtonClick={handleClose}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

UploadFromURI.defaultProps = {
  tenantId: undefined,
};
export default UploadFromURI;
