import { useRef } from 'react';

import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

interface IProps {
  link: string;
}

const ShareLink = ({ link }: IProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();

  const onCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard.writeText(link);
      enqueueSnackbar('Copied to clipboard!', {
        variant: 'success',
      });
    }
  };

  return (
    <Box alignItems="center" display="flex">
      <TextField
        className="filled-variant"
        defaultValue={link}
        fullWidth
        id="share-link-input-id"
        InputProps={{
          readOnly: true,
        }}
        inputRef={inputRef}
        size="small"
      />
      <Box pl={1}>
        <Tooltip arrow placement="top" title="Copy to clipboard">
          <IconButton
            aria-label="Copy to clipboard"
            onClick={onCopyClick}
            type="button"
          >
            <FontAwesomeIcon icon={faCopy} size="sm" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ShareLink;
