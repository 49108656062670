import { useFormContext } from 'react-hook-form';

import { TextFieldProps } from '@mui/material';
import { ClientAutocomplete } from 'common';
import UserType from 'enums/User';
import {
  IAdaptedClientGroupTableRow,
  IAdaptedClientIndividualTableRow,
  IAdaptedClientTableRow,
} from 'interfaces/client-management';
import { IInvitee } from 'interfaces/virtual-visit';
import { getFullName } from 'utils/common';

type Props = {
  autoFocusInput?: boolean;
  name: string;
  disabled?: boolean;
  multiple?: boolean;
  userType: UserType;
  showHelperText?: boolean;
  guestData?: IInvitee[];
  clientSelectHandler?: (
    client:
      | IAdaptedClientGroupTableRow
      | IAdaptedClientIndividualTableRow
      | null
  ) => void;
  clientMultiSelectHandler?: (
    client:
      | IAdaptedClientGroupTableRow[]
      | IAdaptedClientIndividualTableRow[]
      | null
  ) => void;
  showPrimaryMembersOnly?: boolean;
  setParentClientName?: boolean;
  textFieldVariant?: 'standard' | 'outlined';
  watchedFieldValue?: string;
  setFieldValue?: (value: IAdaptedClientTableRow | null) => void;
  setMultiFieldValue?: (value: IAdaptedClientTableRow[] | null) => void;
} & TextFieldProps;

const FormClientAutocomplete = ({
  autoFocusInput = true,
  multiple,
  name,
  disabled = false,
  userType,
  showHelperText,
  clientMultiSelectHandler,
  clientSelectHandler,
  showPrimaryMembersOnly = false,
  setParentClientName = false,
  textFieldVariant = 'standard',
  // ----------------------
  // `watchedFieldValue` and `setFieldValue` are handy when the field value set in the react hook form is not a string.
  // For eg: name = 'client' and client: {id: '', name: ''}
  // In such case you'll need to pass these props and set value in the form (i.e. with `setValue`) in the parent component
  // But if your field value is a string. For eg: name = clientId and clientId: '', you don't need to pass these props at all.
  watchedFieldValue,
  setFieldValue,
  setMultiFieldValue,
  guestData,
  // ----------------------
  ...others
}: Props): JSX.Element => {
  const methods = useFormContext();
  const {
    setValue,
    watch,
    formState: { errors },
  } = methods;

  /**
   * clientId: string <- Single select autocomplete
   * clientId: IAdaptedClientTableRow[] <- Multiselect autocomplete
   */
  const clientId = watch(name);

  const fieldValue =
    watchedFieldValue !== undefined ? watchedFieldValue : clientId;

  const setFormFieldValue = (value: IAdaptedClientTableRow | null) => {
    // Use parent prop to set value in the form if present
    if (setFieldValue !== undefined) {
      setFieldValue(value);
      return;
    }
    setValue(name, value?.clientId || '');
  };

  const setMultiFormFieldValue = (value: IAdaptedClientTableRow[] | null) => {
    // Use parent prop to set value in the form if present
    if (setMultiFieldValue !== undefined) {
      setMultiFieldValue(value);
      return;
    }
    // need work here
    setValue(name, '' || '');
  };
  const handleSelectClient = (client: IAdaptedClientTableRow | null) => {
    if (!client) {
      setFormFieldValue(null);
      if (clientSelectHandler !== undefined) {
        clientSelectHandler(null);
      }
    } else {
      setFormFieldValue(client);

      if (setParentClientName) {
        const { businessName, type, clientEnrollmentId } = client;

        const autocompleteClientId = client?.clientId;

        if (type === UserType.GROUP) {
          setValue('groupName', businessName);
          setValue('groupEnrollmentId', clientEnrollmentId);
          setValue('groupId', autocompleteClientId);
        } else {
          setValue(
            'primaryName',
            getFullName(client.firstName, client.lastName, client.middleName)
          );
          setValue('primaryEnrollmentId', clientEnrollmentId);
          setValue('primaryId', autocompleteClientId);
        }
      }

      if (clientSelectHandler !== undefined) {
        clientSelectHandler(client);
      }
    }
  };

  const handleMultiSelectClient = (
    clients: IAdaptedClientTableRow[] | null
  ) => {
    if (!clients) {
      setFormFieldValue(null);
      if (clientSelectHandler !== undefined) {
        clientSelectHandler(null);
      }
    } else {
      setMultiFormFieldValue(clients);
      if (clientMultiSelectHandler !== undefined) {
        clientMultiSelectHandler(clients);
      }
    }
  };

  return (
    <ClientAutocomplete
      autoFocusInput={autoFocusInput}
      clientId={fieldValue || ''}
      clients={clientId} // incase of multiple select clients is used
      disabled={disabled}
      guestData={guestData}
      inputProps={{
        ...others,
        error: !!errors[name],
        helperText: showHelperText
          ? (errors[name]?.message as React.ReactNode)
          : null,
      }}
      multiple={multiple}
      onMultiSelectHandler={handleMultiSelectClient}
      onSelectHandler={handleSelectClient}
      showPrimaryMembersOnly={showPrimaryMembersOnly}
      textFieldVariant={textFieldVariant}
      userType={userType}
    />
  );
};

FormClientAutocomplete.defaultProps = {
  multiple: false,
  showHelperText: false,
  clientSelectHandler: undefined,
  clientMultiSelectHandler: undefined,
  disabled: false,
  showPrimaryMembersOnly: false,
  setParentClientName: false,
  textFieldVariant: 'standard',
  watchedFieldValue: undefined,
  setFieldValue: undefined,
  setMultiFieldValue: undefined,
  autoFocusInput: true,
  guestData: [],
};

export default FormClientAutocomplete;
