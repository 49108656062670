import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  faFile,
  faHandHoldingDollar,
  faHospital,
  faIdCard,
  faMedkit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tab, Tabs } from '@mui/material';
import { Modal, TabPanel } from 'common';
import ModalKey from 'enums/ModalKey';
import { EnrollmentCode } from 'enums/tenant-management/tenant';
import MembershipIdCard from 'features/dashboard/membership-id-card';
import { useClientDetailQuery } from 'services/client-management';
import {
  clearEditClientData,
  clearParentClient,
  setEditClientData,
} from 'stores/client-management';
import { useAppDispatch } from 'stores/hooks';
import { getAllyPropsForTabs } from 'utils/mui';
import { checkIsEnrollmentSettingEnabled } from 'utils/tenantSetting';

import Billing from './billing';
import FileList from './files';
import Information from './information';
import Membership from './membership';

const AddClientModal = (): JSX.Element => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const tabSetRef = useRef(false);

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  /* Whether it is edit or update */
  const isMembershipAction = searchParams.get('from');
  const clientIdFromParams = searchParams.get('id');
  const clientEnrollmentIdFromParams = searchParams.get('clientEnrollmentId');
  const isEditMode = !!clientIdFromParams;

  const hideClientType = searchParams.get('hideClientType');
  let clientTypeFromParams = searchParams.get('clientType');
  clientTypeFromParams = clientTypeFromParams?.replace('_', ' ') || '';

  const clientDetailQuery = useClientDetailQuery(
    clientIdFromParams!,
    {
      enabled: !!clientIdFromParams && !!clientEnrollmentIdFromParams,
    },
    { clientEnrollmentId: clientEnrollmentIdFromParams! }
  );

  const isShowMembershipIDCard = checkIsEnrollmentSettingEnabled(
    EnrollmentCode.MEMBERSHIP_ID_CARD
  );

  useEffect(() => {
    if (clientIdFromParams && clientDetailQuery.data) {
      dispatch(setEditClientData(clientDetailQuery.data));
      if (isMembershipAction && !tabSetRef.current) {
        tabSetRef.current = true;
        setActiveTab(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetailQuery, clientIdFromParams, dispatch, isMembershipAction]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (!isEditMode) return;
    setActiveTab(newValue);
  };

  const handleClose = () => {
    tabSetRef.current = false;
    // need to pop up miltiple time since the prevvious modal is not closing
    const popMultipleRoute =
      isMembershipAction === ModalKey.RESTART_CLIENT_ENROLLMENT;
    navigate(popMultipleRoute ? -2 : -1);
  };

  const handleCancelButtonClick = () => {
    handleClose();
  };

  const getTitle = () => {
    let title = '';
    if (isMembershipAction === ModalKey.RESTART_CLIENT_ENROLLMENT)
      title = 'Update';
    else if (isMembershipAction === ModalKey.CHANGE_CLIENT_ENROLLMENT)
      title = 'Change';
    else if (isEditMode) title = 'Edit';
    else title = 'Add';

    if (hideClientType && clientTypeFromParams) {
      title = `${title} ${clientTypeFromParams}`;
    } else {
      title = `${title} Client`;
    }

    return title;
  };

  /**
   * Reset the store on modal close
   */
  useEffect(
    () => () => {
      dispatch(clearEditClientData());
      dispatch(clearParentClient());
    },
    [dispatch]
  );

  return (
    <Modal
      handleClose={handleClose}
      icon={isEditMode ? 'faUserPen' : 'faUserPlus'}
      maxWidth="md"
      open
      title={getTitle()}
    >
      <>
        <Tabs
          aria-label="basic tabs example"
          className="modal-tab"
          onChange={handleTabChange}
          value={activeTab}
        >
          <Tab
            data-cy="add-client-information"
            icon={<FontAwesomeIcon icon={faHospital} />}
            label="Information"
            {...getAllyPropsForTabs(0)}
          />
          <Tab
            data-cy="add-client-membership"
            icon={<FontAwesomeIcon icon={faMedkit} />}
            label="Membership"
            {...getAllyPropsForTabs(1)}
          />
          <Tab
            data-cy="add-client-billing"
            icon={<FontAwesomeIcon icon={faHandHoldingDollar} />}
            label="Billing"
            {...getAllyPropsForTabs(2)}
          />
          <Tab
            data-cy="add-client-files"
            icon={<FontAwesomeIcon icon={faFile} />}
            label="Files"
            {...getAllyPropsForTabs(3)}
          />
          {isShowMembershipIDCard && (
            <Tab
              data-cy="add-client-id"
              icon={<FontAwesomeIcon icon={faIdCard} />}
              label="ID"
              {...getAllyPropsForTabs(4)}
            />
          )}
        </Tabs>
        <>
          <TabPanel
            data-cy="add-client-information"
            index={0}
            value={activeTab}
          >
            <Information
              handleCancelButtonClick={handleCancelButtonClick}
              isEditMode={!!clientIdFromParams}
              loading={clientDetailQuery.isLoading}
              setActiveTab={setActiveTab}
            />
          </TabPanel>
          <TabPanel data-cy="add-client-membership" index={1} value={activeTab}>
            <Membership
              handleCancelButtonClick={handleCancelButtonClick}
              setActiveTab={setActiveTab}
            />
          </TabPanel>
          <TabPanel data-cy="add-client-billings" index={2} value={activeTab}>
            <Billing
              handleCancelButtonClick={handleCancelButtonClick}
              setActiveTab={setActiveTab}
            />
          </TabPanel>
          <TabPanel data-cy="add-client-files" index={3} value={activeTab}>
            <FileList />
          </TabPanel>
          <TabPanel data-cy="add-client-id" index={4} value={activeTab}>
            <Box p={4}>
              <MembershipIdCard clientId={clientIdFromParams || ''} />
            </Box>
          </TabPanel>
        </>
      </>
    </Modal>
  );
};

export default AddClientModal;
