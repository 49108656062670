/* eslint-disable no-useless-escape */
const regex = {
  ZIP: /\b\d{5}\b/g,
  PHONE: /^[0-9]{10}$/,
  NPI: /^[0-9]{10}$/,
  EIN: /^[0-9]{9}$/,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  MENTION: /@\[([^\]]+)\]\([^\)]+\)/g,
  removeBrackets: /[\])}[{("]/g, // used to remove [{()}] and "
  ONLY_DIGITS: /^[0-9]+$/,
  LINK: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  DECIMAL_WITH_TWO_POINT: /^\d*\.?\d{0,2}$/,
  HEX_CODE: /^#([0-9a-f]{3}){1,2}$/i, // supports 3 digit code as well (#333)
  ONLY4Digit: /^\d{4}$/,
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export default regex;
