import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CometChat } from '@cometchat-pro/chat';
import { faComments, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ICometChatMediaFile } from 'interfaces/chat';
import { useUsersQuery } from 'services/tenant-management/users';
import { CometChatService } from 'services/virtual-visit/comet-chat/comet-chat-service';
import { selectAuthTenantData } from 'stores/auth';
import {
  addMessage,
  selectAcceptedFriends,
  selectIsWindowOpen,
  selectSelectedFriend,
  selectUnreadCount,
  setAcceptedFriends,
  setAddUnreadCount,
  setFriendRequest,
  setFriends,
  setIsLoading,
  setIsWindowOpen,
  setLastMessage,
  setUnreadCount,
} from 'stores/chat';
import { useAppSelector } from 'stores/hooks';
import {
  selectUserAdvancedSearch,
  selectUserFilter,
  selectUserSort,
} from 'stores/tenant-management/user';
import { onPressFriend } from 'utils/chat';
import { getCometChatUID, getFullName } from 'utils/common';
import { combineFilters } from 'utils/misc';
import { getCurrentTime } from 'utils/moment';

import ChatContent from './ChatContent';
import ChatList from './ChatList';

const timeStamp = getCurrentTime();

const ChatComponent = () => {
  const theme = useTheme();
  const authTenantData = useAppSelector(selectAuthTenantData);
  const advancedSearch = useAppSelector(selectUserAdvancedSearch);
  const sort = useAppSelector(selectUserSort);

  const acceptedFriends = useAppSelector(selectAcceptedFriends);
  const selectedUser = useAppSelector(selectSelectedFriend);
  const isWindowOpen = useAppSelector(selectIsWindowOpen);
  const unreadMeassageCount = useAppSelector(selectUnreadCount);
  const dispatch = useDispatch();

  const filters = useAppSelector(selectUserFilter);
  const combinedFilters = combineFilters(
    { ...filters, ...sort },
    advancedSearch
  );

  const groupCode = authTenantData?.tenantAssociation?.code ?? '';

  const { data } = useUsersQuery(
    {
      ...combinedFilters,
      tenantId: authTenantData?.tenantAssociation.tenantId,
    },
    {
      fetchAdminUsers: true,
    }
  );

  const getUnreadCount = (messages: any) => {
    let sum = 0;
    if (messages !== null) {
      const keys = Object.keys(messages);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        const value = messages[key];
        sum += value;
      }
    }
    return sum;
  };

  useEffect(() => {
    (async () => {
      if (authTenantData?.userId && data?.rows) {
        const loginUser = await CometChatService.login(
          getCometChatUID(groupCode, authTenantData?.userId)
        );
        const getLoggedInUser = await CometChat.getLoggedinUser();
        if (!loginUser || !getLoggedInUser) {
          CometChatService.createUser(
            getCometChatUID(groupCode, authTenantData?.userId) ?? '',
            getFullName(
              authTenantData.firstName,
              authTenantData.middleName,
              authTenantData.lastName
            )
          ).finally(async () => {
            await CometChatService.login(
              getCometChatUID(groupCode, authTenantData?.userId)
            );

            dispatch(setFriends({ data: data?.rows, groupCode }));
            dispatch(setIsLoading(false));
          });
        } else {
          const res = await CometChatService.fetchConversationList();
          dispatch(setAcceptedFriends(JSON.stringify(res)));
          if (res?.length > 0) {
            onPressFriend(
              res[0]?.conversationWith?.uid,
              {
                ...res[0],
                userId: res[0]?.conversationWith?.uid,
                uid: res[0]?.conversationWith?.uid,
                name: res[0]?.conversationWith?.name ?? '',
              },
              0,
              authTenantData.userId,
              groupCode
            );
          }
          dispatch(setFriends({ data: data?.rows, groupCode }));
          dispatch(setIsLoading(false));
        }
        const messagesRequest =
          await CometChat.getUnreadMessageCountForAllUsers();
        dispatch(setUnreadCount(getUnreadCount(messagesRequest)));
      }
    })();
  }, [authTenantData, data?.rows, dispatch, groupCode]);

  const filterFriends = useCallback(
    (textMessage: any) => {
      const ff = acceptedFriends.map((item: any) =>
        item?.userId?.toLowerCase()
      );

      if (!ff.includes(textMessage.sender.uid?.toLowerCase())) {
        dispatch(setFriendRequest({ groupCode, friend: textMessage.sender }));
      }
    },
    [acceptedFriends, dispatch, groupCode]
  );

  useEffect(() => {
    CometChatService.listenForMessage({
      onTextMessageReceived: (textMessage: CometChat.TextMessage | any) => {
        filterFriends(textMessage);

        if (isWindowOpen) {
          CometChat.markAsRead(textMessage);
        } else {
          dispatch(setAddUnreadCount());
          dispatch(setLastMessage(textMessage));
        }

        if (
          textMessage?.sender?.uid?.toLowerCase() ===
          selectedUser?.uid?.toLowerCase()
        ) {
          const messageText =
            CometChatService.extractTextFromMessage(textMessage);
          const sender = CometChatService.extractSenderFromMessage(textMessage);
          dispatch(
            addMessage({
              payload: {
                text: messageText,
                isSentByMe: false,
                isTextMessage: true,
                time: timeStamp,
                sender,
              },
            })
          );
        }
      },
      onMediaReceived: (mediaMessage: ICometChatMediaFile) => {
        if (isWindowOpen) {
          CometChat.markAsRead(mediaMessage);
        } else {
          dispatch(setAddUnreadCount());
          dispatch(setLastMessage(mediaMessage));
        }
        if (mediaMessage?.sender?.uid === selectedUser?.uid) {
          fetch(mediaMessage?.data?.url)
            .then((response) => response.text())
            .then((file) => {
              const mediaData = JSON.parse(file);
              dispatch(
                addMessage({
                  payload: {
                    text: mediaData.data,
                    isSentByMe: false,
                    isTextMessage: false,
                    time: timeStamp,
                    sender: mediaMessage?.sender?.name,
                    title: 'Document attached',
                    type: mediaMessage?.data?.attachments[0]?.extension,
                    fileName: mediaData.name,
                  },
                })
              );
            })
            .catch(() => {});
        }
      },
      listenerID: selectedUser?.userId ?? '',
    });
    return () => CometChat.removeMessageListener(selectedUser?.userId ?? '');
  }, [
    dispatch,
    filterFriends,
    isWindowOpen,
    selectedUser,
    selectedUser?.uid,
    selectedUser?.userId,
  ]);

  return (
    <>
      {isWindowOpen && (
        <Box
          bgcolor={theme.palette.common.white}
          bottom={0}
          boxShadow="0px 0px 6px #00000029"
          display="flex"
          flex={1}
          height={500}
          position="fixed"
          right="10%"
          width={700}
        >
          <Box overflow="hidden" width={320}>
            <Stack alignItems="center" direction="row" gap={1} p={2}>
              <FontAwesomeIcon
                color={theme.palette.primary.main}
                icon={faEnvelope}
              />
              <Typography pt={0.5}>Message</Typography>
            </Stack>
            <Divider />
            <ChatList />
          </Box>
          <Divider orientation="vertical" />
          <ChatContent />
        </Box>
      )}
      <IconButton
        className="snackbar-close-button"
        color="inherit"
        onClick={() => {
          dispatch(setUnreadCount(0));
          dispatch(setIsWindowOpen(!isWindowOpen));
        }}
        size="1x"
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 10,
          backgroundColor: 'white',
          padding: 2,
          boxShadow: theme.customShadows.medium,
        }}
      >
        <Badge
          badgeContent={unreadMeassageCount}
          color="error"
          sx={{ position: 'absolute', top: 10, right: 10 }}
        />
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          icon={faComments}
          size="sm"
        />
      </IconButton>
    </>
  );
};

export default ChatComponent;
